import React, { ReactNode, useCallback, useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Container,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import ReviewModal from "./ReviewModal";
import { getListOfTasksNotReviewed } from "@gogig-in/gogig-clients";

export interface Task {
  taskId: string;
  updatedAt: string;
  questionaireResponse: string;
  [key: string]: string | number | Record<string, any>;
}

const isQuestionObject = (obj: any): obj is { question: string } => {
  return typeof obj === "object" && "question" in obj;
};

const TaskReview: React.FC = () => {
  const [taskData, setTaskData] = useState<Task[]>([]);
  const { gigId } = useParams<{ gigId?: string }>();
  const [columnNames, setColumnNames] = useState<string[]>();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);

  const renderAnswer = (answer: any): ReactNode => {
    if (typeof answer === "object" && answer !== null) {
      if ("answer" in answer) {
        if (answer.type === "image") {
          return (
            <img
              src={String(answer.answer)}
              alt="uploaded"
              style={{ width: "100px", height: "100px" }}
            />
          );
        }
        if (answer.type === "text" || answer.type === "number") {
          const stringValue = String(answer.answer).trim();
          return stringValue !== "" ? <div>{stringValue}</div> : null;
        }
        if (Object.keys(answer.answer).length > 0) {
          return (
            <div>
              {Object.entries(answer.answer).map(([key, value]) => (
                <React.Fragment key={key}>
                  {value !== null &&
                    value !== "" && ( // Check if value is not null and not an empty string
                      <div>
                        <Typography variant="h6">{key}:</Typography>{" "}
                        {renderAnswer(value)}
                      </div>
                    )}
                </React.Fragment>
              ))}
            </div>
          );
        }
      } else if (Object.keys(answer).length > 0) {
        return (
          <div>
            {Object.entries(answer.answer).map(([key, value]) => (
              <React.Fragment key={key}>
                {value !== null && value !== "" && (
                  <div>
                    <Typography variant="h6">{key}:</Typography>{" "}
                    {renderAnswer(value)}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        );
      }
    } else {
      const stringValue = String(answer).trim();
      return stringValue !== "" ? <div>{stringValue}</div> : null;
    }
    return null;
  };

  const renderHeaderLabel = (question: string): string => {
    if (
      question.toLowerCase().includes("society") &&
      question.toLowerCase().includes("name")
    ) {
      return "Society Name";
    } else if (
      question.toLowerCase().includes("society") &&
      question.toLowerCase().includes("address")
    ) {
      return "Address of the Society";
    } else if (question.toLowerCase().includes("upload")) {
      return "Image";
    }
    return question;
  };

  const fetchData = useCallback(async () => {
    try {
      if (!gigId) {
        return;
      }
      const response: any = await getListOfTasksNotReviewed(gigId);

      let responseQuestionaireData: Task[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const object: Task = {
          ...JSON.parse(response.data[i].questionaireResponse),
          taskId: response.data[i].taskId,
          updatedAt: response.data[i].updatedAt,
        };
        responseQuestionaireData.push(object);
      }

      const firstData = responseQuestionaireData[0];
      const questions = Object.keys(firstData).filter(
        (key) => key !== "taskId" && key !== "updatedAt"
      );
      setColumnNames(questions);
      setTaskData(responseQuestionaireData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [gigId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleReviewClick = (task: Task) => {
    setSelectedTask(task);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedTask(null);
    fetchData();
  };

  return (
    <Container sx={{ margin: "20px auto" }}>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              {columnNames?.map((columnName, index) => (
                <TableCell key={index}>
                  <strong>
                    {taskData.length > 0 &&
                      isQuestionObject(taskData[0][columnName]) &&
                      renderHeaderLabel(
                        (taskData[0][columnName] as { question: string })
                          .question
                      )}
                  </strong>
                </TableCell>
              ))}
              <TableCell>
                <strong>Action </strong>
              </TableCell>
            </TableRow>

            {taskData.map((task, rowIndex) => (
              <TableRow key={rowIndex}>
                {columnNames?.map((columnName, cellIndex) => (
                  <TableCell key={cellIndex}>
                    {renderAnswer(task[columnName])}
                  </TableCell>
                ))}
                <TableCell>
                  <Button
                    onClick={() => handleReviewClick(task)}
                    variant="outlined"
                  >
                    Review
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedTask && (
        <ReviewModal
          isOpen={isModalOpen}
          handleClose={handleCloseModal}
          taskData={selectedTask}
        />
      )}
    </Container>
  );
};

export default TaskReview;
