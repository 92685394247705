import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SpotrueApiBaseUrl } from "./baseUrls";
import { AuditRequest } from "../components/vendors/vehicleAuditComponents/types";

export async function getAuditRequestById(
  authHeader: string,
  gogigRequesterId: string,

) {
  const fetchedResponse = await fetchWrapper(
    `${SpotrueApiBaseUrl}/getAuditRequest?gogigReqId=${gogigRequesterId}`,
    {
      method: "GET",
      headers: {
        Authorization: authHeader,
      },
    }
  );

  return fetchedResponse.data as AuditRequest;
}
