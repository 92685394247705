import { userRoles } from "../../types/userRoles";
import { HomeSectionProps } from "./HomeSection";
import { NavigateFunction } from "react-router-dom";

export const getSectionsByRole = (
  role: string | undefined,
  navigate: NavigateFunction
): HomeSectionProps[] => {
  const sections: HomeSectionProps[] = [];

  // Add all sections for admin and operation roles
  if (role === userRoles.admin || role === userRoles.operation) {
    sections.push(
      {
        title: "Manage Gig and Category",
        actions: [
          {
            label: "Review Applications",
            onClick: () => navigate("/admin/review-gig-applications"),
          },
          { label: "Manage Gigs", onClick: () => navigate("/manage-gigs") },
          {
            label: "Create Gig",
            onClick: () => navigate("/create-gig/basic-details"),
          },
          {
            label: "Manage Categories",
            onClick: () => navigate("/category-list"),
          },
        ],
      },
      {
        title: "Manage Users",
        actions: [
          { label: "Manage Users", onClick: () => navigate("/manage-users") },
          {
            label: "Manage Supervisors",
            onClick: () => navigate("/manage-supervisors"),
          },
        ],
      },
      {
        title: "Others",
        actions: [
          {
            label: "Resolve Queries",
            onClick: () => navigate("/admin/queries"),
          },
        ],
      },
      {
        title: "Manage Payment",
        actions: [
          {
            label: "Withdraw List",
            onClick: () => navigate("/admin/review-withdrawal"),
          },
          {
            label: "Upload ICICI sheet",
            onClick: () => navigate("/admin/payment-to-upload"),
          },
        ],
      },
      {
        title: "Manage Audit",
        actions: [
          {
            label: "Manage Audit List",
            onClick: () => navigate("/manage-audit-list"),
            subLabel: "Request list",
          },
          {
            label: "Manage Excel Upload",
            onClick: () => navigate("/audit-excel-upload"),
            subLabel: "Excel Upload",
          },
        ],
      }
    );
  }

  // Add the "Manage Audit" section for both admin/operation and process roles
  if (role === userRoles.processor) {
    sections.push({
      title: "Manage Audit",
      actions: [
        {
          label: "Manage Audit List",
          onClick: () => navigate("/manage-audit-list"),
          subLabel: "Request list",
        },
      ],
    });
  }

  return sections;
};
