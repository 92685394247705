import React, { useEffect } from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  MenuItem,
} from "@mui/material";
import { ProfileData } from "../profile/ProfileValues";
import ProfileMenu from "../header/ProfileMenu";
import { Logo } from "../logo";
import { Close } from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuthContext } from "../../providers/AuthProvider";

interface SideNavigationProps {
  isOpen: boolean;
  onClose: () => void;
  profileData?: ProfileData;
  tourStep?: number | null;
}

const SideNavigation: React.FC<SideNavigationProps> = ({
  isOpen,
  onClose,
  profileData,
  tourStep,
}) => {
  const { isAuthenticated, logout } = useAuthContext();
  const closeMobileMenu = () => {};

  const currentStep = tourStep;

  useEffect(() => {
    if ((currentStep === 1 || currentStep === 3) && !isOpen) {
      onClose();
    }
  }, [currentStep, isOpen, onClose]);

  const handleLogout = () => {
    logout({ returnTo: "/" });
    onClose();
  };

  return (
    <Drawer anchor="left" open={isOpen} onClose={onClose}>
      <List sx={{ width: "250px" }}>
        <Box
          sx={{
            padding: "5px 20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Logo variant="secondary" />
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider sx={{ backgroundColor: "black" }} />
        {isAuthenticated && (
          <>
            <Box className="profile-menu">
              <ProfileMenu
                profileData={profileData}
                closeMobileMenu={closeMobileMenu}
                onClick={onClose}
              />
            </Box>
            <Divider />
            <MenuItem
              onClick={handleLogout}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                "&:hover": {
                  backgroundColor: "#F0F7FF",
                  borderRadius: "10px",
                },
                width: "100%",
              }}
            >
              Logout
              <LogoutIcon sx={{ fontSize: "20px" }} />
            </MenuItem>
          </>
        )}
      </List>
    </Drawer>
  );
};

export default SideNavigation;
