import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SpotrueApiStorageBaseUrl } from "./baseUrls";
export default function deleteFileFromS3(authHeader: string, fileUrl: string) {
    const url: string = `${SpotrueApiStorageBaseUrl}/deleteFileFromS3`;
    return fetchWrapper(url, {
        method: "POST",
        body: { url: fileUrl },
        headers: {
            Authorization: authHeader,
        },
    })
}