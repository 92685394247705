import { useState } from 'react';

export const LocationMapper = () => {

  const [lattitude, setLattitude] = useState();
  const [longitude, setLongitude] = useState();

  if(!lattitude || !longitude)
  {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
    }
  }

  function success(position: any) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setLattitude(latitude);
    setLongitude(longitude);
    localStorage.setItem('location_lat', latitude);
    localStorage.setItem('location_long', longitude);
  }
  function error() {
      console.log("Unable to retrieve your location");
  }
}