import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

interface ImageCellProps {
  imagePath: string | null;
}

const ImageCell: React.FC<ImageCellProps> = ({ imagePath }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (imagePath) {
      const img = new Image();
      setIsLoading(true);
      setHasError(false);

      // Start loading the image
      img.src = imagePath;

      // When the image is successfully loaded
      img.onload = () => {
        setIsLoading(false);
      };

      // If the image fails to load (e.g., a 404 or other error)
      img.onerror = () => {
        setIsLoading(false);
        setHasError(true);
      };
    }
  }, [imagePath]);

  if (!imagePath) {
    return (
      <Box
        sx={{
          height: "150px",
          width: "100%",
          backgroundColor: "#f0f0f0", // Placeholder color
          borderRadius: "8px",
        }}
      >
        No Image Available
      </Box>
    );
  }

  if (isLoading) {
    return (
      <Box
        sx={{
          height: "150px",
          width: "100%",
          backgroundColor: "#f0f0f0", // Placeholder color
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Loading...
      </Box>
    );
  }

  if (hasError) {
    return (
      <Box
        sx={{
          height: "150px",
          width: "100%",
          backgroundColor: "#ffcccc", // Error color
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Failed to Load Image
      </Box>
    );
  }

  return (
    <img
      src={imagePath}
      alt="Vehicle"
      loading="lazy"
      style={{
        height: "150px",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        padding: "1px",
      }}
    />
  );
};

export default ImageCell;
