import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SpotrueApiStorageBaseUrl } from "./baseUrls";

export default function generatePresignedUrls(
  fileId: string,
  id: string,
  parts: number
) {
  return fetchWrapper(`${SpotrueApiStorageBaseUrl}/generatePresignedUrls?clientId=gogig`, {
    method: "POST",
    body: {
      fileId: fileId,
      uploadId: id,
      parts: parts,
    },
  });
}