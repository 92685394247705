import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SpotrueApiBaseUrl } from "./baseUrls";

export async function getAuditRequestData(authHeader: string) {
  return fetchWrapper(`${SpotrueApiBaseUrl}/getAuditRequest`, {
    method: "GET",
    headers: {
      Authorization: authHeader,
    },
  });
}
