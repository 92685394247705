import { AuthApiBaseUrl } from "./baseUrls";
import { fetchWrapper } from "@gogig-in/gogig-clients";

export async function getRoles(authHeader:string){
        return fetchWrapper(`${AuthApiBaseUrl}/getRoles`,{
        method: 'GET',
        headers: {
            Authorization: authHeader,
          },
    });
}