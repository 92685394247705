import {
  Grid,
  TextField,
  Typography,
  Box,
  Button,
  useMediaQuery,
  Container,
  InputAdornment,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import "./Signup.css";
import GlobalSnackbar, { Severity } from "../snackbarComponent/Snackbar";
import { OTP } from "./OtpInput";
import { useNavigate } from "react-router";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CustomLoading from "../custom/Loading";
import { useAuthContext } from "../../providers/AuthProvider";
import EditIcon from "@mui/icons-material/Edit";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { WhatsAppBaseNumber } from "../../clients/baseUrls";
import { getUserData } from "@gogig-in/gogig-clients";
import { sendVerificationRequest } from "@gogig-in/gogig-clients";

export function isNullOrEmpty(value: string | null | undefined) {
  return value === null || value === "";
}

const SignUp: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState<string | number | any>("");
  const isLargeScreen = useMediaQuery("(min-width:600px)");
  const [open, setOpen] = useState<boolean>(false);
  const [otp, setOtp] = useState("");
  const [showOtpInput, setShowOtpInput] = useState<Boolean>(false); // Show
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [severity, setSeverity] = useState<Severity>(undefined);
  const navigate = useNavigate();
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [isBtnDisabled, setIsBtnDisabled] = useState(true); // Disable button if the timer
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { loginWithPhoneNumber, authHeader } = useAuthContext();
  const [timer, setTimer] = useState(15);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [timer]);

  //change length of OTP component
  const otpLength = 4;

  useEffect(() => {
    if (otp.length === otpLength) {
      setIsBtnDisabled(false);
    } else {
      setIsBtnDisabled(true);
    }
  }, [otp]);

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value: string = event.target.value;
    setPhoneNumberError("");

    if (value.length === 10) {
      setPhoneNumberError("");
    } else if (!/^\d{0,10}$/.test(value)) {
      setPhoneNumberError("Please enter valid a phone number");
    }
    // Check if input value contains alphabets
    if (/^[a-zA-Z]+$/.test(value)) {
      setPhoneNumberError("Phone number should not contain alphabets");
    }
    if (value.length === 10 && isNaN(Number(value[9]))) {
      setPhoneNumberError("Phone number should not contain alphabets");
    }
    if (value.length === 10) {
      const hasLetters = value.split("").some((char) => isNaN(Number(char)));
      if (hasLetters) {
        setPhoneNumberError("Phone number should not contain letters");
      }
    }
    // Check if the entered value starts with "+91"
    if (value.startsWith("+91")) {
      // If so, set the phone number without modification
      setPhoneNumber(value.slice(3)); // Exclude the "+91" prefix
    } else {
      // Otherwise, update the phone number with the entered value
      setPhoneNumber(value);
    }
  };

  const validatePhoneNumber = (input: string) => {
    // Regular expression for validating phone number
    const phoneRegex = /[1-9]{1}[0-9]{9}/;
    if (input.length === 10) {
      setPhoneNumberError("");
      return true;
    } else if (input.length !== 10) {
      setPhoneNumberError("Enter a valid Phone number");
      return false;
    } else if (!phoneRegex.test(input)) {
      setPhoneNumberError("Invalid phone number");
      return false;
    }
    setPhoneNumberError("");
    return true;
  };

  const validateOtp = (input: string) => {
    // You can add more specific validation for OTP if needed
    if (input.length !== 4) {
      setOtpError("OTP must be 4 digits");
      return false;
    } else {
      setOtpError("");
      return true;
    }
  };

  const handleLoginSubmit = (): void => {
    const isPhoneNumberValid = validatePhoneNumber(phoneNumber);
    try {
      if (isPhoneNumberValid) {
        // Submit logic goes here
        setIsLoading(true);
        setTimer(15);
        if (authHeader) {
          sendVerificationRequest(phoneNumber, authHeader)
            .then(() => {
              handleOpenSnackbar();
              setShowOtpInput(true);
              setIsLoading(false);
              setOtp("");
            })
            .catch((error: any) => {
              setIsLoading(false);
              setOtpError(error);
              setOpenSnackbar(true);
              setSnackbarMessage(`error in OTP ${otpError}`);
              setSeverity("error");
            });
        }
      } else {
        console.log("Form not submitted, please fix errors");
      }
    } catch (e) {
      console.warn("Unexpected Error", e);
    }
  };

  const handleOpenSnackbar = () => {
    const phone = phoneNumber;
    setOpenSnackbar(true);
    setSnackbarMessage(`OTP sent to ${phone}`);
    setSeverity("success");
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleChange = (otp: any) => {
    setOtp(otp);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOtpSubmit = async () => {
    try {
      setIsLoading(true);
      if (otp === "") {
        alert("Please enter OTP");
      } else if (validateOtp(otp) === false) {
        alert("Invalid OTP");
      } else {
        await loginWithPhoneNumber(otp, phoneNumber).then((value) => {
          checkUserAndRedirect(value);
          setIsLoading(true);
        });
      }
    } catch (e: any) {
      setIsLoading(false);
      setOpenSnackbar(true);
      setSnackbarMessage(`${e.status}`);
      setSeverity("error");
    }
  };

  const redirectToWhatsApp = () => {
    window.open(
      `https://api.whatsapp.com/send?phone=${WhatsAppBaseNumber}&text=` +
        encodeURIComponent("help me login"),
      "_blank"
    );
  };

  const checkUserAndRedirect = async (accessToken: string) => {
    try {
      if (accessToken) {
        const response = await getUserData(`Bearer ${accessToken}`);
        let res: any = response.data;
        if (
          isNullOrEmpty(res.emailId) ||
          isNullOrEmpty(res.dob) ||
          isNullOrEmpty(res.firstName) ||
          isNullOrEmpty(res.lastName) ||
          isNullOrEmpty(res.gender)
        ) {
          navigate("/user-entry/register");
        } else {
          navigate("/");
        }
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  return (
    <Grid>
      <GlobalSnackbar
        open={openSnackbar}
        message={snackbarMessage}
        onClose={handleCloseSnackbar}
        severity={severity}
        vertical={"top"}
        horizontal={"left"}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Thank You</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box>Phone number is verified sucessfully!</Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {isLoading ? (
        <CustomLoading />
      ) : (
        <Box marginTop={5}>
          <Container
            id="customContainer"
            sx={{
              position: "relative",
              top: "0",
              border: isLargeScreen ? "1px solid #ddd" : "none",
              borderRadius: "10px",
              padding: "20px",
            }}
            maxWidth={"xs"}
          >
            <Box textAlign="center" mb={2}>
              {/* <img src={login_ils} alt="Phone Login Illustration" style={{ height: '200px' }} /> */}
              <Typography
                variant="h2"
                textAlign="center"
                gutterBottom
                mx={2}
                my={2}
              >
                Welcome to GoGig
              </Typography>
              <Typography
                variant="h6"
                textAlign="center"
                gutterBottom
                mx={2}
                my={2}
              >
                Your Gateway to Flexible Work and Boundless Opportunities
              </Typography>
            </Box>
            {showOtpInput ? (
              <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <Typography
                  variant="h5"
                  textAlign="start"
                  gutterBottom
                  mx={2}
                  my={2}
                >
                  Verify OTP sent to your phone number :
                  <br />
                  <span
                    style={{
                      color: "#1976D2",
                    }}
                  >
                    +91 {phoneNumber}{" "}
                    <EditIcon
                      onClick={() => setShowOtpInput(false)}
                      fontSize="small"
                      style={{
                        marginTop: "4px",
                        cursor: "pointer",
                      }}
                    />
                  </span>
                </Typography>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-around"
                >
                  <OTP
                    separator={"-"}
                    value={otp}
                    onChange={handleChange}
                    length={otpLength}
                  />
                </Box>
                <Grid item xs={12}>
                  {timer === 0 ? (
                    <Button onClick={handleLoginSubmit}>Resend OTP</Button>
                  ) : (
                    <Typography variant="body1" sx={{ mt: 2 }}>
                      Please wait for{" "}
                      <span style={{ color: "#1976d2" }}>00:{timer}</span>{" "}
                      before resending the OTP.
                    </Typography>
                  )}
                </Grid>

                <Button
                  onClick={handleOtpSubmit}
                  variant="contained"
                  color="primary"
                  disabled={isBtnDisabled}
                >
                  Verify
                </Button>
                <Typography variant="h4" textAlign="center" display="block">
                  Or
                </Typography>
                <Button
                  variant="outlined"
                  style={{
                    border: "2px solid #32a852",
                    background: "#fff",
                    color: "#32a852",
                  }}
                  onClick={() => {
                    redirectToWhatsApp();
                  }}
                >
                  <WhatsAppIcon />
                  <span
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    What's app
                  </span>
                </Button>
              </Box>
            ) : (
              // Phone Number Entry //
              <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <Typography
                  variant="h4"
                  textAlign="start"
                  gutterBottom
                  mx={2}
                  my={2}
                >
                  Login / Register
                </Typography>
                <TextField
                  label="Phone Number"
                  type="tel"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  fullWidth
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+91</InputAdornment>
                    ),
                  }}
                  error={!!phoneNumberError}
                  helperText={phoneNumberError}
                />

                <Typography
                  textAlign="start"
                  gutterBottom
                  mx={2}
                  marginBottom={4}
                >
                  By continuing, you agree to our{" "}
                  <span
                    style={{
                      color: "blue",
                      // textDecoration:"underline"
                    }}
                    color="primary"
                  >
                    Privacy Policy
                  </span>{" "}
                  and{" "}
                  <span
                    style={{
                      color: "blue",
                      // textDecoration:"underline"
                    }}
                  >
                    Terms & Conditions
                  </span>
                  .
                </Typography>
                <Button
                  onClick={handleLoginSubmit}
                  variant="contained"
                  color="primary"
                >
                  Login
                </Button>
                {/* Show Google Button only if not in production */}
                <Typography variant="h4" textAlign="center" display="block">
                  Or
                </Typography>
                <Button
                  variant="outlined"
                  style={{
                    border: "2px solid #32a852",
                    background: "#fff",
                    color: "#32a852",
                  }}
                  onClick={() => {
                    redirectToWhatsApp();
                  }}
                >
                  <WhatsAppIcon />
                  <span
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    What's app
                  </span>
                </Button>
              </Box>
            )}
          </Container>
        </Box>
      )}
    </Grid>
  );
};

export default SignUp;
