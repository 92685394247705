import React from 'react';
import { Box, Typography, Button } from '@mui/material';

export interface HomeSectionProps {
    title: string;
    actions: { label: string; onClick: () => void; subLabel?: string }[];
}

const HomeSection = ({ title, actions }: HomeSectionProps) => {
    return (
        <Box
            sx={{
                alignItems: 'center',
                marginBottom: 1,
                borderRadius: '5px',
                border: '1px solid #ddd',
                padding: '5px',
                marginTop: '20px',
            }}
        >
            <Typography
                sx={{
                    paddingLeft: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: '20px',
                    fontWeight: '500',
                    textDecoration: 'underline',
                    marginBottom: '10px',
                }}
            >
                {title}
            </Typography>
            {actions.map((action, index) => (
                <Box
                    key={index}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: 1,
                        borderRadius: '5px',
                    }}
                >
                    <Typography variant="h5" sx={{ paddingLeft: '5px' }}>
                        {action.label}:
                    </Typography>
                    <Button onClick={action.onClick} color="primary">
                        {action.subLabel ? action.subLabel : action.label}
                    </Button>
                </Box>
            ))}
        </Box>
    );
};

export default HomeSection;
