import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Container,
} from "@mui/material";
import "./AdminQuery.css";
import LoadingSpinner from "../LoadingAnimation";
import { useAuthContext } from "../../providers/AuthProvider";
import {
  approvePayment,
  getListOfPaymentsRequests,
} from "@gogig-in/gogig-clients";

interface Payment {
  gigTitle: string;
  acceptanceId: string;
  gigId: string;
  gigStartDate: string;
  firstName: string;
  email: string;
  lastName: string;
  phone: string;
}

const PaymentsToApproveTable: React.FC = () => {
  const [sendPayment, setSendPayment] = useState<Payment[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { authHeader } = useAuthContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (authHeader) {
          const response: any = await getListOfPaymentsRequests(authHeader);
          const responseData = response.data;

          if (responseData && responseData) {
            if (Array.isArray(responseData)) {
              setSendPayment(responseData);
            } else {
              console.error("Data received is not an array:", responseData);
              setSendPayment([]);
            }
          } else {
            console.error(
              "Data received does not contain payments:",
              responseData
            );
            setSendPayment([]);
          }
          setIsLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
    };

    fetchData();
  }, [authHeader]);

  const handleApprove = async (acceptanceId: string) => {
    try {
      if (authHeader) {
        const approveResponse: any = await approvePayment(
          acceptanceId,
          authHeader
        );

        if (approveResponse.status === 200) {
        } else {
          console.error("Failed to approve payment:", approveResponse.error);
        }
      }
    } catch (error) {
      console.error("Error while approving payment:", error);
    }
  };

  return (
    <Container className="queries-container">
      <Typography variant="h4">Payment Approval Page</Typography>
      {isLoading ? (
        <LoadingSpinner label="Please wait while we fetch the queries..." />
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Gig Title</TableCell>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Gig Start Date</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sendPayment.map((payment) => (
                  <TableRow key={payment.acceptanceId}>
                    <TableCell>{payment.gigTitle}</TableCell>
                    <TableCell>{payment.firstName}</TableCell>
                    <TableCell>{payment.lastName}</TableCell>
                    <TableCell>{payment.email}</TableCell>
                    <TableCell>{payment.phone}</TableCell>
                    <TableCell>{payment.gigStartDate}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        onClick={() => handleApprove(payment.acceptanceId)}
                      >
                        Approve
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Container>
  );
};

export default PaymentsToApproveTable;
