import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../providers/AuthProvider";
import { ProfileData } from "../profile/ProfileValues";
import { getUserData } from "@gogig-in/gogig-clients";

const VerifyWhatsApp: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const { loginWithWhatsApp, resetUser, authHeader } = useAuthContext();

  useEffect(() => {
    const data = async () => {
      try {
        if (authHeader) {
          await loginWithWhatsApp(token);
          const user = await getUserData(authHeader);
          const userData = user.data as ProfileData;
          if (
            userData.emailId === null ||
            userData.dob === null ||
            userData.firstName === null
          ) {
            navigate("/user-entry/register");
          } else {
            await resetUser();
            navigate("/");
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (token) {
      data();
    }
  }, [navigate, loginWithWhatsApp, token, resetUser, authHeader]);

  return <h1>Verifying your phone number...</h1>;
};

export default VerifyWhatsApp;
