export const VehicleNumberValidator = (input: string): boolean => {
  // Define the regex pattern:
  // - ^: Start of the string
  // - [A-Z]{2}: Exactly two uppercase letters
  // - \s: A single space
  // - [0-9]{2}: Exactly two digits
  // - \s: A single space
  // - [A-Z]{0,2}: Zero to two uppercase letters (optional)
  // - \s: A single space
  // - [0-9]{4}: Exactly four digits
  // - $: End of the string
  const vehicleRegex = /^[A-Z]{2}[0-9]{2}[A-Z]{0,2}[0-9]{4}$/;

  // Test the input against the regex pattern and return the result (true or false)
  return vehicleRegex.test(input);
};
