import React, { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useAuthContext } from "../../providers/AuthProvider";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useParams } from "react-router-dom";
import { amountTransfer } from "@gogig-in/gogig-clients";

interface AmountModalProps {
  open: boolean;
  onClose: () => void;
  selectedRows: any[];
  apiStatus: (severity: string, message: string) => void;
}

const AmountModal: React.FC<AmountModalProps> = ({
  open,
  onClose,
  selectedRows,
  apiStatus,
}) => {
  const [amount, setAmount] = useState<string | null>(null);
  const [remarks, setRemarks] = useState<string | null>(null);
  const { authHeader } = useAuthContext();
  const [paymentStatuses, setPaymentStatuses] = useState<{
    [key: string]: string;
  }>({});
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [customAmounts, setCustomAmounts] = useState<{ [key: string]: string }>(
    {}
  );
  const [isFrozen, setIsFrozen] = useState(false);
  const [message, setMessage] = useState<{ [key: string]: string }>({});
  const [completed, setCompleted] = useState(false);
  const { gigId } = useParams<{ gigId: string }>();
  
  useEffect(() => {
    const initialPaymentStatuses: { [key: string]: string } = {};
    selectedRows.forEach((row) => {
      initialPaymentStatuses[row.userId] = "loading";
    });
    setPaymentStatuses(initialPaymentStatuses);
  }, [open, selectedRows]);

  useEffect(() => {
    if (!open) {
      setErrorMessage("");
      setAmount("");
      setRemarks("");
      setCustomAmounts({});
      setIsFrozen(false);
      setMessage({});
      setCompleted(false);
    }
  }, [open]);

  const handleAmountTransfer = async () => {
    try {
      if (!amount) {
        setErrorMessage("Please fill all the required fields correctly.");
        return;
      }
      setIsFrozen(true);
      setCompleted(true);
      if (authHeader) {
        const userIdsArray = selectedRows.map((row) => row.userId);
        if (userIdsArray.length === 0) {
          return;
        }

        const failedUserIds = [];

        for (const userId of userIdsArray) {
          const formData = {
            amount: customAmounts[userId],
            remarks: remarks,
            receiverUserId: userId,
            ...(gigId && { gigId: gigId }),
          };

          try {
            await amountTransfer(formData, authHeader);
            setPaymentStatuses((prevStatuses) => ({
              ...prevStatuses,
              [userId]: "sent",
            }));
            setMessage((prevMessages) => ({
              ...prevMessages,
              [userId]: "Payment sent successfully.",
            }));
          } catch (error: any) {
            if (error.status === 404 && error.errorCause === "walletNotFound") {
              console.error(
                `Failed to send amount for userId ${userId}:`,
                error
              );
              setMessage((prevMessages) => ({
                ...prevMessages,
                [userId]: "Receivers wallet not found",
              }));
            } else if (
              error.status === 400 &&
              error.errorCause === "insufficientWalletBalance"
            ) {
              console.error(
                `Failed to send amount for userId ${userId}:`,
                error
              );
              setMessage((prevMessages) => ({
                ...prevMessages,
                [userId]: "Insufficient wallet balance.",
              }));
            }  else if (
              error.status === 400 &&
              error.errorCause === "sameWalletError"
            ) {
              console.error(
                `Failed to send amount for userId ${userId}:`,
                error
              );
              setMessage((prevMessages) => ({
                ...prevMessages,
                [userId]: "Self transfer is not allowed.",
              }));
            }else {
              console.error(error);
              setMessage((prevMessages) => ({
                ...prevMessages,
                [userId]: "Error occurred please try again later.",
              }));
            }
            failedUserIds.push(userId);
            setPaymentStatuses((prevStatuses) => ({
              ...prevStatuses,
              [userId]: "failed",
            }));
          }
        }

        if (failedUserIds.length === 0) {
          apiStatus("success", "Amount transferred sent successfully.");
        } else {
          apiStatus(
            "warning",
            `Failed to send amount for ${failedUserIds.length} user(s).`
          );
        }
        setAmount(null);
        setRemarks(null);
        setCompleted(true);
      }
    } catch (e) {
      console.error(e);
      apiStatus("error", "Error sending amount. Please try again.");
    }
  };

  const handleCustomAmountChange =
    (userId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      const numberPattern = /^[1-9]\d*(\.\d+)?$/;
      if (numberPattern.test(inputValue) && parseFloat(inputValue) > 0) {
        setCustomAmounts((prevAmounts) => ({
          ...prevAmounts,
          [userId]: inputValue,
        }));
        setErrorMessage("");
      } else {
        setCustomAmounts((prevAmounts) => ({
          ...prevAmounts,
          [userId]: "",
        }));
      }
    };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const numberPattern = /^[1-9]\d*(\.\d+)?$/;
    if (numberPattern.test(inputValue) && parseFloat(inputValue) > 0) {
      selectedRows.forEach((row) => {
        setCustomAmounts((prevAmounts) => ({
          ...prevAmounts,
          [row.userId]: inputValue,
        }));
      });
      setAmount(inputValue);
      setErrorMessage("");
    } else {
      setAmount("");
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "10px",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" sx={{ paddingLeft: "30px" }}>
          Send Amount
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent sx={{ padding: "30px" }}>
        <TextField
          label="Enter Amount"
          variant="outlined"
          margin="normal"
          value={amount}
          onChange={handleAmountChange}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Rs</InputAdornment>
            ),
          }}
          error={amount === ""}
          helperText={
            amount === "" ? "Please enter a valid amount greater than 0" : ""
          }
          disabled={isFrozen}
        />
        <TextField
          label="Remarks(optional)"
          variant="outlined"
          fullWidth
          multiline
          maxRows={4}
          margin="normal"
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
          size="small"
          disabled={isFrozen}
          focused
        />
      </DialogContent>
      {errorMessage && (
        <Typography color="error" variant="subtitle2" align="center">
          {errorMessage}
        </Typography>
      )}
      <TableContainer
        sx={{
          maxHeight: 250,
          border: "1px solid #ddd",
          maxWidth: 450,
          margin: "auto",
        }}
      >
        <Table size="small" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>User Name</TableCell>
              <TableCell>Customize the amount</TableCell>
              <TableCell>Payment Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedRows.map((userDetails: any) => (
              <TableRow key={userDetails.userId}>
                <TableCell>
                  {userDetails.firstName && userDetails.lastName ? (
                    `${userDetails.firstName} ${userDetails.lastName}`
                  ) : (
                    <Typography variant="body2">.....</Typography>
                  )}
                </TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    size="small"
                    value={customAmounts[userDetails.userId] || ""}
                    onChange={handleCustomAmountChange(userDetails.userId)}
                    error={customAmounts[userDetails.userId] === ""}
                    helperText={
                      customAmounts[userDetails.userId] === ""
                        ? "Please enter a valid amount greater than 0"
                        : ""
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">Rs</InputAdornment>
                      ),
                    }}
                    disabled={isFrozen}
                  />
                </TableCell>
                <TableCell>
                  {paymentStatuses[userDetails.userId] === "loading" ? (
                    <>
                      {isFrozen ? (
                        <LinearProgress
                          variant="buffer"
                          value={0}
                          valueBuffer={5}
                          sx={{ width: "40px" }}
                        />
                      ) : (
                        <Typography variant="caption">Not initiated</Typography>
                      )}
                    </>
                  ) : (
                    <Typography
                      variant="caption"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {paymentStatuses[userDetails.userId] === "sent" ? (
                        <CheckCircleOutlineIcon
                          sx={{ color: "green", marginLeft: "5px" }}
                        />
                      ) : paymentStatuses[userDetails.userId] === "failed" ? (
                        <ErrorOutlineIcon
                          sx={{ color: "red", marginLeft: "5px" }}
                        />
                      ) : null}
                      {message[userDetails.userId]}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DialogActions>
        <Button
          onClick={handleAmountTransfer}
          disabled={isFrozen}
          sx={{ textTransform: "none" }}
        >
          {!isFrozen && !completed
            ? "Send"
            : isFrozen && !completed
            ? "Sending..."
            : "Completed"}
        </Button>
        {!isFrozen && (
          <Button onClick={onClose} sx={{ textTransform: "none" }}>
            Cancel
          </Button>
        )}
        {completed && (
          <Button onClick={onClose} sx={{ textTransform: "none" }}>
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AmountModal;
