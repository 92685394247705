import React, { FC } from "react";
import { Box, Tooltip } from "@mui/material";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

interface Props {
  onClick?: () => void;
  variant?: "primary" | "secondary";
}
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}));

const Logo: FC<Props> = ({ onClick, variant }) => {
  const navigate = useNavigate();
  const handleGoToHome = () => {
    navigate("/");
  };
  return (
    <Box onClick={handleGoToHome}>
      <LightTooltip title="Click.Work.Earn">
        <img
          src={`${process.env.PUBLIC_URL}/images/logos/gogig.svg`}
          alt="Click.Work.Earn"
          style={{ width: 100, cursor:'pointer' }}
        />
      </LightTooltip>
    </Box>
  );
};

Logo.defaultProps = {
  variant: "primary",
};

export default Logo;
