import React from "react";
import { Box } from "@mui/material";
import SummaryText from "../../vehicleAuditComponents/AuditResultComponents/FilterCount";
import { VehicleAuditDetails } from "../../vehicleAuditComponents/types";

interface FilterSummaryProps {
  data: VehicleAuditDetails[];
  filters: {
    vehicleClasses: string[];
    authenticity: number[];
    fuelTypes: string[];
    vehicleModels: string[];
    manufacturers: string[];
  };
}

const FilterSummary: React.FC<FilterSummaryProps> = ({ data, filters }) => {
  const getFilteredCount = (
    data: VehicleAuditDetails[],
    filterKey: keyof VehicleAuditDetails,
    filterValues: string[] | number[] | null
  ): number => {
    // Use data here if needed
    if (
      !filterValues ||
      (Array.isArray(filterValues) && filterValues.length === 0)
    ) {
      return 0;
    }
    const isStringArray = (value: any): value is string[] => {
      return Array.isArray(value) && value.every((val) => typeof val === "string");
    };

    // Type Guard for number arrays
    const isNumberArray = (value: any): value is (number | null)[] => {
      return (
        Array.isArray(value) &&
        value.every((val) => typeof val === "number" || "null")
      );
    };

    return data.filter((row) => {
      const rowValue = row[filterKey];
      if (Array.isArray(filterValues)) {
        if (isStringArray(filterValues) && typeof rowValue === "string") {
          return (filterValues as string[]).includes(rowValue as string);
        }
        if (
          isNumberArray(filterValues) &&
          (typeof rowValue === "number" || "null")
        ) {
          return (filterValues as number[]).includes(
            rowValue === null ? -1 : (rowValue as number)
          );
        }

        return false;
      }
      return row[filterKey] === filterValues;
    }).length;
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", padding: 2 }}>
      {filters.vehicleClasses.length > 0 && (
        <SummaryText
          label="Vehicle Class Count"
          count={getFilteredCount(data, "vehicleClass", filters.vehicleClasses)}
        />
      )}
      {filters.authenticity !== null && (
        <SummaryText
          label="Authenticity Count"
          count={getFilteredCount(data, "authenticity", filters.authenticity)}
        />
      )}
      {filters.fuelTypes.length > 0 && (
        <SummaryText
          label="Fuel Type Count"
          count={getFilteredCount(data, "fuelType", filters.fuelTypes)}
        />
      )}
      {filters.vehicleModels.length > 0 && (
        <SummaryText
          label="Vehicle Model Count"
          count={getFilteredCount(
            data,
            "makerModelFromSource",
            filters.vehicleModels
          )}
        />
      )}
      {filters.manufacturers.length > 0 && (
        <SummaryText
          label="Vehicle Manufacturer Count"
          count={getFilteredCount(
            data,
            "manufacturerFromSource",
            filters.manufacturers
          )}
        />
      )}
    </Box>
  );
};

export default FilterSummary;
