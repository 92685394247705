import { Outlet } from "react-router-dom";
import { Header } from "./components/header";

function DefaultContainer() {
  
  return (
      <div className={"layout-container"}>
          <Header />
          <Outlet />
      </div>

  );
}

export default DefaultContainer;
