import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Container,
  Modal,
  TextField,
  Snackbar,
  Alert,
  tableCellClasses,
  styled,
} from "@mui/material";
import "./AdminQuery.css";
import LoadingSpinner from "../LoadingAnimation";
import PageTitleComponent from "../PageTitleComponent";
import { useAuthContext } from "../../providers/AuthProvider";
import {
  getClosedQueries,
  getOpenQueries,
  resolveOpenQuery,
} from "@gogig-in/gogig-clients";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#5A5959",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
interface Query {
  queryId: string;
  isAddressed: number;
  queryType: string;
  name: string;
  companyName?: string | null;
  phoneNumber: string;
  email: string;
  createdAt: string;
  UpdatedAt: string;
  addressedBy?: string | null;
  comments?: string | null;
  query: string;
  seekerId?: string | null;
}

export interface Response {
  comments: string;
}

const Queries: React.FC = () => {
  const [queryDetails, setQueryDetails] = useState<Query[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { authHeader } = useAuthContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedQuery, setSelectedQuery] = useState<Query | null>(null);
  const [responseText, setResponseText] = useState("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [isDisplayingClosedQueries, setIsDisplayingClosedQueries] =
    useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (authHeader) {
          const response: any = await getOpenQueries(authHeader);
          setQueryDetails(response.data);
          setIsLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, [authHeader]);

  const handleAnswerClick = (query: Query) => {
    setSelectedQuery(query);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleResponseTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setResponseText(e.target.value);
  };

  const handleResponseSubmit = async () => {
    try {
      if (!authHeader || !selectedQuery) {
        console.error("Authentication header or query not available");
        return;
      }

      const response = await resolveOpenQuery(
        authHeader,
        selectedQuery.queryId,
        {
          comments: responseText,
        }
      );

      if (response) {
        console.log("Query resolved successfully");
        setIsModalOpen(false);
        setSelectedQuery(null);
        setSuccessMessage("Comments updated successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        console.error("Error resolving query:");
      }
    } catch (error) {
      console.error("Error resolving query:", error);
    }
  };

  const handleSnackbarClose = () => {
    setSuccessMessage(null as unknown as string);
  };

  const handleViewOpenQueries = async () => {
    try {
      if (authHeader) {
        const response: any = await getOpenQueries(authHeader);
        setQueryDetails(response.data);
        setIsLoading(false);
        setIsDisplayingClosedQueries(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleViewClosedQueries = async () => {
    try {
      if (authHeader) {
        const response: any = await getClosedQueries(authHeader);
        setQueryDetails(response.data);
        setIsLoading(false);
        setIsDisplayingClosedQueries(true);
      }
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Container className="queries-container">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <PageTitleComponent title="Queries" />
        <Box>
          <Button
            size="small"
            variant={isDisplayingClosedQueries ? "outlined" : "contained"}
            sx={{
              marginTop: "15px",
              textTransform: "none",
              marginRight: "10px",
            }}
            onClick={() => handleViewOpenQueries()}
            disabled={!isDisplayingClosedQueries} // Disable if already viewing open queries
          >
            View Open Queries
          </Button>
          <Button
            size="small"
            variant={isDisplayingClosedQueries ? "contained" : "outlined"}
            sx={{ marginTop: "15px", textTransform: "none" }}
            onClick={() => handleViewClosedQueries()}
            disabled={isDisplayingClosedQueries} // Disable if already viewing closed queries
          >
            View Closed Queries
          </Button>
        </Box>
      </Box>
      {isLoading ? (
        <LoadingSpinner label="Please wait while we fetch the queries..." />
      ) : (
        <>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Query</StyledTableCell>
                <StyledTableCell>Created At</StyledTableCell>
                <StyledTableCell>
                  {isDisplayingClosedQueries ? "Comments" : "Action"}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {queryDetails.map((query) => (
                <TableRow key={query.queryId}>
                  <TableCell>{query.query}</TableCell>
                  <TableCell>{query.createdAt}</TableCell>
                  <TableCell>
                    {isDisplayingClosedQueries ? (
                      // Display comments for closed queries
                      <Typography>{query.comments}</Typography>
                    ) : (
                      // Display action button for open queries
                      <Button
                        onClick={() => handleAnswerClick(query)}
                        variant="outlined"
                      >
                        Answer
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Modal open={isModalOpen} onClose={handleModalClose}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography variant="h5" mb={2}>
                Enter Response
              </Typography>
              {selectedQuery && (
                <Typography>Query: {selectedQuery.query}</Typography>
              )}
              <TextField
                label="Response Text"
                multiline
                rows={4}
                fullWidth
                value={responseText}
                onChange={handleResponseTextChange}
                sx={{ mb: 2 }}
              />
              <Button
                onClick={handleResponseSubmit}
                variant="contained"
                color="primary"
              >
                Submit Response
              </Button>
            </Box>
          </Modal>
          {successMessage && (
            <Snackbar
              open={true}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
            >
              <Alert onClose={handleSnackbarClose} severity="success">
                {successMessage}
              </Alert>
            </Snackbar>
          )}
        </>
      )}
    </Container>
  );
};

export default Queries;
