import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Container,
  Grid,
  Typography,
  FormControlLabel,
  IconButton,
  Select,
  MenuItem,
  TextField,
  Box,
  styled,
  LinearProgress,
  linearProgressClasses,
  Divider,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { preSetQuestionnaire } from "./preApprovanceQuestionnaire";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../providers/AuthProvider";
import CapabilitiesSelector from "../profile/CapabilitiesSelector";
import {
  getGigDetailsByGigId,
  addGigRequirement,
  getCapabilityList,
  getGigCapabilitiesByGigId,
  Capability,
  updateGigDetails,
} from "@gogig-in/gogig-clients";

export interface GigCapabilityProps {
  capabilityId: number;
  proficiencyRequired?: string | null;
  gigId: number | undefined;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const PreApproveQuestionnaire: React.FC = () => {
  const [questions, setQuestions] = useState<Array<any>>([]);
  const location = useLocation();
  const category = location.state?.category;
  const gigId = location.state?.gigId;
  const { authHeader } = useAuthContext();
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [capabilities, setCapabilities] = useState<Capability[]>([]);
  const [gigCapabilities, setGigCapabilities] = useState<Capability[]>([]);
  const [selectedUserSkills, setSelectedUserSkills] = useState<number[]>([]);
  const [selectedUserAssets, setSelectedUserAssets] = useState<number[]>([]);

  // Fetch the list of all capabilities
  useEffect(() => {
    const fetchCapabilityData = async () => {
      try {
        const data = await getCapabilityList();
        setCapabilities(data);
      } catch (e) {
        console.log(`Something went wrong ${e}`);
      }
    };

    fetchCapabilityData();
  }, []);

  const userSkills = capabilities.filter(
    (capability) => capability.capabilityType === "skill"
  );
  const userAssets = capabilities.filter(
    (capability) => capability.capabilityType === "asset"
  );

  useEffect(() => {
    if (category) {
      const presetQuestions= preSetQuestionnaire[category as keyof typeof preSetQuestionnaire];
      if(presetQuestions){
      setQuestions(Object.values(presetQuestions));
    }
    }
  }, [category]);

  useEffect(() => {
    const fetchData = async () => {
      if (category && gigId) {
        setQuestions(
          Object.values(
            preSetQuestionnaire[category as keyof typeof preSetQuestionnaire]
          )
        );
      } else if (gigId) {
        try {
          const gigDetailsResponse: any = await getGigDetailsByGigId(gigId);

          const questionnaireJson = JSON.parse(
            gigDetailsResponse.data.preAcceptanceForm || "{}"
          );

          setQuestions(Object.values(questionnaireJson));
        } catch (error) {
          console.error("Error fetching gig details", error);
        }
      }
    };

    const fetchCapabilityData = async () => {
      try {
        if (gigId) {
          const res: any = await getGigCapabilitiesByGigId(gigId);
          setGigCapabilities(res.data);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchCapabilityData();
    fetchData();
  }, [category, gigId]);

  useEffect(() => {
    const initialSelectedSkills: any = gigCapabilities
      .filter((capability) => capability.capabilityType === "skill")
      .map((s) => s.capabilityId);
    const initialSelectedAssets = gigCapabilities
      .filter((capability) => capability.capabilityType === "asset")
      .map((a) => a.capabilityId);
    setSelectedUserAssets(initialSelectedAssets);
    setSelectedUserSkills(initialSelectedSkills);
  }, [gigCapabilities]);

  const handleQuestionChange = (index: number, field: string, value: any) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][field] = value;

    if (
      field === "type" &&
      (value === "text" ||
        value === "number" ||
        value === "rating" ||
        value === "boolean")
    ) {
      updatedQuestions[index].answer = [];
    }

    setQuestions(updatedQuestions);
  };

  const addQuestion = () => {
    const newQuestion = {
      type: "text",
      answer: [],
      question: "",
      isMandatory: true,
    };
    setQuestions([...questions, newQuestion]);
  };

  const handleSkillsChange = (capabilityIds: number[]) => {
    setSelectedUserSkills(capabilityIds);
  };

  const handleAssetsChange = (capabilityIds: number[]) => {
    setSelectedUserAssets(capabilityIds);
  };

  const handleSkillsDelete = (capabilityId: number) => {
    const filteredUserSkills = selectedUserSkills.filter(
      (id) => id !== capabilityId
    );
    setSelectedUserSkills(filteredUserSkills);
  };

  const handleAssetsDelete = (capabilityId: number) => {
    const filteredUserAssets = selectedUserAssets.filter(
      (id) => id !== capabilityId
    );
    setSelectedUserAssets(filteredUserAssets);
  };

  const removeQuestion = (index: number) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };

  const addOption = (index: number) => {
    const updatedQuestions = [...questions];
    const newOption = "";

    if (
      updatedQuestions[index].type === "singleChoice" ||
      updatedQuestions[index].type === "multiChoice"
    ) {
      updatedQuestions[index].answer.push(newOption);
    } else {
      updatedQuestions[index].options.push(newOption);
    }

    setQuestions(updatedQuestions);
  };

  const removeOption = (questionIndex: number, optionIndex: number) => {
    const updatedQuestions = [...questions];

    if (
      updatedQuestions[questionIndex].type === "singleChoice" ||
      updatedQuestions[questionIndex].type === "multiChoice"
    ) {
      updatedQuestions[questionIndex].answer.splice(optionIndex, 1);
    } else {
      updatedQuestions[questionIndex].options.splice(optionIndex, 1);
    }

    setQuestions(updatedQuestions);
  };

  const generateJson = () => {
    const questionJson: { [key: string]: any } = {};

    questions.forEach((question, index) => {
      const { type, question: questionText, isMandatory, answer } = question;

      questionJson[`Q${index + 1}`] = {
        type,
        answer:
          type === "singleChoice" || type === "multiChoice"
            ? answer.filter(Boolean)
            : type === "text" ||
              type === "number" ||
              type === "rating" ||
              type === "boolean"
            ? []
            : undefined,
        question: questionText,
        isMandatory: isMandatory ? 1 : 0,
      };
    });

    return JSON.stringify(questionJson, null, 2);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const preAcceptanceForm = generateJson();
    const formData = new FormData();

    // CAPABILITIES LIST
    const capabilityList = [...selectedUserSkills, ...selectedUserAssets].map(
      (capabilityId) => ({
        gigId: gigId,
        capabilityId,
        proficiencyRequired: null, // replace with actual proficiency if available
      })
    );

    if (authHeader) {
      formData.append("preAcceptanceForm", preAcceptanceForm);
      try {
        await Promise.all([
          addGigRequirement(capabilityList, authHeader, gigId),
          updateGigDetails(formData, authHeader, gigId),
        ]);
        history("/create-gig/task-form", {
          state: {
            category: category,
            gigId: gigId,
          },
        });
      } catch (error) {
        // Handle other errors
        console.error("Error occurred:", error);
      } finally {
        setLoading(false);
      }
    } else {
      console.error("No authHeader available");
    }
  };

  const handleBack = () => {
    history("/create-gig/basic-details", {
      state: {
        gigId: gigId,
      },
    });
  };

  const handleNext = () => {
    history("/create-gig/task-form", {
      state: {
        gigId: gigId,
      },
    });
  };

  return (
    <Container sx={{ marginTop: 2, marginBottom: "20px" }}>
      <Box
        sx={{
          justifyContent: "space-between",
          display: "flex",
          margin: "0px 5px",
        }}
      >
        <Button onClick={handleBack}>Back</Button>
        <Typography variant="h6" sx={{ margin: "auto" }}>
          Step 2 out of 4
        </Typography>
        <Button onClick={handleNext} disabled={!gigId || (category && gigId)}>
          Next
        </Button>
      </Box>
      <BorderLinearProgress
        variant="determinate"
        value={50}
        sx={{ marginBottom: "10px" }}
      />
      <Typography variant="h3" sx={{ padding: "10px" }}>
        Questions to be asked for Gig Seeker while applying.
      </Typography>
      <form>
        <Grid container spacing={2}>
          {questions.map((question, index) => (
            <Grid key={index} item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={3}>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Select
                      size="small"
                      fullWidth
                      value={question.type || "text"}
                      onChange={(e) =>
                        handleQuestionChange(index, "type", e.target.value)
                      }
                    >
                      <MenuItem value="text">Text</MenuItem>
                      <MenuItem value="singleChoice">Single Choice</MenuItem>
                      <MenuItem value="multiChoice">Multi Choice</MenuItem>
                      <MenuItem value="boolean">Boolean</MenuItem>
                      <MenuItem value="number">Number</MenuItem>
                      <MenuItem value="rating">Rating</MenuItem>
                    </Select>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(question.isMandatory)}
                          onChange={(e) =>
                            handleQuestionChange(
                              index,
                              "isMandatory",
                              e.target.checked
                            )
                          }
                          sx={{ marginLeft: "10px" }}
                        />
                      }
                      label="Mandatory"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <TextField
                      size="small"
                      label={`Question ${index + 1}`}
                      fullWidth
                      value={question.question || ""}
                      onChange={(e) =>
                        handleQuestionChange(index, "question", e.target.value)
                      }
                      multiline
                      maxRows={2}
                    />
                    <IconButton onClick={() => removeQuestion(index)}>
                      <DeleteForeverIcon />
                    </IconButton>
                  </Box>
                  {(question.type === "singleChoice" ||
                    question.type === "multiChoice") && (
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {question.answer.map(
                        (option: string, optionIndex: number) => (
                          <div
                            key={optionIndex}
                            style={{
                              marginRight: "10px",
                              marginBottom: "5px",
                              marginTop: "10px",
                            }}
                          >
                            <TextField
                              label={`Option ${optionIndex + 1}`}
                              value={option}
                              onChange={(e) => {
                                const updatedQuestions = [...questions];
                                updatedQuestions[index].answer[optionIndex] =
                                  e.target.value;
                                setQuestions(updatedQuestions);
                              }}
                              size="small"
                            />
                            <IconButton
                              onClick={() => removeOption(index, optionIndex)}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </div>
                        )
                      )}
                      <IconButton onClick={() => addOption(index)}>
                        <AddCircleOutlineIcon />
                        <Typography>Add Option</Typography>
                      </IconButton>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Button
                  variant="outlined"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={addQuestion}
                >
                  Add Question
                </Button>
              </Grid>
            </Grid>
            <Divider sx={{ mt: "12px" }} />
            <Grid
              container
              spacing={2}
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <Grid item xs={12} sm={6}>
                <CapabilitiesSelector
                  label="Skills"
                  capabilities={userSkills}
                  selectedCapabilityIds={selectedUserSkills}
                  onChange={handleSkillsChange}
                  onDelete={handleSkillsDelete}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CapabilitiesSelector
                  label="Assets"
                  capabilities={userAssets}
                  selectedCapabilityIds={selectedUserAssets}
                  onChange={handleAssetsChange}
                  onDelete={handleAssetsDelete}
                />
              </Grid>
            </Grid>
            <Grid item>
              {loading ? (
                <Button variant="contained" color="primary" disabled>
                  Submitting
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default PreApproveQuestionnaire;
