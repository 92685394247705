import React, { useEffect, useState } from "react";
import { Box, Typography, SelectChangeEvent } from "@mui/material";
import { VehicleModelDropDown } from "./vehicleOptionsConstants";
import { UpdateVehicleInterface } from "./types";
import { VehicleNumberValidator } from "../../../validators/VehicleNumberValidator";
import VehicleImageDisplay from "./VehicleImageDisplay";
import VehicleDetailsForm from "./VehicleDetailsForm";

interface PreProcessAuditDetailsProps {
  selectedImageBlobUrl: string;
  selectedImagePageNumber: number | null;
  gogigReqId: string;
  vehicleData: UpdateVehicleInterface[];
  handleUpdate: (
    gogigReqId: string,
    selectedVehicleData: UpdateVehicleInterface,
    index: number
  ) => Promise<void>;
}

const PreProcessAuditDetails: React.FC<PreProcessAuditDetailsProps> = ({
  gogigReqId,
  selectedImagePageNumber,
  vehicleData,
  handleUpdate,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [vehicleNumberError, setVehicleNumberError] = useState<boolean>(false);
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [isColorError, setIsColorError] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<
    UpdateVehicleInterface | undefined
  >();
  const [updatePreProcess, setUpdatePreProcess] = useState<
    UpdateVehicleInterface
  >({
    vehicleAuditId: "",
    manufacturerFromPhoto: "",
    makerModelFromPhoto: "",
    rcNumber: "",
    colorFromPhoto: "",
    remarks: "",
    imagePath: "",
    pageNumber: null,
    recheck: false,
    reasonForRecheck: [],
    imageOrientation: "",
    rcModifiedFlag: false,
  });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatePreProcess((prevData) => ({
      ...prevData,
      recheck: event.target.checked,
    }));
  };
  const validationMessage = (message: string) => {
    alert(message);
    setUpdatePreProcess((prevData) => ({
      ...prevData,
      recheck: true,
    }));
    setOpenDropdown(true);
    setLoading(false);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUpdateButton = async () => {
    setLoading(true);
    try {
      const updatedReasonForRecheck = updatePreProcess.reasonForRecheck || [];
      if (vehicleNumberError) {
        if (!updatedReasonForRecheck?.includes("rcNumberNotVisible")) {
          validationMessage(
            "Please add 'RC number is not visible' in the reason for recheck dropdown."
          );
          return;
        }
      }
      if (isColorError) {
        if (!updatedReasonForRecheck?.includes("colorNotFound")) {
          validationMessage(
            "please fill the 'color not found' in the reason for recheck dropdown"
          );
          return;
        }
      }

      if (selectedImagePageNumber !== null) {
        const filter = vehicleData.filter(
          (vehicle) => vehicle.pageNumber === updatePreProcess.pageNumber
        );
        const isDataChanged =
          JSON.stringify(filter[0]) === JSON.stringify(updatePreProcess);
        if (isDataChanged) {
          alert("change something and update");
          setLoading(false);
          return;
        }
        await handleUpdate(
          gogigReqId,
          updatePreProcess as UpdateVehicleInterface,
          selectedImagePageNumber
        );
      }
      setLoading(false);
    } catch (error) {
      console.error(error, "error updating details");
    }
  };
  const vehicleError = (reasonForRecheck: string[] | string) => {
    if (!selectedImage) return;
    const isValid = VehicleNumberValidator(updatePreProcess.rcNumber);
    if (reasonForRecheck?.includes("rcNumberNotVisible")) {
      setVehicleNumberError(false);
    } else {
      setVehicleNumberError(!isValid);
    }
    if (!selectedImage.colorFromPhoto || selectedImage.colorFromPhoto === "") {
      if (reasonForRecheck?.includes("colorNotFound")) {
        setIsColorError(false);
      } else {
        setIsColorError(true);
      }
    } else {
      setIsColorError(false);
    }
  };

  const handleSelectChange = (event: SelectChangeEvent<string[] | string>) => {
    const { value } = event.target;
    setUpdatePreProcess((prevData) => ({
      ...prevData,
      reasonForRecheck: typeof value === "string" ? value.split(",") : value,
    }));
    vehicleError(value);
  };
  useEffect(() => {
    if (selectedImage) {
      vehicleError(selectedImage.reasonForRecheck);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImage]);
  useEffect(() => {
    const getVehicleData = async () => {
      try {
        const selectedAuditData = vehicleData.find(
          (index) => selectedImagePageNumber === index.pageNumber
        );
        setSelectedImage(selectedAuditData);

        if (selectedAuditData) {
          setUpdatePreProcess({
            ...selectedAuditData,
          });
        } else {
          setUpdatePreProcess({
            vehicleAuditId: "",
            manufacturerFromPhoto: "",
            makerModelFromPhoto: "",
            rcNumber: "",
            colorFromPhoto: "",
            remarks: "",
            imagePath: "",
            pageNumber: selectedImagePageNumber,
            recheck: false,
            reasonForRecheck: [],
            imageOrientation: "",
            rcModifiedFlag: false,
          });
          setVehicleNumberError(false);
        }
      } catch (error) {
        console.error("Error getting vehicle details:", error);
      }
    };

    getVehicleData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImagePageNumber]);
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        handleUpdateButton(); // Trigger update on Enter key press
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleUpdateButton, selectedImagePageNumber, updatePreProcess]);

  const handleSelectFieldChange = (
    event: SelectChangeEvent<string>,
    field: string
  ) => {
    const value = event.target.value;

    if (field === "makerModelFromPhoto") {
      // Find the corresponding manufacturer for the selected model
      const manufacturer = Object.keys(VehicleModelDropDown).find((key) =>
        VehicleModelDropDown[key].includes(value)
      );

      // Automatically update the manufacturer when the model is selected
      setUpdatePreProcess((prevData) => ({
        ...prevData,
        manufacturerFromPhoto: manufacturer || "",
        [field]: value,
      }));
    } else if (field === "manufacturerFromPhoto") {
      const models = VehicleModelDropDown[value] || [];
      setUpdatePreProcess((prevData) => ({
        ...prevData,
        makerModelFromPhoto: models.length === 1 ? models[0] : "", // Automatically select if only one model
        [field]: value,
      }));
    } else if (field === "colorFromPhoto") {
      setUpdatePreProcess((prevData) => ({
        ...prevData,
        [field]: value,
      }));
      if (value != null) {
        setIsColorError(false);
      }
    }
  };
  const handleImageOrientation = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setUpdatePreProcess((prevData) => ({
      ...prevData,
      imageOrientation: value,
    }));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    // For Vehicle Number (rcNumber) validation
    if (name === "rcNumber") {
      const uppercaseValue = value.toUpperCase(); // Force uppercase
      const isValid = VehicleNumberValidator(uppercaseValue); // Validate the regex

      // Update error state based on validation
      setVehicleNumberError(!isValid);

      setUpdatePreProcess((prevData) => ({
        ...prevData,
        [name]: uppercaseValue, // Set the uppercase value
      }));
    } else {
      setUpdatePreProcess((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  return (
    <Box>
      {updatePreProcess.imagePath ? (
        <>
          <Box
            key={updatePreProcess.pageNumber}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "10px",
              backgroundColor: "#f5f5f5",
              margin: "0 auto",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            }}
          >
            {/* Image Section */}
            <VehicleImageDisplay updatePreProcess={updatePreProcess} />

            {/* Form Section */}
            {(updatePreProcess.pageNumber || updatePreProcess.pageNumber === 0) && <VehicleDetailsForm
              updatePreProcess={updatePreProcess}
              vehicleData={vehicleData[updatePreProcess.pageNumber]}
              handleUpdateButton={handleUpdateButton}
              vehicleNumberError={vehicleNumberError}
              isColorError={isColorError}
              loading={loading}
              handleInputChange={handleInputChange}
              handleSelectChange={handleSelectChange}
              handleSelectFieldChange={handleSelectFieldChange}
              handleImageOrientation={handleImageOrientation}
              handleCheckboxChange={handleCheckboxChange}
              setOpenDropdown={setOpenDropdown}
              openDropdown={openDropdown}
            />}
          </Box>
        </>
      ) : (
        <Typography variant="h6" sx={{ ml: 50, mt: 30 }}>
          No image selected.
        </Typography>
      )}
    </Box>
  );
};

export default PreProcessAuditDetails;
