import React, { useEffect, useState } from "react";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Tabs,
  Tab,
  Card,
  CardContent,
  Grid,
  Pagination,
  useMediaQuery,
} from "@mui/material";
import { useParams } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import {
  aggregateInformation,
  getQuestionaireResponseByGigId,
} from "@gogig-in/gogig-clients";

interface ViewDataItem {
  [key: string]: React.ReactNode;
}

const GigResult: React.FC = () => {
  const [viewData, setViewData] = useState<ViewDataItem[] | undefined>(
    undefined
  );
  const [currentRow, setCurrentRow] = useState(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(12);
  const [isTableView, setIsTableView] = useState(false);
  const { gigId } = useParams<{ gigId?: string }>();
  const [open, setOpen] = useState(false);
  const [additionalInformation, setAdditionalInformation] = useState<any>(null);

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!gigId) {
          return;
        }

        const responseAggregate: any = await aggregateInformation(gigId);
        setAdditionalInformation(responseAggregate.data);

        const totalPages = Math.ceil(
          (responseAggregate.data?.totalCount || 0) / pageSize
        );
        const newCurrentPage = Math.min(Math.max(1, currentPage), totalPages);

        const response: any = await getQuestionaireResponseByGigId(
          gigId,
          newCurrentPage,
          pageSize
        );
        const resultData = response.data;
        setViewData(resultData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [gigId, currentPage, pageSize, isTableView]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleImageClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRowClick = (index: number) => {
    setCurrentRow(index);
    setOpen(true);
  };

  const handleNextRow = () => {
    setCurrentRow((prevRow) =>
      prevRow < viewData!.length - 1 ? prevRow + 1 : prevRow
    );
  };

  const handlePrevRow = () => {
    setCurrentRow((prevRow) => (prevRow > 0 ? prevRow - 1 : prevRow));
  };

  const handlePageSizeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setPageSize(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleViewChange = () => {
    setIsTableView((prevIsTableView) => !prevIsTableView);
  };

  const redirectToPDF = () => {
    const newTab = window.open(`/gig-result-pdf/${gigId}`, "_blank");
    if (newTab) {
      newTab.focus();
    } else {
      console.log("Pop-up was blocked. Please allow pop-ups for this site.");
    }
  };

  const renderHeader = () => {
    if (!viewData || viewData.length === 0) return null;
    const fields = Object.keys(viewData[0]);
    const getHeaderLabel = (field: string) => {
      const lowerCaseField = field.toLowerCase();
      if (lowerCaseField.includes("vehicle number")) {
        return "Vehicle Number";
      } else if (lowerCaseField.includes("image")) {
        return "Image";
      }
      return field;
    };

    return (
      <TableHead>
        <TableRow>
          {fields
            .filter((f) => !(isSmallScreen && f === "comments"))
            .map((field) => (
              <TableCell
                sx={{ textTransform: "capitalize", fontSize: "18px" }}
                key={field}
              >
                {getHeaderLabel(field)}
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
    );
  };

  const renderTableBody = () => {
    if (!viewData || viewData.length === 0) return null;

    const renderTableCell = (value: any) => (
      <TableCell>
        {typeof value === "string" && isValidImageUrl(value) ? (
          <img
            src={value}
            alt={`${value}`}
            style={{
              cursor: "pointer",
              height: "100px",
            }}
            onClick={() => handleImageClick()}
          />
        ) : typeof value === "object" ? (
          <div>{renderNestedItems(value)}</div>
        ) : (
          <div>{value}</div>
        )}
      </TableCell>
    );

    const renderNestedItems = (data: any) => {
      return Object.values(data).map((value, index) => (
        <div key={index}>{renderTableCell(value)}</div>
      ));
    };

    return (
      <TableBody>
        {viewData.map((data, index) => (
          <TableRow
            key={index}
            onClick={() => handleRowClick(index)}
            style={{ cursor: "pointer" }}
          >
            {Object.entries(data)
              .filter(
                ([key, value]) =>
                  !(isSmallScreen && isTableView && key === "comments")
              )
              .map(([key, value], innerIndex) => (
                <React.Fragment key={innerIndex}>
                  {renderTableCell(value)}
                </React.Fragment>
              ))}
          </TableRow>
        ))}
      </TableBody>
    );
  };

  const renderCardBody = () => {
    if (!viewData || viewData.length === 0) return null;

    const renderCardContent = (key: string, value: any) => (
      <div key={key}>
        {typeof value === "string" && isValidImageUrl(value) ? (
          <img
            src={value}
            alt={`${key}`}
            style={{
              cursor: "pointer",
              width: "150px",
              height: "170px",
              objectFit: "cover",
              margin: "10px",
            }}
            onClick={() => handleImageClick()}
          />
        ) : (
          <Typography variant="body2" key={key}>
            <strong>
              {key.toLowerCase().includes("vehicle number")
                ? "Vehicle Number"
                : key}
              :
            </strong>{" "}
            {typeof value === "object" ? (
              <div>{renderNestedItems(value)}</div>
            ) : (
              value
            )}
          </Typography>
        )}
      </div>
    );

    const renderNestedItems = (data: any) => {
      return Object.entries(data).map(([key, value]) => (
        <div key={key}>{renderCardContent(key, value)}</div>
      ));
    };

    return (
      <Grid container spacing={3}>
        {viewData.map((data, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
            <Card
              style={{ cursor: "pointer" }}
              onClick={() => handleRowClick(index)}
            >
              <CardContent>
                {Object.entries(data).map(([key, value], innerIndex) => (
                  <React.Fragment key={innerIndex}>
                    {renderCardContent(key, value)}
                  </React.Fragment>
                ))}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderSlideImage = (key: string, imageUrl: string) => {
    return isValidImageUrl(imageUrl) ? (
      <div>
        <div
          style={{
            position: "relative",
            textAlign: "center",
            margin: "25px",
          }}
        >
          <Typography variant="body2" key={key} style={{ color: "white" }}>
            {key}:
          </Typography>

          <img
            src={imageUrl}
            alt="Popup"
            style={{
              width: "100%",
              objectFit: "contain",
            }}
            onClick={() => handleImageClick()}
          />
        </div>
      </div>
    ) : null;
  };

  const isValidImageUrl = (url: string) => {
    if (typeof url !== "string") {
      return false;
    }

    return /\.(jpg|jpeg|png|gif)$/.test(url.toLowerCase());
  };

  const renderDialogContent = () => {
    if (!viewData) return null;

    const totalRows = viewData.length;

    const renderDialogItem = (key: string, value: any) => (
      <div key={key}>
        {isValidImageUrl(value) && renderSlideImage(key, value as string)}
        {!key.startsWith("Upload") && (
          <Typography variant="body2" key={key}>
            <strong>
              {key.toLowerCase().includes("vehicle number")
                ? "Vehicle Number:"
                : key}
              :
            </strong>{" "}
            {typeof value === "object" ? (
              <div>{renderNestedItems(value)}</div>
            ) : (
              value
            )}
          </Typography>
        )}
      </div>
    );

    const renderNestedItems = (data: any) => {
      return Object.entries(data).map(([key, value]) => (
        <div key={key}>{renderDialogItem(key, value)}</div>
      ));
    };

    return (
      <DialogContent>
        <DialogContentText>
          {currentRow > 0 && (
            <IconButton
              onClick={handlePrevRow}
              style={{
                position: "absolute",
                left: "-10px",
                top: "50%",
                transform: "translateY(-50%)",
                fontSize: "40px",
              }}
            >
              <ChevronLeftIcon fontSize="inherit" />
            </IconButton>
          )}

          {Object.entries(viewData[currentRow]).map(([key, value]) => (
            <React.Fragment key={key}>
              {renderDialogItem(key, value)}
            </React.Fragment>
          ))}

          {currentRow < totalRows - 1 && (
            <IconButton
              onClick={handleNextRow}
              style={{
                position: "absolute",
                right: "-10px",
                top: "50%",
                transform: "translateY(-50%)",
                fontSize: "40px",
              }}
            >
              <ChevronRightIcon fontSize="inherit" />
            </IconButton>
          )}
        </DialogContentText>
      </DialogContent>
    );
  };

  return (
    <Container>
      {additionalInformation && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ margin: "10px", marginLeft: "0" }}
          >
            <img
              src={additionalInformation.companyLogo ?? ""}
              alt="Popup"
              style={{ width: "60px", height: "30px" }}
            />{" "}
            {additionalInformation.companyName}
          </Typography>
          <div>
            <Typography sx={{ margin: "10px", textAlign: "end" }}>
              Total Count: {additionalInformation.totalCount}
            </Typography>
            <div style={{ margin: "10px", textAlign: "end" }}>
              Page Size:{" "}
              <select value={pageSize} onChange={handlePageSizeChange}>
                {[12, 24, 36].map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>
            <Button variant="contained" onClick={() => redirectToPDF()}>
              Generate full report
            </Button>
          </div>
        </div>
      )}

      <div>
        <Tabs value={isTableView ? 1 : 0} onChange={handleViewChange}>
          <Tab label="Card View" />
          <Tab label="Table View" />
        </Tabs>

        {isTableView ? (
          <div>
            <TableContainer component={Paper}>
              <Table>
                {renderHeader()}
                {renderTableBody()}
              </Table>
            </TableContainer>
            <div
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Pagination
                count={Math.ceil(
                  (additionalInformation?.totalCount || 0) / pageSize
                )}
                page={currentPage}
                onChange={(event, value) => handlePageChange(value)}
              />
            </div>
          </div>
        ) : (
          <div>
            {renderCardBody()}
            <div
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Pagination
                count={Math.ceil(
                  (additionalInformation?.totalCount || 0) / pageSize
                )}
                page={currentPage}
                onChange={(event, value) => handlePageChange(value)}
              />
            </div>
          </div>
        )}
      </div>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Selected Details</DialogTitle>
        {renderDialogContent()}
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default GigResult;
