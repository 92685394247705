import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SpotrueApiBaseUrl } from "./baseUrls";

export default function upateReportUrl(
  gogigReqId: string,
  requesterId: string,
  authHeader: string,
  resultUrl: string
) {
  const url = `${SpotrueApiBaseUrl}/updateReportUrl?gogigReqId=${gogigReqId}&requesterId=${requesterId}`;

  return fetchWrapper(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader,
    },
    body: {
      resultUrl: resultUrl,
    },
  });
}
