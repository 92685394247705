export const preSetQuestionnaire = {
    "branding": {
      "Q1": {
        "type": "boolean",
        "answer": [],
        "question": "Do you have a smartphone with a camera and a good internet connection?",
        "isMandatory": 1
      }
    },
    "survey": {
      "Q1": {
        "type": "boolean",
        "answer": [],
        "question": "Do you have a smartphone with a camera and a good internet connection?",
        "isMandatory": 1
      },
      "Q2": {
        "type": "boolean",
        "answer": [],
        "question": "Do you have a two-wheeler?",
        "isMandatory": 1
      }
    },
    "onSiteDataCollection": {
      "Q1": {
        "type": "boolean",
        "answer": [],
        "question": "Do you have a smartphone with a camera and a good internet connection?",
        "isMandatory": 1
      },
      "Q2": {
        "type": "boolean",
        "answer": [],
        "question": "Do you have a two-wheeler?",
        "isMandatory": 1
      }
    },
    "telecalling": {
      "Q1": {
        "type": "boolean",
        "answer": [],
        "question": "Do you have a smartphone with a camera and a good internet connection?",
        "isMandatory": 1
      },
      "Q2": {
        "type": "rating",
        "answer": [],
        "question": "How much do you rate yourself in English?",
        "isMandatory": 1
      }
    }
  }
  