import { Capability } from "@gogig-in/gogig-clients";
import {
  Box,
  Chip,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

interface CapabilitiesSelectorProps {
  label: string;
  capabilities: Capability[];
  selectedCapabilityIds: number[];
  onChange: (capabilityIds: number[]) => void;
  onDelete: (capabilityId: number) => void;
}

export default function CapabilitiesSelector({
  label,
  capabilities,
  selectedCapabilityIds,
  onChange,
  onDelete,
}: CapabilitiesSelectorProps) {
  const handleChange = (event: any) => {
    const capabilityIds = event.target.value as number[];

    onChange(capabilityIds);
  };

  const getLabelByCapabilityId = (id: number) => {
    const capability = capabilities.find((c) => c.capabilityId === id);
    return capability ? capability.capabilityName : "";
  };

  return (
    <Box>
      <Box>
        <Typography variant="h5">{label}</Typography>
      </Box>
      <Box>
        <FormControl sx={{ m: 1, width: 300 }}>
          <Select
            multiple
            value={selectedCapabilityIds}
            onChange={handleChange}
          >
            {capabilities.map((capability) => (
              <MenuItem
                key={capability.capabilityId}
                value={capability.capabilityId}
              >
                {capability.capabilityName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant="body1" sx={{ m: 1 }}>
          {selectedCapabilityIds.map((capabilityId) => (
            <Chip
              key={capabilityId}
              label={getLabelByCapabilityId(capabilityId)}
              onDelete={() => onDelete(capabilityId)}
              sx={{ m: 0.5 }}
            />
          ))}
        </Typography>
      </Box>
    </Box>
  );
}
