import React from 'react';
import { Avatar } from '@mui/material';

interface Props {
  firstName?: string;
  lastName?: string;
  imageUrl?: string;
}
  
const CircularImageView: React.FC<Props> = ({ firstName, lastName, imageUrl }) => {
  const firstLetterFirstName = firstName?.charAt(0).toUpperCase();
  const firstLetterLastName = lastName?.charAt(0).toUpperCase();

  return (
    <Avatar alt={`${firstName} ${lastName}`} src={imageUrl} sx={{ width: 150, height: 150 , backgroundColor: 'lightBlue'}}>
      {imageUrl ? null : (
        <>
          {firstLetterFirstName}
          {firstLetterLastName}
        </>
      )}
    </Avatar>
  );
};

export default CircularImageView;
