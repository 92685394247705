import { Box, Typography } from "@mui/material";
import React from "react";
import { ReportTemplate } from "./ReportComponents/ReportTemplate";

const BasicReportPage2 = () => {
  return (
    <ReportTemplate
      children={
        <Box sx={{ padding: 2}}>
          {/* CONDITIONS OF REPORTING */}
          <Typography
            variant="h6"
            align="center"
            sx={{ fontWeight: "bold", marginBottom: 2 }}
          >
            CONDITIONS OF REPORTING
          </Typography>
          <Typography variant="body2" paragraph fontSize={14}>
            - The reported results are for information and interpretation of the
            referring client only. <br />
            - It is presumed that the tests performed on the specimen belong to
            the client; named or identified. <br />
            - Should the results indicate an unexpected abnormality, the same
            should be reconfirmed. <br />- Only such professionals who
            understand reporting units, reference ranges and limitations of
            technologies should interpret results. <br />
            - This report is not valid for legal purpose. <br />
            - Neither gQGig, nor its employees/representatives assume any
            liability, responsibility for any loss or damage that may be
            incurred by any person as a result of presuming the meaning or
            contents of the report. <br />- For support please contact between
            10:00 to 18:00.
          </Typography>

          {/* EXPLANATIONS */}
          <Typography
            variant="h6"
            align="center"
            sx={{ fontWeight: "bold", marginTop:2, marginBottom: 2 }}
            fontSize={14}
          >
            EXPLANATIONS
          </Typography>
          <Typography fontSize={14} variant="body2" paragraph>
            Majority of the images processed by the tool are collected by
            Engineers and who provide the excel or ppt we call them as
            “Clients”. <br />
            <strong>Client Name</strong> - The name is as declared by the client
            and recorded by the personnel who collected the excel or ppt. <br />
            <strong>Test Case Author</strong> - The name of the personnel who
            has recommended testing as declared by the client. <br />
            <strong>Test Case Reviewer</strong> - The name of the personnel who
            has reviewed testing as declared by the gQGig. <br />
            <strong>Credits Used</strong> - The number of credits used for
            performing this test. <br />
            <strong>Credits left</strong> - The number of credits left in the
            user account. <br />
            <strong>Test Case ID</strong> - This is the accession number in our
            tool and it helps us in archiving and retrieving the data. <br />
            <strong>Test Processing</strong> - The number of times processing
            performed to complete this test. <br />
            <strong>Execution Status</strong> - ‘Pass’ means all the tests were
            performed successfully, ‘Failed’ means not all the tests were
            performed successfully. <br />
            <strong>Input Data</strong> - This is the images quantity inserted
            and it states that the results are for the images bearing the Test
            Case ID (irrespective of the name). <br />
            <strong>Expected Results</strong> - The expected number of images as
            per the input data which should be read by the tool. <br />
            <strong>Actual Results</strong> - The actual number of images as per
            the input data which were read by the tool. <br />
            <strong>Preconditions</strong> - Determines that if previous data
            was cleared before inserting the new one. <br />
            <strong>Initiation Date</strong> - The date when the excel or ppt
            was uploaded. <br />
            <strong>Completion Date</strong> - The date when the output data was
            downloaded. <br />
            <strong>Completion Time</strong> - The time when the output data was
            downloaded. <br />
            <strong>Test Environment</strong> - Confidential <br />
            <strong>Uncertain Images</strong> - The number of images with bad
            condition maybe not visible properly. <br />
            <strong>Tampered Images</strong> - The number of images found
            Tampered. <br />
            <strong>Correct Images</strong> - The number of images found
            correct. <br />
            <strong>Correct Images %</strong> - Determines the percentage of
            images where tamperedness is observed. <br />
            <strong>Uncertain Images %</strong> - Determines the percentage of
            images with bad condition maybe not visible properly.
          </Typography>

          {/* SUGGESTIONS */}
          <Typography
            variant="h6"
            align="center"
            sx={{ fontWeight: "bold", marginTop: 4 }}
            fontSize={14}
          >
            SUGGESTIONS
          </Typography>
          <Typography variant="body2" paragraph fontSize={14}>
            Values out of reference range requires reconfirmation before
            starting any verification process. <br />
            Retesting is needed if you suspect any quality shortcomings. <br />
            Testing or retesting should be done in allotted time slots. <br />
            For suggestions, complaints or feedback, write to us at
            contact@gogig.in with &lt;Test Case ID&gt;.
          </Typography>
        </Box>
      }
    />
  );
};

export default BasicReportPage2;
