export const ApiBaseUrl = process.env.REACT_APP_API_BASE_URL;
export const UsersApiBaseUrl = process.env.REACT_APP_USERS_API_BASE_URL;
export const PaymentsApiBaseUrl = process.env.REACT_APP_PAYMENTS_API_BASE_URL;
export const UploadsApiBaseUrl = process.env.REACT_APP_UPLOADS_API_BASE_URL;
export const AuthApiBaseUrl = process.env.REACT_APP_AUTH_API_BASE_URL;
export const WhatsAppBaseNumber = process.env.REACT_APP_WHATSAPP_NUMBER;
export const GigsApiBaseUrl = process.env.REACT_APP_GIGS_API_BASE_URL;
export const GingerMediaApiBaseUrl =
  process.env.REACT_APP_GINGER_MEDIA_API_BASE_URL;
export const SpotrueApiBaseUrl = process.env.REACT_APP_SPOTRUE_API_BASE_URL;
export const clientId = process.env.REACT_APP_CLIENT_ID;
export const SpotrueApiStorageBaseUrl= process.env.REACT_APP_STORAGE_API_BASE_URL;