import React, { useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Logo } from "../logo";
import { Navigation } from "../navigation";
import ProfileMenu from "./ProfileMenu";
import MenuIcon from "@mui/icons-material/Menu";
import SideNavigation from "../navigation/SideNavigation";
import SignUpButton from "../SignUpButton";
import { useAuthContext } from "../../providers/AuthProvider";
import UserRoleChip from "./UserRoleChip";
import { userRoles } from "../../types/userRoles";

const Header = () => {
  const [visibleMenu, setVisibleMenu] = useState(false);
  const { breakpoints } = useTheme();
  const matchMobileView = useMediaQuery(breakpoints.down("md"));
  const closeMobileMenu = () => {
    setVisibleMenu(false);
  };
  const [isNavOpen, setIsNavOpen] = useState(false);
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  const { userDetails, isAuthenticated } = useAuthContext();
  

  return (
    <Box sx={{ backgroundColor: "#F5F7FA" }}>
      <Container sx={{ py: { xs: 1, md: 1 } }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            {matchMobileView && (
              <IconButton edge="start" color="inherit" onClick={toggleNav}>
                <MenuIcon />
              </IconButton>
            )}
            <Box sx={{ marginLeft: matchMobileView ? "10px" : 0 }}>
              <Logo variant="secondary" />
            </Box>
          </Box>
          {matchMobileView && (
            <Box
              sx={{
                display: "flex",
                alignItems: "end",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {userDetails && (
                <UserRoleChip
                  userDetails={{
                    role: userDetails?.role,
                  }}
                  userRoles={{
                    admin: userRoles.admin,
                    operations: userRoles.operation,
                  }}
                />
              )}
            </Box>
          )}

          <Box>
            {matchMobileView && <>{!isAuthenticated && <SignUpButton />}</>}
          </Box>
          <SideNavigation
            profileData={userDetails || undefined}
            isOpen={isNavOpen}
            onClose={toggleNav}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              transition: (theme) => theme.transitions.create(["top"]),
              ...(matchMobileView && {
                py: 6,
                backgroundColor: "background.paper",
                zIndex: "appBar",
                position: "fixed",
                height: "auto",
                top: visibleMenu ? 0 : "-120vh",
                left: 0,
              }),
            }}
          >
            <Box
              sx={{
                width: "80%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <Navigation closeMobileMenu={closeMobileMenu} />
            </Box>
            {!visibleMenu && !matchMobileView && (
              <Box
                sx={{
                  marginLeft: "auto",
                  display: "flex",
                  alignItems: "center",
                }}
                className="menu-icon"
              >
                <ProfileMenu
                  profileData={userDetails}
                  closeMobileMenu={closeMobileMenu}
                  onClick={closeMobileMenu}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Header;
