import React, { useState } from "react";
import {
  Button,
  Typography,
  Container,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
} from "@mui/material";
import * as XLSX from "xlsx";
import { uploadWithdrawRequestData } from "../../clients/uploadWithdrawRequestData";
import GlobalSnackbar, {
  Severity,
} from "../../components/snackbarComponent/Snackbar";
import theme from "../../theme";
import { useNavigate } from "react-router-dom";

const UploadPaymentSheet: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [sheetData, setSheetData] = useState<any[]>([]);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarData, setSnackbarData] = useState<{
    message: string;
    severity: Severity;
  }>({ message: "", severity: undefined });
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const snackbar = (message: string, severity: Severity) => {
    setSnackbarData({ message, severity });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      if (
        selectedFile.type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        setError("Please select a valid .xlsx file.");
        setFile(null);
      } else {
        setFile(selectedFile);
        setError(null);
        previewSheet(selectedFile);
      }
    }
  };

  // Preview the first sheet of the uploaded file
  const previewSheet = (file: File) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target?.result) {
        const data = new Uint8Array(event.target.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        setSheetData(jsonData.slice(0, 5)); // Preview first 5 rows
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleUpload = () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      // Handle the uploaded file as needed
      uploadData(formData);
      console.log("Processing uploaded file...");
    }
  };

  const uploadData = async (dataFile: FormData) => {
    try {
      const response: any = await uploadWithdrawRequestData(dataFile);
      if (response.data.message === "Data updated") {
        setOpenSnackbar(true);
        snackbar("uploaded data successfully", "success");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    } catch (error) {
      console.error({ error });
      setOpenSnackbar(true);
      snackbar("Failed to upload the Sheet", "error");
    }
  };

  return (
    <Container component="main" maxWidth={isMobile ? "xs" : "md"}>
      <GlobalSnackbar
        open={openSnackbar}
        message={snackbarData.message}
        onClose={() => setOpenSnackbar(false)}
        severity={snackbarData.severity}
        vertical={"top"}
        horizontal={"center"}
      />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5" textAlign={"center"}>
          Upload the Completed Payment Transactions from the partnered Bank
        </Typography>
        <input
          type="file"
          accept=".xlsx"
          onChange={handleFileChange}
          style={{ marginTop: "20px", marginBottom: "20px" }}
        />
        {error && (
          <Typography color="error" variant="subtitle2">
            {error}
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={!file}
          style={{ marginBottom: "20px" }}
        >
          Upload
        </Button>
        {sheetData.length > 0 && (
          <TableContainer
            component={Paper}
            sx={{ maxWidth: "100%", overflowX: "auto" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {sheetData[0].map((header: any, index: any) => (
                    <TableCell key={index}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sheetData.slice(1).map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {row.map((cell: any, cellIndex: any) => (
                      <TableCell key={cellIndex}>{cell}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Container>
  );
};

export default UploadPaymentSheet;
