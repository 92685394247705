import { Grid } from "@mui/material";
import ReportTextComponent from "../reportTextComponent";
import { ClientInfoProps } from "../reportComponentProps";


export const ClientInfo: React.FC<{ data: ClientInfoProps }> = ({
    data,
  }) => (
    <Grid container spacing={2} sx={{ padding: 2 }}>
      <Grid item xs={6}>
        <ReportTextComponent text="Client Name" subString={data?.clientName} />
        <ReportTextComponent text="Priority" subString={data?.priority} />
        <ReportTextComponent
          text="Test Execution"
          subString={data?.testExecution}
        />
      </Grid>
      <Grid item xs={6}>
        <ReportTextComponent
          text="Test Case Author"
          subString={data?.testCaseAuthor}
        />
        <ReportTextComponent
          text="Test Case Reviewer"
          subString={data?.testCaseReviewer}
        />
        <ReportTextComponent
          text="Test Case Version"
          subString={data?.testCaseReviewer}
        />
      </Grid>
    </Grid>
  );
