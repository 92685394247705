import React from "react";
import { Typography, Divider, styled, TableCell } from "@mui/material";

interface ModelDisplayProps {
  dataFromPhoto: string | null;
  dataFromSource: string | null;
}

const StyledBodyCell = styled(TableCell)(() => ({
  border: "1px solid #ccc",
  textAlign: "center",
  justifyContent: "center",
}));

const SourcePhotoFieldCell = ({
  dataFromPhoto,
  dataFromSource,
}: ModelDisplayProps) => {
  return (
    <StyledBodyCell
      sx={{
        position: "relative",
      }}
    >
      <span
        style={{
          position: "absolute",
          left: 4,
          color: "green",
        }}
      >
        <strong>P</strong>
      </span>
      <Typography>{dataFromPhoto ?? "N/A"}</Typography>
      <Divider
        sx={{
          borderBottomWidth: 3,
          borderColor: "darkgrey",
          my: 2,
        }}
      />
      <span
        style={{
          position: "absolute",
          left: 4,
          color: "green",
        }}
      >
        <strong>S</strong>
      </span>
      <Typography>{dataFromSource ?? "N/A"}</Typography>
    </StyledBodyCell>
  );
};

export default SourcePhotoFieldCell;
