import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  styled,
  Typography,
  Box,
  Button
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import BusinessCenterTwoToneIcon from "@mui/icons-material/BusinessCenterTwoTone";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../providers/AuthProvider";
import { getAllGigDetails } from "@gogig-in/gogig-clients";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    padding: "10px 20px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0 16px",
  },
}));

const FirstColumnStyledTableCell = styled(StyledTableCell)({
  borderRight: "1px solid #ddd",
  width: "260px",
  height: "50px",
});

const GigListingOps: React.FC = () => {
  const [gigData, setGigData] = useState<any[]>([]);
	const {authHeader} = useAuthContext();
  const history = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        if (authHeader) {
          const response: any = await getAllGigDetails(authHeader);
          setGigData(response.data);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [authHeader]);

  return (
    <Container sx={{ marginTop: "30px" }}>
      <Box sx={{display: "flex", justifyContent: "space-Between"}}>
        <Typography variant="h4" sx={{ marginBottom: "15px" }}>
          Manage Gigs
        </Typography>
        <Link to={"/create-gig/basic-details"}>
          <Button color="primary" variant="contained" sx={{borderRadius: "22px"}}>Create Gig</Button>
        </Link>
      </Box>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <FirstColumnStyledTableCell>Gig Title</FirstColumnStyledTableCell>
              <StyledTableCell>Company Name</StyledTableCell>
              <StyledTableCell>Created By</StyledTableCell>
              <StyledTableCell>Category</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gigData.map((gigDetails) => (
              <TableRow
                key={gigDetails.gigId}
                sx={{ backgroundColor: "#FEFDFD" }}
              >
                <FirstColumnStyledTableCell>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      maxWidth: "320px",
                      padding: "5px 0px",
                    }}
                  >
                    {gigDetails.companyLogo ? (
                      <img
                        src={gigDetails.companyLogo}
                        alt={`Logo of ${gigDetails.companyName}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          maxWidth: "40px",
                          maxHeight: "40px",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <BusinessCenterTwoToneIcon
                        style={{
                          width: "100%",
                          height: "100%",
                          maxWidth: "40px",
                          maxHeight: "40px",
                        }}
                      />
                    )}
                    <Typography
                      variant="h6"
                      sx={{
                        marginLeft: "8px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {gigDetails.gigTitle}
                    </Typography>
                    {/* <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                      size="small"
                      sx={{ marginLeft: "auto" }}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      PaperProps={{
                        sx: {
                          minWidth: "150px",
                          borderRadius: "7px",
                          boxShadow: "none",
                          backgroundColor: "white",
                          border: "1px solid #ddd",
                        },
                      }}
                    >
                      <MenuItem sx={{ padding: "5px 16px" }}>
                        Edit Company Details
                      </MenuItem>
                      <MenuItem
                        sx={{
                          padding: "5px 16px",
                          color: "red",
                        }}
                      >
                        Remove Company
                      </MenuItem>
                    </Menu> */}
                  </Box>
                </FirstColumnStyledTableCell>
                <StyledTableCell>
                  {gigDetails.companyName ? (
                    gigDetails.companyName
                  ) : (
                    <Typography variant="body2">.....</Typography>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {gigDetails.firstName && gigDetails.lastName ? (
                    `${gigDetails.firstName} ${gigDetails.lastName}`
                  ) : (
                    <Typography variant="body2">.....</Typography>
                  )}
                </StyledTableCell>

                <StyledTableCell>
                  {gigDetails.category ? (
                    gigDetails.category
                  ) : (
                    <Typography variant="body2">.....</Typography>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  <Typography>{gigDetails.status}</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                    size="medium"
                    sx={{ textTransform: "none" }}
                    onClick={() => {
                      history(`/manage-gigs/${gigDetails.gigId}`);
                    }}
                  >
                    Manage Gig
                  </Button>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default GigListingOps;
