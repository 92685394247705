import React from "react";
import { ReportTemplate } from "./ReportComponents/ReportTemplate";
import { PieChartComponent } from "./components/PieChartComponent";
import { ReportTestDetails } from "./components/ReportTestDetails";
import { ReportDescription } from "./components/ReportDescription";
import {
  ClientInfoProps,
  ReportTestDetailsProps,
  PieChartComponentProps,
  AuditReportProps,
} from "./reportComponentProps";
import { ClientInfo } from "./components/ClientInfo";

interface ReportProps {
  auditData: AuditReportProps;
}

const BasicReport = ({ auditData }: ReportProps) => {
  return (
    <>
      {auditData && (
        <ReportTemplate>
          <ClientInfo data={auditData as ClientInfoProps} />
          <ReportTestDetails data={auditData as ReportTestDetailsProps} />
          <PieChartComponent data={auditData as PieChartComponentProps} />
          <ReportDescription />
        </ReportTemplate>
      )}
    </>
  );
};

export default BasicReport;
