import { Box, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import WorkExperience from "./WorkExperience";
import SkillSets from "./SkillSets";
import PersonalDetails from "./PersonalDetails";
import ProfileDetailsReview from "./ProfileDetailsReview";
import { ProfileCreationStep } from "./types";
import { ProfileCreationStepper } from "./ProfileCreationStepper";
import { useAuthContext } from "../../providers/AuthProvider";
import {
  createProfile,
  getProfile,
  getUser,
  saveUser,
} from "@gogig-in/gogig-clients";

export interface FormValues {
  phoneNumber: number;
  gender: string;
  dob: string;
  workLocation: string;
  workExperience: string;
  lastCompany: string;
  lastRole: string;
  skillSets: string;
  languages: string;
}

const initialFormValues: FormValues = {
  phoneNumber: 0,
  gender: "",
  dob: "",
  workLocation: "",
  workExperience: "",
  lastCompany: "",
  lastRole: "",
  skillSets: "",
  languages: "",
};

const profileCreationSteps: ProfileCreationStep[] = [
  {
    stepName: "work-experience",
    nextStepLabel: "add your skills",
  },
  {
    stepName: "skills",
    nextStepLabel: "add Personal Details",
  },
  {
    stepName: "personal-details",
    nextStepLabel: "save and preview",
  },
  {
    stepName: "review",
    nextStepLabel: "Submit",
  },
];

const UserEntryForm = () => {
  const { stepName } = useParams();
  const initialStep =
    profileCreationSteps.find((s) => s.stepName === stepName) ||
    profileCreationSteps[0];
  const [emptyFieldsError, setEmptyFieldsError] = useState("");
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [profileDetails, setProfileDetails] =
    useState<FormValues>(initialFormValues);
  const history = useNavigate();
  const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null);
  const {
    workExperience,
    skillSets,
    languages,
    lastCompany,
    lastRole,
    workLocation,
    dob,
    phoneNumber,
    gender,
  } = profileDetails;
  const { authHeader } = useAuthContext();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "phoneNumber") {
      const phoneNumberError =
        isNaN(Number(value)) || value.length !== 10
          ? "Phone number must be 10 digits"
          : null;
      setPhoneNumberError(phoneNumberError);
    }
    setProfileDetails({ ...profileDetails, [name]: value });
  };

  useEffect(() => {
    const fetchExistingDetails = async () => {
      if (authHeader) {
        const stepDetails: any = await getProfile(authHeader);
        if (stepDetails.data[0]) {
          setProfileDetails(stepDetails.data[0]);
        }
        const userDetails: any = await getUser(authHeader);
        const { phoneNumber, dob, gender } = userDetails.data;

        // Merge user-specific details into profileDetails
        setProfileDetails((prevDetails) => ({
          ...prevDetails,
          phoneNumber,
          dob,
          gender,
        }));
      }
    };

    fetchExistingDetails();
  }, [authHeader]); // Trigger the effect when authHeader changes

  const handleNext = async () => {
    const stepRequiredFields: Record<string, Array<keyof FormValues>> = {
      "work-experience": ["workExperience"],
      skills: ["languages"],
      "personal-details": ["workLocation", "dob", "phoneNumber", "gender"],
    };

    const currentStepRequiredFields =
      stepRequiredFields[currentStep.stepName] || [];
    let stepError = false;

    if (currentStep.stepName === "work-experience") {
      const visibleFields: Array<keyof FormValues> = [
        "lastCompany",
        "lastRole",
      ];
      visibleFields.forEach((field) => {
        if (
          profileDetails.workExperience !== "" &&
          profileDetails.workExperience !== "I haven't worked before"
        ) {
          currentStepRequiredFields.push(field);
        }
      });
    }

    const stepMissingFields = currentStepRequiredFields.filter(
      (field) => !profileDetails[field]
    );
    if (
      stepMissingFields.length > 0 ||
      (currentStep.stepName === "work-experience" &&
        !profileDetails.workExperience)
    ) {
      stepError = true;
      setEmptyFieldsError("Please fill out all required fields.");
    } else {
      setEmptyFieldsError("");
    }

    if (!stepError && !phoneNumberError) {
      const currentStepIndex = profileCreationSteps.indexOf(currentStep);
      if (currentStepIndex < profileCreationSteps.length - 1) {
        const nextStep = profileCreationSteps[currentStepIndex + 1];
        setCurrentStep(nextStep);
        if (authHeader) {
          let shouldStartWithPatch = false;
          const stepDetails: any = await getProfile(authHeader);
          if (stepDetails.data[0]) {
            shouldStartWithPatch = true;
          }

          if (shouldStartWithPatch) {
            const gigSeekerSpecificDetails = {
              workExperience,
              workLocation,
              languages,
              lastCompany,
              lastRole,
              skillSets,
            };

            // Start with PATCH calls
            await createProfile(gigSeekerSpecificDetails, authHeader, "PATCH");

            const { phoneNumber, dob, gender } = profileDetails;
            const userSpecificDetails = { phoneNumber, dob, gender };
            if (userSpecificDetails.dob !== null) {
              await saveUser(authHeader, userSpecificDetails);
            }
          } else {
            // Start with POST and then PATCH
            const gigSeekerSpecificDetails = {
              workExperience,
              workLocation,
              languages,
              lastCompany,
              lastRole,
              skillSets,
            };

            // Start with PATCH calls
            await createProfile(gigSeekerSpecificDetails, authHeader, "POST");

            const { phoneNumber, dob, gender } = profileDetails;
            const userSpecificDetails = { phoneNumber, dob, gender };
            if (userSpecificDetails.dob !== null) {
              await saveUser(authHeader, userSpecificDetails);
            }
          }
        } else {
          console.log("No auth token");
        }
      } else {
        history("/user-entry/profile-created-page");
      }
    }
  };

  const handleBack = () => {
    const currentStepIndex = profileCreationSteps.indexOf(currentStep);
    if (currentStepIndex > 0) {
      const previousStep = profileCreationSteps[currentStepIndex - 1];
      setCurrentStep(previousStep);
    }
  };

  useEffect(() => {
    history(`/user-entry/user-entry-form/${currentStep.stepName}`);
  }, [currentStep, history]);

  const handleSkillChange = (
    event: React.ChangeEvent<{}>,
    newValue: string[] | null
  ) => {
    if (newValue !== null) {
      const selectedSkills = newValue
        .filter((lang) => lang.trim() !== "")
        .join(", ");
      setProfileDetails({ ...profileDetails, skillSets: selectedSkills });
    }
  };

  const handleLanguagesChange = (
    event: React.ChangeEvent<{}>,
    newValue: string[] | null
  ) => {
    if (newValue !== null) {
      const selectedLanguages = newValue
        .filter((lang) => lang.trim() !== "")
        .join(", ");
      setProfileDetails({ ...profileDetails, languages: selectedLanguages });
    }
  };

  const RenderQuestionsForStep = () => {
    switch (currentStep.stepName) {
      case "work-experience":
        return (
          <WorkExperience
            experienceDetails={{
              workExperience: workExperience,
              lastCompany: lastCompany,
              lastRole: lastRole,
            }}
            handleChange={handleChange}
            emptyFieldsError={emptyFieldsError}
          />
        );
      case "skills":
        return (
          <SkillSets
            skillSets={skillSets}
            languages={languages}
            handleLanguagesChange={handleLanguagesChange}
            handleSkillChange={handleSkillChange}
            emptyFieldsError={emptyFieldsError}
          />
        );
      case "personal-details":
        return (
          <PersonalDetails
            profileDetails={{
              workLocation: workLocation,
              dob: dob,
              phoneNumber: phoneNumber,
              gender: gender,
              firstName: "vishal",
              lastName: "desai",
              profileUrl: "hello",
            }}
            handleChange={handleChange}
            phoneNumberError={phoneNumberError}
            emptyFieldsError={emptyFieldsError}
          />
        );
      case "review":
        return <ProfileDetailsReview profileDetails={profileDetails} />;
      default:
        return null;
    }
  };

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          height: { xs: "calc(100vh - 230px)", md: "calc(100vh - 355px)" },
          p: { md: 10 },
        }}
      >
        {RenderQuestionsForStep()}
      </Box>
      <ProfileCreationStepper
        profileCreationSteps={profileCreationSteps}
        currentStep={currentStep}
        handleNext={handleNext}
        handleBack={handleBack}
      />
    </Container>
  );
};

export default UserEntryForm;
