import {
  Card,
  CardContent,
  Typography,
  Box,
  Stack,
  List,
  ListItem,
  Divider,
} from "@mui/material";
import { Info } from "@mui/icons-material";
import { ProcessTimestamps } from "./ProcessFlowUi";

// Define prop types for GuideLines
interface GuideLinesProps {
  status: string;
  isReRun?: boolean;
  resultUrl?: string;
  isReportGeneratedAgain?: boolean;
  timestamps: ProcessTimestamps;
  isAutoRerun?:boolean;

}

export const GuideLines: React.FC<GuideLinesProps> = ({
  status,
  isReRun,
  resultUrl,
  isReportGeneratedAgain,
  timestamps,
  isAutoRerun
}) => {
  const getGuidelineText = (stage: string) => {
    switch (stage) {
      case "automaticpreprocessing":
        return {
          title: "Automatic Pre-Processing",
          description: isAutoRerun
            ? "Automatic Preprocessing has been updated. complete the automatic pre process again."
            : "You are currently in the Automatic Pre-Processing stage.",
          nextStep: isAutoRerun
            ? "Automatic Preprocessing has been updated. run the commands again."
            : "You are currently in the Automatic Pre-Processing stage.run all the commands.",
        };
        case "automaticpreprocessed":
          return {
            title: "Automatic Pre-Processing Completed",
            description: "Automatic Pre-Processing has been successfully completed.",
            nextStep:
              "Click on the 'START PROCESSING' button to start the manual processing.",
          };


      case "manualpreprocessing":
        return {
          title: "Manual Pre-Processing",
          description: isReRun
            ? "Preprocessing has been updated. end the pre process again."
            : "You are currently in the Manual Pre-Processing stage.",
          nextStep: isReRun
            ? "Preprocessing has been updated. Click on the 'START PRE-PROCESSING' button again. Please review the data and click 'END PRE-PROCESSING' button to proceed to the next stage."
            : "You are currently in the Manual Pre-Processing stage. Click on the 'START PRE-PROCESSING' button, review the data, and click 'END PRE-PROCESSING' button to proceed to the next stage.",
        };

      case "manualpreprocessed":
        return {
          title: "Manual Pre-Processing Completed",
          description: "Manual Pre-Processing has been successfully completed.",
          nextStep:
            "Click on the 'START PROCESSING' button to start the manual processing.",
        };

      case "processing":
        return {
          title: "Processing",
          description: "Processing is in progress.",
          nextStep:
            "Once Processing is complete, you’ll proceed to Post-Processing.",
        };

      case "processed":
        return {
          title: "Processing Completed",
          description:
            "Processing has been successfully completed. You can now proceed to Post-Processing.",
          nextStep: "Click on the 'START MATCHING' button.",
        };

      case "postprocessing":
        return {
          title: "Post-Processing",
          description:
            "Post-Processing is ongoing. Please wait for it to complete or check the results.",
          nextStep:
            "When this step completes, review the report and proceed as necessary.",
        };

      case "postprocessed":
        const isReportUploaded = resultUrl;
        const isReportRegenerated = isReportUploaded && isReportGeneratedAgain;
        const isReportNotGenerated = isReportUploaded &&!timestamps.reportGeneratedAt;
        const isReportNotUploaded = !isReportUploaded;
        return {
          title: "Post-Processing Completed",
          
          description: isReportRegenerated
          ? "The entire process has been rerun. Please upload the report again."
          : isReportNotGenerated
          ? "The report was uploaded before it was generated."
          : isReportNotUploaded
          ? "Post-processing is complete. Please generate and upload the report."
          : "The report has been successfully uploaded.",
          nextStep: isReportRegenerated
          ? "To upload the new report, please delete the previous one.\nStep 1: Click the 'DELETE REPORT' button to remove the old report."
          : isReportNotGenerated
          ? "To upload the newly generated report, please delete the old one.\nStep 1: Click the 'DELETE REPORT' button to remove the old report."
          : isReportNotUploaded
          ? `Step 1: Click the 'AUDIT VEHICLE DATA' button.\nStep 2: Click 'GENERATE REPORT' to download the report.\nStep 3: Return to the audit request page, click 'UPLOAD' and upload the report.`
          : "The report has been successfully uploaded.",
        };

      default:
        return {
          title: "Start Pre-Processing",
          description: "Begin with the Manual Pre-Processing stage.",
          nextStep: `To Begin with the Manual Pre-Processing, follow these steps:
          Step 1: Select the Vehicle class from the drop-down above, and click on the 'START EXTRACTING FILE' button.
          Step 2: After the file is extracted,you have to run the commands. You can understand these commands from this document. <a href="https://example.com/commands-document" target="_blank" rel="noopener noreferrer">Click here</a>.`,
        };
    }
  };

  // Helper function to convert the description string to a list of points
  const getBulletPoints = (text: string) => {
    return text.split("\n").map((point, index) => {
      return point.trim().endsWith(".")
        ? point.trim().slice(0, -1)
        : point.trim();
    });
  };

  const guideline = getGuidelineText(status);

  return (
    <Card variant="outlined" sx={{ margin: 2, boxShadow: 3 }}>
      <CardContent>
        <Stack spacing={2}>
          <Box display="flex" alignItems="center">
            <Info sx={{ color: "#212121", marginRight: 1 }} />
            <Typography variant="h6" fontWeight="bold">
              {guideline.title}
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Typography variant="body1" fontWeight="bold">
            Current Step:
          </Typography>
          <List dense>
            {getBulletPoints(guideline.description).map((point, index) => (
              <ListItem
                key={index}
                sx={{ display: "list-item", paddingLeft: 2 }}
              >
                <Typography variant="body2">{point}</Typography>
              </ListItem>
            ))}
          </List>

          <Divider sx={{ my: 2 }} />

          <Typography variant="body1" fontWeight="bold">
            Next Steps:
          </Typography>
          <List dense>
            {getBulletPoints(guideline.nextStep).map((point, index) => (
              <ListItem
                key={index}
                sx={{ display: "list-item", paddingLeft: 2 }}
              >
                <Typography
                  variant="body2"
                  dangerouslySetInnerHTML={{ __html: point }}
                />
              </ListItem>
            ))}
          </List>
        </Stack>
      </CardContent>
    </Card>
  );
};
