import React from "react";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

interface FilterSelectProps {
  value: (number)[];
  options: number[];
  onChange: (event: SelectChangeEvent<number[]>) => void;
}

const AuthenticityFilterSelect = ({ value, options,onChange }: FilterSelectProps) => (
  <FormControl variant="outlined" size="small" sx={{ mr: 1, minWidth: 200 }}>
    <InputLabel id="authenticity-label">Authenticity</InputLabel>
    <Select
      multiple
      labelId="authenticity-label"
      value={value}
      renderValue={(selected) => {
        const selectedDisplay = selected
          .map((val) => (val === -1 ? "N/A" : val)) // Display "N/A" for empty strings
          .join(", ");
        return (
          <span
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {selectedDisplay}
          </span>
        );
      }}
      onChange={onChange}
      label="Authenticity"
    >
      {options.map((authValue, index) => (
        <MenuItem
          key={index}
          value={authValue !== null ? Number(authValue) : -1}
        >
          <Checkbox
            checked={
              value.indexOf(authValue !== null ? Number(authValue) : -1) > -1
            }
          />{" "}
          <ListItemText
            primary={authValue != null ? Number(authValue) : "N/A"}
          />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default AuthenticityFilterSelect;
