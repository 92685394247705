import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SpotrueApiBaseUrl } from "./baseUrls";

export async function matchVehicleAuditData(
  gogigReq: string,
  authHeader: string
) {
  return fetchWrapper(
    `${SpotrueApiBaseUrl}/matchVehicleAuditData?gogigReqId=${gogigReq}`,
    {
      method: "POST",
      headers: {
        Authorization: authHeader,
      },
    }
  );
}
