import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link as Links } from "react-router-dom";
import Button from "@mui/material/Button";
import {
  Box,
  Chip,
  Container,
  Divider,
  Grid,
  LinearProgress,
  Link,
  MenuItem,
  Paper,
  Select,
  Typography,
  styled,
  useTheme,
  linearProgressClasses,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CustomButton from "../ButtonWrapper";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import Strings from "./../../Locale/strings.json";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Map from "@mui/icons-material/MapOutlined";
import {
  CalendarTodayOutlined as CalendarIcon,
  TimerOutlined as TimerIcon,
  WorkOutlineOutlined as WorkIcon,
  Link as LinkIcon,
} from "@mui/icons-material";
import Wallet from "@mui/icons-material/AccountBalanceWalletOutlined";
import FilterIcon from "@mui/icons-material/FilterNoneOutlined";
import TrainingIcon from "@mui/icons-material/ModelTraining";
import CloseIcon from "@mui/icons-material/Close";
import {
  HandleImageQuestion,
  HandleAddressQues,
  HandlePersonQues,
  HandleTextQues,
  HandleBooleanQuestion,
  HandleMultipleChoiceQuestion,
  HandleSingleChoiceQuestions,
  HandleNumberQuestion,
} from "../handleQuestions/index";
import { useAuthContext } from "../../providers/AuthProvider";
import MDEditor from "@uiw/react-md-editor";
import {
  getGigDetailsByGigId,
  getGigCapabilitiesByGigId,
  Capability,
  publishProject,
} from "@gogig-in/gogig-clients";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const ReviewPage: React.FC = () => {
  const { gigId } = useParams<{ gigId?: string }>();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const [gigDetails, setGigDetails] = useState<any>(null);
  const [answers, setAnswers] = useState<{ [key: string]: any }>({});
  const isSubmissionSuccessful = false;
  const history = useNavigate();
  const handleFileRemove = (questionToRemove: any) => {};
  const [selectedGigId, setSelectedGigId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { authHeader } = useAuthContext();
  const [gigCapabilities, setGigCapabilities] = useState<Capability[]>([]);

  const handleAnswerChange = (questionId: string, answer: any) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: answer,
    }));
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleChildError = () => {};

  useEffect(() => {
    const fetchGigDetails = async () => {
      try {
        if (gigId) {
          const response: any = await getGigDetailsByGigId(gigId);
          setGigDetails(response.data);
        }
      } catch (error) {
        console.error("Error fetching gig details", error);
      }
    };

    const fetchCapabilityData = async () => {
      try {
        if (gigId) {
          const res: any = await getGigCapabilitiesByGigId(gigId);
          console.log({ res });
          setGigCapabilities(res.data);
        }
      } catch (e) {}
    };
    fetchCapabilityData();
    fetchGigDetails();
  }, [gigId]);

  function renderQuestionControl(
    questionId: any,
    questionData: any,
    answers: any,
    isSubmissionSuccessful: boolean
  ) {
    const { type, question, answer } = questionData;

    if (!type || !question) {
      return <label>Error: Invalid question data</label>;
    }

    let renderedControl;

    switch (type) {
      case "singleChoice":
        return (
          <HandleSingleChoiceQuestions
            question={question}
            questionId={questionId}
            answers={answers}
            handleAnswerChange={handleAnswerChange}
            options={answer}
            disabled={isSubmissionSuccessful}
          />
        );

      case "multiChoice":
        return (
          <HandleMultipleChoiceQuestion
            question={question}
            questionId={questionId}
            answers={answers}
            handleAnswerChange={handleAnswerChange}
            options={answer}
            disabled={isSubmissionSuccessful}
          />
        );

      case "boolean":
        return (
          <HandleBooleanQuestion
            question={question}
            questionId={questionId}
            answers={answers}
            handleAnswerChange={handleAnswerChange}
            disabled={isSubmissionSuccessful}
          />
        );

      case "text":
        if (question.toLowerCase().includes("vehicle number")) {
          return (
            <HandleTextQues
              question={question}
              questionId={questionId}
              answers={answers}
              handleAnswerChange={handleAnswerChange}
              gridSpace={
                (questionData as { question: string }).question.length > 74
                  ? 12
                  : 6
              }
              disabled={isSubmissionSuccessful}
              onErrorChange={handleChildError}
              vehicleNumbers={[]}
            />
          );
        } else {
          return (
            <HandleTextQues
              question={question}
              questionId={questionId}
              answers={answers}
              handleAnswerChange={handleAnswerChange}
              gridSpace={
                (questionData as { question: string }).question.length > 74
                  ? 12
                  : 6
              }
              disabled={isSubmissionSuccessful}
              onErrorChange={handleChildError}
              vehicleNumbers={[]}
            />
          );
        }

      case "number":
        return (
          <HandleNumberQuestion
            question={question}
            questionId={questionId}
            answers={answers}
            handleAnswerChange={handleAnswerChange}
            disabled={isSubmissionSuccessful}
            onErrorChange={handleChildError} // Pass the function to handle errors in the child component
          />
        );

      case "address":
        return (
          <HandleAddressQues
            question={question}
            questionId={questionId}
            answers={answers}
            handleAnswerChange={handleAnswerChange}
            disabled={isSubmissionSuccessful}
            onErrorChange={handleChildError}
          />
        );

      case "person":
        if (type === "person" && answer && Array.isArray(answer)) {
          return (
            <HandlePersonQues
              key={questionId}
              question={`Enter details for ${questionData.question}`}
              questionId={questionId}
              answers={answers}
              handleAnswerChange={handleAnswerChange}
              disabled={false}
              onErrorChange={handleChildError}
              answerFields={answer}
            />
          );
        }

        return null;

      case "image":
        return (
          <HandleImageQuestion
            question={question}
            onFileSelected={(file, question) => {}}
            handleFileRemove={handleFileRemove}
            disabled={isSubmissionSuccessful}
          />
        );

      default:
        renderedControl = null;
        break;
    }

    if (isSubmissionSuccessful && renderedControl) {
      renderedControl = React.cloneElement(renderedControl, {
        disabled: true,
      });
    }

    return renderedControl;
  }

  const handleBack = () => {
    history("/create-gig/task-form", {
      state: {
        gigId: gigId,
      },
    });
  };

  const openModal = (gigId: string) => {
    setSelectedGigId(gigId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handlePublish = async () => {
    if (selectedGigId && authHeader) {
      try {
        await publishProject(selectedGigId, authHeader);
        closeModal();
        window.location.reload();
      } catch (error) {
        console.error(error);
        closeModal();
      }
    }
  };

  const showGigSkills = gigCapabilities
    .filter((s) => s.capabilityType === "skill")
    .map((s) => s.capabilityName)
    .join(", ");
  const showGigAssets = gigCapabilities
    .filter((s) => s.capabilityType === "asset")
    .map((s) => s.capabilityName)
    .join(", ");

  const fontAsPerMd =
    "apple-system, BlinkMacSystemFont, Segoe UI, Noto Sans, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji";

  return (
    <Container className="vendor-details-container">
      <Box
        sx={{
          justifyContent: "space-between",
          display: "flex",
          margin: "5px 5px",
        }}
      >
        <Button onClick={handleBack}>Back</Button>
        <Typography variant="h6" sx={{ margin: "auto" }}>
          Step 4 out of 4
        </Typography>
        {gigDetails && (
          <Button
            variant="contained"
            onClick={() => openModal(gigDetails.gigId)}
            style={{
              color: "white",
              backgroundColor: "secondary",
              borderRadius: "22px",
              marginRight: "15px",
            }}
            disabled={gigDetails.status !== "draft"}
          >
            Publish Gig
          </Button>
        )}
        <Links to={"/manage-gigs"}>
          <Button
            variant="outlined"
            endIcon={<CloseIcon />}
            sx={{
              borderRadius: "22px",
            }}
          >
            Close
          </Button>
        </Links>
      </Box>
      <BorderLinearProgress
        variant="determinate"
        value={100}
        sx={{ marginBottom: "10px" }}
      />
      <Box
        sx={{
          justifyContent: "space-between",
          display: "flex",
          margin: "0px 5px",
        }}
      >
        <Typography variant="h3">Review and Publish Page </Typography>
        <Typography variant="h4">Completed</Typography>
      </Box>
      <Box sx={{ marginTop: "15px" }}>
        <Typography variant="h3" sx={{ marginBottom: "10px" }}>
          Gig Details view according to Gig Seeker{" "}
        </Typography>
        <Paper
          elevation={3}
          sx={{
            padding: "30px",
            backgroundColor: "white",
            border: "1px dashed #000",
          }}
        >
          {gigDetails && (
            <Grid
              container
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <Grid item xs={12} sm={7.5}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "-10px",
                  }}
                >
                  {gigDetails?.companyLogo ? (
                    <img
                      src={gigDetails.companyLogo}
                      alt={gigDetails.companyName}
                      style={{
                        maxWidth: "132px",
                        height: "60px",
                        marginRight: "10px",
                      }}
                    />
                  ) : (
                    <img
                      src={`${process.env.PUBLIC_URL}/images/logos/gmg-logo.png`}
                      alt="company Logo"
                      style={{
                        width: "132px",
                        marginRight: "10px",
                      }}
                    />
                  )}

                  <Box>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        lineHeight: "1.2",
                        fontFamily: "Inter, sans-serif",
                      }}
                    >
                      {gigDetails.gigTitle}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "5px",
                        fontFamily: "Inter, sans-serif",
                        marginTop: "5px",
                        "@media (min-width: 600px)": {
                          flexDirection: "row",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          marginTop: "5px",
                          marginRight: "15px",
                          textTransform: "capitalize",
                        }}
                      >
                        at {gigDetails.companyName}
                      </Typography>
                      <Chip label={gigDetails.jobType || "PART-TIME"} />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                {!isMobileView && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <CustomButton
                      tag="GigDetails/bookmark"
                      size="large"
                      sx={{
                        marginRight: "10px",
                      }}
                    >
                      <BookmarkBorderIcon sx={{ cursor: "pointer" }} />
                    </CustomButton>
                    <CustomButton
                      tag="GigDetails/ApplyNow"
                      variant="contained"
                      size="large"
                      endIcon={<ArrowForwardIcon />}
                    >
                      {Strings.GigDetails.ApplyNow}
                    </CustomButton>
                  </Box>
                )}
              </Grid>
            </Grid>
          )}

          <Box>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={7}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    flex: 1,
                    marginBottom: "20px",
                    padding: "20px 20px 0 20px",
                    backgroundColor: "background.default",
                    borderRadius: "8px",
                  }}
                >
                  {gigDetails && (
                    <Box>
                      {showGigSkills && (
                        <>
                          <Typography
                            style={{
                              fontFamily: fontAsPerMd,
                              fontWeight: "600",
                              fontSize: "18px",
                              color: "#18191C",
                            }}
                          >
                            Skills Required
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: fontAsPerMd,
                              fontWeight: "400",
                              fontSize: "16px",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {showGigSkills}
                          </Typography>
                        </>
                      )}
                      {showGigAssets && (
                        <>
                          <Typography
                            style={{
                              fontFamily: fontAsPerMd,
                              fontWeight: "600",
                              fontSize: "18px",
                              color: "#18191C",
                            }}
                          >
                            Assets Required
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: fontAsPerMd,
                              fontWeight: "400",
                              fontSize: "16px",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {showGigAssets}
                          </Typography>
                        </>
                      )}
                      {gigDetails.communicationLanguages && (
                        <>
                          <Typography
                            style={{
                              fontFamily: fontAsPerMd,
                              fontWeight: "600",
                              fontSize: "18px",
                              color: "#18191C",
                            }}
                          >
                            Languages to know
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: fontAsPerMd,
                              fontWeight: "400",
                              fontSize: "16px",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {gigDetails.communicationLanguages}
                          </Typography>
                        </>
                      )}
                      <Box
                        data-color-mode="light"
                        sx={{ textAlign: "justify" }}
                      >
                        <MDEditor.Markdown source={gigDetails.description} />
                      </Box>
                      <Typography
                        style={{
                          fontFamily: "Inter,sans-serif",
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#5E6670",
                          whiteSpace: "pre-line",
                        }}
                      ></Typography>
                      {gigDetails.notes && (
                        <Box sx={{ marginTop: "25px" }}>
                          <Typography variant="h6">
                            Points to remember:
                          </Typography>
                          <Typography sx={{ color: "#5E6670" }}>
                            {gigDetails.notes}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Paper
                  elevation={0}
                  sx={{
                    flex: 1,
                    marginBottom: "20px",
                    padding: "20px",
                    backgroundColor: "background.default",
                    borderRadius: "8px",
                    border: "2px solid #E7F0FA",
                  }}
                >
                  {gigDetails && (
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <Box sx={{ textAlign: "center" }}>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontFamily: "inter, sans-serif",
                            }}
                          >
                            {Strings.GigDetails.Salary}
                          </Typography>
                          <Typography
                            sx={{
                              color: "#0BA02C",
                              fontSize: "20px",
                              fontWeight: "500",
                            }}
                          >
                            {gigDetails.minWagePerDay !== null
                              ? Strings.INR + " " + gigDetails.minWagePerDay
                              : "hello world"}
                          </Typography>
                          <Typography
                            sx={{
                              color: "grey",
                              fontSize: "16px",
                              fontFamily: "inter, sans-serif",
                            }}
                          >
                            {Strings.GigDetails.SalaryPeriod}
                            <br />
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box sx={{ textAlign: "center" }}>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontFamily: "inter, sans-serif",
                            }}
                          >
                            Earn
                          </Typography>
                          <Typography
                            sx={{
                              color: "#0BA02C",
                              fontSize: "20px",
                              fontWeight: "500",
                            }}
                          >
                            {gigDetails.pricePerClient !== null
                              ? Strings.INR + " " + gigDetails.pricePerClient
                              : "hello world"}
                          </Typography>
                          <Typography
                            sx={{
                              color: "grey",
                              fontSize: "16px",
                              fontFamily: "inter, sans-serif",
                            }}
                          >
                            per valid entry
                            <br />
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box
                          sx={{
                            backgroundColor: "background.default",
                            color: "blue",
                            borderRadius: "5px",
                            textAlign: "center",
                          }}
                        >
                          <Map color="primary" sx={{ marginRight: "10px" }} />
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontFamily: "inter, sans-serif",
                            }}
                          >
                            <strong>
                              Job Location
                              <br />
                            </strong>
                          </Typography>
                          <Typography
                            sx={{
                              color: "grey",
                              fontSize: "16px",
                              fontFamily: "inter, sans-serif",
                            }}
                          >
                            {gigDetails.location !== ""
                              ? gigDetails.location
                              : "India"}
                            <br />
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </Paper>
                <Paper
                  elevation={0}
                  sx={{
                    backgroundColor: "background.default",
                    borderRadius: "8px",
                    border: "2px solid #E7F0FA",
                  }}
                >
                  <Box sx={{ margin: "20px" }}>
                    {gigDetails && (
                      <Grid container spacing={3}>
                        <Grid
                          item
                          xs={6}
                          sm={4}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <CalendarIcon
                            sx={{ fontSize: "30px", color: "#0A65CC" }}
                          />
                          <Typography
                            sx={{
                              color: "grey",
                              fontSize: "12px",
                              fontFamily: "inter, sans-serif",
                            }}
                          >
                            JOB STARTS FROM
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              fontFamily: "inter, sans-serif",
                            }}
                          >
                            {gigDetails.gigStartDate !== "0000-00-00"
                              ? formatDate(gigDetails.gigStartDate)
                              : "Date is not given"}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={4}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <TimerIcon
                            sx={{ fontSize: "30px", color: "#0A65CC" }}
                          />
                          <Typography
                            sx={{
                              color: "grey",
                              fontSize: "12px",
                              fontFamily: "inter, sans-serif",
                            }}
                          >
                            JOB EXPIRES IN
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              fontFamily: "inter, sans-serif",
                            }}
                          >
                            {gigDetails.gigEndDate !== "0000-00-00"
                              ? formatDate(gigDetails.gigEndDate)
                              : "Date is not given"}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={4}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <FilterIcon
                            sx={{ fontSize: "30px", color: "#0A65CC" }}
                          />
                          <Typography
                            sx={{
                              color: "grey",
                              fontSize: "12px",
                              fontFamily: "inter, sans-serif",
                            }}
                          >
                            JOB LEVEL
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              fontFamily: "inter, sans-serif",
                            }}
                          >
                            Entry Level
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={4}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Wallet sx={{ fontSize: "30px", color: "#0A65CC" }} />
                          <Typography
                            sx={{
                              color: "grey",
                              fontSize: "12px",
                              fontFamily: "inter, sans-serif",
                            }}
                          >
                            EXPERIENCE
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              fontFamily: "inter, sans-serif",
                            }}
                          >
                            Not Applicable
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={4}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <WorkIcon
                            sx={{ fontSize: "30px", color: "#0A65CC" }}
                          />
                          <Typography
                            sx={{
                              color: "grey",
                              fontSize: "12px",
                              fontFamily: "inter, sans-serif",
                            }}
                          >
                            EDUCATION
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              fontFamily: "inter, sans-serif",
                            }}
                          >
                            {gigDetails.academicQualificationRequired
                              ? gigDetails.academicQualificationRequired
                              : "-"}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={4}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          <TrainingIcon
                            sx={{ fontSize: "30px", color: "#0A65CC" }}
                          />
                          <Typography
                            sx={{
                              color: "grey",
                              fontSize: "12px",
                              fontFamily: "inter, sans-serif",
                            }}
                          >
                            <Link underline="always">{"SUPPLEMENTS"}</Link>
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              fontFamily: "inter, sans-serif",
                            }}
                          >
                            Tutorial
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                  <Divider sx={{ marginY: "10px", width: "100%" }} />{" "}
                  {/* Full-width horizontal divider */}
                  <Box
                    sx={{
                      paddingLeft: "30px",
                      alignItems: "center",
                    }}
                  >
                    <Typography>
                      <strong>{Strings.GigDetails.ShareJob}</strong>
                    </Typography>
                    <CustomButton
                      tag="GigDetails/CopyLink"
                      startIcon={<LinkIcon />}
                    >
                      {Strings.ShareType.CopyLink}
                    </CustomButton>

                    <CustomButton>Share on whatsapp</CustomButton>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>

      <Typography variant="h3" sx={{ margin: "10px" }}>
        Task Questions Form:
      </Typography>
      <Paper
        elevation={3}
        sx={{
          padding: "30px",
          backgroundColor: "white",
          border: "1px dashed #000",
        }}
      >
        {gigDetails && <Box sx={{ marginBottom: "30px" }}></Box>}
        <div>
          {gigDetails?.category !== "branding" && (
            <Grid container spacing={2}>
              {Object.entries(JSON.parse(gigDetails?.questionaire || "{}")).map(
                ([questionId, questionData], index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <div>
                      {renderQuestionControl(
                        questionId,
                        questionData,
                        answers,
                        isSubmissionSuccessful
                      )}
                    </div>
                  </Grid>
                )
              )}
            </Grid>
          )}
          {gigDetails?.category === "branding" && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                {Object.entries(JSON.parse(gigDetails?.questionaire || "{}"))
                  .filter(([questionId, questionData]) => {
                    return (
                      questionData &&
                      typeof questionData === "object" &&
                      "type" in questionData &&
                      questionData.type !== "image"
                    );
                  })
                  .map(([questionId, questionData]) => (
                    <div key={questionId}>
                      {renderQuestionControl(
                        questionId,
                        questionData,
                        answers,
                        isSubmissionSuccessful
                      )}
                    </div>
                  ))}
              </Grid>
              <Grid item xs={12} sm={6}>
                {Object.entries(JSON.parse(gigDetails?.questionaire || "{}"))
                  .filter(([questionId, questionData]) => {
                    return (
                      questionData &&
                      typeof questionData === "object" &&
                      "type" in questionData &&
                      questionData.type === "image" // Corrected condition
                    );
                  })
                  .map(([questionId, questionData]) => (
                    <div
                      key={questionId}
                      style={{ marginBottom: "20px", marginLeft: "40px" }}
                    >
                      {renderQuestionControl(
                        questionId,
                        questionData,
                        answers,
                        isSubmissionSuccessful
                      )}
                    </div>
                  ))}
              </Grid>
            </Grid>
          )}
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: "20px", minWidth: "115px" }}
            >
              Submit Answers
            </Button>
          </Box>
        </div>
        {gigDetails?.category === "telecalling" && (
          <>
            <Box
              sx={{
                backgroundColor: "#FFEEC3",
                display: "flex",
                alignItems: "center",
                padding: "8px",
                borderBottom: "2px solid #FFA500",
                marginTop: "35px",
                width: "fit-content",
              }}
            >
              <WarningAmberIcon sx={{ color: "#FCAA23" }} />
              <Typography sx={{ color: "#FF8C00", marginLeft: "8px" }}>
                Select the option only if you are unable to connect to the
                vendor.
              </Typography>
            </Box>
            <div className="dropdown">
              <Select displayEmpty sx={{ width: "300px" }}>
                <MenuItem value="">
                  <em>Select the reason</em>
                </MenuItem>
                <MenuItem value="busy">Busy</MenuItem>
                <MenuItem value="unreachable">Unreachable</MenuItem>
                <MenuItem value="invalid">Invalid</MenuItem>
                <MenuItem value="be right back">Be right back</MenuItem>
              </Select>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{
                  marginTop: "20px",
                }}
              >
                Confirm Call Status
              </Button>
            </div>
          </>
        )}
      </Paper>
      <Dialog open={isModalOpen} onClose={closeModal}>
        <DialogTitle>Confirm Publish</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to publish this gig?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handlePublish} color="primary">
            Confirm Publish
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ReviewPage;
