import { Typography, Button, Box, Grid } from "@mui/material"
import { useNavigate } from "react-router-dom";

const ProfileCreatedPage = () => {
    const history = useNavigate();
const redirectToBrowseJobs = () => {
        history("/BrowseJobs");
    };
    return (<Grid container justifyContent="center" sx={{ marginTop: '15px', paddingLeft: '15px', paddingRight: '15px' }}>
        <Grid item xs={12} sm={8} md={6}>
            <Box textAlign="center">
                <img
                    alt="like.png"
                    src={`${process.env.PUBLIC_URL}/like.png`}
                    style={{ height: '100px', width: '100px' }}
                />
            </Box>
                    <Box sx={{ mt: 3 }}>
                <Typography variant="h3" sx={{ textAlign: 'center' }}>
                            Nice work, User your profile's ready.
                        </Typography>
                <Typography sx={{ textAlign: 'center', mt: 2 }}>
                            Congratulations! With thousands to choose from, it's time to start bidding on roles that are the perfect fit for your skills.
                        </Typography>
                        </Box>
            <Box mt={3} display="flex" justifyContent="center" sx={{ margin: '15px' }} flexDirection={{ xs: 'column', sm: 'row' }}>
                        {/* <Button variant="contained" onClick={redirectToProfile} sx={{ mr: 2, mt: 2 }}>View my profile</Button> */}
                        <Button sx={{ mt: 2 }} onClick={redirectToBrowseJobs}>Browse jobs</Button>
                        </Box>
                </Grid>
            </Grid>
            )
    
}
export default ProfileCreatedPage