import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SpotrueApiBaseUrl } from "./baseUrls";

export interface FileUrls {
  sheetUrl?: string;
  pptUrl?: string;
  requestTitle: string;
}

export async function createUserAuditRequest(
  authHeader: string,
  data: FileUrls
) {
  const payload = {
    sheetUrl: data.sheetUrl,
    pptUrl: data.pptUrl,
    requestTitle: data.requestTitle,
  };
  const url: string = `${SpotrueApiBaseUrl}/createUserAuditRequest`;
  return fetchWrapper(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader,
    },
    body: payload,
  });
}
