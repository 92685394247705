import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SpotrueApiStorageBaseUrl } from "./baseUrls";
import { FileData } from "../components/vendors/vehicleAuditComponents/types";

export async function uploadFileWithMetadata(authHeader: string, formData: FormData) {
  const fileData = await fetchWrapper(
    `${SpotrueApiStorageBaseUrl}/uploadFileWithMetadata?clientId=gogig`,
    {
      method: "POST",
      headers: {
        Authorization: authHeader,
      },
      body: formData,
    }
  );

  const data = fileData.data as { fileData: FileData, message: string };
  return data.fileData;
}
