import {
  Paper,
  Grid,
  Typography,
  Box,
  TextField,
  CircularProgress,
  Button,
} from "@mui/material";
import FileUploadSection from "./FileUploadSection";

interface AuditRequestFormProps {
  requestTitle: string;
  isRequestTitle: boolean;
  checked: boolean;
  loading: boolean;
  pptxFile: File | null;
  sheetFile: File | null;
  isFileUpload: boolean | null;
  sheetUrl: string;
  pptUrl: string;
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleExcelSheetChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRequestTitle: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleExcelSheetUpload: () => void;
  handleCreateAuditRequest: (param1: string, param2: string) => void;
}

export default function AuditRequestForm({
  requestTitle,
  isRequestTitle,
  checked,
  loading,
  pptxFile,
  sheetFile,
  isFileUpload,
  sheetUrl,
  pptUrl,
  handleRequestTitle,
  handleExcelSheetUpload,
  handleCreateAuditRequest,
  handleCheckboxChange,
  handleExcelSheetChange,
  handleFileChange,
}: AuditRequestFormProps) {
  return (
    <Paper elevation={3} sx={{ padding: 3, mt: 0 }}>
      <Grid
        container
        spacing={2}
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs={8} sx={{ alignContent: "center" }}>
          <Typography variant="h5" gutterBottom>
            Request Title
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <TextField
              label="Request Title"
              type="text"
              value={requestTitle}
              onChange={handleRequestTitle}
              fullWidth
              error={!isRequestTitle}
              helperText={!isRequestTitle && "Request Title is required"}
              required
              size="medium"
              sx={{ height: "56px" }}
            />
          </Box>
        </Grid>

        <Grid item xs={4} sx={{ alignContent: "center" }}>
          <Typography variant="h5" gutterBottom>
            Upload here
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              !checked
                ? handleExcelSheetUpload()
                : handleCreateAuditRequest(sheetUrl, pptUrl)
            }
            disabled={isFileUpload===true || loading || (!checked && (!pptxFile && !sheetFile))}
            sx={{ whiteSpace: "nowrap", padding: "10px 16px", height: "56px" }}
            fullWidth
            size="medium"
          >
            {loading ? (
              <>
                Uploading...
                <CircularProgress size={24} sx={{ ml: 2 }} />
              </>
            ) : isFileUpload ? (
              "Uploaded"
            ) : (
              "Upload"
            )}
          </Button>
        </Grid>
      </Grid>

      <FileUploadSection
        checked={checked}
        handleCheckboxChange={handleCheckboxChange}
        sheetFile={sheetFile}
        pptxFile={pptxFile}
        handleExcelSheetChange={handleExcelSheetChange}
        handleFileChange={handleFileChange}
        loading={loading}
      />
    </Paper>
  );
}
