import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SpotrueApiBaseUrl } from "./baseUrls";

export default function endManualPreProcess(
  authHeader: string,
  gogigReqId: string,
) {
  const url = `${SpotrueApiBaseUrl}/endManualPreProcess?gogigReqId=${gogigReqId}`;
  return fetchWrapper(url, {
    method: "POST",
    headers: {
      Authorization: authHeader,
    },
  });
}
