export enum userRoles {
  supervisor = "gogig-role-s2",
  seeker = "gogig-role-s1",
  operation = "gogig-role-o1",
  admin = "gogig-role-a1",
  anonymous = "gogig-role-z1",
  employer = "gogig-role-e1",
  externalAudit = "gogig-role-audit",
  gmgConsoleRC = "gmg-role-console-rc",
  processor = "gogig-role-processor",
}
