import { ProfileData } from "./ProfileValues";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import CircularImageView from "../custom/circularImageView";
import { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SkeletonModal from "../skeleton/SkeletonModal";
import dateFormat from "dateformat";

export default function UserProfileDataComponent({
  userDetails,
}: {
  userDetails: ProfileData | null;
}) {
  const [dob, setDob] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Check if userDetails exists and userDetails.dob exists
    // If userDetails or userDetails.dob is null or undefined, set dob to an empty string
    const dob =
      userDetails && userDetails.dob
        ? dateFormat(userDetails.dob, "dd-mm-yyyy")
        : "";

    // Set isLoading to false when userDetails is not null
    const isLoading = userDetails !== null ? false : true;

    setDob(dob);
    setIsLoading(isLoading);
  }, [userDetails?.dob, setIsLoading, userDetails]);

  return (
    <Grid>
      {isLoading ? (
        <Paper>
          <SkeletonModal skeletonType="dashboard" />
        </Paper>
      ) : (
        <Paper
          sx={{
            position: "relative",
            top: "0",
            border: "1px solid #ddd",
            borderRadius: "10px",
            padding: "20px",
            boxShadow: "0px 4px 8px rgba(0,0,0,0.3)",
            backgroundColor: "#fff",
          }}
        >
          <Typography variant="h4" align="left" my={1}>
            Profile
          </Typography>
          <Divider />

          <Box textAlign="center" my={2}>
            <CircularImageView //AVATAR
              imageUrl={""}
              firstName={userDetails?.firstName} //FIRST NAME
              lastName={userDetails?.lastName} // LAST NAME
            />
            <Grid container mt={2}>
              <Grid item xs={6}>
                <Typography variant="h3" textAlign="left" mx={2}>
                  {userDetails?.firstName + " " + userDetails?.lastName}
                </Typography>
              </Grid>
            </Grid>

            {/* PHONE NUMBER */}
            <Box
              sx={{
                display: "flex",
                justifyItems: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" textAlign={"left"} mx={2}>
                {userDetails?.phoneNumber}
              </Typography>
              <CheckCircleIcon style={{ color: "green" }} />
            </Box>

            {/* EMAIL */}
            <Typography variant="h6" textAlign="left" mx={2}>
              {userDetails?.emailId}
            </Typography>
          </Box>

          {/* GENDER & DOB */}
          <Box display="flex" flexWrap={"wrap"}>
            <Typography variant="h6" textAlign="left" mx={2}>
              {userDetails?.gender?.toLocaleUpperCase()}
            </Typography>
            <span>{"|"}</span>
            <Typography variant="h6" textAlign="left" mx={2}>
              {dob}
            </Typography>
          </Box>
        </Paper>
      )}
    </Grid>
  );
}
