import React from "react";
import { Box, Typography } from "@mui/material";

interface reportTextComponentProps {
  text: string;
  subString: string | number;
}

const ReportTextComponent = ({ text, subString }: reportTextComponentProps) => {
  return (
    <Box
      sx={{
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: "5px",
        }}
      >
        <Typography variant="body1" fontSize={16}>
          <strong>{text}:</strong>
        </Typography>
        <Typography variant="body1" fontSize={16}>
          {subString}
        </Typography>
      </Box>
    </Box>
  );
};

export default ReportTextComponent;
