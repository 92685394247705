import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Box,
  Divider,
  Grid,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  CloudUpload,
  ContentCopy,
  DateRange,
  InsertDriveFile,
  Link as LinkIcon,
} from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import { AuditRequest } from "../vehicleAuditComponents/types";
import { useAuthContext } from "../../../providers/AuthProvider";
import { userRoles } from "../../../types/userRoles";
import dateFormat from "dateformat";
import getFileAccessUrl from "../../../clients/getFileAccessUrl";

interface RequestDetailsCardProps {
  auditRequest: AuditRequest;
  viewReport: boolean;
  emailCount: number | null;
  deleteLoading: boolean;
  isReportGeneratedAgain?: boolean;
  status?: string;
  handleUploadClick: () => void;
  handleSendMail: () => void;
  handleDeleteReport: () => void;
  fetchAuditRequest: () => Promise<void>;
}

const RequestDetailsCard = ({
  auditRequest,
  viewReport,
  emailCount,
  deleteLoading,
  isReportGeneratedAgain,
  status,
  handleUploadClick,
  handleSendMail,
  handleDeleteReport,
  fetchAuditRequest,
}: RequestDetailsCardProps) => {
  const { userDetails, authHeader } = useAuthContext();
  // Define copied state to track individual fields' copied status
  const [copied, setCopied] = useState<{ [key: string]: boolean }>({
    requesterId: false,
    gogigRequestId: false,
  });

  // Handle clipboard copy
  const handleCopyToClipboard = async (
    text: string,
    field: "requesterId" | "gogigRequestId"
  ) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied((prev) => ({ ...prev, [field]: true }));
      setTimeout(() => {
        setCopied((prev) => ({ ...prev, [field]: false }));
      }, 2000); // Reset copied state after 2 seconds
    } catch (error) {
      console.error("Failed to copy text to clipboard", error);
    }
  };

  if (!auditRequest) return null;
  const handleAccessUrl = async (url: string): Promise<string | undefined> => {
    if (!authHeader) return;

    try {
      if (url.includes('.s3.')) {
        const res = await getFileAccessUrl(authHeader, url);
        const signedUrl = res.data as { url: string };
        return signedUrl.url; // Return the signed URL
      } else return url;
    } catch (error) {
      console.error("Failed to fetch signed PPT URL", error);
    }
  };

  const handleViewReport = (resultUrl: string | undefined) => {
    if (!resultUrl) {
      alert("No report available to view.");
      return;
    }

    // Open the report in a new tab or download it directly
    const link = document.createElement("a");
    link.href = resultUrl;
    link.target = "_blank"; // Open in a new tab
    link.download = "Audit_Report.pdf"; // Optional: specify download name
    link.click();
  };

  const hasAccess = userDetails?.role === userRoles.admin;
  return (
    <Card variant="outlined" sx={{ ml: 2, mr: 2, mt: 2, boxShadow: 3 }}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Box sx={{ flexDirection: "column", p: 0 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
              Requester Name : {auditRequest.requesterName}
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Request Title : {auditRequest?.requestTitle}
            </Typography>
          </Box>
          {userDetails?.role === userRoles.admin && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {viewReport ? (
                <CardActions sx={{ justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleDeleteReport}
                    sx={{ height: "40px" }}
                    disabled={deleteLoading} // Disable button while loading
                  >
                    {deleteLoading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Delete Report"
                    )}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleViewReport(auditRequest.resultUrl)}
                    sx={{ height: "40px" }}
                  >
                    View Report
                  </Button>
                </CardActions>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<CloudUpload />}
                  onClick={handleUploadClick}
                  disabled={isReportGeneratedAgain}
                  sx={{ height: "40px" }}
                >
                  Upload Report
                </Button>
              )}
              <Button
                variant="text"
                size="small"
                endIcon={<SendIcon />}
                disabled={
                  !viewReport ||
                  isReportGeneratedAgain ||
                  (status === "postprocessed" && isReportGeneratedAgain)
                }
                onClick={handleSendMail}
                sx={{ height: "40px", ml: "4px" }}
              >
                Send Email
              </Button>
            </Box>
          )}
        </Box>
        <Divider sx={{ mb: 2 }} />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center" mb={1}>
              <DateRange sx={{ marginRight: 1, color: "primary.main" }} />
              <Typography variant="subtitle1" color="textPrimary">
                <strong>Updated At:</strong>{" "}
                {dateFormat(auditRequest.updatedAt, "dd mmm yyyy hh:mm TT")}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mb={1}>
              <DateRange sx={{ marginRight: 1, color: "primary.main" }} />
              <Typography variant="subtitle1" color="textPrimary">
                <strong>Created At:</strong>{" "}
                {dateFormat(auditRequest.createdAt, "dd mmm yyyy hh:mm TT")}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center" mb={1}>
              <InsertDriveFile sx={{ marginRight: 1, color: "primary.main" }} />
              <Typography variant="subtitle1" color="textPrimary">
                <strong>Requester ID:</strong> {auditRequest.requesterId}
              </Typography>
              <Tooltip
                title={copied.requesterId ? "Copied!" : "Copy to clipboard"}
              >
                <IconButton
                  onClick={() =>
                    handleCopyToClipboard(
                      auditRequest.requesterId,
                      "requesterId"
                    )
                  }
                >
                  <ContentCopy />
                </IconButton>
              </Tooltip>
            </Box>

            <Box display="flex" alignItems="center" mb={1}>
              <InsertDriveFile sx={{ marginRight: 1, color: "primary.main" }} />
              <Typography variant="subtitle1" color="textPrimary">
                <strong>Gogig Request ID:</strong> {auditRequest.gogigRequestId}
              </Typography>
              <Tooltip
                title={copied.gogigRequestId ? "Copied!" : "Copy to clipboard"}
              >
                <IconButton
                  onClick={() =>
                    handleCopyToClipboard(
                      auditRequest.gogigRequestId,
                      "gogigRequestId"
                    )
                  }
                >
                  <ContentCopy />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ my: 2 }} />

        {hasAccess && (
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center" sx={{ flex: 1 }}>
              <LinkIcon sx={{ marginRight: 1, color: "primary.main" }} />

              <Typography variant="subtitle1" color="textPrimary">
                <strong>Processed Sheet URL :</strong>
              </Typography>
              {auditRequest.processedSheetUrl ? (<Button
                sx={{
                  border: "none",
                  textDecoration: "underline",
                  color: "blue",
                  cursor: "pointer",
                  padding: 0, // Remove default padding
                  lineHeight: 1.5, // Match line height to Typography
                  minWidth: "auto", // Allow button to take up only required width
                  ml: 1, // Add space between Typography and Button
                }}
                onClick={async () => {
                  const signedUrl = await handleAccessUrl(
                    auditRequest.processedSheetUrl
                  );
                  if (signedUrl) {
                    window.location.href = signedUrl;
                  } else {
                    alert("Failed to fetch the SHEET URL");
                  }
                }}
              >
                [View File]
              </Button>) : (
                <Typography variant="subtitle1" color="error" pl={1}>
                  <strong>[NO FILE]</strong>
                </Typography>
              )}
            </Box>

            <Box display="flex" alignItems="center" sx={{ flex: 1 }}>
              <LinkIcon sx={{ marginRight: 1, color: "primary.main" }} />

              <Typography variant="subtitle1" color="textPrimary">
                <strong>Processed PPT URL :</strong>
              </Typography>
              <Button
                sx={{
                  border: "none",
                  textDecoration: "underline",
                  color: "blue",
                  cursor: "pointer",
                  padding: 0, // Remove default padding
                  lineHeight: 1.5, // Match line height to Typography
                  minWidth: "auto", // Allow button to take up only required width
                  ml: 1, // Add space between Typography and Button
                }}
                onClick={async () => {
                  const signedUrl = await handleAccessUrl(
                    auditRequest.processedPptUrl
                  );
                  if (signedUrl) {
                    window.location.href = signedUrl;
                  } else {
                    alert("Failed to fetch the PPT URL");
                  }
                }}
              >
                [View File]
              </Button>
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default RequestDetailsCard;
