import React, { useState, useEffect, useRef } from "react";
import {
  DialogContent,
  SnackbarCloseReason,
  Grid,
  CardContent,
  CircularProgress,
  Card,
} from "@mui/material";
import { useAuthContext } from "../../../providers/AuthProvider";
import ReportUploadDialog from "./ReportUploadDialog";
import RequestDetailsCard from "./DetailsContentComponent";
import ProcessDetailsCard from "../vehicleAuditComponents/ProcessDetailsCard";
import { AuditRequest } from "../vehicleAuditComponents/types";
import GlobalSnackbar, { Severity } from "../../snackbarComponent/Snackbar";
import { useParams } from "react-router-dom";
import { getAuditRequestById } from "../../../clients/getAuditRequestById";
import sendAuditRequestMail from "../../../clients/sendAuditRequestMail";
import updateReportUrl from "../../../clients/updateReportUrl";
import { uploadFileWithMetadata } from "../../../clients/uploadFileWithMetadata";
import ProcessFlowUI from "./ProcessFlowUi";
import { GuideLines } from "./GuideLines";
import { deleteReport } from "../../../clients/deleteReport";
import AuditRequestReview from "./AuditRequestReview";

// Define SlideUpTransition for sliding up effect

const AuditRequestDialog = () => {
  const { gogigRequestId } = useParams<{
    gogigRequestId?: string;
  }>(); // Get the route parameter `id`

  const { authHeader } = useAuthContext();
  const [auditRequest, setAuditRequest] = useState<AuditRequest | null>(null);
  const [viewReport, setViewReport] = useState<boolean>(false);
  const [progression, setProgression] = useState<boolean>(true);
  const progressionRef = useRef<boolean>(progression);
  const [progressBarColor, setProgressBarColor] = useState<"success" | "error">(
    "success"
  );
  const [openUploadDialog, setOpenUploadDialog] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [emailCount, setEmailCount] = useState<number | null>(null);
  const [severity, setSeverity] = useState<Severity>("warning");
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [triggerAuditRequestApi, setTriggerAuditRequestApi] = useState<boolean>(
    false
  );
  const triggerAuditRequestApiRef = useRef<boolean>(triggerAuditRequestApi); // Create a ref for triggerAuditRequestApi

  useEffect(() => {
    triggerAuditRequestApiRef.current = triggerAuditRequestApi; // Keep the ref updated with the latest state
  }, [triggerAuditRequestApi]);
  const timestamps = {
    automaticPreProcessStart: auditRequest?.automaticPreProcessStart
      ? new Date(auditRequest.automaticPreProcessStart)
      : undefined,
    automaticPreProcessEnd: auditRequest?.automaticPreProcessEnd
      ? new Date(auditRequest.automaticPreProcessEnd)
      : undefined,
    manualPreProcessStart: auditRequest?.manualPreProcessStart
      ? new Date(auditRequest.manualPreProcessStart)
      : undefined,
    manualPreProcessEnd: auditRequest?.manualPreProcessEnd
      ? new Date(auditRequest.manualPreProcessEnd)
      : undefined,
    processStart: auditRequest?.processStart
      ? new Date(auditRequest.processStart)
      : undefined,
    processEnd: auditRequest?.processEnd
      ? new Date(auditRequest.processEnd)
      : undefined,
    postProcessStart: auditRequest?.postProcessStart
      ? new Date(auditRequest.postProcessStart)
      : undefined,
    postProcessEnd: auditRequest?.postProcessEnd
      ? new Date(auditRequest.postProcessEnd)
      : undefined,
    reportGeneratedAt: auditRequest?.reportGeneratedAt
      ? new Date(auditRequest.reportGeneratedAt)
      : undefined,
  };
  const lastUpdatedManualPreProcess = auditRequest?.lastUpdatedManualPreProcess
    ? new Date(auditRequest.lastUpdatedManualPreProcess)
    : undefined;
  const isReRun =
    lastUpdatedManualPreProcess &&
    timestamps.manualPreProcessEnd &&
    lastUpdatedManualPreProcess > timestamps.manualPreProcessEnd;
  const isAutoReRun =
    timestamps.automaticPreProcessEnd &&
    timestamps.manualPreProcessEnd &&
    timestamps.automaticPreProcessEnd > timestamps.manualPreProcessEnd;
  const isReportGeneratedAgain =
    timestamps.reportGeneratedAt &&
    timestamps.postProcessEnd &&
    timestamps.reportGeneratedAt < timestamps.postProcessEnd;
  const handleUploadClick = () => {
    setOpenUploadDialog(true);
    setProgression(true);
    setProgressBarColor("success");
    setUploadProgress(null);
    setSelectedFile(null);
  };
  useEffect(() => {
    progressionRef.current = progression;
  }, [progression]);
  const fetchAuditRequest = async () => {
    if (!gogigRequestId || !authHeader) return;
    try {
      setLoading(true);
      // Replace with your API call to fetch audit request details
      const response = await getAuditRequestById(authHeader, gogigRequestId);
      const fetchedData = response as AuditRequest;
      setAuditRequest(fetchedData);
    } catch (error) {
      console.error("Error fetching audit request:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    // Fetch the auditRequest based on the ID parameter

    fetchAuditRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authHeader]);

  useEffect(() => {
    if (auditRequest?.resultUrl) {
      setViewReport(true);
    } else {
      setViewReport(false);
    }
  }, [auditRequest]);

  if (auditRequest && (
    auditRequest.requestStatus === "requestCreated" ||
    auditRequest.requestStatus === "requestUnderReview" ||
    auditRequest.requestStatus === "rejected")
  ) {
    return <AuditRequestReview auditRequest={auditRequest} authHeader={authHeader}/>;
  }

  const handleCloseUploadDialog = () => {
    setOpenUploadDialog(false);
    setSelectedFile(null);
    setUploadProgress(null);
    setProgression(true);
    setProgressBarColor("success");
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };
  const handleDeleteReport = async () => {
    const userConfirmed = window.confirm(
      "Please confirm: do you want to delete the report?"
    );

    // If the user does not confirm, exit the function early
    if (!userConfirmed) return;
    if (!authHeader || !auditRequest?.gogigRequestId) return;

    setDeleteLoading(true); // Start loading

    try {
      await deleteReport(authHeader, auditRequest.gogigRequestId);
      setOpenSnackbar(true);
      setSnackbarMessage("Successfully Deleted");
      setSeverity("success");
      setViewReport(false);
      setTriggerAuditRequestApi(true);
    } catch (error) {
      const errorCause = (error as { errorCause: string }).errorCause;
      setOpenSnackbar(true);
      setSnackbarMessage(JSON.stringify(errorCause));
      setSeverity("error");
      setViewReport(true);
      console.error(error);
    } finally {
      setDeleteLoading(false); // End loading
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
      setViewReport(true);
      setProgression(true);
      setProgressBarColor("success");
      setUploadProgress(0);
      const simulatedUpload = setInterval(() => {
        setUploadProgress((prevProgress) => {
          if (prevProgress === null) return 0; // Start from 0 if null

          if (prevProgress >= 100) {
            clearInterval(simulatedUpload); // Clear interval when upload is complete
            setTimeout(() => {
              setOpenUploadDialog(false);
            }, 1000);

            return 100; // Ensure it returns 100 when done
          }
          if (triggerAuditRequestApiRef.current) {
            clearInterval(simulatedUpload); // Clear interval when upload is complete
            setTimeout(() => {
              setOpenUploadDialog(false);
            }, 1000);
            return 100; // Ensure it returns 100 when done
          }
          if (!progressionRef.current) {
            clearInterval(simulatedUpload);
            setProgressBarColor("error");
            return prevProgress; // Stop incrementing
          }

          return prevProgress + 10;
          // Keep the previous progress value
        });
      }, 1000);
      try {
        await handleFileUpload();
      } catch (error) {
        // Ensure interval is cleared in case of unexpected errors
        clearInterval(simulatedUpload);
      }
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFile || !authHeader) return;

    const formData = new FormData();
    formData.append("Report FILE", selectedFile);

    const metadata = {
      uploaderName: `${auditRequest?.processorId}`,
      gogigRequestId: `${auditRequest?.gogigRequestId}`,
    };

    formData.append("tags", `${auditRequest?.requesterId}`);
    formData.append("metadata", JSON.stringify(metadata));

    try {
      setLoading(true);
      const response = await uploadFileWithMetadata(authHeader, formData);
      const data = response.fileUrl;
      setOpenSnackbar(true);
      setSnackbarMessage("Successfully Uploaded");
      setSeverity("success");
      if (data) {
        handleUpdateReportUrl(data);
        setViewReport(true);
        setProgression(true);
      }
    } catch (error) {
      setLoading(false);
      setViewReport(false); // Set to false on error
      setOpenSnackbar(true);
      setProgression(false);
      setSnackbarMessage("Uploading failed...");
      setSeverity("error");
      console.error("Error uploading file:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateReportUrl = async (s3Link: string) => {
    setLoading(true);
    if (
      !s3Link ||
      !auditRequest?.gogigRequestId ||
      !auditRequest?.requesterId ||
      !authHeader
    ) {
      const missingFields = [];
      if (!s3Link) missingFields.push("s3Data");
      if (!auditRequest?.gogigRequestId) missingFields.push("gogigRequestId");
      if (!auditRequest?.requesterId) missingFields.push("requesterId");
      if (!authHeader) missingFields.push("authHeader");

      const errorMessage = `Missing required fields: ${missingFields.join(
        ", "
      )}`;
      setSnackbarMessage(errorMessage);
      setOpenSnackbar(true);
      setSeverity("error");
      setLoading(false);
      return;
    }

    try {
      const response = await updateReportUrl(
        auditRequest.gogigRequestId,
        encodeURIComponent(auditRequest.requesterId),
        authHeader,
        s3Link
      );

      const messageResponse = (response.data as { message: string }).message;

      if (messageResponse) {
        setTriggerAuditRequestApi(true);
        setSnackbarMessage(messageResponse);
        setOpenSnackbar(true);
        setSeverity("success");
      } else {
        setSnackbarMessage("Failed to save the report");
        setOpenSnackbar(true);
        setSeverity("error");
      }
    } catch (error) {
      console.error("Error updating report URL:", error);
      setSnackbarMessage("Something went wrong");
      setOpenSnackbar(true);
      setSeverity("error");
      setTriggerAuditRequestApi(false);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    if (triggerAuditRequestApi) {
      setTriggerAuditRequestApi(false);
      fetchAuditRequest();
    }
  };

  const handleSendMail = async () => {
    if (
      !auditRequest?.gogigRequestId ||
      !authHeader ||
      !auditRequest.requesterId
    )
      return;
    try {
      const response = await sendAuditRequestMail(
        authHeader,
        auditRequest.gogigRequestId,
        encodeURIComponent(auditRequest.requesterId)
      );

      const mail = (response.data as { emailsSent: number }).emailsSent;
      if (mail) {
        setEmailCount(mail);
      }
      const message = (response.data as { message: string }).message;
      if (message) {
        setOpenSnackbar(true);
        setSnackbarMessage(message);
        setSeverity("success");
      }
    } catch (error) {
      console.error("Error sending mail:", error);
    }
  };
  return (
    <>
      <GlobalSnackbar
        open={openSnackbar}
        message={snackbarMessage}
        onClose={handleClose}
        severity={severity}
        vertical={"top"}
        horizontal={"center"}
      />
      <DialogContent sx={{ flex: 1, display: "flex", overflow: "auto" }}>
        {loading ? (
          <Card
            variant="outlined"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              width: "100vw", // Full height of the container
            }}
          >
            <CardContent>
              <CircularProgress />
            </CardContent>
          </Card>
        ) : (
          <>
            {auditRequest && (
              <Grid container sx={{ flex: 1, overflow: "hidden" }}>
                {/* Left Side: ProcessFlowUI */}
                <Grid item xs={12} sm={4} md={4} sx={{ overflowY: "auto" }}>
                  <ProcessFlowUI
                    timestamps={timestamps}
                    status={auditRequest.status}
                    lastUpdatedManualPreProcess={lastUpdatedManualPreProcess}
                    resultUrl={auditRequest.resultUrl}
                    isReRun={isReRun}
                    isAutoRerun={isAutoReRun}
                    isReportGeneratedAgain={isReportGeneratedAgain}

                  />
                </Grid>

                {/* Right Side: RequestDetailsCard and ProcessDetailsCard stacked */}
                <Grid item xs={12} sm={8} md={8} sx={{ overflowY: "auto" }}>
                  <Grid container direction="column">
                    <Grid item xs={12} sm={4} md={4}>
                      <RequestDetailsCard
                        auditRequest={auditRequest}
                        handleUploadClick={handleUploadClick}
                        handleDeleteReport={handleDeleteReport}
                        status={auditRequest.status}
                        emailCount={emailCount}
                        deleteLoading={deleteLoading}
                        handleSendMail={handleSendMail}
                        viewReport={viewReport}
                        isReportGeneratedAgain={isReportGeneratedAgain}
                        fetchAuditRequest={fetchAuditRequest}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <ProcessDetailsCard
                        isReRun={isReRun}
                        status={auditRequest.status}
                        manualPreProcessStart={auditRequest.automaticPreProcessStart}
                        gogigReqId={auditRequest.gogigRequestId}
                        requesterId={auditRequest.requesterId}
                        fetchAuditRequest={fetchAuditRequest}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <GuideLines
                        status={auditRequest.status}
                        isReRun={isReRun}
                        isAutoRerun={isAutoReRun}
                        isReportGeneratedAgain={isReportGeneratedAgain}
                        resultUrl={auditRequest.resultUrl}
                        timestamps={timestamps}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </DialogContent>

      <ReportUploadDialog
        open={openUploadDialog}
        onClose={handleCloseUploadDialog}
        onUpload={handleUpload}
        uploadProgress={uploadProgress}
        selectedFile={selectedFile}
        onFileChange={handleFileChange}
        viewReport={viewReport}
        progressBarColor={progressBarColor}
      />
    </>
  );
};

export default AuditRequestDialog;
