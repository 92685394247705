import React from "react";
import { Box, Grid } from "@mui/material";
import Container from "@mui/system/Container/Container";
import UserProfileDataComponent from "./UserProfileData";
import { useAuthContext } from "../../providers/AuthProvider";

const UserData: React.FC = () => {
  const { userDetails } = useAuthContext();

  return (
    <Grid>
      <Box marginTop={5}>
        <Container>
          <Box textAlign="center" mb={2}>
            <UserProfileDataComponent userDetails={userDetails} />
          </Box>
        </Container>
      </Box>
    </Grid>
  );
};

export default UserData;
