import React from "react";
import {
  TableBody,
  TableRow,
  TableCell,
  styled,
  Table,
  TableHead,
  TableSortLabel,
  Box,
} from "@mui/material";
import { VehicleAuditDetails } from "../../vehicleAuditComponents/types";
import dateFormat from "dateformat";
import { camelCaseToNormal } from "../../../../validators/camelCaseToNormal";
import SourcePhotoFieldCell from "../../vehicleAuditComponents/AuditComponents/sourcePhotoFieldCell";
import ImageCell from "./ImageCell";

type Order = "asc" | "desc";

interface AuditResultTableProps {
  order: Order;
  data: VehicleAuditDetails[];
  orderBy: keyof VehicleAuditDetails;
  handleRequestSort: (property: keyof VehicleAuditDetails) => void;
}

// Styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: "10px 20px",
  border: "1px solid #ccc",
  textAlign: "center",
}));

const StyledBodyCell = styled(TableCell)(() => ({
  border: "1px solid #ccc",
  textAlign: "center",
  justifyContent: "center",
}));
const getRowBackgroundColor = (
  hasUnknown: boolean,
  authenticity: number | null
) => {
  if (hasUnknown) return "#dbd32c"; // Yellow for "Unknown" in reasonForRecheck

  switch (authenticity) {
    case 1:
      return "#FFCCCB"; // Red for authenticity === 1
    case 3:
      return "#FFD580"; // Orange for authenticity === 3
    case 5:
      return "#CCFFCC"; // Green for authenticity === 5
    default:
      return "none"; // Default color
  }
};


const AuditResultTable = ({
  data,
  order,
  orderBy,
  handleRequestSort,
}: AuditResultTableProps) => {
  const decodeReasonForRecheck = (reason: string | null) => {
    if (!reason) return [];
    try {
      return JSON.parse(reason);
    } catch {
      return [];
    }
  };
 
  return (
    <Table>
      <TableHead>
        <TableRow>
          <StyledTableCell
            sortDirection={orderBy === "pageNumber" ? order : false}
          >
            <TableSortLabel
              active={orderBy === "pageNumber"}
              direction={orderBy === "pageNumber" ? order : "asc"}
              onClick={() => handleRequestSort("pageNumber")}
            >
              Page Number
            </TableSortLabel>
          </StyledTableCell>
          <StyledTableCell
            sortDirection={orderBy === "vehicleClass" ? order : false}
          >
            <TableSortLabel
              active={orderBy === "vehicleClass"}
              direction={orderBy === "vehicleClass" ? order : "asc"}
              onClick={() => handleRequestSort("vehicleClass")}
            >
              Vehicle Class
            </TableSortLabel>
          </StyledTableCell>
          <StyledTableCell
            sortDirection={orderBy === "authenticity" ? order : false}
          >
            <TableSortLabel
              active={orderBy === "authenticity"}
              direction={orderBy === "authenticity" ? order : "asc"}
              onClick={() => handleRequestSort("authenticity")}
            >
              Authenticity
            </TableSortLabel>
          </StyledTableCell>
          {[
            "Vehicle Number",
            "Maker Model",
            "Manufacturer",
            "Vehicle Color",
            "Image Path",
            "Reason for Recheck",
            "Reason for Authenticity",
            "Remarks",
            "Status",
            "Recheck",
            "Match Status",
            "Match Summary",
            "ID Not Found",
            "Source Down",
            "Created At",
            "Updated At",
            "Matched At",
            "Owner Name",
            "RTO Name",
            "Fuel Type",
            "Chassis Number",
            "Request ID",
            "Source",
            "Auth ID",
          ].map((header) => (
            <StyledTableCell key={header}>{header}</StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, index) => {
          const {
            pageNumber,
            vehicleClass,
            vehicleNumber,
            rcNumber,
            makerModelFromPhoto,
            makerModelFromSource,
            manufacturerFromPhoto,
            manufacturerFromSource,
            colorFromPhoto,
            colorFromSource,
            imagePath,
            authenticity,
            reasonForRecheck,
            reasonForAuthenticity,
            remarks,
            status,
            recheck,
            matchStatus,
            matchSummary,
            idNotFound,
            sourceDown,
            createdAt,
            updatedAt,
            matchTimeStamp,
            ownerName,
            rtoName,
            fuelType,
            chassisNumber,
            requestId,
            source,
            authId,
          } = row;
          const hasUnknown = decodeReasonForRecheck(
            reasonForRecheck
          ).some((reason: string) => reason.toLowerCase().includes("unknown")) && row.authenticity===0;

          return (
            <TableRow
              key={index}
              sx={{
                backgroundColor: getRowBackgroundColor(
                  hasUnknown,
                  authenticity
                ),
              }}
            >
              <StyledBodyCell>{pageNumber ?? "N/A"}</StyledBodyCell>
              <StyledBodyCell>
                {vehicleClass === "" ? "Blank" : vehicleClass ?? "N/A"}
              </StyledBodyCell>
              <StyledBodyCell>{authenticity ?? "N/A"}</StyledBodyCell>

              <SourcePhotoFieldCell
                dataFromPhoto={vehicleNumber}
                dataFromSource={rcNumber}
              />
              <SourcePhotoFieldCell
                dataFromPhoto={makerModelFromPhoto}
                dataFromSource={makerModelFromSource}
              />
              <SourcePhotoFieldCell
                dataFromPhoto={manufacturerFromPhoto}
                dataFromSource={manufacturerFromSource}
              />
              <SourcePhotoFieldCell
                dataFromPhoto={colorFromPhoto}
                dataFromSource={colorFromSource}
              />

              <StyledBodyCell>
                {" "}
                <ImageCell imagePath={imagePath} />
              </StyledBodyCell>
              <StyledBodyCell
                sx={{
                  minWidth: 150,
                  padding: 0,
                }}
              >
                {reasonForRecheck === null
                  ? "null"
                  : decodeReasonForRecheck(reasonForRecheck).length > 0
                  ? decodeReasonForRecheck(reasonForRecheck).map(
                      (reason: string, idx: number) => (
                        <Box key={idx}>
                          <li>{camelCaseToNormal(reason)}</li>
                        </Box>
                      )
                    )
                  : "N/A"}
              </StyledBodyCell>
              <StyledBodyCell>
                {reasonForAuthenticity ? (
                  <ul>
                    {reasonForAuthenticity.split(",").map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                ) : (
                  "N/A"
                )}
              </StyledBodyCell>
              <StyledBodyCell>{remarks ?? "N/A"}</StyledBodyCell>
              <StyledBodyCell>{status ?? "N/A"}</StyledBodyCell>
              <StyledBodyCell>{recheck ?? "N/A"}</StyledBodyCell>
              <StyledBodyCell>{matchStatus ?? "N/A"}</StyledBodyCell>
              <StyledBodyCell>{matchSummary ?? "N/A"}</StyledBodyCell>
              <StyledBodyCell>{idNotFound ?? "N/A"}</StyledBodyCell>
              <StyledBodyCell>{sourceDown ?? "N/A"}</StyledBodyCell>
              <StyledBodyCell>
                {dateFormat(createdAt, "dd-mmm-yyyy hh:mm TT") ?? "N/A"}
              </StyledBodyCell>
              <StyledBodyCell>
                {dateFormat(updatedAt, "dd-mmm-yyyy hh:mm TT") ?? "N/A"}
              </StyledBodyCell>
              <StyledBodyCell>
                {dateFormat(matchTimeStamp, "dd-mmm-yyyy hh:mm TT") ?? "N/A"}
              </StyledBodyCell>
              <StyledBodyCell>{ownerName ?? "N/A"}</StyledBodyCell>
              <StyledBodyCell>{rtoName ?? "N/A"}</StyledBodyCell>
              <StyledBodyCell>{fuelType ?? "N/A"}</StyledBodyCell>
              <StyledBodyCell>{chassisNumber ?? "N/A"}</StyledBodyCell>
              <StyledBodyCell>{requestId ?? "N/A"}</StyledBodyCell>
              <StyledBodyCell>{source ?? "N/A"}</StyledBodyCell>
              <StyledBodyCell>{authId ?? "N/A"}</StyledBodyCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default AuditResultTable;
