import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SpotrueApiBaseUrl } from "./baseUrls";

export async function deleteReport(
  authHeader: string,
  gogigReqId: string,
) {
  return fetchWrapper(
    `${SpotrueApiBaseUrl}/deleteReport?gogigReqId=${gogigReqId}`,
    {
      method: "POST",
      headers: {
        Authorization: authHeader,
      },
    }
  );
}