import React from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import ReactGA from "react-ga4";

ReactGA.initialize("G-LFKJQ46PC5");

type CustomButtonProps = {
  variant?: "outlined" | "text" | "contained";
  startIcon?: React.ReactNode;
  children: React.ReactNode;
  onClick?: () => void;
  style?: React.CSSProperties;
  tag?: string; // Add a new prop for the button tag
} & ButtonProps;

function CustomButton({
  variant,
  children,
  onClick,
  style,
  tag, // Accept the 'tag' prop
  ...rest
}: CustomButtonProps) {
  const defaultStyle: React.CSSProperties = {
    ...style,
  };

  const handleClick = () => {
    // Add your custom logic here
    console.log(`Button clicked with tag: ${tag}`);
    ReactGA.event({
      category: "action",
      action: "click-" + tag,
      label: tag,
    });

    // Call the onClick prop if it's provided
    if (onClick) {
      onClick();
    }
  };

  return (
    <Button
      variant={variant}
      onClick={handleClick}
      style={defaultStyle}
      {...rest}
    >
      {children}
    </Button>
  );
}

export default CustomButton;
