import React, { useState, ChangeEvent, useEffect, useRef } from "react";
import { Button, Typography, Box } from "@mui/material";
import { useAuthContext } from "../../../providers/AuthProvider";
import {
  createUserAuditRequest,
  FileUrls,
} from "../../../clients/createUserAuditRequest";
import useFileUpload from "../../../hook/FileUploadHook";
import GlobalSnackbar from "../../snackbarComponent/Snackbar";
import { VehicleInterface } from "./types";
import JSZip from "jszip";
import { extractImagesFromPptx } from "../utils/extractImagesFromPptx";
import { uploadFileWithMetadata } from "../../../clients/uploadFileWithMetadata";
import AuditRequestForm from "./AuditCreationComponents/AuditRequestComponent";
import ImageGrid from "./AuditCreationComponents/ImageGrid";
import AuditFieldsForm from "./AuditCreationComponents/AuditDetailsForm";
import { formatFileName } from "../../../utils/formatFileName";
interface ImageData {
  pageNumber: number;
  imagePath: string;
  saved: boolean;
}

const UploadAndCreateRequest = () => {
  const [pptxFile, setPptxFile] = useState<File | null>(null);
  const [sheetFile, setSheetFile] = useState<File | null>(null);
  const { authHeader, userDetails } = useAuthContext();
  const [gogigRequestId, setGogigRequestId] = useState<string>("");
  const [requesterId, setRequesterId] = useState<string>("");
  const [showAuditFields, setShowAuditFields] = useState<boolean>(false);
  const [, setUploadProgress] = useState<Record<string, number>>(
    {}
  );
  const deletingIndexRef = useRef<number | null>(null);

  const { uploadFile, loading, setLoading, error } = useFileUpload(
    setUploadProgress, deletingIndexRef
  );
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [severity, setSeverity] = useState<boolean>(false);
  const [isFileUpload, setIsFileUpload] = useState<boolean | null>(null);
  const [isImageParsed, setIsImageParsed] = useState<boolean>(true);
  const [requestTitle, setRequestTitle] = useState<string>("");
  const [isRequestTitle, setIsRequestTitle] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [vehicleAuditData, setVehicleAuditData] = useState<VehicleInterface>({
    imagePath: "",
    pageNumber: 0,
    manufacturerFromPhoto: "",
    makerModelFromPhoto: "",
    rcNumber: "",
    colorFromPhoto: "",
    remarks: "",
    recheck: false,
    reasonForRecheck: [],
    imageOrientation: "",
  });
  const [imagesData, setImagesData] = useState<ImageData[]>([]);
  const [checked, setChecked] = useState<boolean>(false);
  const [pptxUrl, setPptxUrl] = useState<string | null>(null);
  const [sheetUrl, setSheetUrl] = useState<string | null>(null);

  // Trigger handleCreateAuditRequest once pptxUrl && sheetUrl is updated
  useEffect(() => {
    if (sheetUrl && pptxUrl) {
      handleCreateAuditRequest(sheetUrl, pptxUrl);

    } else if (pptxUrl && !sheetFile) {
      handleCreateAuditRequest("", pptxUrl);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sheetUrl, pptxUrl]);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {

    setIsImageParsed(false);
    const uploadedFile = event.target.files?.[0];
    if (uploadedFile) {
      const formattedFileName = formatFileName(uploadedFile);
      const formattedFile = new File([uploadedFile], formattedFileName, {
        type: uploadedFile.type,
      });
      setPptxFile(formattedFile);
      const zip = new JSZip();
      const pptData = await zip.loadAsync(uploadedFile);
      const extractedImages = await extractImagesFromPptx(pptData);
      setImagesData(
        extractedImages.map((image, index) => ({
          pageNumber: index,
          imagePath: image,
          saved: false,
        }))
      );
    }
    setShowAuditFields(false);
    setIsImageParsed(true);
    event.target.value = "";
  };

  const handleUpload = async () => {
    // Check if either pptxFile or sheetFile is uploaded
    if (!pptxFile) {
      setSnackbarMessage(
        "Please upload PPT."
      );
      setSnackbarOpen(true);
      setSeverity(true);
      return;
    }

    // Validate the request title
    if (requestTitle === "" || requestTitle === null) {
      setIsRequestTitle(false);
      setSnackbarMessage("Enter the request title");
      setSnackbarOpen(true);
      setSeverity(true);
      return;
    }

    setIsRequestTitle(true);
    setIsFileUpload(false);

    try {
      // Upload PPTX file if it exists
      if (pptxFile) {
        const pptUrl = await uploadFile(1, pptxFile);
        setPptxUrl(pptUrl);
      }

      // Upload Excel sheet if it exists
      if (sheetFile) {
        const formData = new FormData();
        formData.append("excel sheet", sheetFile);

        const metadata = {
          uploaderName: `Gogig Admin ${userDetails?.firstName} ${userDetails?.lastName}`,
        };

        formData.append("tags", "AUDIT DATA")
        formData.append("metadata", JSON.stringify(metadata))
        if (!authHeader) return null;
        const res = await uploadFileWithMetadata(authHeader, formData);
        const sheetUrl = res.fileUrl;
        setSheetUrl(sheetUrl);
      }
    } catch (error) {
      console.error("Error during upload or creating audit request:", error);
      setSnackbarMessage("An error occurred. Please try again.");
      setSnackbarOpen(true);
      setSeverity(true);
    }
  };

  const handleCreateAuditRequest = async (sheetUrl: string, pptUrl: string) => {
    if (!requestTitle) return setIsRequestTitle(false);
    setLoading(true);
    let payload: FileUrls = {
      sheetUrl: sheetUrl,
      pptUrl: pptUrl,
      requestTitle: requestTitle,
    };

    // Create audit request logic here
    try {
      if (authHeader) {
        const response = await createUserAuditRequest(authHeader, payload);
        const data = (
          response.data as {
            auditRequestData: { gogigRequestId: string; requesterId: string };
          }
        ).auditRequestData;
        setGogigRequestId(data.gogigRequestId);
        setLoading(false);
        setShowAuditFields(true);
        setRequesterId(encodeURIComponent(data.requesterId));
        setSnackbarOpen(true);
        setSnackbarMessage("Audit Request Created Successfully");
        setSeverity(false);
        setIsFileUpload(true);
      }
    } catch (error) {
      setLoading(false);
      setShowAuditFields(false);
      setSnackbarOpen(true);
      setSnackbarMessage("Audit Request Creation Failed");
      setSeverity(false);
      setIsFileUpload(false);
    }
  };

  const handleRequestTitle = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;

    // Update the request title state
    setRequestTitle(value);

    // Check if the input is empty
    if (value === "") {
      setIsRequestTitle(false);
    } else {
      setIsRequestTitle(true);
    }
  };

  const handleClearAll = () => {
    setPptxFile(null);
    setSheetFile(null);
    setGogigRequestId("");
    setRequesterId("");
    setSheetUrl(null);
    setImagesData([]);
    setRequestTitle("");
    setIsFileUpload(false);
    setShowAuditFields(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const encodedValue = encodeURIComponent(e.target.value);
    setRequesterId(encodedValue);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleExcelSheetChange = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile && selectedFile.name.endsWith(".xlsx")) {
      setSheetFile(selectedFile);
    } else {
      alert("Please select a valid excel file.");
      setSheetFile(null);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 4,
      }}
    >
      <GlobalSnackbar
        open={error != null || snackbarOpen}
        message={error ? error : snackbarMessage ? snackbarMessage : ""}
        onClose={() => setSnackbarOpen(!snackbarOpen)}
        severity={error || severity ? "error" : "success"}
        vertical="top"
        horizontal="center"
      />

      <Typography variant="h5" component="h2" sx={{ mb: 0 }}>
        Create Audit Request
      </Typography>

      <AuditRequestForm
        requestTitle={requestTitle}
        isRequestTitle={isRequestTitle}
        handleRequestTitle={handleRequestTitle}
        handleExcelSheetUpload={handleUpload}
        handleCreateAuditRequest={handleCreateAuditRequest}
        checked={checked}
        loading={loading}

        pptxFile={pptxFile}
        sheetFile={sheetFile}
        handleCheckboxChange={handleCheckboxChange}
        handleExcelSheetChange={handleExcelSheetChange}
        handleFileChange={handleFileChange}
        isFileUpload={isFileUpload}
        sheetUrl={sheetUrl ?? ""}
        pptUrl={pptxUrl ?? ""}
      />

      {showAuditFields && (
        <AuditFieldsForm
          gogigRequestId={gogigRequestId}
          requesterId={requesterId}
          handleChange={handleChange}
          setGogigRequestId={setGogigRequestId}
        />
      )}

      {(pptxFile || sheetFile) && (
        <Box>
          <Button
            sx={{ whiteSpace: "nowrap", padding: "10px 16px", mx: 5 }}
            variant="contained"
            onClick={handleClearAll}
          >
            Clear All
          </Button>

          <ImageGrid isImageParsed={isImageParsed} imagesData={imagesData} />
        </Box>
      )}
    </Box>
  );
};

export default UploadAndCreateRequest;
