import React from "react";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

interface FilterSelectProps {
  label: string;
  value: string[];
  options: string[];
  onChange: (event: SelectChangeEvent<string[]>) => void;
}

const FilterSelect = ({
  label,
  value,
  options,
  onChange,
}: FilterSelectProps) => (
  <FormControl variant="outlined" size="small" sx={{ mr: 1, minWidth: 200 }}>
    <InputLabel>{label}</InputLabel>
    <Select
      multiple
      value={value}
      onChange={onChange}
      label={label}
      sx={{
        maxWidth: 200, // Set a fixed width or adjust as needed
        whiteSpace: "nowrap", // Prevent text wrapping
        overflow: "hidden", // Hide overflowed content
        textOverflow: "ellipsis", // Show ellipsis for overflowing text
      }}
      renderValue={(selected) => {
        // If selected values overflow the width, show ellipsis
       
        const selectedDisplay = selected
          .map((val) => (val === null ? "N/A" :val===""?"Blank": val)) // Display "N/A" for empty strings
          .join(", ");
        return (
          <span
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {selectedDisplay}
          </span>
        );
      }}
    >
      {options.map((option, index) => (
        <MenuItem key={index} value={option}>
          <Checkbox checked={value.indexOf(option) > -1} />
          <ListItemText primary={option?option:option===""?"Blank":"N/A"} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default FilterSelect;
