export const vehicleColorDropDown: { [key: string]: string } = {
  Black: "Black",
  Yellow: "Yellow",
  Green: "Green",
  "Black/Yellow": "Black/Yellow",
  Pink:"Pink"
};

export const ReCheckComments: { [key: string]: string } = {
  rcNumberNotVisible: "RC number is not visible",
  damagedVehicle: "Vehicle is damaged",
  photoShopped: "Vehicle is photoshopped",
  colorNotFound:"Color not found"
};
export const vehicleClassDropDown: {[key: string]: string} ={
  bus:"bus",
  "3wt": "three wheeler",
  car:"car"
};

export const VehicleModelDropDown: { [key: string]: string[] } = {
  "Bajaj Auto": ["RE", "RE COMPACT"],
  "TVS Motor": ["TVS KING"],
  "Piaggio": ["APE CITY"],
  "Atul Auto": ["ATUL"],
  "Mahindra & Mahindra": ["MAHINDRA & MAHINDRA"],
  "Not Able To Understand": ["NOT ABLE TO UNDERSTAND"],
};

export const VehicleManufacturer: { [key: string]: string } = {
  "Bajaj Auto": "Bajaj Auto Ltd",
  "TVS Motor": "TVS Motor Company Ltd",
  "Piaggio": "Piaggio",
  "Mahindra & Mahindra": "Mahindra & Mahindra Ltd",
  "Atul Auto": "Atul Auto",
  "Not Able To Understand": "Not Able To Understand",
};
