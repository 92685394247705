import { MenuItem, TextField, Typography } from "@mui/material"

const workExperienceLabels = [
  { value: "I haven't worked before", label: "I haven't worked before" },
  { value: "0-1 year", label: "0-1 year" },
  { value: "1-2 years", label: "1-2 years" },
  { value: "2-4 years", label: "2-4 years" },
  { value: "4-6 years", label: "4-6 years" },
  { value: "above 6 years", label: "above 6 years" },
];

interface WorkExperienceProps {
  experienceDetails: { workExperience: string; lastCompany: string; lastRole: string; };
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  emptyFieldsError: string | null,
}

const WorkExperience = ({ experienceDetails: profileDetails, handleChange, emptyFieldsError }: WorkExperienceProps) => {
  return (
    <>
      <Typography sx={{ fontFamily: 'inter, sans-serif', fontSize: '24px' }}>
        A few questions: first, Do you have any work experience?
      </Typography>
      <Typography sx={{ fontFamily: 'inter, sans-serif', fontSize: '14px' }}>
        Could you share your years of experience in a professional capacity
      </Typography>
      <TextField
        sx={{ display: 'flex', marginTop: '20px', maxWidth: '400px' }}
        select
        required
        label="Work Experience"
        name="workExperience"
        onChange={handleChange}
        value={profileDetails.workExperience}
      >
        {workExperienceLabels.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      {emptyFieldsError && !profileDetails.workExperience && (
        <Typography color="error">Please select your work experience</Typography>
      )}
      {profileDetails.workExperience !== '' && profileDetails.workExperience !== "I haven't worked before" && (
        <TextField
          sx={{ display: 'flex', marginTop: '20px', maxWidth: '400px' }}
          label="Last Company you worked in"
          name="lastCompany"
          required
          onChange={handleChange}
          value={profileDetails.lastCompany}
        />
      )}
      {emptyFieldsError && !profileDetails.lastCompany && profileDetails.workExperience !== ''
        && profileDetails.workExperience !== "I haven't worked before" && (
          <Typography color="error">Please enter your last company name</Typography>
        )}
      {profileDetails.workExperience !== '' && profileDetails.workExperience !== "I haven't worked before" && (
        <TextField
          sx={{ display: 'flex', marginTop: '20px', maxWidth: '400px' }}
          label="Your Role in Last Company"
          name="lastRole"
          required
          onChange={handleChange}
          value={profileDetails.lastRole}
        />
      )}
      {emptyFieldsError && !profileDetails.lastRole && profileDetails.workExperience !== ''
        && profileDetails.workExperience !== "I haven't worked before" && (
          <Typography color="error">Please enter your role in last company</Typography>
        )}
    </>
  )
}

export default WorkExperience;