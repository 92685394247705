// AuthenticityCountDisplay.tsx
import { Box, Typography } from "@mui/material";
import { VehicleAuditDetails } from "../../vehicleAuditComponents/types";

interface AuthenticityCountDisplayProps {
  data: VehicleAuditDetails[];
}

const AuthenticityCountDisplay = ({ data }: AuthenticityCountDisplayProps) => {
  const correctDataCount = data.filter((row) => row.authenticity === 5).length;
  const authenticityThreeCount = data.filter((row) => row.authenticity === 3).length;
  const incorrectDataCount = data.filter((row) => row.authenticity === 1).length;
  const authenticityZeroCount = data.filter((row) => row.authenticity === 0).length;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flex: 1,
        mx: 5,
      }}
    >
      <Typography variant="body2">
        Correct Data:{" "}
        <strong
          style={{
            color: "green",
          }}
        >
          {correctDataCount}
        </strong>
      </Typography>
      <Typography variant="body2">
        Authenticity 3:{" "}
        <strong
          style={{
            color: "orange",
          }}
        >
          {authenticityThreeCount}
        </strong>
      </Typography>
      <Typography variant="body2">
        Incorrect Data:{" "}
        <strong
          style={{
            color: "red",
          }}
        >
          {incorrectDataCount}
        </strong>
      </Typography>
      <Typography variant="body2">
        Authenticity 0:{" "}
        <strong>{authenticityZeroCount}</strong>
      </Typography>
    </Box>
  );
};

export default AuthenticityCountDisplay;
