import { Grid, Typography, Box } from "@mui/material";
import React from "react";
import QRCode from "react-qr-code";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import StarRateIcon from "@mui/icons-material/StarRate";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import DescriptionIcon from "@mui/icons-material/Description";
import SettingsIcon from "@mui/icons-material/Settings";
import ImageIcon from "@mui/icons-material/Image";
import BarChartIcon from "@mui/icons-material/BarChart";
import DataUsageIcon from "@mui/icons-material/DataUsage";

export const ReportFooter = () => {
  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LocationOnIcon sx={{ marginRight: 1 }} />
          <Typography variant="h6" fontSize={14} color="black">
            Rukmani Knowledge Park, Bangalore, Karnataka, India, 560 064
          </Typography>
        </Grid>

        <Grid
          item
          xs={3}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <EmailIcon sx={{ marginRight: 1 }} />
          <Typography variant="h6" fontSize={14} color="black">
            contact@gogig.in
          </Typography>
        </Grid>

        <Grid
          item
          xs={3}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h6"
            fontSize={14}
            color="black"
            style={{ display: "flex", alignItems: "center" }}
          >
            4.9
            <StarRateIcon sx={{ color: "#ffb300", marginLeft: 1 }} />
            <StarRateIcon sx={{ color: "#ffb300" }} />
            <StarRateIcon sx={{ color: "#ffb300" }} />
            <StarRateIcon sx={{ color: "#ffb300" }} />
            <StarHalfIcon sx={{ color: "#ffb300" }} />
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{
          padding: "20px",
          maxWidth: "1000px",
          margin: "auto",
          backgroundColor: "#FF7A00",

        }}
      >
        <Grid
          container
          alignItems="center"
          spacing={2}
          justifyContent="space-between"
        >
          {/* Icon with Text - Campaign Report */}
          <Grid item xs={2} style={{ textAlign: "center", color: "white" }}>
            <DescriptionIcon style={{ fontSize: 30 }} />
            <Typography variant="body2" fontSize={12}>
              CAMPAIGN REPORT
            </Typography>
          </Grid>

          {/* Icon with Text - Pre Processing */}
          <Grid item xs={2} style={{ textAlign: "center", color: "white" }}>
            <SettingsIcon style={{ fontSize: 30 }} />
            <Typography variant="body2" fontSize={12}>
              PRE PROCESSING OF PPT AND EXCEL
            </Typography>
          </Grid>

          {/* Icon with Text - Image Processing */}
          <Grid item xs={2} style={{ textAlign: "center", color: "white" }}>
            <ImageIcon style={{ fontSize: 30 }} />
            <Typography variant="body2" fontSize={12}>
              IMAGE PROCESSING AGAINST EACH VEHICLE
            </Typography>
          </Grid>

          {/* Icon with Text - Generation of Final Report */}
          <Grid item xs={2} style={{ textAlign: "center", color: "white" }}>
            <BarChartIcon style={{ fontSize: 30 }} />
            <Typography variant="body2" fontSize={12}>
              GENERATION OF FINAL REPORT
            </Typography>
          </Grid>

          {/* Icon with Text - Post Processing */}
          <Grid item xs={2} style={{ textAlign: "center", color: "white" }}>
            <DataUsageIcon style={{ fontSize: 40 }} />
            <Typography variant="body2" fontSize={12}>
              POST PROCESSING OF ALL DATA
            </Typography>
          </Grid>

          {/* QR Code Section */}
          <Grid item xs={2} style={{ textAlign: "center" }}>
            <QRCode
              size={256}
              style={{
                height: "auto",
                maxWidth: "100%",
                width: "100%",
              }}
              value={"https://gogig.in/"}
              viewBox={`0 0 256 256`}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
