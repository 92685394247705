import React, { useEffect, useRef, useState } from "react";
import {
    Tabs,
    Tab,
    Button,
    TextField,
    List,
    ListItem,
    Typography,
    LinearProgress,
    IconButton,
    Card,
    CardContent,
    Box,
    Snackbar,
    Alert,
    Grid,
    CircularProgress,
} from "@mui/material";
import { UploadFile, Delete, InsertDriveFile, ArrowBack } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { AuditRequest, FileData } from "../vehicleAuditComponents/types";
import { reviewAuditRequest } from "../../../clients/reviewAuditRequest";
import useFileUpload from "../../../hook/FileUploadHook";
import deleteFileFromS3 from "../../../clients/deleteFileFromS3";
import getFileAccessUrl from "../../../clients/getFileAccessUrl";
import getFileData from "../../../clients/getFileData";

interface AuditRequestReviewProps {
    auditRequest: AuditRequest;
    authHeader?: string;
}

const AuditRequestReview: React.FC<AuditRequestReviewProps> = ({
    auditRequest,
    authHeader,
}) => {
    const { gogigRequestId } = useParams<{ gogigRequestId?: string }>();
    const [activeTab, setActiveTab] = useState<'approve' | 'comment' | 'reject'>(auditRequest.requestReview || "approve");
    const [loading, setLoading] = useState<boolean>(false);
    const [comment, setComment] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [uploadProgress, setUploadProgress] = useState<Record<string, number>>(
        {}
    );
    const [uploadedUrls, setUploadedUrls] = useState<Record<string, string>>({});
    const deletingIndexRef = useRef<number | null>(null);

    const { uploadFile } = useFileUpload(
        setUploadProgress,
        deletingIndexRef
    );
    const [pptxFile, setPptxFile] = useState<File | null>(null);
    const [sheetFile, setSheetFile] = useState<File | null>(null);
    const [hasSubmit, setHasSubmit] = useState<boolean>(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");

    const handleSnackbarClose = () => setSnackbarOpen(false);
    const navigate = useNavigate();

    const [clientFiles, setClientFiles] = useState<{ fileId: string, url: string, fileExtension: string }[]>([]);

    const effectCalledRef = useRef(false);

    useEffect(() => {
        setLoading(true);
        if (effectCalledRef.current) return;
        effectCalledRef.current = true;

        const fetchUrls = async () => {
            if (auditRequest.clientUrls && authHeader) {
                try {
                    const fetchedUrls = await Promise.all(auditRequest.clientUrls.split(", ").map(async (url) => {
                        const fileDataResponse = (await getFileData(authHeader, url.split("/").slice(-1)[0])).data as { fileData: FileData };
                        const fileData = fileDataResponse.fileData;
                        const fileExtension = fileData.originalFileName.split(".").slice(-1)[0];
                        return { fileId: fileData.fileId, url, fileExtension };
                    }));

                    setClientFiles(fetchedUrls);
                } catch (error) {
                    console.error('Failed to fetch URLs', error);
                }
            }
        };

        fetchUrls();

    }, [auditRequest.clientUrls, authHeader]);

    useEffect(() => {
        if (auditRequest.clientUrls && auditRequest.clientUrls.split(", ").length === clientFiles.length)
            setLoading(false);
    }, [auditRequest.clientUrls, clientFiles.length, loading])

    const handleFileUpload = async (file: File, type: "excel" | "ppt") => {
        const index = type === "excel" ? 0 : 1;
        const url = await uploadFile(index, file) as string;
        if (url) {
            setUploadedUrls((prev) => ({ ...prev, [type]: url }));
            setUploadProgress((prev) => ({ ...prev, [index]: 100 }));
            setSnackbarMessage(`${type.toUpperCase()} file uploaded successfully.`);
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        } else {
            setSnackbarMessage(`Failed to upload ${type.toUpperCase()} file.`);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
        type === "excel" ? setSheetFile(file) : setPptxFile(file);
    };

    const handleDeleteUpload = async (type: "excel" | "ppt") => {
        const index = type === "excel" ? 0 : 1;
        deletingIndexRef.current = index;
        try {
            if (authHeader) {
                await deleteFileFromS3(authHeader, uploadedUrls[type]);
            }

            setSnackbarMessage(`${type.toUpperCase()} file deleted successfully.`);
            setSnackbarSeverity("success");
            setSnackbarOpen(true);

            setUploadedUrls((prev) => {
                const updated = { ...prev };
                delete updated[type];
                return updated;
            });

            setUploadProgress((prev) => ({ ...prev, [index]: undefined }));
            type === "excel" ? setSheetFile(null) : setPptxFile(null);
        } catch (error) {
            setSnackbarMessage(`Failed to delete ${type.toUpperCase()} file.`);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        } finally {
            deletingIndexRef.current = null;
        }
    };

    const handleSubmit = async () => {
        if (!authHeader || !gogigRequestId) return null;
        setHasSubmit(true);
        const body: any = {};
        auditRequest['requestReview'] = activeTab;
        if (activeTab === "approve") {
            body.dueDate = dueDate;
            if (uploadedUrls.excel) body.processedSheetUrl = uploadedUrls.excel;
            if (uploadedUrls.ppt) body.processedPptUrl = uploadedUrls.ppt;
        } else if (activeTab === "comment" || activeTab === "reject") {
            body.reviewComment = comment;
        }

        try {
            await reviewAuditRequest(activeTab, authHeader, gogigRequestId, body);
            setSnackbarMessage(`${activeTab.charAt(0).toUpperCase() + activeTab.slice(1)} request submitted successfully.`);
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
            window.location.reload();
        } catch (error) {
            setSnackbarMessage(`Failed to ${activeTab} request. Please try again.`);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };

    function capitalize(input: string | undefined) {
        if (!input) return input;
        const sentences = input.split(/(?<=\.|\?|!)(\s+|$)/);
        const firstSentenceCapitalized = sentences[0].charAt(0).toUpperCase() + sentences[0].slice(1);
        const result = [firstSentenceCapitalized, ...sentences.slice(1)].join('');
        return result;
    }

    const handleAccessUrl = async (clientFile: { fileId: string, url: string, fileExtension: string }): Promise<string | undefined> => {
        if (!authHeader) return;
        try {
            if (clientFile.url.includes('.s3.')) {
                const res = await getFileAccessUrl(authHeader, clientFile.url);
                const signedUrl = res.data as { url: string };
                return signedUrl.url;
            } else return clientFile.url;
        } catch (error) {
            console.error(`Failed to fetch ${clientFile.fileId}.${clientFile.fileExtension} URL`, error);
        }
    };

    return (
        <Box p={3} height="85vh">
            <Box display="flex" alignItems="center" gap={2} pl={2}>
                <IconButton onClick={() => navigate("/manage-audit-list")} edge="start" color="primary" aria-label="back">
                    <ArrowBack />
                </IconButton>
                <Typography variant="h3" gutterBottom>
                    Audit Request Review
                </Typography>
            </Box>

            {(auditRequest.requestReview === "comment" || auditRequest.requestReview === "reject") &&
                <Card
                    variant="outlined"
                    sx={{ mb: 3, height: "5%", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: auditRequest.requestReview === "comment" ? "warning.main" : "error.main" }}
                >
                    <Typography color="white" fontWeight="bold" fontSize="20px" paddingRight={10}>
                        <strong>REVIEW STATUS : </strong> {(auditRequest.requestReview).toUpperCase() + "ED"}
                    </Typography>
                </Card>
            }

            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                {/* Left Side: Static Information */}
                <Box sx={{ flex: 1, minWidth: '47%' }}>
                    <Card variant="outlined" sx={{ mb: 3 }}>
                        <CardContent>
                            <Typography variant="h5" gutterBottom pl={1}>Request Details</Typography>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mt: 2, "& > *": { fontSize: "0.875rem" }, }}>
                                <Typography>
                                    <strong>Request ID:</strong> {auditRequest.gogigRequestId}
                                </Typography>
                                <Typography>
                                    <strong>Requester Name:</strong> {auditRequest.requesterName}
                                </Typography>
                                <Typography>
                                    <strong>Client Comment:</strong> {auditRequest.clientComment ? auditRequest.clientComment : "No Comments by Client"}
                                </Typography>
                                <Typography>
                                    <strong>Campaign Name:</strong> {auditRequest.campaignName}
                                </Typography>
                                {auditRequest.campaignLocation && (
                                    <Typography>
                                        <strong>Campaign Location:</strong> {auditRequest.campaignLocation}
                                    </Typography>
                                )}
                            </Box>
                        </CardContent>
                    </Card>

                    {auditRequest.clientCommentsHistory && (
                        <Card variant="outlined" sx={{ mb: 3, overflow: "hidden", display: "flex", flexDirection: "column" }}>
                            <CardContent>
                                <Typography variant="h5" gutterBottom pl={1} pb={2}>
                                    Comments History
                                </Typography>
                                <Box>
                                    <List sx={{ display: "flex", flexDirection: "column", overflowY: "auto", paddingBottom: 1 }}>
                                        {JSON.parse(auditRequest.clientCommentsHistory).map((comment: string, index: number) => {
                                            if (typeof comment !== "string") {
                                                console.error(`Invalid comment at index ${index}:`, comment);
                                                return null;
                                            }

                                            const [timestampMatch, senderMatch, messageMatch] = [
                                                comment.match(/\[time\]:\s([^\s]+)\s/),
                                                comment.match(/\[(client|reviewer)\]:/),
                                                comment.match(/\[(client|reviewer)\]:\s(.+)/),
                                            ];

                                            const timestamp = timestampMatch ? timestampMatch[1] : "Unknown Time";
                                            const sender = senderMatch ? senderMatch[1] : "Unknown Sender";
                                            const message = messageMatch ? messageMatch[2] : "Unknown Message";
                                            const formattedTimestamp = new Date(timestamp).toLocaleString("en-US", {
                                                weekday: "short", // Short day of the week (e.g., Mon, Tue)
                                                month: "short",   // Short month (e.g., Jan, Feb)
                                                day: "numeric",   // Day of the month (e.g., 1, 2, 3)
                                                year: "numeric",  // Year (e.g., 2025)
                                                hour: "numeric",  // Hour (e.g., 1, 2, 3)
                                                minute: "numeric",// Minute (e.g., 12, 45)
                                                hour12: true,     // Use 12-hour clock
                                            });

                                            const isClient = sender === "client";
                                            const bubbleStyle = { backgroundColor: isClient ? "#0058cc" : "#d2e9fc", color: isClient ? "white" : "black" };
                                            const avatarSrc = isClient
                                                ? `${process.env.PUBLIC_URL}/images/logos/ClientLogo.png`
                                                : `${process.env.PUBLIC_URL}/images/logos/ReviewerLogo.png`;

                                            return (
                                                <ListItem key={index} sx={{ display: "flex", flexDirection: isClient ? "row" : "row-reverse", justifyContent: isClient ? "start" : "end", alignItems: "flex-end", mb: 1 }}>
                                                    {/* Avatar */}
                                                    <Box component="img" src={avatarSrc} alt={isClient ? "Client" : "Reviewer"} sx={{ width: 30, height: 30, borderRadius: "50%", margin: isClient ? "0 8px 0 0" : "0 0 0 8px", mb: 1 }} />

                                                    {/* Message Bubble and Timestamp */}
                                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: isClient ? "flex-start" : "flex-end" }}>
                                                        {/* Message Bubble */}
                                                        <Box sx={{ ...bubbleStyle, padding: "8px 12px", borderRadius: "15px", wordWrap: "break-word", maxWidth: "70%" }}>
                                                            <Typography variant="body1" sx={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>{message}</Typography>
                                                        </Box>

                                                        {/* Timestamp */}
                                                        <Typography variant="caption" sx={{ fontSize: "0.75rem", color: "black", marginTop: "4px" }}>
                                                            {formattedTimestamp}
                                                        </Typography>
                                                    </Box>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </Box>
                            </CardContent>
                        </Card>
                    )}
                </Box>
                {/* Right Side: Review Actions */}
                <Box sx={{ flex: 2 }}>
                    {clientFiles && (
                        <Card variant="outlined" sx={{ mb: 3 }}>
                            <CardContent>
                                <Typography variant="h5" gutterBottom pl={1} pb={2}>
                                    Client Files
                                </Typography>
                                {loading ?
                                    (<CardContent sx={{ textAlign: "center" }}>
                                        <CircularProgress />
                                    </CardContent>) :
                                    (<Grid container spacing={2}>
                                        {clientFiles.map((clientFile, index) => (
                                            <Grid item xs={6} sm={3} key={index}>
                                                <Button
                                                    onClick={async () => { await handleAccessUrl(clientFile) }}
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<InsertDriveFile />}
                                                    fullWidth
                                                >
                                                    {`${clientFile.fileId}.${clientFile.fileExtension}`}
                                                </Button>
                                            </Grid>
                                        ))}
                                    </Grid>)}
                            </CardContent>
                        </Card>
                    )}
                    {(auditRequest.requestReview === "reject") ?
                        (<Card variant="outlined" sx={{ mb: 3 }}>
                            <CardContent>
                                <Typography variant="h5" color="error" gutterBottom pl={1}>This Request Has Been Rejected</Typography>
                                <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mt: 2 }}>
                                    <Typography>
                                        <strong>Reject Reason : </strong> {capitalize(auditRequest.reviewComment)}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>)
                        :
                        (<Card variant="outlined">
                            <CardContent>
                                <Typography variant="h5" gutterBottom pl={1}>
                                    Review Action
                                </Typography>
                                <Tabs
                                    value={activeTab}
                                    onChange={(e, value) => setActiveTab(value)}
                                    textColor="primary"
                                    indicatorColor="primary"
                                    centered
                                    sx={{ pb: 2 }}
                                >
                                    <Tab label="Approve" value="approve" />
                                    <Tab label="Comment" value="comment" />
                                    <Tab label="Reject" value="reject" />
                                </Tabs>
                                {activeTab === "approve" && (
                                    <div>
                                        <Typography variant="h6" gutterBottom>
                                            Approve Request
                                        </Typography>
                                        <TextField
                                            type="date"
                                            label="Due Date"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            sx={{ mb: 2, mt: 2 }}
                                            value={dueDate}
                                            onChange={(e) => setDueDate(e.target.value)}
                                            disabled={auditRequest.requestReview === "comment"}
                                            inputProps={{
                                                min: new Date().toISOString().split('T')[0]
                                            }}
                                        />
                                        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                                            <Button
                                                variant="contained"
                                                startIcon={<UploadFile />}
                                                component="label"
                                                disabled={!!sheetFile || auditRequest.requestReview === "comment"}
                                            >
                                                Upload Excel
                                                <input
                                                    type="file"
                                                    accept=".xlsx"
                                                    hidden
                                                    onChange={(e) =>
                                                        e.target.files?.[0] &&
                                                        handleFileUpload(e.target.files[0], "excel")
                                                    }
                                                />
                                            </Button>

                                            {uploadProgress[0] !== undefined && (
                                                <>
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={uploadProgress[0]}
                                                        sx={{ flex: 1 }}
                                                    />
                                                    <IconButton onClick={() => handleDeleteUpload("excel")}>
                                                        <Delete />
                                                    </IconButton>
                                                </>
                                            )}
                                        </div>
                                        {uploadedUrls.excel && (
                                            <Typography variant="body2">{uploadedUrls.excel}</Typography>
                                        )}
                                        <div style={{ display: "flex", alignItems: "center", gap: "1rem", marginTop: "1rem" }}>
                                            <Button
                                                variant="contained"
                                                startIcon={<UploadFile />}
                                                component="label"
                                                disabled={!!pptxFile || auditRequest.requestReview === "comment"}
                                            >
                                                Upload PPT
                                                <input
                                                    type="file"
                                                    accept=".pptx"
                                                    hidden
                                                    onChange={(e) =>
                                                        e.target.files?.[0] &&
                                                        handleFileUpload(e.target.files[0], "ppt")
                                                    }
                                                />
                                            </Button>
                                            {uploadProgress[1] !== undefined && (
                                                <>
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={uploadProgress[1]}
                                                        sx={{ flex: 1 }}
                                                    />
                                                    <IconButton onClick={() => handleDeleteUpload("ppt")}>
                                                        <Delete />
                                                    </IconButton>
                                                </>
                                            )}
                                        </div>
                                        {uploadedUrls.ppt && (
                                            <Typography variant="body2">{uploadedUrls.ppt}</Typography>
                                        )}
                                    </div>
                                )}
                                {(activeTab === "comment" || activeTab === "reject") && (
                                    <div>
                                        <Typography variant="h6" gutterBottom>
                                            {activeTab === "comment" ? "Add Comment" : "Reject Request"}
                                        </Typography>
                                        <TextField
                                            label={activeTab === "comment" ? "Comment" : "Rejection Reason"}
                                            multiline
                                            fullWidth
                                            rows={4}
                                            sx={{ mb: 2, mt: 2 }}
                                            value={auditRequest.requestReview === "comment" && activeTab === "comment" ? auditRequest.reviewComment : comment}
                                            onChange={(e) => setComment(e.target.value)}
                                            disabled={auditRequest.requestReview === "comment"}
                                        />
                                    </div>
                                )}
                                <Button
                                    variant="contained"
                                    color={activeTab === "reject" ? "error" : activeTab === "approve" ? "primary" : "warning"}
                                    fullWidth
                                    sx={{ mt: 3 }}
                                    onClick={handleSubmit}
                                    disabled={
                                        (activeTab === "approve" && (!dueDate || !pptxFile)) ||
                                        ((activeTab === "comment" || activeTab === "reject") && !comment) ||
                                        (!!auditRequest.reviewComment) ||
                                        (!!hasSubmit)
                                    }
                                >
                                    {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)} Request
                                </Button>
                            </CardContent>
                        </Card>
                        )}
                </Box>
            </Box>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AuditRequestReview;