import { PaymentsApiBaseUrl } from "./baseUrls";
import { fetchWrapper } from "@gogig-in/gogig-clients";

export async function uploadWithdrawRequestData(file: FormData) {
  const url = `${PaymentsApiBaseUrl}/uploadWithdrawalsData`;

  return fetchWrapper(url, {
    method: "POST",
    body: file,
  });
}
