export const taskQuestionnaireArray = {
    "branding": {
      "Q1": {
        "type": "text",
        "answer": [],
        "question": "Enter Vehicle Number",
        "isMandatory": 1
      },
      "Q2": {
        "type": "text",
        "answer": [],
        "question": "Enter Name",
        "isMandatory": 1
      },
      "Q3": {
        "type": "number",
        "answer": [],
        "question": "Enter Phone Number",
        "isMandatory": 1
      },
      "Q4": {
        "type": "text",
        "answer": [],
        "question": "Comments",
        "isMandatory": 0
      },
      "Q5": {
        "type": "image",
        "answer": [],
        "question": "Upload back Image",
        "isMandatory": 1
      },
      "Q6": {
        "type": "image",
        "answer": [],
        "question": "Upload left Image",
        "isMandatory": 1
      },
      "Q7": {
        "type": "image",
        "answer": [],
        "question": "Upload right Image",
        "isMandatory": 1
      }
    },
    "survey": {
      "Q1": {
        "type": "text",
        "answer": [],
        "question": "Enter the Name of the society",
        "isMandatory": 1
      },
      "Q2": {
        "type": "address",
        "question": "Enter the Address of the society",
        "isMandatory": 1
      },
      "Q3": {
        "type": "person",
        "answer": [
          "Name",
          "Phone Number",
          "Email",
          "Designation"
        ],
        "question": "Contact Person",
        "isMandatory": 1
      },
      "Q4": {
        "type": "text",
        "answer": [],
        "question": "Tentative Prize range",
        "isMandatory": 1
      },
      "Q5": {
        "type": "number",
        "answer": [],
        "question": "Number of apartments in that society",
        "isMandatory": 1
      },
      "Q6": {
        "type": "image",
        "answer": [],
        "question": "Upload the selfie and that should contain society in the background",
        "isMandatory": 1
      },
      "Q7": {
        "type": "text",
        "answer": [],
        "question": "Comments",
        "isMandatory": 0
      }
    },
    "telecalling": {
    }
  }
  