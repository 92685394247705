import {
  Container,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BasicReport from "./BasicReport";
import BasicReportPage2 from "./BasicReportPage2";
import generatePDF, { Margin, Options } from "react-to-pdf";
import { getAuditReportById } from "../../../clients/getReportById";
import { AuditReportProps } from "./reportComponentProps";
import { useParams } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import { useAuthContext } from "../../../providers/AuthProvider";
import { useSnackbar } from "../../snackbarComponent/useSnackbar";
import GlobalSnackbar from "../../snackbarComponent/Snackbar";
const options: Options = {
  filename: "Basic-Report.pdf",
  method: "open",
  page: {
    margin: Margin.NONE,
    format: "letter",
    orientation: "portrait",
  },
};

const AuditReport = () => {
  const targetRef = React.useRef();
  const {authHeader}=useAuthContext();
  const [boxSize, setBoxSize] = useState({ width: "100%", height: "auto" });
  const [reportData, setReportData] = useState<AuditReportProps>();
  const [loading, setLoading] = useState(false);
  const { reportId } = useParams<{ reportId?: string }>();
  const { snackbar, openSnackbar, closeSnackbar } = useSnackbar();
  

  // Fetch report data by report ID
  useEffect(() => {
    const handleGetData = async () => {
      try {
        if (!reportId || !authHeader) return;
        const response = await getAuditReportById(authHeader,reportId);
        const data = (response.data as { reportData: AuditReportProps })
          .reportData;
        const errorMessage = (response.data as { message?: string }).message;
        if (errorMessage) {
          openSnackbar(
            errorMessage,
            "error"
          );
         
        } else {
          setReportData(data);
         
        }
      } catch (error) {
        openSnackbar("Failed to get audit report","error")
        console.error(error, "Failed to get audit report");
      }
    };
    handleGetData();
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId,authHeader]);

  // Handle PDF download with better UX
  const handleDownload = async () => {
    setLoading(true); // Show loading state while PDF is generated
    setBoxSize({ width: "1000px", height: "auto" }); // Scale the box for PDF generation

    await new Promise((resolve) => setTimeout(resolve, 100)); // Optional delay for rendering

    // Generate PDF
    generatePDF(targetRef, options);

    // Reset the box size and stop loading
    setBoxSize({ width: "100%", height: "auto" });
    setLoading(false);
  };

  return (
    <Container sx={{ py: 4, px: 3 }}>
      <GlobalSnackbar
        open={snackbar.open}
        message={snackbar.message}
        onClose={closeSnackbar}
        severity={snackbar.severity}
        vertical={"top"}
        horizontal={"center"}
      />
      {/* Header Section */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <Typography variant="h4" component="h1" fontWeight="bold">
          Audit Report
        </Typography>

        <Typography>
          <strong>Report ID:</strong> {reportId}
        </Typography>

        {reportData && (
          <Typography variant="h5">
            <strong>Client Name:</strong> {reportData?.clientName || "N/A"}
          </Typography>
        )}

        {/* Download Button with loading indicator */}
        <Button
          onClick={handleDownload}
          variant="contained"
          color="primary"
          startIcon={
            loading ? <CircularProgress size={20} /> : <DownloadIcon />
          }
          disabled={loading}
        >
          {loading ? "Generating PDF..." : "Download PDF"}
        </Button>
      </Box>

      {/* Box component wrapped with ref and dynamically styled */}
      <Box
        ref={targetRef}
        sx={{
          width: boxSize.width,
          height: boxSize.height,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {reportData && <BasicReport auditData={reportData} />}
        <Box sx={{ marginTop: 14 }}>
          <BasicReportPage2 />
        </Box>
      </Box>
    </Container>
  );
};

export default AuditReport;
