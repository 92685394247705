// UploadDialog.tsx
import React, { ChangeEvent } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Box,
    LinearProgress
} from '@mui/material';

interface ReportUploadDialogProps {
    open: boolean;
    onClose: () => void;
    onUpload: (file: File) => void;
    uploadProgress: number | null;
    selectedFile: File | null;
    onFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
    viewReport:boolean ;
    progressBarColor:"success" | "error" ;
}

const ReportUploadDialog: React.FC<ReportUploadDialogProps> = ({
    open,
    onClose,
    onUpload,
    uploadProgress,
    selectedFile,
    onFileChange,
    viewReport,
    progressBarColor,
}) => {
    const handleUpload = () => {
        if (selectedFile) {
            onUpload(selectedFile);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Upload Report</DialogTitle>
            <DialogContent>
                <input
                    accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
                    style={{ display: 'none' }}
                    id="upload-file-input"
                    type="file"
                    onChange={onFileChange}
                />
                <label htmlFor="upload-file-input">
                    <Button variant="contained" color="primary" component="span" disabled={!!selectedFile} >
                        Choose File
                    </Button>
                </label>
                {selectedFile && (
                    <Box mt={2}>
                        <Typography variant="subtitle1">
                            Selected file: {selectedFile.name}
                        </Typography>
                    </Box>
                )}
                {uploadProgress !== null && (
                    <Box mt={2}>
                        <LinearProgress variant="determinate" value={uploadProgress} color={progressBarColor} />
                        <Typography variant="body2" color={progressBarColor}>
                            {uploadProgress}% completed
                        </Typography>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={handleUpload}
                    color="primary"
                    disabled={!selectedFile || viewReport===true}
                >
                    Upload
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ReportUploadDialog;
