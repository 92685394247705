import React from "react";
import { Container, Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../providers/AuthProvider";
import HomeSection from "./HomeSection";
import { getSectionsByRole } from "./DashboardSection";
import { LocationMapper } from "../../hook/Location";

const HomePage = () => {
  LocationMapper();
  const navigate = useNavigate();
  const { isAuthenticated, userDetails } = useAuthContext();

  // Get the sections based on user role
  const sections = getSectionsByRole(userDetails?.role, navigate);

  return (
    <Container sx={{ marginTop: "30px" }}>
      {isAuthenticated ? (
        sections.length > 0 ? (
          <Grid container spacing={2}>
            {sections.map((section, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <HomeSection title={section.title} actions={section.actions} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Container sx={{ marginTop: "30px" }}>
            <Typography variant="h3" sx={{ textAlign: "center" }}>
              Unauthorized access
            </Typography>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              You are not authorized to access this page
            </Typography>
          </Container>
        )
      ) : (
        <Container sx={{ marginTop: "30px" }}>
          <Typography variant="h3" sx={{ textAlign: "center" }}>
            Welcome to Admin Dashboard
          </Typography>
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            Please login to access the dashboard
          </Typography>
        </Container>
      )}
    </Container>
  );
};

export default HomePage;
