import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SpotrueApiStorageBaseUrl } from "./baseUrls";

export interface UploadedPart {
  ETag: string | null;
  PartNumber: number;
}

export default function completeFileUpload(
  fileId: string,
  uploadId: string,
  parts: UploadedPart[]
) {
  return fetchWrapper(`${SpotrueApiStorageBaseUrl}/completeFileUpload?clientId=gogig`, {
    method: "POST",
    body: {
      fileId: fileId,
      uploadId: uploadId,
      parts: parts,
    },
  });
}
