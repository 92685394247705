import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SpotrueApiBaseUrl } from "./baseUrls";

export default function getProcessedVehicleData(
  authHeader: string,
  gogigReqId: string,
  requesterId: string
) {
  return fetchWrapper(
    `${SpotrueApiBaseUrl}/getProcessedVehicleData?gogigReqId=${gogigReqId}&requesterId=${requesterId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
    }
  );
}
