import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SpotrueApiStorageBaseUrl } from "./baseUrls";

export default function getFileData(
    authHeader: string,
    fileId: string
) {
    return fetchWrapper(`${SpotrueApiStorageBaseUrl}/getFileData?fileId=${fileId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: authHeader,
        }     
    });
}
