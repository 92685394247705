import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "./styles/globals.css";
import AdminPage from "./components/adminPage/AdminPage";
import Queries from "./components/adminPage/AdminGetQuery";
import PaymentsToApproveTable from "./components/adminPage/AdminSendForPaymentApproval";
import GigResult from "./components/taskResult/GigResult";
import UserEntry from "./components/userEntryFlow/UserEntrance";
import UserEntryForm from "./components/userEntryFlow/UserEntryForms";
import VerifyEmail from "./components/VerifyEmail";
import GoToEmailInbox from "./components/GoToEmailInbox";
import ProfileCreatedPage from "./components/ProfileCreatedPage";
import SignUpPage from "./components/SignUpPage";
import LoginPage from "./components/LoginPage";
import DefaultContainer from "./DefaultContainer";
import ForgotPasswordPage from "./components/ForgotPasswordPage";
import PdfTestComponent from "./components/PdfTestComponent";
import TaskReview from "./components/taskResult/TaskReview";
import AdminDashboard from "./components/RWADashboard/AdminDashboard";
import CsvFilePreview from "./components/RWADashboard/uploadCSVpreview";
import ReviewFormat from "./components/RWADashboard/reviewFormat";
import GigListingOps from "./components/operationRolePages/GigListingOps";
import ManageGig from "./components/operationRolePages/ManageGig";
import BasicGigDetails from "./components/createGig/BasicGigDetails";
import PreApproveQuestionnaire from "./components/createGig/PreApproveQuestionnaire";
import TaskQuestionnaire from "./components/createGig/TaskQuestionnaire";
import ReviewPage from "./components/createGig/ReviewPage";
import SignUp from "./components/onBoardingv2/Signup";
import Register from "./components/onBoardingv2/Register";
import UserData from "./components/profile/UserData";
import VerifyWhatsApp from "./components/onBoardingv2/VerifyWhatsApp";
import GigWorkersByGig from "./components/taskResult/GigWorkersByGig";
import UserReport from "./components/taskResult/UserReport";
import CreateCategory from "./components/operationRolePages/CreateCategory";
import CategoryListing from "./components/operationRolePages/CategoryListing";
import ManageUsers from "./components/manageUsers/ManageUsers";
import ManageSubordinates from "./components/manageUsers/ManageSubordinates";
import ManageSupervisors from "./components/manageUsers/ManageSupervisors";
import ManagerGigUsers from "./components/manageUsers/ManageUsersOnAGig";
import WithdrawRequestList from "./components/paymentPage/WithdrawRequestList";
import UploadPaymentSheet from "./components/paymentPage/uploadPaymentSheet";
import AuditRequestDashboard from "./components/vendors/AuditRequestDashboard";
import HomePage from "./components/homeComponent/Home";
import PreProcessingData from "./components/spoTrue/PreProcessingData";
import { AuditResultDashboard } from "./components/vendors/auditResultComponents/AuditResultDashboard";
import CreateAuditDialog from "./components/vendors/vehicleAuditComponents/AuditCreationDialog";
import CreateExcelRequestPage from "./components/vendors/vehicleAuditComponents/CreateExcelRequestPage";
import AuditReport from "./components/vendors/report/AuditReport";
import AuditRequestDialog from "./components/vendors/vehicleAdmin/AuditRequestDialog";
import UnknownVehicleDataUI from "./components/vendors/auditResultComponents/components/UnknownVehicleDataUI";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/">
          <Route element={<DefaultContainer />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/profile" element={<UserData />} />
            <Route path="/home" element={<HomePage />} />
            <Route
              path="/gig-result/:gigId/admin-dashboard"
              element={<AdminDashboard />}
            />
            <Route
              path="/gig-result/:gigId/upload-csv"
              element={<CsvFilePreview />}
            />
            <Route
              path="/gig-result/:gigId/view-format"
              element={<ReviewFormat />}
            />
            <Route
              path="/gig-result-pdf/:gigId"
              element={<PdfTestComponent />}
            />
            <Route path="/task-review/:gigId" element={<TaskReview />} />
            <Route path="/manage-gigs" element={<GigListingOps />} />
            <Route path="/manage-gigs/:gigId" element={<ManageGig />} />
            <Route path="/create-category" element={<CreateCategory />} />
            <Route
              path="/update-category/:categoryId"
              element={<CreateCategory />}
            />
            <Route path="/category-list" element={<CategoryListing />} />
            <Route
              path="/create-gig/basic-details"
              element={<BasicGigDetails />}
            />
            <Route
              path="/create-gig/pre-acceptance-form"
              element={<PreApproveQuestionnaire />}
            />
            <Route
              path="/create-gig/task-form"
              element={<TaskQuestionnaire />}
            />
            <Route path="/review/:gigId" element={<ReviewPage />} />
            <Route
              path="/admin/review-withdrawal"
              element={<WithdrawRequestList />}
            />
            <Route
              path="/admin/payment-to-upload"
              element={<UploadPaymentSheet />}
            />
            <Route
              path="/admin/approve-send-for-payment"
              element={<PaymentsToApproveTable />}
            />
            <Route
              path="/admin/review-gig-applications"
              element={<AdminPage />}
            />
            <Route path="/admin/queries" element={<Queries />} />
            <Route path="/gig-result/:gigId" element={<GigResult />} />
            <Route
              path="/gig-result-grouping/:gigId"
              element={<GigWorkersByGig />}
            />
            <Route
              path="/user-report/:gigId/:userId"
              element={<UserReport />}
            />
            <Route path="/manage-users" element={<ManageUsers />} />
            <Route
              path="/manage-users-gig/:gigId"
              element={<ManagerGigUsers />}
            />
            <Route
              path="/manage-subordinates"
              element={<ManageSubordinates />}
            />
            <Route
              path="/manage-subordinates/:gigId"
              element={<ManageSubordinates />}
            />
            <Route
              path="/manage-subordinates/:gigId/:supervisorId"
              element={<ManageSubordinates />}
            />
            <Route
              path="/manage-subordinate/:supervisorId"
              element={<ManageSubordinates />}
            />
            <Route
              path="/manage-supervisors/:gigId"
              element={<ManageSupervisors />}
            />
            <Route path="/manage-supervisors" element={<ManageSupervisors />} />
            <Route
              path="/audit-excel-upload"
              element={<CreateExcelRequestPage />}
            />
            <Route
              path="/manage-audit-list"
              element={<AuditRequestDashboard />}
            />
            <Route
              path="/audit-request/:gogigRequestId"
              element={<AuditRequestDialog />}
            />
            <Route
              path="/upload-createRequest"
              element={<CreateAuditDialog />}
            />
            <Route
              path="/pre-process/:gogigRequestId"
              element={<PreProcessingData />}
            />
            <Route
              path="/audit-process/:gogigReqId"
              element={<AuditResultDashboard />}
            />
            <Route
              path="/audit-process/unKnownVehicleData/:gogigReqId"
              element={<UnknownVehicleDataUI />}
            />

            {/* :gogigRequestId/:requesterId/:vehicleId */}
            <Route path="/audit-report/:reportId" element={<AuditReport />} />
          </Route>
          <Route path="/user-entry" element={<UserEntry />} />
          <Route
            path="/user-entry/user-entry-form/:stepName"
            element={<UserEntryForm />}
          />
          <Route path="/user-entry/sign-up" element={<SignUpPage />} />

          <Route path="/user-entry/login" element={<LoginPage />} />
          <Route
            path="/user-entry/go-to-email-inbox"
            element={<GoToEmailInbox />}
          />
          <Route path="/user-entry/signup" element={<SignUp />} />

          <Route
            path="/user-entry/verifyWhatsappLogin/token/:token"
            element={<VerifyWhatsApp />}
          />
          <Route
            path="/user-entry/verifyWhatsappLogin/token/:token"
            element={<VerifyWhatsApp />}
          />
          <Route path="/user-entry/register" element={<Register />} />
          <Route
            path="/user-entry/profile-created-page"
            element={<ProfileCreatedPage />}
          />
          <Route
            path="/user-entry/signup/verify-email/token/:token"
            element={<VerifyEmail />}
          />

          <Route
            path="/user-entry/forgot-password"
            element={<ForgotPasswordPage />}
          />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
