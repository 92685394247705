import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Link,
  Modal,
  useMediaQuery,
  useTheme,
  IconButton,
  TextareaAutosize,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CustomButton from "../ButtonWrapper";
import Strings from "../../Locale/strings.json";
import LoadingSpinner from "../LoadingAnimation";
import { useAuthContext } from "../../providers/AuthProvider";
import {
  getAcceptanceDetailsByAcceptanceId,
  getUnVerifiedAcceptances,
  reviewAcceptance,
} from "@gogig-in/gogig-clients";

interface UnverfiedAcceptanceItem {
  gigTitle: string;
  acceptanceId: string;
  gigId: string;
  gigStartDate: string;
  firstName: string;
  emailId: string;
  lastName: string;
  phoneNumber: string;
}

interface Review {
  status: "approve" | "reject";
  rejectCause?: string;
}

const AdminPage: React.FC = () => {
  const [acceptanceData, setAcceptanceData] = useState<
    UnverfiedAcceptanceItem[]
  >([]);
  const [selectedAcceptanceForm, setSelectedAcceptanceForm] = useState<
    any | null
  >(null);
  const [selectedAcceptanceId, setSelectedAcceptanceId] = useState<
    string | null
  >(null);
  const [isRejectMode, setIsRejectMode] = useState(false);
  const [rejectionMessage, setRejectionMessage] = useState("");
  const [isRejectionSuccessful, setIsRejectionSuccessful] = useState(false);
  const [isApprovalSuccessful, setIsApprovalSuccessful] = useState(false);
  const { authHeader } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const isTabletOrSmaller = useMediaQuery(theme.breakpoints.down(900));
  const [isApproveMode, setIsApproveMode] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (authHeader) {
          const unverifiedAcceptance: any = await getUnVerifiedAcceptances(
            authHeader
          );
          setAcceptanceData(unverifiedAcceptance);
          setIsLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
    };

    fetchData();
  }, [authHeader]);

  useEffect(() => {
    const logSelectedAcceptanceForm = async () => {
      await new Promise((resolve) => setTimeout(resolve, 100));
    };

    logSelectedAcceptanceForm();
  }, [selectedAcceptanceForm]);

  document.title = Strings.PageTitles.GigHistoryPage;

  const handleViewAcceptanceClick = async (id: string) => {
    try {
      if (authHeader) {
        const acceptanceData: any = await getAcceptanceDetailsByAcceptanceId(
          id
        );
        const acceptanceForm = Object.values(
          JSON.parse(acceptanceData.data[0].acceptanceForm)
        );
        setSelectedAcceptanceForm(acceptanceForm);
        setSelectedAcceptanceId(acceptanceData.data[0].acceptanceId);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleCloseModal = () => {
    setSelectedAcceptanceForm(null);
    setSelectedAcceptanceId(null);
    setIsRejectMode(false);
    setRejectionMessage("");
    setIsRejectionSuccessful(false);
    setIsApprovalSuccessful(false);
    setIsApproveMode(false);
  };

  const handleGigApprovalClick = async (selectedAcceptanceId: any) => {
    try {
      if (authHeader) {
        const newReview: Review = {
          status: "approve",
        };
        const verifyAcceptanceFromAdmin: any = await reviewAcceptance(
          selectedAcceptanceId,
          authHeader,
          newReview
        );
        console.log(verifyAcceptanceFromAdmin);
        const unverifiedAcceptance: any = await getUnVerifiedAcceptances(
          authHeader
        );
        setAcceptanceData(unverifiedAcceptance);
        setIsLoading(false);
      }
      setIsApprovalSuccessful(true);
      setIsApproveMode(true);
    } catch (e) {
      console.error(e);
      setIsApprovalSuccessful(false);
      setIsApproveMode(true);
    }
  };

  const handleSubmitReject = async (
    id: string | null,
    rejectionCause: string
  ) => {
    try {
      if (authHeader && id) {
        const newReview: Review = {
          status: "reject",
          rejectCause: rejectionCause,
        };
        await reviewAcceptance(id, authHeader, newReview);
      }
      setIsRejectionSuccessful(true);
      if (authHeader) {
        const unverifiedAcceptance: any = await getUnVerifiedAcceptances(
          authHeader
        );
        setAcceptanceData(unverifiedAcceptance);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error submitting rejection cause:", error);
    }
  };

  const handleGigRejectClick = () => {
    setIsRejectMode(true);
  };

  const handleCloseRejectMode = () => {
    setIsRejectMode(false);
  };

  return (
    <Container>
      <Typography variant="h4" align="center" sx={{ marginBottom: 2 }}>
        Gig Application approval page
      </Typography>
      <Box sx={{ marginTop: 3, marginBottom: 2, maxWidth: "100%" }}>
        {isLoading ? (
          <LoadingSpinner label="Please wait..." />
        ) : (
          <div>
            <Typography variant="h5">Applications for Gigs :</Typography>
            {isTabletOrSmaller ? (
              acceptanceData.map((acceptance) => (
                <Card key={acceptance.acceptanceId} sx={{ marginBottom: 2 }}>
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: 1,
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ textAlign: "left", fontWeight: "bold" }}
                      >
                        Gig Title:
                      </Typography>
                      <Typography variant="body1" sx={{ textAlign: "right" }}>
                        {acceptance.gigTitle}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: 1,
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ textAlign: "left", fontWeight: "bold" }}
                      >
                        Gig Start Date:
                      </Typography>
                      <Typography variant="body1" sx={{ textAlign: "right" }}>
                        {acceptance.gigStartDate.split("T")[0]}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: 1,
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ textAlign: "left", fontWeight: "bold" }}
                      >
                        Seeker Name:
                      </Typography>
                      <Typography variant="body1" sx={{ textAlign: "right" }}>
                        {`${acceptance.firstName} ${acceptance.lastName}`}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: 1,
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ textAlign: "left", fontWeight: "bold" }}
                      >
                        Seeker Email:
                      </Typography>
                      <Typography variant="body1" sx={{ textAlign: "right" }}>
                        {acceptance.emailId}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: 1,
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ textAlign: "left", fontWeight: "bold" }}
                      >
                        Seeker Phone:
                      </Typography>
                      <Typography variant="body1" sx={{ textAlign: "right" }}>
                        {acceptance.phoneNumber}
                      </Typography>
                    </Box>
                    <CustomButton
                      tag="Admin/GigApprove"
                      size="small"
                      onClick={() =>
                        handleViewAcceptanceClick(acceptance.acceptanceId)
                      }
                      style={{
                        textTransform: "none",
                        color: "primary",
                        float: "right", // This will make the button originate from the right
                        width: "25%",
                        marginBottom: "5%",
                      }}
                      variant="contained"
                    >
                      View
                    </CustomButton>
                  </CardContent>
                </Card>
              ))
            ) : (
              <Table
                sx={{ minWidth: 600, overflowX: "auto" }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow style={{ backgroundColor: "#f2f2f2" }}>
                    <TableCell
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "left",
                        width: "50%",
                      }}
                    >
                      gig title
                    </TableCell>
                    <TableCell
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "left",
                        width: "50%",
                      }}
                    >
                      gigStartDate
                    </TableCell>
                    <TableCell
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "left",
                        width: "50%",
                      }}
                    >
                      seeker name
                    </TableCell>
                    <TableCell
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "left",
                        width: "50%",
                      }}
                    >
                      seeker email
                    </TableCell>
                    <TableCell
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "left",
                        width: "50%",
                      }}
                    >
                      seeker phone
                    </TableCell>
                    <TableCell
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "left",
                        width: "50%",
                      }}
                    >
                      action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {acceptanceData.map((acceptance) => (
                    <TableRow
                      key={acceptance.acceptanceId}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Link
                          href={"/gig-details/" + acceptance.gigId}
                          color="inherit"
                        >
                          {acceptance.gigTitle}
                        </Link>
                      </TableCell>
                      <TableCell align="left">{`${
                        acceptance.gigStartDate.split("T")[0]
                      }`}</TableCell>
                      <TableCell align="left">{`${acceptance.firstName} ${acceptance.lastName}`}</TableCell>
                      <TableCell align="left">{acceptance.emailId}</TableCell>
                      <TableCell align="left">
                        {acceptance.phoneNumber}
                      </TableCell>
                      <TableCell align="left">
                        <CustomButton
                          tag="Admin/GigApprove"
                          size="small"
                          onClick={() =>
                            handleViewAcceptanceClick(acceptance.acceptanceId)
                          }
                          style={{ textTransform: "none", color: "primary" }}
                          variant="contained"
                        >
                          View
                        </CustomButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            <Modal
              open={selectedAcceptanceForm !== null}
              onClose={handleCloseModal}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "85%", // Adjust the width as needed for mobile and tablet views
                  maxWidth: 600, // Set a maximum width if necessary
                  height: "auto",
                  bgcolor: "background.paper",
                  p: 2,
                }}
              >
                <Typography variant="h6">
                  {isRejectMode ? "Rejection Cause" : "Application Form"}
                </Typography>
                <IconButton
                  edge="end"
                  color="error"
                  onClick={
                    isRejectMode ? handleCloseRejectMode : handleCloseModal
                  }
                  sx={{
                    position: "absolute",
                    top: "-2px",
                    right: "8px",
                    zIndex: 1,
                  }}
                >
                  <CloseIcon />
                </IconButton>
                {isRejectMode && !isRejectionSuccessful && (
                  <div>
                    <TextareaAutosize
                      aria-label="reject-reason"
                      placeholder="Enter rejection reason..."
                      minRows={3}
                      style={{
                        width: "100%",
                        marginTop: "8px",
                        marginBottom: "8px",
                      }}
                      value={rejectionMessage}
                      onChange={(e) => setRejectionMessage(e.target.value)}
                    />
                    <Box
                      sx={{ display: "flex", justifyContent: "center", mt: 2 }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          handleSubmitReject(
                            selectedAcceptanceId,
                            rejectionMessage
                          )
                        }
                        sx={{ width: "100px" }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </div>
                )}
                {isRejectionSuccessful && isRejectMode && (
                  <div>
                    <Typography variant="body1" sx={{ marginBottom: "16px" }}>
                      Review submitted successfully!
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleCloseModal();
                        }}
                        sx={{ width: "100px" }}
                      >
                        Close
                      </Button>
                    </Box>
                  </div>
                )}
                {isApproveMode && isApprovalSuccessful && (
                  <div>
                    <Typography variant="body1" sx={{ marginBottom: "16px" }}>
                      Application approved successfully!
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleCloseModal();
                        }}
                        sx={{ width: "100px" }}
                      >
                        Close
                      </Button>
                    </Box>
                  </div>
                )}
                {isApproveMode && !isApprovalSuccessful && (
                  <div>
                    <Typography>
                      Something went wrong please try again later ...
                    </Typography>
                  </div>
                )}
                {!isRejectMode && !isApproveMode && (
                  <div>
                    <Table>
                      <TableHead>
                        <TableRow style={{ backgroundColor: "#f2f2f2" }}>
                          <TableCell
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "left",
                              width: "50%",
                            }}
                          >
                            Question
                          </TableCell>
                          <TableCell
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "left",
                              width: "50%",
                            }}
                          >
                            Answer
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedAcceptanceForm &&
                          Object.entries(selectedAcceptanceForm).map(
                            ([key, value]: [string, any]) => (
                              <TableRow key={key}>
                                <TableCell
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "left",
                                  }}
                                >
                                  {value.question}
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "left",
                                  }}
                                >
                                  {value.answer}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                      </TableBody>
                    </Table>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        mt: 2,
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          handleGigApprovalClick(selectedAcceptanceId)
                        }
                        sx={{
                          width: { xs: "48%", sm: "100%" },
                          maxWidth: "120px",
                          marginRight: { xs: "2%", sm: 0 },
                        }}
                      >
                        Approve
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleGigRejectClick}
                        sx={{
                          width: { xs: "48%", sm: "100%" },
                          maxWidth: "120px",
                          backgroundColor: "#CC0000",
                          "&:hover": {
                            backgroundColor: "#A10000", // Dark red on hover
                          },
                        }}
                      >
                        Reject
                      </Button>
                    </Box>
                  </div>
                )}
              </Box>
            </Modal>
          </div>
        )}
      </Box>
    </Container>
  );
};

export default AdminPage;
