import React, { ChangeEvent } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";

interface FileUploadSectionProps {
  checked: boolean;
  sheetFile: File | null;
  pptxFile: File | null;
  loading: boolean;
  handleExcelSheetChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function FileUploadSection({
  checked,
  sheetFile,
  pptxFile,
  loading,
  handleExcelSheetChange,
  handleFileChange,
  handleCheckboxChange,
}: FileUploadSectionProps) {
  return (
    <Box mt={2}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleCheckboxChange}
            name="no file required"
          />
        }
        label="No file Required"
      />

      {!checked && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 3,
            mb: 5,
          }}
        >
          <FileButton
            label="Upload Excel sheet"
            file={sheetFile}
            accept=".csv, .xlsx"
            onChange={handleExcelSheetChange}
            loading={loading}
          />
          <FileButton
            label="Upload PPTX file"
            file={pptxFile}
            accept=".pptx"
            onChange={handleFileChange}
            loading={loading}
          />
        </Box>
      )}
    </Box>
  );
}

interface FileButtonProps {
  label: string;
  file: File | null;
  accept: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
}

const FileButton = ({
  label,
  file,
  accept,
  onChange,
  loading,
}: FileButtonProps) => (
  <Button
    component="label"
    fullWidth
    variant="outlined"
    disabled={loading}
    sx={{
      textTransform: "none",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "8px 16px",
      flex: 1,
    }}
  >
    {file ? (
      file.name
    ) : (
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          fontWeight: "700",
          justifyContent: "center",
        }}
      >
        <UploadFileIcon sx={{ fontSize: 28, marginRight: 1 }} />
        {label}
      </Typography>
    )}
    <input type="file" accept={accept} onChange={onChange} hidden />
  </Button>
);
