import React from 'react';
import { Grid, Box } from '@mui/material';
import UploadAndCreateRequest from './UploadAndCreateRequest';

const CreateAuditRequestGrid = () => {

  return (
    <Grid container spacing={2} sx={{ padding: 3 }}>
      <Grid item xs={12}>
        <Box sx={{ maxHeight: "100px" }}>
          <UploadAndCreateRequest />
        </Box>
      </Grid>
    </Grid>
  );
};

export default CreateAuditRequestGrid;
