import React, { useEffect, useState } from "react";
import AuditResultTableContainer from "./components/AuditResultTableContainer";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Typography,
  Paper,
  Grid,
} from "@mui/material";
import getProcessedVehicleData from "../../../clients/getProcessedVehicleData";
import { useAuthContext } from "../../../providers/AuthProvider";
import * as XLSX from "xlsx";
import { useNavigate, useParams } from "react-router-dom";
import { AuditRequest, VehicleAuditDetails } from "../vehicleAuditComponents/types";
import { getAuditRequestById } from "../../../clients/getAuditRequestById";

export const AuditResultDashboard = () => {
  const { gogigReqId } = useParams<{
    gogigReqId?: string;
  }>();
  const navigate = useNavigate();
  const [vehicleData, setVehicleData] = useState<VehicleAuditDetails[]>([]);
  const [auditData,setAuditData]=useState<AuditRequest>()
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { authHeader } = useAuthContext();

  useEffect(() => {
    const fetchData = async () => {
      if (!authHeader || !gogigReqId ) return;

      try {
        const res= await getAuditRequestById(authHeader,gogigReqId);
        const fetchedData = res as AuditRequest;
        setAuditData(fetchedData);
        if(!fetchedData.requesterId) return;
        const response = await getProcessedVehicleData(
          authHeader,
          gogigReqId,
          encodeURIComponent(fetchedData.requesterId)
        );
        const data = response.data as VehicleAuditDetails[];
        setVehicleData(data);
      } catch (error) {
        setError("Failed to fetch vehicle data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [authHeader, gogigReqId]);
  const handleGenerateReport = (reportId: string) => {
    navigate(`/audit-report/${reportId}`);
  };

  const handleDownloadExcel = () => {
    const tableHeaders =
      vehicleData.length > 0 ? Object.keys(vehicleData[0]) : [];
    const ws = XLSX.utils.json_to_sheet(vehicleData, { header: tableHeaders });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "VehicleAudit");
    XLSX.writeFile(wb, "VehicleAudit.xlsx");
  };

  const handleUnknownVehicleData=() => {
   navigate(`/audit-process/unknownVehicleData/${gogigReqId}`)
  }

  return (
    <Grid container sx={{ width: "100vw" }}>
      <Grid item sx={{ width: "100%" }}>
        <Typography variant="h4" gutterBottom align="center">
          Vehicle Audit
        </Typography>

        {loading && (
          <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
            <CircularProgress />
          </Box>
        )}

        {error && (
          <Box sx={{ mb: 4 }}>
            <Alert severity="error" variant="filled">
              {error}
            </Alert>
          </Box>
        )}

        {!loading && !error && (
          <Box
            sx={{
              mb: 4,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mx: 4,
            }}
          >
            <Paper
              elevation={5}
              sx={{
                p: 2,
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                textAlign: "center",
              }}
            >
               <Typography variant="subtitle1" color="textSecondary">
               Title:<strong>{auditData?.requestTitle}</strong>              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                gOGig Request ID: <strong>{gogigReqId}</strong>
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                Requester ID: <strong>{auditData?.requesterId}</strong>
              </Typography>
              {vehicleData.length !== 0 && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDownloadExcel}
                    sx={{ ml: 2 }}
                  >
                    Download Excel
                  </Button>
                 { gogigReqId &&
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleGenerateReport(gogigReqId)}
                    sx={{ ml: 2 }}
                  >
                    Generate Report
                  </Button>
                  }
                 
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleUnknownVehicleData()}
                    sx={{ ml: 2 }}
                  >
                    Get Unknown Vehicle Data
                  </Button>
                </>
              )}
            </Paper>
          </Box>
        )}
      </Grid>
      <Grid item sx={{ width: "100%" }}>
        <Typography sx={{ mx: 2 }}>
          <strong>Search Results:</strong> {vehicleData.length} results
        </Typography>
        {!loading && <AuditResultTableContainer vehicleData={vehicleData} />}
      </Grid>
    </Grid>
  );
};
