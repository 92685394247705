import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SpotrueApiBaseUrl } from "./baseUrls";
import { AuditRequest } from "../components/vendors/vehicleAuditComponents/types";

export async function reviewAuditRequest(
    review: 'approve' | 'comment' | 'reject',
    authHeader: string,
    gogigRequestId: string,
    body: AuditRequest
) {    
    await fetchWrapper(
        `${SpotrueApiBaseUrl}/reviewAuditRequest?gogigReqId=${gogigRequestId}&review=${review}`,
        {
            method: "POST",
            headers: {
                Authorization: authHeader,
            },
            body: body
        }
    );
}
