import React, { useState, useMemo } from "react";
import {
  Paper,
  TablePagination,
  Box,
  Button,
  TableContainer,
  Typography,
  SelectChangeEvent,
} from "@mui/material";
import { VehicleAuditDetails } from "../../vehicleAuditComponents/types";
import AuditResultTable from "./AuditResultTable";
import ClearIcon from "@mui/icons-material/Clear";
import FilterSelect from "../../vehicleAuditComponents/AuditResultComponents/FilterSelect";
import SearchBar from "./SearchBar";
import AuthenticityCountDisplay from "./AuthenticityCountDisplay";
import FilterSummary from "./FilterSummary";
import AuthenticityFilterSelect from "../../vehicleAuditComponents/AuditResultComponents/AuthenticityFilterSelect";
interface VehicleAuditTableProps {
  vehicleData: VehicleAuditDetails[];
}

type Order = "asc" | "desc";

const AuditResultTableContainer = ({ vehicleData }: VehicleAuditTableProps) => {
  const [pagination, setPagination] = useState({ page: 0, rowsPerPage: 50 });
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({
    authenticity: [] as number[],
    vehicleClasses: [] as string[],
    fuelTypes: [] as string[],
    vehicleModels: [] as string[],
    manufacturers: [] as string[],
    reasonForAuthenticity: [] as string[],
    reasonForRecheck: [] as string[],
  });
  const [sorting, setSorting] = useState({
    order: "asc" as Order,
    orderBy: "pageNumber" as keyof VehicleAuditDetails,
  });

  const anyFilterSelected = useMemo(() => {
    const {
      vehicleClasses,
      fuelTypes,
      vehicleModels,
      manufacturers,
      authenticity,
      reasonForRecheck,
    } = filters;
    return (
      vehicleClasses.length > 0 ||
      fuelTypes.length > 0 ||
      vehicleModels.length > 0 ||
      manufacturers.length > 0 ||
      authenticity?.length > 0 ||
      reasonForRecheck.length > 0
    );
  }, [filters]);

  const uniqueAuthenticityValues = useMemo(
    () => Array.from(new Set(vehicleData.map((item) => item.authenticity))),
    [vehicleData]
  );

  const uniqueVehicleClasses = useMemo(
    () => Array.from(new Set(vehicleData.map((row) => row.vehicleClass))),
    [vehicleData]
  );
  const uniqueFuelTypes = useMemo(
    () => Array.from(new Set(vehicleData.map((row) => row.fuelType))),
    [vehicleData]
  );
  const uniqueVehicleModels = useMemo(
    () =>
      Array.from(new Set(vehicleData.map((row) => row.makerModelFromSource))),
    [vehicleData]
  );
  const uniqueManufacturers = useMemo(
    () =>
      Array.from(new Set(vehicleData.map((row) => row.manufacturerFromSource))),
    [vehicleData]
  );

  const combinedRecheckReasons = vehicleData
    .map((item: { reasonForRecheck: string }) => {
      const parsedReasons = JSON.parse(item.reasonForRecheck) as string[];
      // Check if the parsed result is an empty array
      return parsedReasons
        ? parsedReasons.length === 0
          ? "N/A"
          : parsedReasons
        : "null";
    })
    .flat();
  const uniqueRecheckReasons: string[] = useMemo(
    () => Array.from(new Set(combinedRecheckReasons)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [vehicleData]
  );

  const uniqueReasonForAuthenticityValues = useMemo(() => {
    const allValues = vehicleData
      .flatMap((row) =>
        row.reasonForAuthenticity ? row.reasonForAuthenticity.split(",") : []
      )
      .map((value) => value.trim()); // To handle any extra spaces

    return Array.from(new Set(allValues)); // Remove duplicates
  }, [vehicleData]);

  // Handle Pagination
  const handlePageChange = (_: unknown, newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };
  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPagination({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  // Handle Sorting
  const handleRequestSort = (property: keyof VehicleAuditDetails) => {
    setSorting((prev) => ({
      order: prev.orderBy === property && prev.order === "asc" ? "desc" : "asc",
      orderBy: property,
    }));
  };

  // Handle Filter Changes
  const handleFilterChange = (filterKey: keyof typeof filters) => (
    event: SelectChangeEvent<string[]>
  ) => {
    const { value } = event.target;
    setFilters((prev) => ({
      ...prev,
      [filterKey]: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleAuthenticityFilterChange = (filterKey: keyof typeof filters) => (
    event: SelectChangeEvent<number[]>
  ) => {
    setFilters((prev) => ({
      ...prev,
      authenticity: Array.isArray(event.target.value)
        ? event.target.value.map((val) => (val === null ? -1 : Number(val))) // Ensure all values are numbers
        : [],
    }));
  };

  const clearAllFilters = () => {
    setFilters({
      authenticity: [],
      vehicleClasses: [],
      fuelTypes: [],
      vehicleModels: [],
      manufacturers: [],
      reasonForAuthenticity: [],
      reasonForRecheck: [],
    });
    setSearchTerm("");
    setPagination({ page: 0, rowsPerPage: 50 });
  };

  // Sorting and Filtering Data
  const sortedData = useMemo(() => {
    const { order, orderBy } = sorting;
    const comparator = (a: VehicleAuditDetails, b: VehicleAuditDetails) => {
      if ((a[orderBy] ?? Infinity) < (b[orderBy] ?? Infinity)) {
        return order === "asc" ? -1 : 1;
      }
      if ((a[orderBy] ?? -Infinity) > (b[orderBy] ?? -Infinity)) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    };
    return [...vehicleData].sort(comparator);
  }, [vehicleData, sorting]);

  const filteredData = useMemo(() => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const {
      vehicleClasses,
      authenticity,
      fuelTypes,
      vehicleModels,
      manufacturers,
      reasonForAuthenticity,
      reasonForRecheck,
    } = filters;
    const isPageNumberSearch =
      !isNaN(Number(searchTerm)) && searchTerm.trim() !== "";

    return sortedData.filter((row) => {
      if (isPageNumberSearch) {
        return (
          row.pageNumber?.toString() === searchTerm ||
          row.vehicleNumber?.toString().toLowerCase() === lowercasedSearchTerm
        );
      }
      const matchesSearchTerm = Object.values(row).some((value) =>
        value?.toString().toLowerCase().includes(lowercasedSearchTerm)
      );

      const matchesVehicleClasses =
        vehicleClasses.length === 0 ||
        vehicleClasses.includes(row.vehicleClass);
      const matchesAuthenticity =
        authenticity.length === 0 ||
        authenticity.includes(row.authenticity) ||
        (authenticity.includes(-1) && row.authenticity === null);

      const matchesFuelTypes =
        fuelTypes.length === 0 || fuelTypes.includes(row.fuelType);
      const matchesVehicleModels =
        vehicleModels.length === 0 ||
        vehicleModels.includes(row.makerModelFromSource);
      const matchesManufacturers =
        manufacturers.length === 0 ||
        manufacturers.includes(row.manufacturerFromSource);

      const matchesReasonForRecheck =
        reasonForRecheck.length === 0 || // If no reason is selected, match everything
        reasonForRecheck.some((reason) => {
          const rowReasonForRecheck = row.reasonForRecheck
            ? JSON.parse(row.reasonForRecheck)
            : "null";
          if (reasonForRecheck == null) {
            return reason === "null";
          }
          // Handle the case where the reasonForRecheck in the row is empty or "N/A"
          if (rowReasonForRecheck?.length === 0) {
            return reason === "N/A"; // Match with "N/A" when the array is empty
          }

          // Otherwise, check if the selected reason matches the parsed reasonForRecheck array
          return rowReasonForRecheck?.includes(reason);
        });
      const matchesReasonForAuthenticity =
        reasonForAuthenticity.length === 0 ||
        reasonForAuthenticity.some((reason) =>
          row.reasonForAuthenticity
            ?.split(",")
            .map((item) => item.trim())
            .includes(reason)
        );

      return (
        matchesSearchTerm &&
        matchesVehicleClasses &&
        matchesAuthenticity &&
        matchesFuelTypes &&
        matchesVehicleModels &&
        matchesManufacturers &&
        matchesReasonForAuthenticity &&
        matchesReasonForRecheck
      );
    });
  }, [searchTerm, sortedData, filters]);
  const paginatedData = useMemo(
    () =>
      filteredData.slice(
        pagination.page * pagination.rowsPerPage,
        pagination.page * pagination.rowsPerPage + pagination.rowsPerPage
      ),
    [filteredData, pagination]
  );

  return (
    <Box sx={{ mx: 2 }}>
      <Paper>
        {/* Search and Filter Section */}
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            p: 2,
            gap: 1,
          }}
        >
          <SearchBar
            searchTerm={searchTerm}
            onSearchChange={(e) => setSearchTerm(e.target.value)}
            onClearSearch={() => setSearchTerm("")}
          />
          <FilterSelect
            label="Source Vehicle Class"
            value={filters.vehicleClasses}
            options={uniqueVehicleClasses}
            onChange={handleFilterChange("vehicleClasses")}
          />
          <FilterSelect
            label="Source Fuel Type"
            value={filters.fuelTypes}
            options={uniqueFuelTypes}
            onChange={handleFilterChange("fuelTypes")}
          />
          <FilterSelect
            label="Source Vehicle Model"
            value={filters.vehicleModels}
            options={uniqueVehicleModels}
            onChange={handleFilterChange("vehicleModels")}
          />
          <FilterSelect
            label="Source Manufacturer"
            value={filters.manufacturers}
            options={uniqueManufacturers}
            onChange={handleFilterChange("manufacturers")}
          />
          <FilterSelect
            label="Reason For Authenticity"
            value={filters.reasonForAuthenticity || []}
            options={uniqueReasonForAuthenticityValues}
            onChange={handleFilterChange("reasonForAuthenticity")}
          />
          <FilterSelect
            label="Reason For Recheck"
            value={filters.reasonForRecheck || []}
            options={uniqueRecheckReasons}
            onChange={handleFilterChange("reasonForRecheck")}
          />
          <AuthenticityFilterSelect
            value={filters.authenticity}
            options={uniqueAuthenticityValues}
            onChange={handleAuthenticityFilterChange("authenticity")}
          />
          {anyFilterSelected && (
            <Button
              variant="outlined"
              color="primary"
              onClick={clearAllFilters}
            >
              <ClearIcon /> Clear
            </Button>
          )}
        </Box>

        {/* Filtered Data Summary */}
        <FilterSummary data={vehicleData} filters={filters} />

        {vehicleData.length > 0 && (
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 2,
              }}
            >
              {filteredData.length > 0 ? (
                <Typography variant="body2">
                  Showing <strong>{filteredData.length} </strong>results
                </Typography>
              ) : (
                <Typography variant="body2">No results found</Typography>
              )}

              {/* Authenticity Count Display */}
              <AuthenticityCountDisplay data={vehicleData} />

              <TablePagination
                component="div"
                count={filteredData.length}
                page={pagination.page}
                onPageChange={handlePageChange}
                rowsPerPage={pagination.rowsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
                rowsPerPageOptions={[50, 100, 150, 200]}
              />
            </Box>
            {/* Data Table */}
            <TableContainer sx={{ overflow: "auto" }} component={Paper}>
              <AuditResultTable
                data={paginatedData}
                order={sorting.order}
                orderBy={sorting.orderBy}
                handleRequestSort={handleRequestSort}
              />
            </TableContainer>

            {/* Pagination */}
            <TablePagination
              component="div"
              count={filteredData.length}
              page={pagination.page}
              onPageChange={handlePageChange}
              rowsPerPage={pagination.rowsPerPage}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPageOptions={[50, 100, 150, 200]}
            />
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default React.memo(AuditResultTableContainer);
