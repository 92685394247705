import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  styled,
  Typography,
  Box,
  Button,
  Avatar,
  Checkbox,
  TextField,
  Modal,
  Card,
  useMediaQuery,
  useTheme,
  IconButton,
  Menu,
  MenuItem,
  Select,
  CardActions,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CustomSnackbar from "../operationRolePages/CustomSnackbar";
import { useAuthContext } from "../../providers/AuthProvider";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import CloseIcon from "@mui/icons-material/Close";
import { Link as LinkIcon } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import AmountModal from "./AmountModal";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import dateFormat from "dateformat";
import {
  assignRole,
  getInviteLink,
  sendNotification,
} from "@gogig-in/gogig-clients";
import MDEditor from "@uiw/react-md-editor";
import { marked } from "marked";
import { getRoles } from "../../clients/getRoles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    padding: "0px 6px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0 6px",
  },
}));

const FirstColumnStyledTableCell = styled(StyledTableCell)({
  borderRight: "1px solid #ddd",
  width: "240px",
  height: "50px",
});

interface ManageUsersUIProps {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  searchResults: any[];
  reloadData?: () => void;
}

interface UserRoles {
  [key: string]: string;
}

const ManageUsersUI: React.FC<ManageUsersUIProps> = ({
  searchTerm,
  setSearchTerm,
  searchResults,
  reloadData,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [title, setTitle] = useState<string | null>(null);
  const { authHeader } = useAuthContext();
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const { userDetails } = useAuthContext();
  const userRole = userDetails?.role;
  const [inviteLink, setInviteLink] = useState<string>("");
  const { gigId } = useParams<{ gigId: string }>();
  const [openAmountModal, setOpenAmountModal] = useState(false);
  const { breakpoints } = useTheme();
  const matchMobileView = useMediaQuery(breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedUserDetails, setSelectedUserDetails] = useState<any>(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState<string | null>(null);
  const [mdBody, setMdBody] = useState<string | undefined>("");
  const [clickAction, setClickAction] = useState<string | null>("");
  const [error, setError] = useState<string | null>(null);
  const [htmlBody, setHtmlBody] = useState("");
  const [userRoles, setUserRoles] =useState<UserRoles>({});
 
  useEffect(() => {
    // Fetch the roles when the component mounts
    async function fetchRoles() {
      try {
        if (authHeader) {
          const response = await getRoles(authHeader);
         const data = response.data as { roleMap : UserRoles}
          setUserRoles(data.roleMap);
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    }

    fetchRoles();
  }, [authHeader]);

  
  const handleSendNotification = () => {
    if (selectedRows.length > 0) {
      setOpenModal(true);
    } else {
      handleSnackbar("error", "Select the user to send notification.");
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setClickAction(newValue);

    if (newValue && !newValue.startsWith("https://")) {
      setError("URL should start with https://");
    } else {
      setError(null);
    }
  };
  const htmlToText = (htmlbody: string) => {
    const element = document.createElement("div");
    element.innerHTML = htmlbody;
    setHtmlBody(element.textContent || "");
    return element.textContent || element.innerText || "";
  };

  const handleCloseAmountModal = () => {
    setOpenAmountModal(false);
    setSelectedRows([]);
  };
  const handleChangeMdEditor = (name: string, value: any) => {
    if (name === "mdBodyText") {
      const body = value ? value : "";
      setMdBody(body);
      const values = marked.parse(value || "");

      htmlToText(values.toLocaleString());
    }
  };

  function stringToColor(string: string | null) {
    if (string == null) return "#000";
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  function stringAvatar(firstName: string, lastName: string) {
    const avatarText = (
      (firstName ? firstName[0] : "") + (lastName ? lastName[0] : "")
    ).toUpperCase();

    return {
      sx: {
        bgcolor: stringToColor(firstName),
      },
      children: avatarText || "",
    };
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (severity: any, message: string) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSend = async () => {
    try {
      if (!htmlBody || !mdBody || !title || !clickAction) {
        if (!clickAction?.startsWith("https://")) {
          setError("URL should start with https://");
        } else {
          setError(null);
        }
        handleSnackbar("error", "Fill all the forms");
        return;
      }
      if (authHeader) {
        const formData = {
          title: title,
          body: htmlBody,
          markdown: mdBody,
          clickAction: clickAction,
          userIds: selectedRows.map((row) => row.userId),
        };
        await sendNotification(formData, authHeader);
        handleSnackbar("success", "Notification sent successfully.");
        setOpenModal(false);
        setMdBody("");
        setTitle(null);
        setClickAction(null);
        setHtmlBody("");
        setSelectedRows([]);
      }
    } catch (e) {
      console.error(e);
      handleSnackbar("error", "Error sending notification. Please try again.");
    }
  };

  const handleCheckboxChange = (row: any) => {
    setSelectedRows((prevSelectedRows) => {
      const updatedRows = [...prevSelectedRows];
      const selectedIndex = updatedRows.findIndex(
        (selectedRow) => selectedRow.userId === row.userId
      );

      if (selectedIndex === -1) {
        updatedRows.push(row);
      } else {
        updatedRows.splice(selectedIndex, 1);
      }

      return updatedRows;
    });
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedRows(searchResults);
    } else {
      setSelectedRows([]);
    }
  };

  useEffect(() => {
    const fetchInviteLink = async () => {
      try {
        if (authHeader) {
          if (gigId) {
            const response: any = await getInviteLink(authHeader, gigId);
            setInviteLink(response.data);
          } else {
            const response: any = await getInviteLink(authHeader);
            setInviteLink(response.data);
          }
        }
      } catch (error) {
        console.error("Error fetching user address", error);
      }
    };
    fetchInviteLink();
  }, [authHeader, gigId]);

  const handleCopyClick = () => {
    const urlToCopy = inviteLink;
    const tempInput = document.createElement("input");
    tempInput.value = urlToCopy;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    handleSnackbar("success", "Link copied successfully.");
  };

  const handleWhatsAppShare = async () => {
    const urlToShare = inviteLink;
    try {
      await navigator.clipboard.writeText(urlToShare);
      window.location.href = `whatsapp://send?text=${encodeURIComponent(
        urlToShare
      )}`;
    } catch (error) {
      console.error("Copy failed:", error);
    }
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    user: any
  ) => {
    setSelectedUserDetails(user);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditDetails = () => {
    setOpenEditModal(true);
  };

  const handleEditCardDetails = (user: any) => {
    setSelectedUserDetails(user);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setSelectedUserDetails(null);
    setOpenEditModal(false);
    setAnchorEl(null);
  };

  const handleSaveChanges = async () => {
    try {
      const userId = selectedUserDetails.userId;
      if (authHeader && selectedRole && reloadData) {
        await assignRole(userId, authHeader, selectedRole);
        handleSnackbar("success", "Role assigned successfully.");
        setOpenEditModal(false);
        setAnchorEl(null);
        setSelectedUserDetails(null);
        setSelectedRole(null);
        reloadData();
      }
    } catch (e) {
      console.error(e);
      handleSnackbar("error", "Error assigning role. Please try again.");
    }
  };

  useEffect(() => {
    if (selectedUserDetails) {
      setSelectedRole(selectedUserDetails.roleName);
    }
  }, [selectedUserDetails]);

  return (
    <Container sx={{ marginTop: "25px" }}>
      {!matchMobileView ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Typography variant="h4">Manage Users</Typography>
          <TextField
            label="Search users"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            size="small"
          />
          {userRole === userRoles.supervisor && (
            <Box
              sx={{
                border: "2px solid #d4d4d4",
                borderRadius: "8px",
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" sx={{ marginLeft: "10px" }}>
                Team Invitation Link:
              </Typography>
              <Button
                endIcon={<LinkIcon />}
                onClick={() => {
                  handleCopyClick();
                }}
                sx={{ textTransform: "none" }}
              >
                Copy Link
              </Button>
              <Button
                onClick={handleWhatsAppShare}
                sx={{ textTransform: "none" }}
              >
                <WhatsAppIcon sx={{ color: "green" }} /> Share on whatsapp
              </Button>
            </Box>
          )}
          <Box>
            {/* <Button
              variant="outlined"
              endIcon={<AccountBalanceIcon />}
              onClick={handleSendAmount}
              sx={{ marginRight: "15px" }}
            >
              Send Amount
            </Button> */}
            <Button
              variant="outlined"
              endIcon={<NotificationsIcon />}
              onClick={handleSendNotification}
            >
              Send Notification
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          <Typography variant="h4">Manage Users</Typography>
          {userRole === userRoles.supervisor && (
            <Box
              sx={{
                border: "2px solid #d4d4d4",
                borderRadius: "8px",
                padding: "10px",
                margin: "10px 0",
              }}
            >
              <Typography variant="h6" sx={{ marginLeft: "10px" }}>
                Team Invitation Link:
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  endIcon={<LinkIcon />}
                  onClick={() => {
                    handleCopyClick();
                  }}
                  sx={{ textTransform: "none", marginRight: "10px" }}
                  variant="outlined"
                >
                  Copy Link
                </Button>
                <Button
                  onClick={handleWhatsAppShare}
                  sx={{ textTransform: "none" }}
                  variant="outlined"
                >
                  <WhatsAppIcon sx={{ color: "green" }} /> Share on whatsapp
                </Button>
              </Box>
            </Box>
          )}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {/* <Button
              variant="outlined"
              endIcon={<AccountBalanceIcon />}
              onClick={handleSendAmount}
              sx={{ marginRight: "15px" }}
            >
              Send Amount
            </Button> */}
            <Button
              variant="outlined"
              endIcon={<NotificationsIcon />}
              onClick={handleSendNotification}
            >
              Send Notification
            </Button>
          </Box>
          <TextField
            label="Search users"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            size="small"
            fullWidth
            sx={{ margin: "10px 0" }}
          />
        </>
      )}
      {!matchMobileView ? (
        <TableContainer
          sx={{
            border: "1px solid #ddd",
            marginBottom: "10px",
          }}
        >
          <Table size="small" stickyHeader aria-label="a dense table">
            <TableHead>
              <TableRow>
                <FirstColumnStyledTableCell>
                  <Checkbox
                    checked={selectedRows.length === searchResults.length}
                    onChange={handleSelectAll}
                  />
                  User Name
                </FirstColumnStyledTableCell>
                <StyledTableCell>Phone No.</StyledTableCell>
                <StyledTableCell>Email-Id</StyledTableCell>
                <StyledTableCell>Role</StyledTableCell>
                <StyledTableCell>Gender</StyledTableCell>
                <StyledTableCell>Notification</StyledTableCell>
                <StyledTableCell>Activity</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchResults.map((userDetails: any) => (
                <TableRow
                  key={userDetails.userId}
                  sx={{ backgroundColor: "#FEFDFD" }}
                >
                  <FirstColumnStyledTableCell>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        maxWidth: "320px",
                        padding: "5px 0px",
                      }}
                    >
                      <Checkbox
                        checked={selectedRows.some(
                          (selectedRow) =>
                            selectedRow.userId === userDetails.userId
                        )}
                        onChange={() => handleCheckboxChange(userDetails)}
                      />
                      <Avatar
                        {...stringAvatar(
                          userDetails.firstName,
                          userDetails.lastName
                        )}
                        src={userDetails?.profilePicture}
                        sx={{
                          width: 36,
                          height: 36,
                          mr: 1,
                          bgcolor: stringToColor(
                            userDetails.firstName + " " + userDetails.lastName
                          ),
                        }}
                      >
                        {userDetails.firstName &&
                          userDetails.firstName.trim() && (
                            <Typography>
                              {userDetails.firstName
                                .trim()
                                .charAt(0)
                                .toUpperCase()}
                            </Typography>
                          )}
                      </Avatar>
                      <Typography
                        variant="h6"
                        sx={{
                          marginLeft: "8px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {userDetails.firstName && userDetails.lastName ? (
                          `${userDetails.firstName} ${userDetails.lastName}`
                        ) : (
                          <Typography variant="body2">.....</Typography>
                        )}
                      </Typography>
                      {(userRole === userRoles.admin ||
                        userRole === userRoles.operation) && (
                        <>
                          <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(event) => handleClick(event, userDetails)}
                            size="small"
                            sx={{ marginLeft: "auto" }}
                          >
                            <MoreHorizIcon />
                          </IconButton>
                          <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            PaperProps={{
                              sx: {
                                borderRadius: "7px",
                                boxShadow: "none",
                                backgroundColor: "white",
                                border: "1px solid #ddd",
                              },
                            }}
                          >
                            <MenuItem onClick={handleEditDetails}>
                              Edit Details
                            </MenuItem>
                          </Menu>
                        </>
                      )}
                    </Box>
                  </FirstColumnStyledTableCell>
                  <StyledTableCell>
                    {userDetails.phoneNumber ? (
                      userDetails.phoneNumber
                    ) : (
                      <Typography variant="body2">.....</Typography>
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {userDetails.emailId ? (
                      userDetails.emailId
                    ) : (
                      <Typography variant="body2">.....</Typography>
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{userDetails.roleName}</StyledTableCell>
                  <StyledTableCell>
                    {userDetails.gender ? (
                      userDetails.gender
                    ) : (
                      <Typography variant="body2">.....</Typography>
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {userDetails.token ? (
                      <DoneOutlineIcon
                        sx={{ color: "green", margin: "auto", display: "flex" }}
                      />
                    ) : (
                      <CloseIcon
                        sx={{ color: "red", margin: "auto", display: "flex" }}
                      />
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {userDetails.gender ? (
                      <>
                        {`Created at: ${dateFormat(
                          userDetails.createdAt,
                          "mmm d, yyyy, h:MM TT"
                        )}`}
                        <br />
                        {`Updated at: ${dateFormat(
                          userDetails.updatedAt,
                          "mmm d, yyyy, h:MM TT"
                        )}`}
                      </>
                    ) : (
                      <Typography variant="body2">.....</Typography>
                    )}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>
          {searchResults.map((user) => (
            <Card
              key={user.userId}
              sx={{
                marginBottom: "10px",
                backgroundColor: "white",
                border: "2px solid #d4d4d4",
                padding: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Checkbox
                    checked={selectedRows.includes(user)}
                    onChange={() => handleCheckboxChange(user)}
                  />
                </Box>
                <Box>
                  <Typography variant="h6">
                    {user.firstName && user.lastName ? (
                      `${user.firstName} ${user.lastName}`
                    ) : (
                      <Typography variant="body2">.....</Typography>
                    )}
                  </Typography>
                  <Typography variant="body1">
                    Phone number:{" "}
                    {user.phoneNumber ? user.phoneNumber : <span>.....</span>}
                  </Typography>
                  <Typography variant="body1">
                    Role: {user.roleName ? user.roleName : <span>.....</span>}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    Notification:
                    {user.token ? (
                      <>
                        <span style={{ margin: "0 5px" }}> Subscribed</span>
                        <DoneOutlineIcon
                          sx={{ color: "green", fontSize: "16px" }}
                        />
                      </>
                    ) : (
                      <>
                        <span style={{ margin: "0 5px" }}>Not subscribed</span>
                        <CloseIcon sx={{ color: "red", fontSize: "16px" }} />
                      </>
                    )}
                  </Typography>
                  {`Created at: ${dateFormat(
                    user.createdAt,
                    "mmm d, yyyy, h:MM TT"
                  )}`}
                  <br />
                  {`Updated at: ${dateFormat(
                    user.updatedAt,
                    "mmm d, yyyy, h:MM TT"
                  )}`}
                </Box>
              </Box>
              {(userRole === userRoles.admin ||
                userRole === userRoles.operation) && (
                <CardActions sx={{ justifyContent: "flex-end" }}>
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: "none",
                    }}
                    onClick={() => handleEditCardDetails(user)}
                    size="small"
                  >
                    Edit Details
                  </Button>
                </CardActions>
              )}
            </Card>
          ))}
        </>
      )}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            minWidth: "300px",
            maxWidth: "400px",
          }}
        >
          <Typography variant="h5">Send Notification</Typography>
          <TextField
            label="Title"
            variant="outlined"
            fullWidth
            margin="normal"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            label="Click Action URL"
            variant="outlined"
            fullWidth
            multiline
            helperText={error}
            error={!!error}
            rows={1}
            margin="normal"
            value={clickAction}
            onChange={handleChange}
          />

          <MDEditor
            style={{
              width: "100%",
              minHeight: "200px",
            }}
            value={mdBody}
            onChange={(value) => {
              const updatedValue = value || "";
              handleChangeMdEditor("mdBodyText", updatedValue);
            }}
          />
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ mt: 1, ml: "12px" }}
          >
            MDEditor is for notification Page
          </Typography>
          <TextField
            label="Body"
            variant="outlined"
            helperText={"Body is for Background messages"}
            fullWidth
            multiline
            rows={2}
            margin="normal"
            value={htmlBody}
            disabled
          />
          <Button onClick={handleSend}>Send</Button>
          <Button onClick={handleCloseModal}>Cancel</Button>
        </Box>
      </Modal>
      <AmountModal
        open={openAmountModal}
        onClose={handleCloseAmountModal}
        selectedRows={selectedRows}
        apiStatus={handleSnackbar}
      />
      <Modal open={openEditModal} onClose={handleCloseEditModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            padding: "20px 20px 5px 20px",
          }}
        >
          <Typography variant="h5">Edit User Details</Typography>
          {selectedUserDetails && (
            <div>
              <Typography>
                Name: {selectedUserDetails.firstName}{" "}
                {selectedUserDetails.lastName}
              </Typography>
              <Typography>Phone: {selectedUserDetails.phoneNumber}</Typography>
              <Typography>Email: {selectedUserDetails.emailId}</Typography>
              <Typography>Role: {selectedUserDetails.roleName}</Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "500", marginTop: "5px" }}
              >
                Assign role to user
              </Typography>
              <Select
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
                sx={{ marginTop: 1, minWidth: "300px"}}
                
              >
                {Object.keys(userRoles).map((role) => (
                  <MenuItem key={role} value={role}>
                    {role.charAt(0).toUpperCase() + role.slice(1)}{" "}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "16px",
            }}
          >
            <Button
              onClick={handleCloseEditModal}
              sx={{ textTransform: "none" }}
            >
              Close
            </Button>
            <Button
              variant="outlined"
              sx={{ textTransform: "none" }}
              onClick={handleSaveChanges}
            >
              Save Changes
            </Button>
          </Box>
        </Box>
      </Modal>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />
    </Container>
  );
};

export default ManageUsersUI;
