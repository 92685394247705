import React, { useState, ChangeEvent, useEffect } from "react";
import {
  Button,
  Typography,
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  CircularProgress,
  Container,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { fileParser, TableData } from "../../../utils/utils";
import { uploadFileWithMetadata } from "../../../clients/uploadFileWithMetadata";
import { useAuthContext } from "../../../providers/AuthProvider";
import theme from "../../../theme";
import {
  createUserAuditRequest,
  FileUrls,
} from "../../../clients/createUserAuditRequest";
import { useSnackbar } from "../../snackbarComponent/useSnackbar";
import GlobalSnackbar from "../../snackbarComponent/Snackbar";

const CreateExcelRequestPage = () => {
  const [previewData, setPreviewData] = useState<TableData[]>([]);
  const [previewColumns, setPreviewColumns] = useState<string[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [s3Data, setS3Data] = useState<string | null>(null);
  const [uploadStatus, setUploadStatus] = useState<
    "not-initiated" | "initiated" | "completed"
  >("not-initiated");
  const { authHeader, userDetails } = useAuthContext();
  const [gogigReqId, setGogigReqId] = useState<string>("");
  const [requesterId, setRequesterId] = useState<string>("");
  const [showAuditFields, setShowAuditFields] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [requesterTitle, setRequesterTitle] = useState<string>("");
  const { snackbar, openSnackbar, closeSnackbar } = useSnackbar();

  // Trigger handleCreateAuditRequest once s3Data is updated
  useEffect(() => {
    if (s3Data && requesterTitle) {
      handleCreateAuditRequest(s3Data);
      setShowAuditFields(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s3Data]);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    handleClearAll();
    setLoading(true);
    const uploadedFile = event.target.files?.[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      try {
        const { filteredData } = await fileParser(uploadedFile);

        const header = filteredData[0];
        const dataRows = filteredData.slice(1);

        const dataObjects = dataRows.map((row) => {
          const obj: TableData = {};
          header.forEach((col, index) => {
            let cellValue = row[index];

            if (typeof cellValue === "string") {
              cellValue = cellValue.replace(/-/g, "").trim();
            }

            obj[col] = cellValue;
          });
          return obj;
        });

        setPreviewData(dataObjects);
        setPreviewColumns(header);
      } catch (error) {
        console.error("Error parsing file:", error);
      }
    }
    event.target.value = "";
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  const handleUpload = async () => {
    setLoading(true);
    if (!file || !authHeader) return;
    const formData = new FormData();
    formData.append("excel sheet", file);

    const metadata = {
      uploaderName: `Gogig Admin ${userDetails?.firstName} ${userDetails?.lastName}`,
    };
    formData.append("tags", "AUDIT DATA")
    formData.append("metadata", JSON.stringify(metadata))
    try {
      const res = await uploadFileWithMetadata(authHeader, formData);
      const data = res.fileUrl;
      setS3Data(data);
      openSnackbar(
        `Uploaded successfully`,
        "success"
      );
    } catch (error) {
      openSnackbar(`Error uploading row`, "error");
      console.error("Error uploading row:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateAuditRequest = async (url: string) => {
    setLoading(true);
    if (!authHeader) return;
    let payload: FileUrls;
    payload = {
      sheetUrl: url,
      pptUrl: "",
      requestTitle: requesterTitle,
    };

    // Create audit request logic here
    try {
      const response = await createUserAuditRequest(authHeader, payload);
      const data = (
        response.data as {
          auditRequestData: { gogigRequestId: string; requesterId: string };
        }
      ).auditRequestData;
      setGogigReqId(data.gogigRequestId);
      setRequesterId(encodeURIComponent(data.requesterId));
      openSnackbar(
        `Request for ${requesterTitle} is created successfully`,
        "success"
      );
    } catch (error) {
      console.error("Error creating audit request:", error);
      setLoading(false);
      openSnackbar(`Something went wrong ${error}`, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleClearAll = () => {
    setFile(null);
    setPreviewData([]);
    setPreviewColumns([]);
    setGogigReqId("");
    setRequesterId("");
    setS3Data(null);
    setUploadStatus("not-initiated");
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const encodedValue = encodeURIComponent(e.target.value);
    setRequesterId(encodedValue);
  };

  return (
    <Container>
      <GlobalSnackbar
        open={snackbar.open}
        message={snackbar.message}
        onClose={closeSnackbar}
        severity={snackbar.severity}
        vertical={"top"}
        horizontal={"center"}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 3,
          gap: 3,
          my: 5,
        }}
      >
        <TextField
          label="Requesters name"
          type="text"
          value={requesterTitle}
          onChange={(e) => setRequesterTitle(e.target.value)}
          fullWidth
          required
          size="medium"
          sx={{
            flex: 1,
          }}
        />
        <Button
          component="label"
          fullWidth
          variant="outlined"
          disabled={uploadStatus === "initiated"}
          sx={{
            textTransform: "none",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px 16px",
            flex: 1,
          }}
        >
          {file ? (
            file.name
          ) : (
            <Typography
              textAlign="center"
              sx={{
                display: "flex",
                alignItems: "center",
                fontWeight: "700",
                justifyContent: "center",
              }}
            >
              <UploadFileIcon
                sx={{
                  fontSize: 28,
                  marginRight: 1,
                }}
              />
              Upload Excel sheet
            </Typography>
          )}
          <input
            type="file"
            accept=".csv, .xlsx"
            onChange={handleFileChange}
            hidden
          />
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={!file || loading || uploadStatus === "initiated"}
          sx={{
            cursor: uploadStatus === "completed" ? "not-allowed" : "pointer",
            whiteSpace: "nowrap",
            flex: 0.5,
          }}
        >
          {loading ? <CircularProgress /> : "Create Audit Request"}
        </Button>
      </Box>

      {showAuditFields && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              mb: 3,
            }}
          >
            <TextField
              label="Gogig Req ID"
              type="text"
              value={gogigReqId}
              onChange={(e) => setGogigReqId(e.target.value)}
              fullWidth
              required
              size="small"
              sx={{
                mr: 2,
              }}
            />
            <TextField
              label="Requester ID"
              type="text"
              value={decodeURIComponent(requesterId)}
              onChange={handleChange}
              fullWidth
              required
              size="small"
            />
          </Box>
        </>
      )}
      <Box overflow={"auto"}>
        <TableContainer
          component={Paper}
          sx={{
            height: "650px",
            overflow: "auto",
            textAlign: "center",
            backgroundColor: "transparent",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {previewColumns.map((column) => (
                  <TableCell
                    key={column}
                    sx={{
                      fontSize: "medium",
                      bgcolor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                      textAlign: "center",
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                    }}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {previewData.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:nth-of-type(even)": { bgcolor: "#fafafa" },
                    "&:hover": { bgcolor: "#f0f0f0" },
                  }}
                >
                  {previewColumns.map((column) => (
                    <TableCell key={column} sx={{ textAlign: "center" }}>
                      {row[column] || "N/A"}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default CreateExcelRequestPage;
