import { Grid, Typography } from "@mui/material";
import React from "react";

export const ReportHeader = () => {
  return (
    <>
      {/* Header Section */}
      <Grid
        container
        spacing={2}
        style={{
          padding: "20px",
        }}
      >
        <Grid item xs={6}>
          <Typography
            variant="h4"
            style={{ color: "#2B7744", fontWeight: "bold" }}
          >
            <img
              src={
                `${process.env.PUBLIC_URL}/images/logos/spotrue.png`
              }
              alt={"SPOTRUE"}
              loading="lazy"
              style={{ height: "60px" }}
            />
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ textAlign: "right" }}>
          <Typography
            variant="h6"
            style={{ fontWeight: "bold", color: "#FF7A00" }}
          >
            <img
              src={
               `${process.env.PUBLIC_URL}/images/logos/logo.png`
              }
              alt={"gOGig"}
              loading="lazy"
              style={{ height: "30px" }}
            />
          </Typography>
          <Typography variant="caption" fontSize={14}>
            <strong>PROCESSED AT: </strong>SpoTrue by gOGig
          </Typography>
        </Grid>
      </Grid>

      {/* Basic Verification Report Section */}
      <Grid container spacing={0} style={{ marginTop: "8px" }}>
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: "#FF7A00",
            padding: "20px",
            color: "white",
          }}
        >
          <Typography variant="h6" textAlign={"center"}>
            BASIC VERIFICATION REPORT
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
