import { Grid } from "@mui/material";
import {ReportTestDetailsProps } from "../reportComponentProps";
import ReportTextComponent from "../reportTextComponent";
export const ReportTestDetails: React.FC<{ data:ReportTestDetailsProps }> = ({
    data,
  }) => (
    <Grid container spacing={2} sx={{ padding: 2 }}>
      <Grid item xs={6}>
        <ReportTextComponent text="Credits Used" subString="500" />
        <ReportTextComponent text="Credits Left" subString={data?.creditsLeft} />
        <ReportTextComponent text="Test Case ID" subString={data?.testCaseId} />
        <ReportTextComponent text="Test Processing" subString="500000" />
        <ReportTextComponent
          text="Execution Status"
          subString={data?.executionStatus}
        />
        <ReportTextComponent text="Input Data" subString="500" />
        <ReportTextComponent
          text="Actual Results"
          subString={data?.actualresults}
        />
        <ReportTextComponent
          text="Expected Results"
          subString={data?.expectedResults}
        />
        <ReportTextComponent
          text="Actual Results"
          subString={data?.actualresults}
        />
      </Grid>
  
      <Grid item xs={6}>
        <ReportTextComponent
          text="Cost per Query"
          subString={data?.costPerQuery}
        />
        <ReportTextComponent
          text="Cost per Vehicle branding"
          subString={data?.costPerVehicleBranding}
        />
        <ReportTextComponent
          text="Cost Invested in branding"
          subString={data?.investedInBranding}
        />
        <ReportTextComponent
          text="Cost Invested in report"
          subString={data?.investedInReport}
        />
        <ReportTextComponent
          text="Cost Saved from report"
          subString={data?.savedFromReport}
        />
      </Grid>
    </Grid>
  );
  