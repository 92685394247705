import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SpotrueApiBaseUrl } from "./baseUrls";
import { SaveVehiclePropertyParams } from "../components/vendors/auditResultComponents/components/UnknownVehicleDataUI";

export default function addVehicleProperty(
  authHeader: string,
  gogigReqId:string,
  payload:SaveVehiclePropertyParams,
) {
  const url = `${SpotrueApiBaseUrl}/addVehicleProperty?gogigReqId=${gogigReqId}`;
  
  return fetchWrapper(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader,
    },
    body:payload
  });
}