/**
 * Converts a camelCase string to a normal sentence case string.
 *
 * @param {string} str - The camelCase string to be converted.
 * @returns {string} - The converted string in normal sentence case.
 */
export const camelCaseToNormal = (str: string) => {
  return (
    str
      // Replace occurrences of a lowercase letter followed by an uppercase letter with the lowercase letter, a space, and the uppercase letter.
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      // Capitalize the first character of the string.
      .replace(/^./, (char) => char.toUpperCase())
  );
};
