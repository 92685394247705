import React from "react";
import {
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  FormLabel,
  ListItemText,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  SelectChangeEvent,
  Chip,
} from "@mui/material";
import { UpdateVehicleInterface } from "./types";
import {
  ReCheckComments,
  vehicleColorDropDown,
  VehicleManufacturer,
  VehicleModelDropDown,
} from "./vehicleOptionsConstants";

interface VehicleDetailsFormProps {
  updatePreProcess: UpdateVehicleInterface;
  handleUpdateButton: () => void;
  vehicleNumberError: boolean;
  loading: boolean;
  isColorError: boolean;
  setOpenDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  openDropdown: boolean;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectChange: (event: SelectChangeEvent<string[] | string>) => void;
  handleSelectFieldChange: (
    event: SelectChangeEvent<string>,
    field: string
  ) => void;
  handleImageOrientation: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  vehicleData: UpdateVehicleInterface;
}

const VehicleDetailsForm: React.FC<VehicleDetailsFormProps> = ({
  updatePreProcess,
  handleUpdateButton,
  vehicleNumberError,
  loading,
  isColorError,
  setOpenDropdown,
  openDropdown,
  handleInputChange,
  handleSelectChange,
  handleSelectFieldChange,
  handleImageOrientation,
  handleCheckboxChange,
  vehicleData
}) => {
  const preWrittenComments = [
    `Incorrect Vehicle Number Extracted  ${vehicleData.rcNumber ? `(${vehicleData.rcNumber})` : ``}`,
    `Incorrect Vehicle Color Extracted ${vehicleData.colorFromPhoto ? `(${vehicleData.colorFromPhoto})` : ""}`,
    `Incorrect Vehicle Manufacturer and Model Extracted ${vehicleData.manufacturerFromPhoto ? `(${vehicleData.manufacturerFromPhoto})` : ""} ${vehicleData.makerModelFromPhoto ? `(${vehicleData.makerModelFromPhoto})` : ""}`,
    `Vehicle Number Extraction Failed`,
    `Vehicle Color Extraction Failed`,
    `Vehicle Manufacturer and Model Extraction Failed`
  ];

  // Function to handle chip clicks
  const handleChipClick = (comment: string) => {
    const currentRemarks = updatePreProcess.remarks || "";
    const commentsArray = currentRemarks
      .split(", ")
      .map((c) => c.trim())
      .filter((c) => c.length > 0);

    if (!commentsArray.includes(comment)) {
      const updatedRemarks = commentsArray.length
        ? `${currentRemarks}, ${comment}`
        : comment;
      handleInputChange({
        target: { name: "remarks", value: updatedRemarks },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <Box sx={{ flex: 2, padding: "20px" }}>
      <Box
        component="form"
        sx={{
          display: "grid",
          gap: 2,
          gridTemplateColumns: "repeat(2, 1fr)",
        }}
      >
        <TextField
          label="Vehicle Number"
          variant="outlined"
          fullWidth
          name="rcNumber"
          value={updatePreProcess.rcNumber || ""}
          onChange={handleInputChange}
          error={!!updatePreProcess.rcNumber && vehicleNumberError}
          helperText={
            !!updatePreProcess.rcNumber && vehicleNumberError ? "Invalid Vehicle Number (AB01CD2345)" : ""
          }
        />

        <FormControl fullWidth>
          <InputLabel>Vehicle Color</InputLabel>
          <Select
            label="Vehicle Color"
            defaultValue=""
            value={updatePreProcess.colorFromPhoto || ""}
            onChange={(e) => handleSelectFieldChange(e, "colorFromPhoto")}
            error={!!updatePreProcess.colorFromPhoto && isColorError}
          >
            {Object.keys(vehicleColorDropDown).map((color) => (
              <MenuItem key={color} value={color}>
                {vehicleColorDropDown[color]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Manufacturer</InputLabel>
          <Select
            label="Manufacturer"
            defaultValue=""
            value={updatePreProcess.manufacturerFromPhoto || ""}
            onChange={(e) =>
              handleSelectFieldChange(e, "manufacturerFromPhoto")
            }
          >
            {Object.keys(VehicleManufacturer).map((manufacturer) => (
              <MenuItem
                key={manufacturer}
                value={manufacturer}
                title={VehicleManufacturer[manufacturer]}
              >
                {VehicleManufacturer[manufacturer]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Maker Model</InputLabel>
          <Select
            label="Maker Model"
            defaultValue=""
            value={updatePreProcess.makerModelFromPhoto || ""}
            onChange={(e) => handleSelectFieldChange(e, "makerModelFromPhoto")}
          >
            {/* Show all models if no manufacturer is selected, else show filtered models */}
            {(updatePreProcess.manufacturerFromPhoto
              ? VehicleModelDropDown[updatePreProcess.manufacturerFromPhoto] ||
              []
              : Object.values(VehicleModelDropDown).flat()
            ) // Show all models if no manufacturer is selected
              .map((model) => (
                <MenuItem key={model} value={model}>
                  {model}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <Box sx={{ gridColumn: "span 2" }}>
          <Typography variant="subtitle1" gutterBottom>
            Quick Comments:
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1, // Applies uniform spacing between chips (horizontal and vertical)
            }}
          >
            {preWrittenComments.map((comment, index) => (
              <Chip
                key={index}
                label={comment}
                onClick={() => handleChipClick(comment)}
                sx={{
                  mb: 1, // Add vertical space explicitly if needed (alternative to gap)
                }}
              />
            ))}
          </Box>
        </Box>

        <TextField
          label="Comments"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          name="remarks"
          value={updatePreProcess.remarks || ""}
          onChange={handleInputChange}
          sx={{ gridColumn: "span 2" }}
        />
        <FormControl fullWidth sx={{ gridColumn: "span 2" }}>
          {" "}
          {/* Add some margin-right to space them out */}
          <FormLabel id="imageOrientation">image orientation</FormLabel>
          <RadioGroup
            row
            aria-labelledby="imageOrientation"
            name="position"
            defaultValue="top"
            value={updatePreProcess.imageOrientation || ""}
            onChange={(e) => handleImageOrientation(e)}
            sx={{ justifyContent: "flex-start", width: "100%" }}

          >
            <FormControlLabel
              value="left"
              control={<Radio />}
              label="left"
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="center"
              control={<Radio />}
              label="center"
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="right"
              control={<Radio />}
              label="right"
              labelPlacement="bottom"
            />
          </RadioGroup>
        </FormControl>

        {/* Recheck Comments Dropdown */}

        <FormControl fullWidth sx={{ gridColumn: "span 2", mt: 1 }}>
          <InputLabel>Recheck Comments</InputLabel>
          <Select
            multiple
            open={openDropdown}
            onClose={() => setOpenDropdown(false)} // Close dropdown when user interacts
            onOpen={() => setOpenDropdown(true)} // Keep it consistent when manually opened
            value={(updatePreProcess.reasonForRecheck as string[]) || []}
            onChange={handleSelectChange}
            renderValue={(selected) =>
              selected.map((value) => ReCheckComments[value]).join(", ")
            }
            label="Recheck Comments"
            disabled={!updatePreProcess.recheck}
          >
            {Object.keys(ReCheckComments).map((reCheckComment) => (
              <MenuItem key={reCheckComment} value={reCheckComment}>
                <Checkbox
                  checked={
                    (updatePreProcess.reasonForRecheck as string[]) &&
                    updatePreProcess.reasonForRecheck.indexOf(reCheckComment) >
                    -1
                  }
                />
                <ListItemText primary={ReCheckComments[reCheckComment]} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Checkbox to Enable Recheck Dropdown */}
        <Box
          sx={{
            gridColumn: "span 2",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Tooltip title="Enable the dropdown to select recheck comments">
            <Checkbox
              checked={updatePreProcess.recheck}
              onChange={handleCheckboxChange}
              inputProps={{ "aria-label": "Enable Recheck Dropdown" }}
            />
          </Tooltip>
          <Typography sx={{ ml: 1 }}>Enable Recheck Dropdown</Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gridColumn: "span 2",
            mt: 2,
            alignItems: "center", // Aligns FormControl and Button on the same level
          }}
        >
          <Box
            sx={{
              color: "black",
              fontSize: "1.5rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {updatePreProcess.pageNumber != null &&
              updatePreProcess.pageNumber !== -1
              ? `Page No: ${updatePreProcess.pageNumber + 1}`
              : "N/A"}
          </Box>
          <Button
            variant="contained"
            sx={{ height: "46px" }} // Keep button height consistent with FormControl
            color="primary"
            onClick={handleUpdateButton}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Update"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default VehicleDetailsForm;
