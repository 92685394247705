import React from "react";
import { Typography } from "@mui/material";

interface SummaryTextProps {
  label: string;
  count: number;
}

export default function SummaryText({ label, count }: SummaryTextProps) {
  return (
    <Typography variant="body1">
      {label}:<strong> {count}</strong>
    </Typography>
  );
}
