import { Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../providers/AuthProvider";
import PPTViewer from "../vendors/vehicleAuditComponents/PPTViewer";
import { getAuditRequestById } from "../../clients/getAuditRequestById";
import { AuditRequest } from "../vendors/vehicleAuditComponents/types";

const PreProcessingData: React.FC = () => {
  const { gogigRequestId } = useParams<{
    gogigRequestId?: string;
  }>();
  const { authHeader } = useAuthContext();
  const [auditData, setAuditData] = useState<AuditRequest>();
  const [error, setError] = useState<string | null>(null);

  const fetchAuditData = useCallback(async () => {
    if (!authHeader || !gogigRequestId) return;
    setError(null);
    try {
      const res = await getAuditRequestById(
        authHeader,
        gogigRequestId,
      );
      const fetchedData = res as AuditRequest;
      setAuditData(fetchedData);
    } catch (error) {
      console.error("Failed to fetch audit data", error);
      setError("Failed to fetch audit data");
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authHeader]);

  useEffect(() => {
    fetchAuditData();
  }, [fetchAuditData]);

  if (error)
    return (
      <Typography variant="h6" color="error">
        {error}
      </Typography>
    );

  return (
    <>
      {auditData && auditData.processedPptUrl && (
        <PPTViewer
          auditData={auditData}
          fetchAuditData={fetchAuditData}
        />
      )}
    </>
  );
};

export default PreProcessingData;
