import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import SuccessSubmission from "./successSubmission";
import { resetPassword } from "@gogig-in/gogig-clients";

const ForgotPasswordPage = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false); // State to track success
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({
    emailId: "",
  });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setErrorMessage("");
    setSuccess(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await resetPassword(values.emailId);
      setSuccess(true);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.error();
      if (error.status === 400) {
        setErrorMessage("Email id does not exist in our database");
      }
    }
  };

  return (
    <>
      {success ? (
        <SuccessSubmission
          logoName={"sent"}
          text={
            "We have sent an email to your email address. Follow the steps provided in the email to update your password."
          }
        />
      ) : (
        <Container sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              maxWidth: "400px",
              border: "2px solid #eee ",
              borderRadius: "10px",
              padding: "25px",
              marginTop: "25px",
            }}
          >
            {errorMessage && (
              <Typography
                sx={{ display: "flex", justifyContent: "center", color: "red" }}
              >
                {errorMessage}
              </Typography>
            )}
            <Box textAlign="center">
              <img
                alt="reset-password.png"
                src={`${process.env.PUBLIC_URL}/reset-password.png`}
                style={{ height: "100px", width: "100px" }}
              />
            </Box>
            <Typography
              variant="h3"
              sx={{ display: "flex", justifyContent: "center" }}
              gutterBottom
            >
              Update Your Password
            </Typography>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "15px",
              }}
            >
              Enter your email id and press Send Email.
            </Typography>
            <TextField
              size="small"
              sx={{ display: "flex", justifyContent: "center" }}
              name="emailId"
              value={values.emailId}
              onChange={handleChange}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              <Button
                sx={{ fontSize: "12px", size: "14px", minWidth: "100px" }}
                variant="contained"
                onClick={handleSubmit}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "white" }} size={24} />
                ) : (
                  "Send Email"
                )}
              </Button>
            </Box>
          </Box>
        </Container>
      )}
    </>
  );
};
export default ForgotPasswordPage;
