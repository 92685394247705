import React from "react";
import { Box } from "@mui/material";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { UpdateVehicleInterface } from "./types";

interface VehicleImageDisplayProps {
    updatePreProcess:UpdateVehicleInterface,
}

const VehicleImageDisplay: React.FC<VehicleImageDisplayProps> = ({
    updatePreProcess,
}) => {
  return (
    <Box>
    <TransformWrapper>
      <TransformComponent>
        <img
          src={updatePreProcess.imagePath}
          key={updatePreProcess.pageNumber}
          alt="Vehicle"
          style={{
            width: "auto",
            height: "calc(100vh - 100px)",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        />
      </TransformComponent>
    </TransformWrapper>
  </Box>
  );
};

export default VehicleImageDisplay;
