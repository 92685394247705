import React, { useState, useMemo, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  TableSortLabel,
  InputAdornment,
  Container,
  styled,
  Typography,
  Box,
  Button,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { Search, Close } from "@mui/icons-material";
import { getAuditRequestData } from "../../clients/getAuditRequest";
import { useAuthContext } from "../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import { AuditRequest } from "./vehicleAuditComponents/types";
import dateFormat from "dateformat";
import { userRoles } from "../../types/userRoles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: "10px 20px",
  border: "1px solid #ccc",
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#E3F2FD", // Background color when hovering over the row
    cursor: "pointer",
  },
}));

const StyledBodyCell = styled(TableCell)(({ theme }) => ({
  border: "1px solid #ccc", // Default border color
  cursor: "pointer",
  backgroundColor: "inherit",
  transition: "background-color 0.3s, border 0.3s", // Transition for smooth hover effect
}));

const HighlightedRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#FFF9C4", // Light yellow color for specific rows
  "&:hover": {
    backgroundColor: "#FFE082", // Slightly darker yellow on hover
    cursor: "pointer",
  },
}));

const AuditRequestDashboard = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [sortBy, setSortBy] = useState<keyof AuditRequest>("updatedAt");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState<AuditRequest[]>([]);
  const { authHeader, userDetails } = useAuthContext();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };
  const handleClear = () => {
    setSearchTerm("");
  };

  const fetchAuditData = async () => {
    setLoading(true);
    if (!authHeader) return;
    try {
      const res = await getAuditRequestData(authHeader);
      const auditData = res.data as AuditRequest[];
      setData(auditData);
    } catch (error) {
      setLoading(false);
      console.error("Failed to fetch audit data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAuditData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authHeader]);

  const handleSortRequest = (property: keyof AuditRequest) => {
    const isAsc = sortBy === property && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setSortBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (item: AuditRequest) => {
    navigate(`/audit-request/${item.gogigRequestId}`);
  };

  const handleOpenAuditDialog = () => {
    navigate("/upload-createRequest");
  };

  const filteredData = useMemo(() => {
    return data
      .filter(
        (item) =>
          item.gogigRequestId
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.requesterId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.requesterName
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.requestTitle?.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((b, a) => {
        const aValue = a[sortBy];
        const bValue = b[sortBy];

        if (aValue == null && bValue == null) {
          return 0;
        }

        if (aValue == null) {
          return sortDirection === "asc" ? -1 : 1;
        }

        if (bValue == null) {
          return sortDirection === "asc" ? 1 : -1;
        }

        if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
        if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
        return 0;
      });
  }, [searchTerm, sortBy, sortDirection, data]);

  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const hasAccess = userDetails?.role === userRoles.admin;
  return (
    <Container>
      <Typography variant="h2" textAlign={"center"}>
        Manage Client Audit Requests
      </Typography>

      <Paper sx={{ padding: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Request List
          </Typography>
          <Typography>
            <strong>Search Results:</strong> {filteredData.length} results
          </Typography>
          {hasAccess && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenAuditDialog}
            >
              {" "}
              Create Request
            </Button>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <TextField
            label="Search"
            variant="outlined"
            fullWidth
            margin="normal"
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: searchTerm && (
                <InputAdornment position="end">
                  <IconButton onClick={handleClear}>
                    <Close />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                my: 10,
              }}
            >
              <CircularProgress size={"4rem"} />
            </Box>
          ) : (
            <>
              <TablePagination
                component="div"
                count={filteredData.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        <TableSortLabel
                          active={sortBy === "requestTitle"}
                          direction={sortDirection}
                          onClick={() => handleSortRequest("requestTitle")}
                        >
                          Title
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell>Requester Details</StyledTableCell>
                      <StyledTableCell>
                        <TableSortLabel
                          active={sortBy === "gogigRequestId"}
                          direction={sortDirection}
                          onClick={() => handleSortRequest("gogigRequestId")}
                        >
                          Gogig Request ID
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell>
                        <TableSortLabel
                          active={sortBy === "updatedAt"}
                          direction={sortDirection}
                          onClick={() => handleSortRequest("updatedAt")}
                          color="black"
                        >
                          Updated At
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell>
                        <TableSortLabel
                          active={sortBy === "createdAt"}
                          direction={sortDirection}
                          onClick={() => handleSortRequest("createdAt")}
                        >
                          Created At
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell>
                        Request Status
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedData.map((item) => (
                      item.requestStatus === 'requestCreationPending' ? (
                        <HighlightedRow key={item.gogigRequestId}>
                          <StyledBodyCell
                            onClick={() => handleRowClick(item)}
                          >
                            <Typography variant="body2" color="text.secondary">
                              {item.requestTitle || item.campaignName}
                            </Typography>
                          </StyledBodyCell>
                          <StyledBodyCell
                            onClick={() => handleRowClick(item)}
                          >
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              fontWeight={"700"}
                            >
                              {item.requesterName}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {item.requesterId}
                            </Typography>
                          </StyledBodyCell>
                          <StyledBodyCell
                            onClick={() => handleRowClick(item)}
                          >{item.gogigRequestId}</StyledBodyCell>
                          <StyledBodyCell
                            onClick={() => handleRowClick(item)}
                          >
                            {dateFormat(item.updatedAt, "dd-mmm-yyyy hh:mm TT")}
                          </StyledBodyCell>
                          <StyledBodyCell
                            onClick={() => handleRowClick(item)}
                          >
                            {dateFormat(item.createdAt, "dd-mmm-yyyy hh:mm TT")}
                          </StyledBodyCell>
                          <StyledBodyCell
                            onClick={() => handleRowClick(item)}
                          >
                            {item.requestStatus}
                          </StyledBodyCell>
                        </HighlightedRow>
                      ) : (
                        <StyledTableRow key={item.gogigRequestId}>
                          <StyledBodyCell
                            onClick={() => handleRowClick(item)}
                          >
                            <Typography variant="body2" color="text.secondary">
                              {item.requestTitle || item.campaignName}
                            </Typography>
                          </StyledBodyCell>
                          <StyledBodyCell
                            onClick={() => handleRowClick(item)}
                          >
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              fontWeight={"700"}
                            >
                              {item.requesterName}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {item.requesterId}
                            </Typography>
                          </StyledBodyCell>
                          <StyledBodyCell
                            onClick={() => handleRowClick(item)}
                          >{item.gogigRequestId}</StyledBodyCell>
                          <StyledBodyCell
                            onClick={() => handleRowClick(item)}
                          >
                            {dateFormat(item.updatedAt, "dd-mmm-yyyy hh:mm TT")}
                          </StyledBodyCell>
                          <StyledBodyCell
                            onClick={() => handleRowClick(item)}
                          >
                            {dateFormat(item.createdAt, "dd-mmm-yyyy hh:mm TT")}
                          </StyledBodyCell>
                          <StyledBodyCell
                            onClick={() => handleRowClick(item)}
                          >
                            {item.requestStatus}
                          </StyledBodyCell>
                        </StyledTableRow>
                      )
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={filteredData.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default AuditRequestDashboard;
