import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ViewListIcon from "@mui/icons-material/ViewList";
import AdminDashboard from "../RWADashboard/AdminDashboard";
import BusinessCenterTwoToneIcon from "@mui/icons-material/BusinessCenterTwoTone";
import { useAuthContext } from "../../providers/AuthProvider";
import { getGigDetailsByGigId, publishProject } from "@gogig-in/gogig-clients";

const ManageGig: React.FC = () => {
  const { gigId } = useParams<{ gigId: string }>();
  const [gigDetails, setGigDetails] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedGigId, setSelectedGigId] = useState<string | null>(null);
  const history = useNavigate();
  const { authHeader } = useAuthContext();

  const openModal = (gigId: string) => {
    setSelectedGigId(gigId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handlePublish = async () => {
    if (selectedGigId && authHeader) {
      try {
        await publishProject(selectedGigId, authHeader);
        closeModal();
        window.location.reload();
      } catch (error) {
        console.error(error);
        closeModal();
      }
    }
  };

  useEffect(() => {
    const fetchGigDetails = async () => {
      try {
        if (!gigId) {
          console.error("Gig ID is not defined");
          return;
        }
        const response: any = await getGigDetailsByGigId(gigId);
        setGigDetails(response.data);
      } catch (error) {
        console.error("Error fetching gig details", error);
      }
    };
    fetchGigDetails();
  }, [gigId]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleEditGig = () => {
    history("/create-gig/basic-details", {
      state: {
        gigId: gigId,
      },
    });
  };

  return (
    <Container sx={{ marginBottom: "40px" }}>
      <div>
        {gigDetails && (
          <Box sx={{ padding: "20px 5px 0px 15px" }}>
            <div>
              <Box sx={{ display: "flex" }}>
                {gigDetails?.companyLogo ? (
                  <img
                    src={gigDetails.companyLogo}
                    alt={gigDetails.companyName}
                    style={{
                      maxWidth: "132px",
                      height: "60px",
                      marginRight: "10px",
                    }}
                  />
                ) : (
                  <BusinessCenterTwoToneIcon
                    style={{
                      width: "100%",
                      height: "100%",
                      maxWidth: "40px",
                      maxHeight: "40px",
                    }}
                  />
                )}
                <Typography variant="h2" sx={{ margin: "auto 10px" }}>
                  {gigDetails.gigTitle}
                </Typography>
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <Typography sx={{ marginTop: "10px" }}>
                    <strong>Gig Status: </strong>
                    {gigDetails.status}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  {gigDetails.status !== "draft" && (
                    <Typography sx={{ marginTop: "10px" }}>
                      <strong>
                        Number of Applicants: {gigDetails.numberOfApplicants}
                      </strong>
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} sx={{ textAlign: "right" }}>
                  <Button
                    onClick={handleEditGig}
                    variant="outlined"
                    sx={{
                      borderRadius: "22px",
                      marginBottom: "-25px",
                      marginRight: "15px",
                    }}
                  >
                    Edit Gig
                  </Button>
                  {gigDetails.status === "draft" && (
                    <Button
                      variant="contained"
                      onClick={() => openModal(gigDetails.gigId)}
                      style={{
                        color: "white",
                        backgroundColor: "secondary",
                        borderRadius: "22px",
                        marginBottom: "-25px",
                        marginRight: "15px",
                      }}
                    >
                      Publish Gig
                    </Button>
                  )}
                  <Link to={`/manage-gigs`}>
                    <Button
                      variant="outlined"
                      endIcon={<CloseIcon />}
                      sx={{
                        borderRadius: "22px",
                        marginBottom: "-25px",
                        marginRight: "15px",
                      }}
                    >
                      Close
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </div>
            <Typography variant="caption">
              Created on {formatDate(gigDetails.createdAt)}
            </Typography>
          </Box>
        )}
      </div>
      <Divider
        style={{
          margin: "2px 10px 10px 10px",
          backgroundColor: "#000",
          height: "3px",
        }}
      />
      <Paper
        elevation={3}
        style={{
          flex: 1,
          marginBottom: "20px",
          padding: "20px 20px 0 20px",
          backgroundColor: "white",
          borderRadius: "15px",
          border: "0.5px solid grey",
        }}
      >
        {gigDetails && (
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <Typography>
                  <strong>Category: </strong>{" "}
                  {gigDetails.category ? gigDetails.category : "not available"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography>
                  <strong>Location:</strong>{" "}
                  {gigDetails.location ? gigDetails.location : "not available"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Typography>
                  <strong>Languages: </strong>
                  {gigDetails.communicationLanguages
                    ? gigDetails.communicationLanguages
                    : "NA"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography>
                  <strong>Gig Start Date: </strong>{" "}
                  {gigDetails.gigStartDate !== "0000-00-00"
                    ? formatDate(gigDetails.gigStartDate)
                    : "Date is not given"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography>
                  <strong>Gig End Date:</strong>{" "}
                  {gigDetails.gigEndDate !== "0000-00-00"
                    ? formatDate(gigDetails.gigEndDate)
                    : "Date is not given"}
                </Typography>
              </Grid>
            </Grid>
            <>
              <Typography variant="h5" sx={{ marginTop: "10px" }}>
                Check and users working on this gig and manage them
              </Typography>
              <Button
                variant="contained"
                endIcon={<ViewListIcon />}
                onClick={() => {
                  history(`/manage-users-gig/${gigDetails.gigId}`);
                }}
                sx={{ marginTop: "10px" }}
              >
                Manage Users
              </Button>
              <Button
                variant="contained"
                endIcon={<ViewListIcon />}
                onClick={() => {
                  history(`/manage-supervisors/${gigDetails.gigId}`);
                }}
                sx={{ marginTop: "10px", marginLeft: "15px" }}
              >
                Manage Supervisors and Subordinates
              </Button>
            </>
            {gigDetails.status !== "draft" && (
              <>
                <Typography variant="h5" sx={{ marginTop: "10px" }}>
                  Check the result of this gig by using the button given below.
                </Typography>
                <Button
                  variant="contained"
                  endIcon={<ViewListIcon />}
                  onClick={() => {
                    history(`/gig-result/${gigDetails.gigId}`);
                  }}
                  sx={{ marginTop: "10px" }}
                >
                  View Result
                </Button>
                <Button
                  variant="contained"
                  endIcon={<ViewListIcon />}
                  onClick={() => {
                    history(`/gig-result-grouping/${gigDetails.gigId}`);
                  }}
                  sx={{ marginTop: "10px", marginLeft: "15px" }}
                >
                  View User based Report
                </Button>
              </>
            )}
            {gigDetails.category === "survey" && (
              <>
                <Typography sx={{ marginTop: "10px", fontSize: "16px" }}>
                  <strong>Manage Data</strong>
                </Typography>
                <AdminDashboard />
              </>
            )}
            <div
              style={{
                margin: "15px 0 5px 0",
                justifyContent: "flex-end",
                display: "flex",
              }}
            >
              <Typography variant="caption">
                Last updated on {formatDate(gigDetails.updatedAt)}
              </Typography>
            </div>
          </div>
        )}
      </Paper>
      <Dialog open={isModalOpen} onClose={closeModal}>
        <DialogTitle>Confirm Publish</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to publish this gig?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handlePublish} color="primary">
            Confirm Publish
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ManageGig;
