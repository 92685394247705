import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  styled,
  Typography,
  Box,
  Button,
  Avatar,
  Checkbox,
  TextField,
  Modal,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CustomSnackbar from "../operationRolePages/CustomSnackbar";
import { useAuthContext } from "../../providers/AuthProvider";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import dateFormat from "dateformat";
import { sendNotification } from "@gogig-in/gogig-clients";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    padding: "10px 15px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0 6px",
  },
}));

const FirstColumnStyledTableCell = styled(StyledTableCell)({
  borderRight: "1px solid #ddd",
  width: "240px",
  height: "50px",
});

interface ManageUsersUIProps {
  gigId: string | undefined;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  searchResults: any[];
}

const ManageSupervisorUI: React.FC<ManageUsersUIProps> = ({
  gigId,
  searchTerm,
  setSearchTerm,
  searchResults,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [title, setTitle] = useState<string | null>(null);
  const [body, setBody] = useState<string | null>(null);
  const { authHeader } = useAuthContext();
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const navigate = useNavigate();

  const handleSendNotification = () => {
    if (selectedRows.length > 0) {
      setOpenModal(true);
    } else {
      handleSnackbar("error", "Select the user to send notification.");
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  function stringToColor(string: string | null) {
    if (string == null) return "#000";
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  function stringAvatar(firstName: string, lastName: string) {
    const avatarText = (
      (firstName ? firstName[0] : "") + (lastName ? lastName[0] : "")
    ).toUpperCase();

    return {
      sx: {
        bgcolor: stringToColor(firstName),
      },
      children: avatarText || "",
    };
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (severity: any, message: string) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSend = async () => {
    try {
      if (authHeader) {
        const formData = {
          title: title,
          body: body,
          userIds: selectedRows.map((row) => row.userId),
        };
        await sendNotification(formData, authHeader);
        handleSnackbar("success", "Notification sent successfully.");
        setOpenModal(false);
        setTitle(null);
        setBody(null);
        setSelectedRows([]);
      }
    } catch (e) {
      console.error(e);
      handleSnackbar("error", "Error sending notification. Please try again.");
    }
  };

  const handleCheckboxChange = (row: any) => {
    setSelectedRows((prevSelectedRows) => {
      const updatedRows = [...prevSelectedRows];
      const selectedIndex = updatedRows.findIndex(
        (selectedRow) => selectedRow.userId === row.userId
      );

      if (selectedIndex === -1) {
        updatedRows.push(row);
      } else {
        updatedRows.splice(selectedIndex, 1);
      }

      return updatedRows;
    });
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedRows(searchResults);
    } else {
      setSelectedRows([]);
    }
  };

  const handleViewTeam = (userId: string) => {
    const supervisorId = userId;
    if (gigId) {
      navigate(`/manage-subordinates/${gigId}/${supervisorId}`);
    } else {
      navigate(`/manage-subordinate/${supervisorId}`);
    }
  };

  return (
    <Container sx={{ marginTop: "30px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "15px",
        }}
      >
        <Typography variant="h4">Manage Supervisors</Typography>
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          size="small"
        />
        <Button
          variant="text"
          endIcon={<NotificationsIcon />}
          onClick={handleSendNotification}
        >
          Send Notification
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                <Checkbox
                  checked={selectedRows.length === searchResults.length}
                  onChange={handleSelectAll}
                />
                User Name
              </TableCell>
              <TableCell>Phone No.</TableCell>
              <TableCell>Email-Id</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>Notification</TableCell>
              <TableCell>Activity</TableCell>
              <TableCell>View Team(count)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchResults.map((userDetails: any) => (
              <TableRow
                key={userDetails.userId}
                sx={{ backgroundColor: "#FEFDFD" }}
              >
                <FirstColumnStyledTableCell>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      maxWidth: "320px",
                      padding: "5px 0px",
                    }}
                  >
                    <Checkbox
                      checked={selectedRows.some(
                        (selectedRow) =>
                          selectedRow.userId === userDetails.userId
                      )}
                      onChange={() => handleCheckboxChange(userDetails)}
                    />
                    <Avatar
                      {...stringAvatar(
                        userDetails.firstName,
                        userDetails.lastName
                      )}
                      src={userDetails?.profilePicture}
                      sx={{
                        width: 36,
                        height: 36,
                        mr: 1,
                        bgcolor: stringToColor(
                          userDetails.firstName + " " + userDetails.lastName
                        ),
                      }}
                    >
                      {userDetails.firstName &&
                        userDetails.firstName.trim() && (
                          <Typography>
                            {userDetails.firstName
                              .trim()
                              .charAt(0)
                              .toUpperCase()}
                          </Typography>
                        )}
                    </Avatar>
                    <Typography
                      variant="h6"
                      sx={{
                        marginLeft: "8px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {userDetails.firstName && userDetails.lastName ? (
                        `${userDetails.firstName} ${userDetails.lastName}`
                      ) : (
                        <Typography variant="body2">.....</Typography>
                      )}
                    </Typography>
                  </Box>
                </FirstColumnStyledTableCell>
                <StyledTableCell>
                  {userDetails.phoneNumber ? (
                    userDetails.phoneNumber
                  ) : (
                    <Typography variant="body2">.....</Typography>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {userDetails.emailId ? (
                    userDetails.emailId
                  ) : (
                    <Typography variant="body2">.....</Typography>
                  )}
                </StyledTableCell>
                <StyledTableCell>{userDetails.roleName}</StyledTableCell>
                <StyledTableCell>
                  {userDetails.gender ? (
                    userDetails.gender
                  ) : (
                    <Typography variant="body2">.....</Typography>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {userDetails.token ? (
                    <DoneOutlineIcon
                      sx={{ color: "green", margin: "auto", display: "flex" }}
                    />
                  ) : (
                    <CloseIcon
                      sx={{ color: "red", margin: "auto", display: "flex" }}
                    />
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {userDetails.gender ? (
                    <>
                      {`Created at: ${dateFormat(
                        userDetails.createdAt,
                        "mmm d, yyyy, h:MM TT"
                      )}`}
                      <br />
                      {`Updated at: ${dateFormat(
                        userDetails.updatedAt,
                        "mmm d, yyyy, h:MM TT"
                      )}`}
                    </>
                  ) : (
                    <Typography variant="body2">.....</Typography>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                    size="medium"
                    onClick={() => handleViewTeam(userDetails.userId)}
                    sx={{ textTransform: "none" }}
                  >
                    View Team ({userDetails.subordinateCount})
                  </Button>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h5">Send Notification</Typography>
          <TextField
            label="Title"
            variant="outlined"
            fullWidth
            margin="normal"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            label="Body"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            margin="normal"
            value={body}
            onChange={(e) => setBody(e.target.value)}
          />
          <Button onClick={handleSend}>Send</Button>
          <Button onClick={handleCloseModal}>Cancel</Button>
        </Box>
      </Modal>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />
    </Container>
  );
};

export default ManageSupervisorUI;
