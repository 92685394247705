import { VehicleAuditFromResponse } from "../components/vendors/vehicleAuditComponents/types";
import { SpotrueApiBaseUrl } from "./baseUrls";
import { fetchWrapper } from "@gogig-in/gogig-clients";

export async function getVehicleAuditData(authHeader: string, gogigReqId: string) {
  const response = await fetchWrapper(
    `${SpotrueApiBaseUrl}/getVehicleAuditData?gogigReqId=${gogigReqId}`,
    {
      method: "GET",
      headers:{
        Authorization: authHeader,
      },
    }
  );
  const vehicleAuditData = response.data as VehicleAuditFromResponse[];

  return vehicleAuditData.map((vehicle) => {
    const parsedReasonForRecheck = JSON.parse(vehicle.reasonForRecheck);

    return {
      ...vehicle,
      reasonForRecheck: parsedReasonForRecheck,
    };
  });
}
