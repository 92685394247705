import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SpotrueApiBaseUrl } from "./baseUrls";
import {
  UpdateVehicleInterface,
  VehicleAuditFromResponse,
} from "../components/vendors/vehicleAuditComponents/types";

export async function updateVehicleAuditData(
  authHeader:string,
  gogigReqId: string,
  preprocessDetails: UpdateVehicleInterface
) {
  const response = await fetchWrapper(
    `${SpotrueApiBaseUrl}/updateVehicleAuditData?gogigReqId=${gogigReqId}&vehicleAuditId=${preprocessDetails.vehicleAuditId}`,
    {
      method: "POST",
      headers:{
        Authorization: authHeader,
      },
      body: {
        ...preprocessDetails,
        reasonForRecheck: JSON.stringify(
          preprocessDetails.reasonForRecheck || []
        ),
      },
    }
  );
  const vehicleAuditData = response.data as VehicleAuditFromResponse;

  const parsedReasonForRecheck = JSON.parse(vehicleAuditData.reasonForRecheck);

  return {
    ...vehicleAuditData,
    reasonForRecheck: parsedReasonForRecheck,
  } as UpdateVehicleInterface;
}
