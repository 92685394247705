import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Button,
  Container,
  Box,
  CircularProgress,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import { SaveAlt as SaveAltIcon } from "@mui/icons-material";
import { useAuthContext } from "../../providers/AuthProvider";
import dateFormat from "dateformat";
import { Transaction } from "./WithdrawTypes";
import { getListOfWithdrawalRequests } from "@gogig-in/gogig-clients";
import { downloadBankUploadSheet } from "../../clients/downloadBankUploadSheet";

const WithdrawRequestList: React.FC = () => {
  const { authHeader } = useAuthContext();
  const [withdrawList, setWithdrawList] = useState<Transaction[]>([]);
  const [selectedWithdrawId, setSelectedWithdrawIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataLoading, setDownloadLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (authHeader) {
          const response: any = await getListOfWithdrawalRequests(authHeader);
          setWithdrawList(response.data);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    if (authHeader) {
      fetchData();
    }
  }, [authHeader]);

  const handleCheckboxChange = (requestId: string) => {
    if (selectedWithdrawId.includes(requestId)) {
      setSelectedWithdrawIds(
        selectedWithdrawId.filter((id) => id !== requestId)
      );
    } else {
      setSelectedWithdrawIds([...selectedWithdrawId, requestId]);
    }
  };

  const handleDownload = async () => {
    try {
      setDownloadLoading(true);
      // Step 1: Fetch data from the server
      const res: any = await downloadBankUploadSheet(selectedWithdrawId);
      // Step 2: Check if the response status is 200 (OK) and data is present
      if (res.data) {
        // Step 3: Extract binary data from the response and convert it to an ArrayBuffer
        const binaryDataBuffer = res.data.data;
        const bufferArray = new Uint8Array(binaryDataBuffer).buffer;

        // Step 4: Create a Blob from the ArrayBuffer, specifying the file type (MIME type)
        const blob = new Blob([bufferArray], {
          type: "octet/stream", // Specify the MIME type of the file
        });

        // Step 5: Create a download link for the Blob
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;

        // Step 6: Set the download attribute and trigger the download
        a.download = "icici_list.xlsx";
        document.body.appendChild(a);
        a.click();

        // Step 7: Clean up the temporary URL
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      } else {
        // Step 2 (Error): Handle the case where the response status is not 200 or data is not present
        alert("Something went wrong!");
        setDownloadLoading(false);
      }
    } catch (error) {
      // Step 2 (Error): Handle any other errors that may occur during processing
      alert("Something went wrong");
      setDownloadLoading(false);
    } finally {
      setDownloadLoading(false);
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#5A5959",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  return (
    <Container className="queries-container">
      <Box display={"flex"} justifyContent={"space-between"} my={2}>
        <h2>Withdraw Request List</h2>
        <Button
          variant="contained"
          color="primary"
          startIcon={
            dataLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <SaveAltIcon />
            )
          }
          onClick={() => handleDownload()}
          disabled={selectedWithdrawId.length === 0 || dataLoading}
          style={{ marginTop: "20px" }}
        >
          {dataLoading ? (
            <Typography>Preparing Download...</Typography>
          ) : (
            "Download Selected"
          )}
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
        <Table stickyHeader aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Checkbox
                  checked={selectedWithdrawId.length === withdrawList.length}
                  indeterminate={
                    selectedWithdrawId.length > 0 &&
                    selectedWithdrawId.length < withdrawList.length
                  }
                  onChange={() => {
                    if (selectedWithdrawId.length === withdrawList.length) {
                      setSelectedWithdrawIds([]);
                    } else {
                      setSelectedWithdrawIds(
                        withdrawList.map((transaction) => transaction.requestId)
                      );
                    }
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>Requested by</StyledTableCell>
              <StyledTableCell>Available balance(₹)</StyledTableCell>
              <StyledTableCell>Requested amount(₹)</StyledTableCell>
              <StyledTableCell>Requested account</StyledTableCell>
              <StyledTableCell>Requested date</StyledTableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableRow>
              <TableCell colSpan={10}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "100px",
                  }}
                >
                  <CircularProgress size={32} color="inherit" />
                </Box>
              </TableCell>
            </TableRow>
          ) : (
            <TableBody sx={{ backgroundColor: "white" }}>
              {withdrawList.map((transaction) => (
                <TableRow key={transaction.requestId}>
                  <TableCell>
                    <Checkbox
                      checked={selectedWithdrawId.includes(
                        transaction.requestId
                      )}
                      onChange={() =>
                        handleCheckboxChange(transaction.requestId)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {transaction.firstName + " " + transaction.lastName}
                    <br />
                    {transaction.emailId}
                  </TableCell>
                  <TableCell>{transaction.availableBalance}</TableCell>
                  <TableCell>₹ {transaction.amount}</TableCell>
                  <TableCell>
                    <span style={{ fontWeight: "600" }}>Acc No.</span>
                    {" - "}
                    {transaction.accountNo}
                    <br />
                    <span style={{ fontWeight: "600" }}>Bank IFSC</span>
                    {" - "}
                    {transaction.ifsc}
                  </TableCell>
                  <TableCell>
                    {dateFormat(
                      transaction.createdAt,
                      "dd-mm-yyyy, h:MM:ss TT"
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Container>
  );
};

export default WithdrawRequestList;
