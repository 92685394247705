import { Grid, Box, Typography, Divider } from "@mui/material";
import { tabInstructions } from "./UnknownVehicleDataUI";

interface InstructionsProps {
  tabIndex: number;
}
export const UnknownVehicleDataInstructions: React.FC<InstructionsProps> = ({
  tabIndex,
}) => {
  return (
    <Grid item xs={12} md={4}>
      <Box
        sx={{
          position: "sticky",
          top: "90px", // Adjust to align with tabs' height
          backgroundColor: "#f9f9f9",
          borderRadius: 2,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          p: 3,
          textAlign: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", mb: 2, color: "#333" }}
        >
          Instructions
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Typography variant="body1" color="textSecondary">
          {tabInstructions[tabIndex]}
        </Typography>
      </Box>
    </Grid>
  );
};
