import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SpotrueApiBaseUrl } from "./baseUrls";

export default function startFileProcessing(
  authHeader:string,
  gogigReqId: string,
  vehicleClass: string
) {
  const url = `${SpotrueApiBaseUrl}/startFileProcessing?gogigReqId=${gogigReqId}`;
  return fetchWrapper(url, {
    method: "POST",
    headers: {
      Authorization: authHeader,
    },
    body: {
      vehicleClass: vehicleClass,
    },
  });
}
