import React, { FC, useState } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../providers/AuthProvider";
import { guestUserNavigation } from "./guestUserNavigation";
import { NavigationItem } from "./types";
import UserRoleChip from "../header/UserRoleChip";
import { userRoles } from "../../types/userRoles";

const getUserNavigationByRole = (
  role: string | undefined
): NavigationItem[] => {
  switch (role) {
    default:
      return guestUserNavigation;
  }
};

interface NavigationProps {
  closeMobileMenu: () => void;
}

const Navigation: FC<NavigationProps> = ({ closeMobileMenu }) => {
  const history = useNavigate();
  const handleNavigation = (destination: string) => {
    history(destination);

    closeMobileMenu();
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { userDetails } = useAuthContext();
  const userRole = userDetails?.role;

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (path: string) => {
    handleNavigation(path);
    handleMenuClose();
  };
  

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
     {(userRole === userRoles.operation || userRole === userRoles.admin) && (
        <UserRoleChip
          userDetails={{ role: userRole }}
          userRoles={{ admin: userRoles.admin, operations: userRoles.operation }}
        />
      )}
      {getUserNavigationByRole(userRole).map(({ path, label, dropdown }) => (
        <>
          <Box
            key={label}
            onClick={
              dropdown ? handleMenuClick : () => handleNavigation(path || "")
            }
            sx={{
              cursor: "pointer",
              fontWeight: 600,
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              ml: 3,
              mr: 3,
              mb: { xs: 3, md: 0 },
              fontSize: { xs: "1.2rem", md: "1.2rem" },
              "&:hover": {
                color: "primary.main",
                "& > div": {
                  display: "block",
                },
              },
            }}
            className={
              path === "/BrowseJobs"
                ? "browseGigs-website"
                : path === "/task/gigHistory"
                ? "dashboard-website"
                : ""
            }
          >
            {/* Wrap the label in an anchor tag */}
            <Link
              to={path || "#"}
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {label}
            </Link>
          </Box>
          {dropdown && (
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {dropdown.map((item: any) => (
                <MenuItem
                  key={item.path}
                  onClick={() => handleMenuItemClick(item.path)}
                >
                  {item.label}
                </MenuItem>
              ))}
            </Menu>
          )}
        </>
      ))}
    </Box>
  );
};

export default Navigation;
