import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Paper,
  Container,
  TextField,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import { Task } from "./TaskReview";
import CloseIcon from "@mui/icons-material/Close";
import { useAuthContext } from "../../providers/AuthProvider";
import { reviewTask } from "@gogig-in/gogig-clients";

interface TaskModalProps {
  isOpen: boolean;
  handleClose: () => void;
  taskData: Task;
}

const ReviewModal: React.FC<TaskModalProps> = ({
  isOpen,
  handleClose,
  taskData,
}) => {
  const { authHeader } = useAuthContext();
  const [formData, setFormData] = useState(parseTaskData(taskData));
  const [showRejectConfirmation, setShowRejectConfirmation] = useState(false);
  const [rejectReason, setRejectReason] = useState("");

  const handleApprove = async (taskId: string) => {
    try {
      if (authHeader) {
        const response = await reviewTask(
          { ...formData, review: "approved" },
          authHeader,
          taskId
        );
        const responseMessage = response.data;
        if (
          responseMessage &&
          typeof responseMessage === "string" &&
          !responseMessage.includes("undefined")
        ) {
          handleClose();
        } else {
          console.error("Error reviewing task:", response);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleReject = () => {
    setShowRejectConfirmation(true);
  };

  const handleConfirmReject = async (taskId: string) => {
    try {
      if (authHeader) {
        if (!rejectReason) {
          console.error("Please provide a reason for rejection");
          return;
        }

        const rejectData: any = {
          ...formData,
          review: "rejected",
          rejectCause: rejectReason,
        };

        const response = await reviewTask(rejectData, authHeader, taskId);
        const responseMessage = response.data;
        if (
          responseMessage &&
          typeof responseMessage === "string" &&
          !responseMessage.includes("undefined")
        ) {
          handleClose();
        } else {
          console.error("Error rejecting task:", response);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setShowRejectConfirmation(false);
      setRejectReason("");
    }
  };

  const handleCloseRejectConfirmation = () => {
    setShowRejectConfirmation(false);
    setRejectReason("");
  };

  const handleInputChange = (key: string, value: string) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  return (
    <Container sx={{ maxWidth: "100%" }}>
      <Dialog open={isOpen} onClose={handleClose} fullWidth>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "10px",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" sx={{ paddingLeft: "30px" }}>
            Task Details
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent sx={{ padding: "5px" }}>
          <Container
            sx={{ maxWidth: "xl", maxHeight: "70vh", overflowY: "auto" }}
          >
            {Object.entries(formData).map(([key, value], index) => (
              <Paper key={index} elevation={3} sx={{ p: 2, mb: 2 }}>
                {key === "imageUrl1" ||
                key === "imageUrl2" ||
                key === "imageUrl3" ? (
                  <img
                    src={value as string}
                    alt={`${key.charAt(key.length - 1)}`}
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                ) : key === "latitude" ||
                  key === "longitude" ||
                  key === "dataCollectionDate" ? (
                  <div>
                    {key}: {value as string}
                  </div>
                ) : (
                  <TextField
                    label={key}
                    value={value as string}
                    fullWidth
                    variant="outlined"
                    onChange={(e) => handleInputChange(key, e.target.value)}
                  />
                )}
              </Paper>
            ))}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px 30px",
              }}
            >
              <Button
                onClick={() => handleApprove(taskData.taskId)}
                variant="outlined"
                sx={{
                  width: { xs: "48%", sm: "100%" },
                  maxWidth: "120px",
                  marginRight: { xs: "2%", sm: 0 },
                }}
              >
                APPROVE
              </Button>
              <Button
                onClick={handleReject}
                variant="outlined"
                color="error"
                sx={{
                  width: { xs: "48%", sm: "100%" },
                  maxWidth: "120px",
                  "&:hover": {
                    backgroundColor: "#ffcdd2",
                  },
                }}
              >
                REJECT
              </Button>
            </Box>
          </Container>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showRejectConfirmation}
        onClose={handleCloseRejectConfirmation}
      >
        <DialogTitle>Confirm Rejection</DialogTitle>
        <DialogContent>
          <TextField
            label="Reason for Rejection"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={rejectReason}
            onChange={(e) => setRejectReason(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Button
            onClick={() => handleConfirmReject(taskData.taskId)}
            variant="outlined"
            sx={{ mt: 2 }}
            color="error"
          >
            CONFIRM REJECTION
          </Button>
          <Button
            onClick={handleCloseRejectConfirmation}
            variant="outlined"
            sx={{ mt: 2, ml: 2 }}
          >
            CANCEL
          </Button>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default ReviewModal;

function parseTaskData(data: Task) {
  const formData: any = {};
  let imageCount = 1;

  Object.entries(data).forEach(([key, value]) => {
    if (key === "taskId") return;
    if (key === "updatedAt") {
      const updatedAtDate = new Date(value as string);
      formData.dataCollectionDate = updatedAtDate.toLocaleDateString();
      return;
    }
    const stringValue =
      typeof value === "string" ? value : JSON.stringify(value);
    const parsedValue = JSON.parse(stringValue);

    if (
      parsedValue.type === "text" &&
      parsedValue.question.toLowerCase().includes("society") &&
      parsedValue.question.toLowerCase().includes("name")
    ) {
      formData.societyName = parsedValue.answer;
    } else if (
      parsedValue.type === "text" &&
      parsedValue.question.toLowerCase().match(/price|prize/i)
    ) {
      if (typeof parsedValue.answer === "string") {
        const priceRange = parsedValue.answer
          .split("-")
          .map((item: string) => item.trim());
        formData.minPrice = parseFloat(priceRange[0]);
        formData.maxPrice = parseFloat(
          priceRange.length > 1 ? priceRange[1] : priceRange[0]
        );
      }
    } else if (parsedValue.type === "address" && parsedValue.answer) {
      formData.addressLine = parsedValue.answer.address;
      if (parsedValue.answer.coordinates) {
        const coordinates = parsedValue.answer.coordinates
          .split(",")
          .map((coord: string) => coord.trim());
        formData.latitude = coordinates[0];
        formData.longitude = coordinates[1];
      }
    } else if (parsedValue.type === "person") {
      const targetData = parsedValue.question
        .toLowerCase()
        .includes("contact person 2")
        ? "additionalContactPerson"
        : "contactPerson";
      const personData = [
        "Name",
        "Email",
        "Phone Number",
        "Designation",
        "Address",
      ];
      for (const field of personData) {
        const sourceField = field.replace(" ", "");
        if (parsedValue.answer[field]) {
          formData[`${targetData}${sourceField}`] = parsedValue.answer[field];
        }
      }
    } else if (
      parsedValue.type === "number" &&
      parsedValue.question.match(/flats|apartments/i)
    ) {
      formData.numberOfFlats = parseInt(parsedValue.answer);
    } else if (parsedValue.type === "image") {
      const imageUrlKey = `imageUrl${imageCount}`;
      formData[imageUrlKey] = parsedValue.answer;
      imageCount++;
      if (imageCount > 3) {
        imageCount = 1;
      }
    } else if (
      parsedValue.type === "text" &&
      (parsedValue.question.includes("comment") ||
        parsedValue.question.includes("comments"))
    ) {
      formData.comments = parsedValue.answer;
    }
  });

  return formData;
}
