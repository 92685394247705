import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Container,
  Grid,
  Typography,
  FormControlLabel,
  IconButton,
  Select,
  MenuItem,
  TextField,
  Box,
  styled,
  LinearProgress,
  linearProgressClasses,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { taskQuestionnaireArray } from "./TaskQuestionnaireArray";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../providers/AuthProvider";
import {
  getGigDetailsByGigId,
  updateGigDetails,
} from "@gogig-in/gogig-clients";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const TaskQuestionnaire: React.FC = () => {
  const [questions, setQuestions] = useState<Array<any>>([]);
  const location = useLocation();
  const category = location.state?.category;
  const gigId = location.state?.gigId;
  const { authHeader } = useAuthContext();
  const history = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (category && gigId) {
        setQuestions(
          Object.values(
            taskQuestionnaireArray[
              category as keyof typeof taskQuestionnaireArray
            ]
          )
        );
      } else if (gigId) {
        try {
          const gigDetailsResponse: any = await getGigDetailsByGigId(gigId);

          const questionaireJson = JSON.parse(
            gigDetailsResponse.data.questionaire || "{}"
          );

          setQuestions(Object.values(questionaireJson));
        } catch (error) {
          console.error("Error fetching gig details", error);
        }
      }
    };

    fetchData();
  }, [category, gigId]);

  const handleQuestionChange = (index: number, field: string, value: any) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][field] = value;

    if (
      field === "type" &&
      (value === "text" ||
        value === "number" ||
        value === "address" ||
        value === "boolean")
    ) {
      updatedQuestions[index].answer = [];
    }

    setQuestions(updatedQuestions);
  };

  const addQuestion = () => {
    const newQuestion = {
      type: "text",
      answer: [],
      question: "",
      isMandatory: true,
    };
    setQuestions([...questions, newQuestion]);
  };

  const removeQuestion = (index: number) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };

  const addOption = (index: number) => {
    const updatedQuestions = [...questions];
    const newOption = "";

    if (
      updatedQuestions[index].type === "singleChoice" ||
      updatedQuestions[index].type === "multiChoice"
    ) {
      updatedQuestions[index].answer.push(newOption);
    } else {
      updatedQuestions[index].options.push(newOption);
    }

    setQuestions(updatedQuestions);
  };

  const removeOption = (questionIndex: number, optionIndex: number) => {
    const updatedQuestions = [...questions];

    if (
      updatedQuestions[questionIndex].type === "singleChoice" ||
      updatedQuestions[questionIndex].type === "multiChoice"
    ) {
      updatedQuestions[questionIndex].answer.splice(optionIndex, 1);
    } else {
      updatedQuestions[questionIndex].options.splice(optionIndex, 1);
    }

    setQuestions(updatedQuestions);
  };

  const generateJson = async () => {
    setLoading(true);
    const questionJson: { [key: string]: any } = {};

    questions.forEach((question, index) => {
      const { type, question: questionText, isMandatory, answer } = question;

      questionJson[`Q${index + 1}`] = {
        type,
        answer:
          type === "singleChoice" || type === "multiChoice"
            ? answer.filter(Boolean)
            : type === "text" ||
              type === "number" ||
              type === "address" ||
              type === "boolean" ||
              type === "image"
            ? []
            : type === "person"
            ? answer.map((item: string) => item)
            : undefined,
        question: questionText,
        isMandatory: isMandatory ? 1 : 0,
      };
    });
    const taskForm = JSON.stringify(questionJson, null, 2);
    const formData = new FormData();
    if (authHeader) {
      formData.append("questionaire", taskForm);
      try {
        await updateGigDetails(formData, authHeader, gigId);
        history(`/review/${gigId}`);
      } catch (error) {
        console.error("Error updating gig details:", error);
      } finally {
        setLoading(false);
      }
    } else {
      console.error("No authHeader available");
    }
  };

  const handleBack = () => {
    history("/create-gig/pre-acceptance-form", {
      state: {
        gigId: gigId,
      },
    });
  };

  const handleNext = () => {
    history(`/review/${gigId}`);
  };

  return (
    <Container sx={{ marginTop: 2, marginBottom: "20px" }}>
      <Box
        sx={{
          justifyContent: "space-between",
          display: "flex",
          margin: "0px 5px",
        }}
      >
        <Button onClick={handleBack}>Back</Button>
        <Typography variant="h6" sx={{ margin: "auto" }}>
          Step 3 out of 4
        </Typography>
        <Button onClick={handleNext}>Next</Button>
      </Box>
      <BorderLinearProgress
        variant="determinate"
        value={75}
        sx={{ marginBottom: "10px" }}
      />
      <Typography variant="h3" sx={{ padding: "10px" }}>
        Questions to be shown in the task details page.
      </Typography>
      <form>
        <Grid container spacing={2}>
          {questions.map((question, index) => (
            <Grid key={index} item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={3}>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Select
                      size="small"
                      fullWidth
                      value={question.type || "text"}
                      onChange={(e) =>
                        handleQuestionChange(index, "type", e.target.value)
                      }
                    >
                      <MenuItem value="text">Text</MenuItem>
                      <MenuItem value="singleChoice">Single Choice</MenuItem>
                      <MenuItem value="multiChoice">Multi Choice</MenuItem>
                      <MenuItem value="boolean">Boolean</MenuItem>
                      <MenuItem value="number">Number</MenuItem>
                      <MenuItem value="image">Image</MenuItem>
                      <MenuItem value="person">Person</MenuItem>
                      <MenuItem value="address">Address</MenuItem>
                    </Select>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(question.isMandatory)}
                          onChange={(e) =>
                            handleQuestionChange(
                              index,
                              "isMandatory",
                              e.target.checked
                            )
                          }
                          sx={{ marginLeft: "10px" }}
                        />
                      }
                      label="Mandatory"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <TextField
                      size="small"
                      label={`Question ${index + 1}`}
                      fullWidth
                      value={question.question || ""}
                      onChange={(e) =>
                        handleQuestionChange(index, "question", e.target.value)
                      }
                      multiline
                      maxRows={2}
                    />
                    <IconButton onClick={() => removeQuestion(index)}>
                      <DeleteForeverIcon />
                    </IconButton>
                  </Box>
                  {question.type === "singleChoice" ||
                  question.type === "multiChoice" ? (
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {question.answer.map(
                        (option: string, optionIndex: number) => (
                          <div
                            key={optionIndex}
                            style={{
                              marginRight: "10px",
                              marginBottom: "5px",
                              marginTop: "10px",
                            }}
                          >
                            <TextField
                              label={`Option ${optionIndex + 1}`}
                              value={option}
                              onChange={(e) => {
                                const updatedQuestions = [...questions];
                                updatedQuestions[index].answer[optionIndex] =
                                  e.target.value;
                                setQuestions(updatedQuestions);
                              }}
                              size="small"
                            />
                            <IconButton
                              onClick={() => removeOption(index, optionIndex)}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </div>
                        )
                      )}
                      <IconButton onClick={() => addOption(index)}>
                        <AddCircleOutlineIcon />
                        <Typography>Add Option</Typography>
                      </IconButton>
                    </div>
                  ) : null}
                  {question.type === "person" && (
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={question.answer.includes("Name")}
                            onChange={() =>
                              handleQuestionChange(
                                index,
                                "answer",
                                toggleCheckbox(question.answer, "Name")
                              )
                            }
                          />
                        }
                        label="Name"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={question.answer.includes("Phone Number")}
                            onChange={() =>
                              handleQuestionChange(
                                index,
                                "answer",
                                toggleCheckbox(question.answer, "Phone Number")
                              )
                            }
                          />
                        }
                        label="Phone Number"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={question.answer.includes("Email")}
                            onChange={() =>
                              handleQuestionChange(
                                index,
                                "answer",
                                toggleCheckbox(question.answer, "Email")
                              )
                            }
                          />
                        }
                        label="Email"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={question.answer.includes("Designation")}
                            onChange={() =>
                              handleQuestionChange(
                                index,
                                "answer",
                                toggleCheckbox(question.answer, "Designation")
                              )
                            }
                          />
                        }
                        label="Designation"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={question.answer.includes("Address")}
                            onChange={() =>
                              handleQuestionChange(
                                index,
                                "answer",
                                toggleCheckbox(question.answer, "Address")
                              )
                            }
                          />
                        }
                        label="Address"
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <IconButton onClick={addQuestion}>
                  <AddCircleOutlineIcon />
                  <Typography variant="h6">Add Question</Typography>
                </IconButton>
              </Grid>
              <Grid item>
                {loading ? (
                  <Button variant="contained" color="primary" disabled>
                    Submitting
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={generateJson}
                  >
                    Submit
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default TaskQuestionnaire;

function toggleCheckbox(array: string[], value: string) {
  return array.includes(value)
    ? array.filter((item) => item !== value)
    : [...array, value];
}
