import { Box, Grid, Typography } from "@mui/material";
import React from "react";

export const ReportDescription = () => (
  <Grid container spacing={2} sx={{  padding: 2 }}>
    <Grid item xs={6}>
      <Box sx={{ border: "1px solid black", padding: 2, borderRadius: 1 }}>
        <Typography variant="body1" fontSize={14}>
          <strong>Description:</strong> Auto branding images in Bangalore need
          to be verified.
        </Typography>
        <Typography variant="body1" fontSize={14}>
          <strong>Test Objective:</strong> To verify auto rickshaw branding
          images.
        </Typography>
        <Typography variant="body1" fontSize={14}>
          <strong>Branding Camp:</strong> Failed
        </Typography>
        <Typography variant="body1" fontSize={14}>
          <strong>Confidential Report:</strong> Each image passed 1000 times
          through the tool.
        </Typography>
      </Box>
    </Grid>

    <Grid item xs={6}>
      <Box sx={{ border: "1px solid black", padding: 2, borderRadius: 1 }}>
        <Typography variant="body1" fontSize={14}>
          <strong>Confidential Report:</strong> Each image passed 1000 times
          through the tool.
        </Typography>
      </Box>
    </Grid>
  </Grid>
);


