import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  styled,
  Typography,
  Button,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { useNavigate, useParams } from "react-router-dom";
import { getUsersWorkingOnAGig } from "@gogig-in/gogig-clients";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    padding: "10px 20px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0 16px",
  },
}));

const FirstColumnStyledTableCell = styled(StyledTableCell)({
  borderRight: "1px solid #ddd",
  width: "260px",
  height: "50px",
});

const GigWorkersByGig: React.FC = () => {
  const [gigWorkers, setGigWorkers] = useState<any[]>([]);
  const history = useNavigate();
  const { gigId } = useParams<{ gigId?: string }>();

  useEffect(() => {
    (async () => {
      try {
        if (gigId) {
          const response: any = await getUsersWorkingOnAGig(gigId);
          setGigWorkers(response.data);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [gigId]);

  return (
    <Container sx={{ marginTop: "30px" }}>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <FirstColumnStyledTableCell>User Name</FirstColumnStyledTableCell>
              <StyledTableCell>View Report</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gigWorkers.map((workingUsers) => (
              <TableRow
                key={workingUsers.userId}
                sx={{ backgroundColor: "#FEFDFD" }}
              >
                <FirstColumnStyledTableCell>
                  {workingUsers.firstName && workingUsers.lastName ? (
                    `${workingUsers.firstName} ${workingUsers.lastName}`
                  ) : (
                    <Typography variant="body2">.....</Typography>
                  )}
                </FirstColumnStyledTableCell>
                <StyledTableCell>
                  <Button
                    size="medium"
                    sx={{ textTransform: "none" }}
                    onClick={() => {
                      history(`/user-report/${gigId}/${workingUsers.userId}`, {
                        state: {
                          userName: `${workingUsers.firstName || ""} ${workingUsers.lastName || ""}`,
                        },
                      });
                    }}
                  >
                    View Report
                  </Button>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default GigWorkersByGig;
