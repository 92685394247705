import React, { useEffect, useState } from "react";
import { Alert, IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface SnackbarProps {
  open: boolean;
  onClose: () => void;
  message: string;
  severity: "success" | "error" | "warning" | "info";
  icon?: React.ReactNode;
}

const CustomSnackbar: React.FC<SnackbarProps> = ({
  open,
  onClose,
  message,
  severity,
  icon,
}) => {
  const [snackbarMessage, setSnackbarMessage] = useState(message);

  useEffect(() => {
    setSnackbarMessage(message);
  }, [message]);

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        icon={icon}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
