import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SpotrueApiBaseUrl } from "./baseUrls";

export default function sendAuditRequestMail(
  authHeader: string,
  gogigReqId: string,
  requesterId: string
) {
  const url = `${SpotrueApiBaseUrl}/sendAuditReportMail?gogigReqId=${gogigReqId}&requesterId=${requesterId}`;
  return fetchWrapper(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader,
    },
  });
}
