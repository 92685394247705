import { Box, Paper } from "@mui/material";
import React from "react";
import { ReportFooter } from "./ReportFooter";
import { ReportHeader } from "./ReportHeader";

interface ReportTemplateProps {
  children: React.ReactNode;
}

export const ReportTemplate = ({ children }: ReportTemplateProps) => {
  return (
    <Box display={"flex"} justifyContent={"center"}>
      <Box>
        <Paper
          elevation={3}
          style={{
            maxWidth: "1000px",
            backgroundColor: "#f8f8f8",
            border: "10px solid #FF7A00",
            margin: 10,
          }}
        >
          <ReportHeader />
          <Box>{children}</Box>
          <ReportFooter />
        </Paper>
      </Box>
    </Box>
  );
};
