import { getQuestionaireResponseByGigId } from "@gogig-in/gogig-clients";
import {
  Document,
  Page,
  Text,
  Image,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// Create styles
const styles = StyleSheet.create({
  page: {
    //   backgroundColor: "#d11fb6",
    //   color: "white",
  },
  section: {
    margin: 10,
    padding: 10,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
});

// Create Document Component
function PdfTestComponent() {
  const [reportData, setReportData] = useState([]);
  const { gigId } = useParams<{ gigId?: string }>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!gigId) {
          return;
        }

        const response: any = await getQuestionaireResponseByGigId(
          gigId,
          1,
          1000
        );
        const resultData = response.data;
        setReportData(resultData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [gigId]);
  return (
    <PDFViewer style={styles.viewer}>
      {/* Start of the document*/}
      <Document>
        {/*render a single page*/}
        {reportData.map((data) => (
          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
              <Text>Vehicle Number: {data["Enter Vehicle Number"]}</Text>
              <Text>Date: {data["date"]}</Text>
              <Image src={data["Upload back-right or back-left Image"]} />
            </View>
          </Page>
        ))}
      </Document>
    </PDFViewer>
  );
}
export default PdfTestComponent;
