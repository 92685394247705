import { fetchWrapper } from "@gogig-in/gogig-clients";
import { PaymentsApiBaseUrl } from "./baseUrls";

export async function downloadBankUploadSheet(formValues: string[]) {
  const body = {
    requestIds: formValues,
  };

  const url = `${PaymentsApiBaseUrl}/downloadBankUploadSheet`;
  const method = "POST";
  return fetchWrapper(url, {
    method,
    headers: {
      "Content-Type": "application/json",
    },
    body: body,
  });
}
