import JSZip from "jszip";

export const extractImagesFromPptx = async (pptxData: JSZip): Promise<string[]> => {
  const images: string[] = [];
  const downloadedImages = new Map<string, string>(); // Map to store downloaded image paths and their URLs

  // A utility function to determine MIME type based on file extension
  const getMimeType = (fileName: string): string => {
    const extension = fileName.split(".").pop()?.toLowerCase();
    switch (extension) {
      case "jpeg":
      case "jpg":
        return "image/jpeg";
      case "png":
        return "image/png";
      case "gif":
        return "image/gif";
      case "bmp":
        return "image/bmp";
      case "tiff":
        return "image/tiff";
      case "svg":
        return "image/svg+xml";
      default:
        return "application/octet-stream";
    }
  };

  // Get the slide relationships to extract images in the correct order
  const slidePaths = Object.keys(pptxData.files).filter(
    (path) => path.startsWith("ppt/slides/slide") && path.endsWith(".xml")
  );
  slidePaths.sort((a, b) => {
    const getSlideNumber = (path: string) =>
      parseInt(path.match(/slide(\d+)\.xml/)?.[1] || "0", 10);
    return getSlideNumber(a) - getSlideNumber(b);
  });

  for (const slidePath of slidePaths) {
    const slideContent = await pptxData.files[slidePath].async("string");
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(slideContent, "application/xml");

    // Find image references in the slide, excluding background images
    const imageNodes = xmlDoc.getElementsByTagName("a:blip");
    const imageNodesArray = Array.from(imageNodes);

    for (const imageNode of imageNodesArray) {
      const embedAttr = imageNode.getAttribute("r:embed");
      if (embedAttr) {
        const relsPath =
          slidePath.replace("slides/slide", "slides/_rels/slide") + ".rels";
        const relsContent = await pptxData.files[relsPath].async("string");
        const relsXml = parser.parseFromString(relsContent, "application/xml");
        const targetAttr = Array.from(
          relsXml.getElementsByTagName("Relationship")
        )
          .find((rel) => rel.getAttribute("Id") === embedAttr)
          ?.getAttribute("Target");

        if (targetAttr) {
          const imagePath = "ppt/" + targetAttr.replace("../", "");
          let imageObjectUrl;

          // Check if the image was already downloaded
          if (downloadedImages.has(imagePath)) {
            imageObjectUrl = downloadedImages.get(imagePath); // Reuse the existing URL
          } else if (pptxData.files[imagePath]) {
            const arrayBuffer = await pptxData.files[imagePath].async(
              "arraybuffer"
            );
            const mimeType = getMimeType(imagePath);
            const imageBlob = new Blob([arrayBuffer], { type: mimeType });
            imageObjectUrl = URL.createObjectURL(imageBlob);
            downloadedImages.set(imagePath, imageObjectUrl); // Store the URL
          }

          if (imageObjectUrl) {
            images.push(imageObjectUrl); // Add to the images array for every reference
          }
        }
      }
    }
  }
  console.log(`Total images extracted: ${images.length}`);
  return images;
};
