import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { colors } from "../vehicleAdmin/ProcessFlowUi";
import { Link } from "react-router-dom";
import { processAuditRequests } from "../../../clients/processAuditRequests";
import { useAuthContext } from "../../../providers/AuthProvider";
import GlobalSnackbar, { Severity } from "../../snackbarComponent/Snackbar";
import { matchVehicleAuditData } from "../../../clients/matchVehicleAuditData";
import { userRoles } from "../../../types/userRoles";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SearchIcon from "@mui/icons-material/Search";
import ListIcon from "@mui/icons-material/List";
import { vehicleClassDropDown } from "./vehicleOptionsConstants";
import { processingProgress } from "../../../clients/processingProgress";
import startFileProcessing from "../../../clients/startFileProcessing";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface ProcessDetailsCardProps {
  gogigReqId: string;
  requesterId: string;
  manualPreProcessStart: string;
  status?: string;
  isReRun?: boolean;
  fetchAuditRequest: () => Promise<void>;
}
interface ProgressResponse {
  read: number;
  unread?: number;
  total: number;
  message?: string;
}

const ProcessDetailsCard = ({
  gogigReqId,
  requesterId,
  manualPreProcessStart,
  status,
  isReRun,
  fetchAuditRequest,
}: ProcessDetailsCardProps) => {
  const { authHeader, userDetails } = useAuthContext();

  const [snackbarState, setSnackbarState] = useState<{
    message: string;
    open: boolean;
    severity: Severity;
  }>({ message: "", open: false, severity: "info" });
  const [jobId, setJobId] = useState<string>("");
  const [isJobId, setIsJobId] = useState<boolean>(false);
  const [vehicleClass, setVehicleClass] = useState<string>("");
  const [isVehicleClass, setIsVehicleClass] = useState<boolean>(true);
  const [isProcessed, setIsProcessed] = useState<boolean>(false);
  const [isMatched, setIsMatched] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [triggerAuditRequestApi, setTriggerAuditRequestApi] = useState<boolean>(
    false
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleSelectFieldChange = (
    event: SelectChangeEvent<string>,
    field: string
  ) => {
    const value = event.target.value;
    setVehicleClass(value);
    if (value === "") {
      setIsVehicleClass(false);
    } else {
      setIsVehicleClass(true);
    }
  };

  useEffect(() => {
    if (status === "processing") {
      setIsProcessed(true);
      pollForProgress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authHeader]);
  const pollForProgress = async () => {
    if (!authHeader || !gogigReqId) return;

    try {
      const progressResponse = await processingProgress(authHeader, gogigReqId);
      const {
        read,
        total,
        message,
      } = progressResponse.data as ProgressResponse;
      if (message) {
        setProgress(100);
        setSnackbarState({
          message: message,
          open: true,
          severity: "info",
        });
        setIsProcessed(false);
        setTriggerAuditRequestApi(true);
      } else {
        const progressPercentage =
          total === 0 ? 100 : Math.round((read / total) * 100);
        setProgress(progressPercentage);

        // Stop polling when progress reaches 100%
        if (progressPercentage === 100) {
          setSnackbarState({
            message: "Processing completed successfully!",
            open: true,
            severity: "success",
          });
          setIsProcessed(false);
          setTriggerAuditRequestApi(true); // Reset button state
        }
      }

      // Calculate progress percentage
    } catch (pollError) {
      console.error(pollError);
      setSnackbarState({
        message: `Error during processing: ${
          (pollError as { errorCause: string }).errorCause || "Unknown error"
        }`,
        open: true,
        severity: "error",
      });
    }
  };

  const handleProcessAuditRequest = async () => {
    if (!authHeader || !gogigReqId) return;
    try {
      setIsProcessed(true);
      setProgress(0);
      const responseMessage = await processAuditRequests(
        authHeader,
        gogigReqId,
        encodeURIComponent(requesterId)
      );
      pollForProgress();

      const { message } = responseMessage.data as { message: string };
      setSnackbarState({ message: message, open: true, severity: "success" });
    } catch (error) {
      console.error(error);
      const errorCause = (error as { errorCause: string }).errorCause;
      setSnackbarState({
        message: `Error sending file to Processing: ${errorCause}`,
        open: true,
        severity: "error",
      });
      setIsProcessed(false);
    }
  };
  const handleStartExtractingImages = async () => {
    if (!vehicleClass || vehicleClass === "") {
      setIsVehicleClass(false); // Set isVehicleClass to false
      setSnackbarState({
        message: `Vehicle class is required`,
        open: true,
        severity: "error",
      });
      return;
    }
    setIsLoading(true);
    if (!authHeader) return;
    try {
      const response = await startFileProcessing(
        authHeader,
        gogigReqId,
        vehicleClass
      );

      const { jobId } = response.data as { jobId: string };
      setJobId(jobId);
      setIsJobId(true);
      setTriggerAuditRequestApi(true);
      setSnackbarState({
        message: "Extracted successfully",
        open: true,
        severity: "success",
      });
    } catch (error) {
      console.error(error, "error fetching job id");
      setIsJobId(false);
      setSnackbarState({
        message: `Error in extracting ${error}`,
        open: true,
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarState((prevState) => ({ ...prevState, open: false }));
    if (triggerAuditRequestApi) {
      fetchAuditRequest();
    }
  };

  const handlematchVehicleData = async () => {
    if (!authHeader || !gogigReqId) return;
    try {
      setIsMatched(true);
      const matchResponse = await matchVehicleAuditData(gogigReqId, authHeader);
      const { message } = matchResponse.data as { message: string };
      setSnackbarState({ message: message, open: true, severity: "success" });
      setTriggerAuditRequestApi(true);
    } catch (error) {
      console.error(error);
      const errorCause = (error as { errorCause: string }).errorCause;
      setIsMatched(false);
      setSnackbarState({
        message: `Error in matching: ${errorCause}`,
        open: true,
        severity: "error",
      });
    }
  };

  const hasAccess = userDetails?.role === userRoles.admin;

  return (
    <Card variant="outlined" sx={{ margin: 2, boxShadow: 3 }}>
      <GlobalSnackbar
        open={snackbarState.open}
        message={snackbarState.message}
        onClose={handleSnackbarClose}
        severity={snackbarState.severity}
        vertical={"top"}
        horizontal={"center"}
      />
      <CardContent>
        <Typography variant="h5" component="h2" gutterBottom>
          File Extraction
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <FormControl
            sx={{ flex: 1 }}
            disabled={isJobId || !!manualPreProcessStart}
          >
            <InputLabel
              sx={{
                transform: "translate(14px, 10px) scale(1)",
                "&.MuiInputLabel-shrink": {
                  transform: "translate(14px, -6px) scale(0.75)",
                },
              }}
            >
              Vehicle Class
            </InputLabel>
            <Select
              label="Vehicle Class"
              defaultValue=""
              value={vehicleClass}
              onChange={(e) =>
                handleSelectFieldChange(e, "vehicleClassFromPhoto")
              }
              fullWidth
              error={!isVehicleClass}
              required
              size="small"
            >
              {Object.keys(vehicleClassDropDown).map((vehicleClass) => (
                <MenuItem key={vehicleClass} value={vehicleClass}>
                  {vehicleClassDropDown[vehicleClass]}
                </MenuItem>
              ))}
            </Select>
            {!isVehicleClass && (
              <FormHelperText>Vehicle class is required</FormHelperText>
            )}
          </FormControl>
          {isJobId && (
            <TextField
              label="job id"
              type="text"
              value={jobId}
              size="small"
              disabled
              sx={{ width: "400px" }}
            />
          )}

          <Button
            variant="contained"
            color="primary"
            onClick={handleStartExtractingImages}
            disabled={isJobId || !!manualPreProcessStart || isLoading}
            sx={{
              whiteSpace: "nowrap",
              padding: "10px 16px",
            }}
            startIcon={
              isLoading ? <CircularProgress size={20} color="inherit" /> : null
            }
          >
            {isJobId || !!manualPreProcessStart
              ? "File Extracted"
              : isLoading
              ? "File Extracting"
              : "Start Extracting File"}
          </Button>
        </Box>

        <Typography variant="h5" component="h2" gutterBottom>
          Process Details Options
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          gap={2}
          justifyContent={"space-between"}
        >
          <Button
            component={Link}
            to={`/pre-process/${gogigReqId}`}
            target="_blank"
            variant="contained"
            color="primary"
            disabled={isProcessed}
            startIcon={<MoveToInboxIcon />}
            sx={{
              border: `4px solid ${
                status === "preprocessed"
                  ? colors.completed
                  : status === "preprocessing"
                  ? isReRun
                    ? colors.rerun
                    : colors.inProgress
                  : "transparent"
              } !important`,
            }}
          >
            Start Pre-processing
          </Button>
          {hasAccess && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem", // Add spacing between components
                }}
              >
                {/* Button */}
                <Button
                  onClick={handleProcessAuditRequest}
                  variant="contained"
                  color="primary"
                  startIcon={!isProcessed && <PlayArrowIcon />}
                  disabled={isProcessed}
                  sx={{
                    border: `4px solid ${
                      isProcessed || status === "processing"
                        ? colors.inProgress
                        : status === "processed"
                        ? colors.completed
                        : "transparent"
                    } !important`,
                    // Center text alignment
                  }}
                >
                  <Typography
                    sx={{
                      color: isProcessed ? "#FF9800" : "primary",
                      fontWeight: 600,
                      fontSize: "0.875rem",
                      lineHeight: "1.75",
                    }}
                  >
                    {isProcessed || status === "processing"
                      ? `${progress}% Processed`
                      : "Start Processing"}
                  </Typography>
                </Button>

                {/* Progress Bar */}
                {(isProcessed || status === "processing") && (
                  <Box
                    sx={{
                      width: "200px", // Match width of the button for better alignment
                      height: "6px", // Compact height for the progress bar
                      borderRadius: "4px",
                      backgroundColor: "#E0E0E0",
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      sx={{
                        width: `${progress}%`,
                        height: "100%",
                        backgroundColor:
                          progress === 100
                            ? colors.completed
                            : colors.inProgress,
                        transition: "width 0.3s ease-in-out",
                      }}
                    />
                  </Box>
                )}
              </Box>

              <Button
                onClick={handlematchVehicleData}
                variant="contained"
                color="primary"
                startIcon={<SearchIcon />}
                disabled={isMatched || isProcessed}
                sx={{
                  border: `4px solid ${
                    status === "postprocessed" || progress === 100
                      ? colors.completed
                      : status === "postprocessing"
                      ? "#FF9800"
                      : "transparent"
                  } !important`,
                }}
              >
                Start Matching
              </Button>
              <Button
                component={Link}
                to={`/audit-process/${gogigReqId}`}
                target="_blank"
                variant="outlined"
                color="primary"
                startIcon={<ListIcon />}
                sx={{
                  fontSize: "14px",
                }}
              >
                Audit Vehicle Data
              </Button>
            </>
          )}
        </Box>
        {isProcessed && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#f5f5f5",
              padding: 2,
              borderRadius: 2,
            }}
          >
            <InfoOutlinedIcon sx={{ color: "#1976d2", marginRight: 1 }} />
            <Typography variant="body2" color="textSecondary">
              "The buttons are disabled while the current process is running.
              They will be enabled when processing is completed."
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default ProcessDetailsCard;
