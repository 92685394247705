import React from "react";
import { Grid, Paper, Typography, Box, CircularProgress } from "@mui/material";

// Define the interfaces
interface ImageData {
  pageNumber: number;
  imagePath: string;
  saved: boolean;
}

interface ImageCardProps {
  image: ImageData;
}

interface ImageGridProps {
  isImageParsed: boolean;
  imagesData: ImageData[];
}

// ImageCard Component
const ImageCard = ({ image }: ImageCardProps) => {
  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        backgroundColor: image.saved ? "#aeefa1" : "none",
        minHeight: 300,
        maxHeight: 300,
        overflow: "hidden",
      }}
    >
      <Box
        component="img"
        src={image.imagePath}
        alt={`Slide ${image.pageNumber + 1}`}
        sx={{
          width: "100%",
          height: "auto",
          maxHeight: "250px",
          objectFit: "contain",
          mb: 1,
        }}
      />
      <Typography variant="body1" gutterBottom>
        Page Number: {image.pageNumber + 1}
      </Typography>
      
    </Paper>
  );
};

// ImageGrid Component
const ImageGrid = ({ isImageParsed, imagesData }: ImageGridProps) => {
  return (
    <Grid container spacing={3} sx={{ mt: 3, mb: 5 }}>
      {isImageParsed ? (
        imagesData.length > 0 ? (
          imagesData.map((image: ImageData) => (
            <Grid item xs={12} md={2.4} key={image.pageNumber}>
              <ImageCard image={image} />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="body1" align="center">
              No Images Available
            </Typography>
          </Grid>
        )
      ) : (
        <Grid item xs={12}>
          <Typography variant="body1" align="center">
            <CircularProgress sx={{ color: "blue" }} size={48} />
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default ImageGrid;
