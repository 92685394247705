import React, { useEffect, useState } from "react";
import ManageUsersUI from "./ManageUsersUI";
import { useAuthContext } from "../../providers/AuthProvider";
import { useParams } from "react-router-dom";
import { getListOfUsersWorkingOnAGig } from "@gogig-in/gogig-clients";

const ManagerGigUsers: React.FC = () => {
  const { gigId } = useParams<{ gigId: string }>();
  const { authHeader } = useAuthContext();
  const [userData, setUserData] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchResults, setSearchResults] = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      try {
        if (authHeader && gigId) {
          const response: any = await getListOfUsersWorkingOnAGig(
            authHeader,
            gigId
          );
          setUserData(response.data);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [authHeader, gigId]);

  useEffect(() => {
    const filteredUsers = userData.filter((user) =>
      Object.values(user).some((value) =>
        value
          ? value.toString().toLowerCase().includes(searchTerm.toLowerCase())
          : false
      )
    );

    const sortedFilteredData = filteredUsers.sort((a, b) => {
      const aSimilarity = calculateSimilarity(a, searchTerm);
      const bSimilarity = calculateSimilarity(b, searchTerm);
      return bSimilarity - aSimilarity;
    });

    setSearchResults(sortedFilteredData);
  }, [searchTerm, userData]);

  const calculateSimilarity = (user: any, term: string) => {
    let similarity = 0;
    Object.values(user).forEach((value) => {
      if (value) {
        const stringValue = value.toString().toLowerCase();
        if (stringValue.includes(term.toLowerCase())) {
          similarity += term.length / stringValue.length;
        }
      }
    });
    return similarity;
  };

  return (
    <ManageUsersUI
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      searchResults={searchResults}
    />
  );
};

export default ManagerGigUsers;
