import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ProfileData } from "../components/profile/ProfileValues";
import { AuthConfiguration, whatsappLogin } from "@gogig-in/gogig-clients";
import { login, getUser, verifyEmail } from "@gogig-in/gogig-clients";
import { verifyOtp } from "@gogig-in/gogig-clients";
import { getAnonymousToken } from "@gogig-in/gogig-clients";

type AuthContextType = {
  authHeader: string | undefined;
  userDetails: ProfileData | null;
  isAuthenticated: boolean;
  logout: ({ returnTo }: LogoutParams) => void;
  loginWithEmail: (emailId: string, password: string, accessToken: string) => void;
  loginWithRedirect: ({ returnTo }: LoginWithRedirectParams) => void;
  loginWithEmailToken: (emailVerificationToken: string) => void;
  loginWithPhoneNumber: (
    phoneNo: string,
    otp: string,
  ) => Promise<string>;
  loginWithWhatsApp: (whatsappToken: any) => Promise<void>;
  resetUser: () => Promise<void>;
};

interface LogoutParams {
  returnTo: string;
}

interface LoginWithRedirectParams {
  returnTo?: string;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuthContext = () => {
  const value = useContext(AuthContext);
  if (!value) {
    throw new Error("useAuthContext must be used within a AuthProvider");
  }
  return value;
};

export interface AuthProviderProps {
  children?: React.ReactNode | React.ReactNode[];
}

const gogigTokenKey = "gogig-access-token";


interface GogigToken {
  type: "user" | "anonymous";
  token: string;
  timestamp: number;
}

export const AuthProvider = (props: AuthProviderProps) => {
  const [authHeader, setAuthHeader] = useState<string | undefined>();
  const [userDetails, setUserDetails] = useState<ProfileData | null>(null);
  const initialTokenString = window.localStorage.getItem(gogigTokenKey);
  const initialToken = initialTokenString
    ? JSON.parse(initialTokenString)
    : null;

  const [token, setToken] = useState<GogigToken | null>(initialToken);

  const isAnonymousToken = () => {
    if (token) {
      return token.type !== "user";
    }
    return true;
  };

  const isAuthenticated = !!token && !isAnonymousToken();

  const logout = useCallback(({ returnTo }: LogoutParams) => {
    window.localStorage.removeItem(gogigTokenKey);
    setToken(null);
    window.location.replace(`${window.location.origin}${returnTo}`);
  }, []);

  const setAllTokens = useCallback(
    (accessToken: string, isAnonymous: boolean = false) => {
      const gogigToken: GogigToken = {
        type: isAnonymous ? "anonymous" : "user",
        token: accessToken,
        timestamp: Date.now(),
      };
      const gogigTokenString = JSON.stringify(gogigToken);
      window.localStorage.setItem(gogigTokenKey, gogigTokenString);
      setToken(gogigToken);
    },
    []
  );

  useEffect(() => {
    (async () => {
      try {
        if (token) {
          const authConfigBearer: AuthConfiguration = {
            authType: "Bearer",
            value: token.token,
          };
          const header = `${authConfigBearer.authType} ${authConfigBearer.value}`;
          setAuthHeader(header);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [token]);

  useEffect(() => {
    (async () => {
      try {
        if (!token) {
          const anonymousToken = await getAnonymousToken();
          setAllTokens(anonymousToken.token, true);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [token, setAllTokens]);

  useEffect(() => {
    (async () => {
      try {
        if (authHeader && isAuthenticated) {
          const response: any = await getUser(authHeader);
          const userDetails: ProfileData = response.data;
          setUserDetails(userDetails);
        }
      } catch (error: any) {
        if (error.status === 401) {
          logout({ returnTo: "/" });
        }
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authHeader]);

  const resetUser = useCallback(async () => {
    if (authHeader) {
      const response: any = await getUser(authHeader);
      const userDetails: ProfileData = response.data;
      setUserDetails(userDetails);
    }
  }, [authHeader]);

  const loginWithEmail = useCallback(
    async (emailId: string, password: string, accessToken: string) => {
      const { token } = await login({ emailId, password , accessToken});
      setAllTokens(token);
    },
    [setAllTokens]
  );

  const loginWithPhoneNumber = useCallback(
    async (otp: string, phoneNumber: string) => {
      if (authHeader) {
        const otpResponse: any = await verifyOtp(otp, phoneNumber, authHeader);
        const { token } = otpResponse.data;
        setAllTokens(token);
        return token;
      }
    },
    [setAllTokens, authHeader]
  );

  const loginWithEmailToken = useCallback(
    async (emailVerificationToken: string) => {
      const { token } = await verifyEmail(emailVerificationToken);
      setAllTokens(token);
    },
    [setAllTokens]
  );

  const loginWithWhatsApp = useCallback(
    async (whatsAppToken: any) => {
      const { token } = await whatsappLogin(whatsAppToken);
      setAllTokens(token);
    },
    [setAllTokens]
  );

  const loginWithRedirect = useCallback(
    ({ returnTo }: LoginWithRedirectParams) => {
      window.location.replace(
        `${window.location.origin}/user-entry/signup${
          returnTo ? "?returnTo=" + returnTo : ""
        }`
      );
    },
    []
  );

  const value = useMemo(() => {
    return {
      isAuthenticated,
      authHeader,
      userDetails,
      logout,
      loginWithEmail,
      loginWithRedirect,
      loginWithEmailToken,
      loginWithPhoneNumber,
      loginWithWhatsApp,
      resetUser,
    };
  }, [
    isAuthenticated,
    authHeader,
    userDetails,
    logout,
    loginWithEmail,
    loginWithRedirect,
    loginWithEmailToken,
    loginWithPhoneNumber,
    loginWithWhatsApp,
    resetUser,
  ]);

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};
