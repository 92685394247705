import { TextField, InputAdornment, IconButton } from "@mui/material";
import { Search } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";

interface SearchBarProps {
  searchTerm: string;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClearSearch: () => void;
}

const SearchBar = ({ searchTerm, onSearchChange, onClearSearch }: SearchBarProps) => (
  <TextField
    label="Search"
    variant="outlined"
    value={searchTerm}
    onChange={onSearchChange}
    size="small"
    sx={{ mr: 1, width: "300px" }}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      ),
      endAdornment: searchTerm && (
        <IconButton onClick={onClearSearch}>
          <ClearIcon />
        </IconButton>
      ),
    }}
  />
);

export default SearchBar;
