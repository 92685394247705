import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../providers/AuthProvider";
import validateEmail from "../validators/EmailValidator";
import validateAge from "../validators/DateValidators";
import validateGender from "../validators/GenderValidators";
import CustomLoading from "../custom/Loading";
import dateFormat from "dateformat";
import { UserData, addUserData } from "@gogig-in/gogig-clients";

const genders = ["male", "female", "other"];

const Register: React.FC = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState<Partial<UserData> | any>({});
  const [userData, setUserData] = useState<UserData>({
    firstName: "",
    lastName: "",
    emailId: "",
    gender: "",
    dob: "",
  });
  const { resetUser, isAuthenticated, userDetails, authHeader } =
    useAuthContext();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (userDetails) {
      setUserData({
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        emailId: userDetails.emailId,
        gender: userDetails.gender,
        dob: userDetails.dob,
      });
    }
  }, [userDetails]);

  useEffect(() => {
    if (
      userData.dob === undefined ||
      userData.dob === null ||
      userData.dob === ""
    ) {
      userData.dob = ""; // Set dob to null if it's undefined, null, or empty
    } else {
      // Assuming userData.dob is a valid date string from the API
      const formattedDate = dateFormat(userData.dob, "yyyy-mm-dd");
      setUserData({ ...userData, dob: formattedDate });
    }
  }, [userData]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: keyof UserData
  ) => {
    setUserData({
      ...userData,
      [field]: e.target.value,
    });
    setErrors({
      ...errors,
      [field]: "", // Clear error message when input changes
    });
  };

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 18);

  // Format the date as YYYY-MM-DD (required format for input type 'date')
  const maxDateString = maxDate.toISOString().split("T")[0];

  const validateForm = (): boolean => {
    let isValid = true;
    const newErrors: Partial<UserData> = {};

    // Check for empty fields
    for (const key in userData) {
      if (!userData[key as keyof UserData]) {
        newErrors[key as keyof UserData] = "This field is required";
        isValid = false;
      }
    }

    // Email validation
    if (!validateEmail(userData.emailId)) {
      newErrors.emailId = "Please enter a valid email address";
      isValid = false;
    }

    // Date of Birth validation
    if (!validateAge(userData.dob)) {
      newErrors.dob = "You must be at least 18 years old";
      isValid = false;
    }

    // Gender validation
    if (!validateGender(userData.gender, genders)) {
      newErrors.gender = "Please select a valid gender";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleUpdateProfile = async () => {
    if (validateForm() && authHeader) {
      try {
        // Assuming AddUserData returns errors in case of failure
        setIsLoading(true);
        await addUserData(userData, authHeader);
        await resetUser();
        if (isAuthenticated) {
          navigate("/");
        }
      } catch (error: any) {
        console.error("Error:", error);
        // Check if the error is an object with errors property
        if (error && error.errors) {
          const newErrors: Partial<UserData> = {};

          // Loop through the errors and set them to corresponding fields
          for (const err of error.errors) {
            newErrors[err.field as keyof UserData] = err.message;
          }

          // Set errors state to display the error messages
          setErrors(newErrors);
        } else {
          // Handle other types of errors if necessary
        }
      }
    }
  };

  return (
    <Grid>
      {isLoading ? (
        <CustomLoading />
      ) : (
        <>
          <Typography variant="h4" align="center">
            Welcome to GoGig
          </Typography>
          <Typography sx={{ mt: 1 }}></Typography>
          <Box>
            <Container
              sx={{
                position: "relative",
                top: "0",
                border: "1px solid #ddd",
                borderRadius: "10px",
                padding: "20px",
              }}
              maxWidth={"sm"}
            >
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    hiddenLabel
                    label="First name"
                    variant="outlined"
                    value={userData.firstName}
                    onChange={(e: any) => handleInputChange(e, "firstName")}
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Last name"
                    variant="outlined"
                    value={userData.lastName}
                    onChange={(e: any) => handleInputChange(e, "lastName")}
                    error={!!errors.lastName}
                    helperText={errors.lastName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email address"
                    variant="outlined"
                    type="email"
                    value={userData.emailId}
                    onChange={(e: any) => handleInputChange(e, "emailId")}
                    error={!!errors.emailId}
                    helperText={errors.emailId}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth error={!!errors.gender}>
                    <InputLabel>Gender</InputLabel>
                    <Select
                      value={userData.gender}
                      label="Gender"
                      onChange={(e: any) => handleInputChange(e, "gender")}
                      error={!!errors.gender}
                      required
                      variant="outlined"
                    >
                      <MenuItem value={"male"}>Male</MenuItem>
                      <MenuItem value={"female"}>Female</MenuItem>
                      <MenuItem value={"others"}>Others</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="date"
                    name="dob"
                    label="Date of Birth"
                    fullWidth
                    onChange={(e: any) => handleInputChange(e, "dob")}
                    value={userData?.dob}
                    error={!!errors.dob}
                    helperText={errors.dob}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      max: maxDateString,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={handleUpdateProfile}
                    variant="contained"
                    color="primary"
                  >
                    Register
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </>
      )}
    </Grid>
  );
};

export default Register;
