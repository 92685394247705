import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  CircularProgress,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import PreProcessAuditDetails from "./PreProcessAuditDetails";
import { getVehicleAuditData } from "../../../clients/getVehicleAuditData";
import { AuditRequest, UpdateVehicleInterface } from "./types";
import { updateVehicleAuditData } from "../../../clients/updateVehicleAuditData";
import CustomSnackbar from "../../operationRolePages/CustomSnackbar";
import endManualPreProcess from "../../../clients/endManualPreProcess";
import { useAuthContext } from "../../../providers/AuthProvider";
import { formatDate } from "../../../utils/formatDate";
import { useNavigate } from "react-router-dom";

interface PPTViewerProps {
  auditData: AuditRequest;
  fetchAuditData: () => Promise<void>;
}

const PPTViewer: React.FC<PPTViewerProps> = ({ auditData, fetchAuditData }) => {
  const { authHeader } = useAuthContext();
  const [selectedImage, setSelectedImage] = useState<string>("");
  const [slideNumberInput, setSlideNumberInput] = useState<string>("");
  const [selectedImagePageNumber, setSelectedImagePageNumber] = useState<
    number | null
  >(null);
  const [vehicleData, setVehicleData] = useState<UpdateVehicleInterface[]>([]);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isSavedMap, setIsSavedMap] = useState<{ [key: number]: boolean }>({});
  const [savedCount, setSavedCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filteredVehicleData, setFilteredVehicleData] = useState<
    UpdateVehicleInterface[]
  >([]);
  const [showCheckedOnly, setShowCheckedOnly] = useState<boolean>(false);
  const [isPreProcessingCompleted, setIsPreProcessingCompleted] = useState(
    false
  );
  const [lastPreprocessDate, setLastPreprocessDate] = useState<string | null>(
    null
  );
  const [initialVehicleData, setInitialVehicleData] = useState<UpdateVehicleInterface[]>([]);
  const [buttonText, setButtonText] = useState("END PRE-PROCESSING");
  const imageRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
  const navigate = useNavigate()
  const handleNextSlide = () => {
    let currentIndex = filteredVehicleData.findIndex(
      (vehicle) => vehicle.pageNumber === selectedImagePageNumber
    );
    if (
      selectedImagePageNumber !== null &&
      filteredVehicleData[currentIndex + 1] &&
      filteredVehicleData[currentIndex + 1].imagePath != null
    ) {
      setSelectedImage(filteredVehicleData[currentIndex + 1].imagePath);
      setSelectedImagePageNumber(
        filteredVehicleData[currentIndex + 1].pageNumber ?? null
      );
    } else {
      // If there is no next slide, you can show a message
      handleSnackbar("success", "No more slides.");
    }
  };
  useEffect(() => {
    // Filter the vehicle data based on the checkbox filter
    const filteredData = showCheckedOnly
      ? vehicleData.filter((vehicle) => vehicle.recheck)
      : vehicleData;
    updateSlideSaveStatus(filteredData, true);
    setFilteredVehicleData(filteredData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCheckedOnly]);
  useEffect(() => {
    const filteredData = showCheckedOnly
      ? vehicleData.filter((vehicle) => vehicle.recheck)
      : vehicleData;
    updateSlideSaveStatus(filteredData, false);
    setFilteredVehicleData(filteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleData])

  const handleShowCheckedOnlyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setShowCheckedOnly(event.target.checked);
  };
  const handleSlideNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;

    // Allow only numbers
    if (/^\d*$/.test(value)) {
      setSlideNumberInput(value);

      // Convert input to number
      const slideNumber = parseInt(value) - 1;

      // Check if the slide number exists in filteredVehicleData
      const matchedSlide = filteredVehicleData.find(
        (slide) => slide.pageNumber === slideNumber
      );

      if (matchedSlide) {
        // Slide exists, select it
        setSelectedImage(matchedSlide.imagePath);
        setSelectedImagePageNumber(matchedSlide.pageNumber ?? null);
      } else if (value) {
        // Slide does not exist, show a warning
        handleSnackbar("warning", "Slide number does not exist.");
      }
    }
  };

  // Navigate to the slide when pressing Enter

  const handlePreviousSlide = () => {
    let currentIndex = filteredVehicleData.findIndex(
      (vehicle) => vehicle.pageNumber === selectedImagePageNumber
    );
    if (selectedImagePageNumber !== null && currentIndex > 0) {
      setSelectedImage(filteredVehicleData[currentIndex - 1].imagePath);
      setSelectedImagePageNumber(
        filteredVehicleData[currentIndex - 1].pageNumber ?? null
      );
    } else {
      handleSnackbar("warning", "No previous slides.");
    }
  };

  // Add a keyboard event listener for the 'ArrowDown' key
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowDown") {
        handleNextSlide(); // Trigger the next slide function
      } else if (event.key === "ArrowUp") {
        handlePreviousSlide(); // Trigger previous slide on up arrow
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImagePageNumber]); // Add dependencies to re-register the listener if the index or data changes
  useEffect(() => {
    if (auditData.manualPreProcessEnd) {
      setButtonText("END PRE-PROCESSING AGAIN");
      setLastPreprocessDate(formatDate(auditData.manualPreProcessEnd));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditData]);
  useEffect(() => {
    if (
      selectedImagePageNumber !== null &&
      imageRefs.current[selectedImagePageNumber]
    ) {
      imageRefs.current[selectedImagePageNumber]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [selectedImagePageNumber]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (severity: any, message: string) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const updateSlideSaveStatus = (
    updatedVehicleList: UpdateVehicleInterface[],
    isInitialLoad = false
  ) => {
    const savedStatusMap: { [key: number]: boolean } = {};

    updatedVehicleList.forEach((vehicle) => {
      const pageNumber = vehicle.pageNumber ?? -1; // Fallback to -1 if pageNumber is missing
      savedStatusMap[pageNumber] = !!(
        (vehicle.colorFromPhoto?.trim() !== "" &&
          vehicle.colorFromPhoto != null) ||
        (vehicle.makerModelFromPhoto?.trim() !== "" &&
          vehicle.makerModelFromPhoto != null) ||
        (vehicle.manufacturerFromPhoto?.trim() !== "" &&
          vehicle.manufacturerFromPhoto != null) ||
        (vehicle.imageOrientation?.trim() !== "" &&
          vehicle.imageOrientation != null) ||
        vehicle.recheck ||
        (Array.isArray(vehicle.reasonForRecheck) &&
          vehicle.reasonForRecheck.length > 0)
      );
    });

    const savedVehiclesCount = Object.values(savedStatusMap).filter(
      (saved) => saved
    ).length;

    setSavedCount(savedVehiclesCount);
    setIsSavedMap(savedStatusMap);

    if (isInitialLoad) {
      const firstUnsaved = updatedVehicleList.find(
        (vehicle) => !savedStatusMap[vehicle.pageNumber ?? -1]
      );

      if (firstUnsaved && firstUnsaved.imagePath) {
        setSelectedImage(firstUnsaved.imagePath);
        setSelectedImagePageNumber(firstUnsaved.pageNumber ?? null);
      } else if (updatedVehicleList.length > 0) {
        // If all are saved, optionally select the first image
        const firstImage = updatedVehicleList[0];
        setSelectedImage(firstImage.imagePath);
        setSelectedImagePageNumber(firstImage.pageNumber ?? null);
      }
    }
  };

  useEffect(() => {
    const fetchAndParsePPT = async () => {
      if (!authHeader) return;
      try {
        setIsLoading(true);
        const vehicleData = (await getVehicleAuditData(authHeader,
          auditData.gogigRequestId
        )) as UpdateVehicleInterface[];
        updateSlideSaveStatus(vehicleData, true);
        setVehicleData(vehicleData);
        setInitialVehicleData(vehicleData);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching or parsing PPT file:", error);
      }
    };

    fetchAndParsePPT();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditData.gogigRequestId]);

  const handleCompletePreprocess = async () => {
    try {
      const isDataChanged = !vehicleData.every((vehicle, index) => {
        // Compare the current vehicle data with the initial data
        return JSON.stringify(vehicle) === JSON.stringify(initialVehicleData[index]);
      });
      const isReRun = auditData.lastUpdatedManualPreProcess && new Date(auditData.lastUpdatedManualPreProcess) > new Date(auditData.manualPreProcessEnd || 0);


      if (!isDataChanged) {
        if (!isReRun) {
          alert("Please make some changes to the vehicle data before completing the manual preprocess.");
          return; // Exit the function if no changes were made
        }
      }
      setInitialVehicleData(vehicleData);
      const isRecheck = vehicleData.filter((vehicle) => vehicle.recheck).length;
      if (isRecheck) {
        const message = `Please uncheck all the rechecks; there ${isRecheck === 1 ? "is" : "are"
          } ${isRecheck} item${isRecheck === 1 ? "" : "s"
          } currently marked for recheck.`;
        alert(message);
        return;
      }
      const userConfirmed = window.confirm(
        "Please confirm: Have you carefully reviewed all data and ensured that everything is complete?"
      );

      // If the user does not confirm, exit the function early
      if (!userConfirmed) return;
      if (!auditData.gogigRequestId || !authHeader) return;

      setIsPreProcessingCompleted(true);
      setButtonText("Processing...");

      await endManualPreProcess(
        authHeader,
        auditData.gogigRequestId,
      );
      await fetchAuditData();

      handleSnackbar("success", "Preprocess completed successfully.");
      setButtonText("END PRE-PROCESSING AGAIN");
      navigate(`/audit-request/${auditData.gogigRequestId}`)
    } catch (error) {
      handleSnackbar(
        "error",
        "Failed to complete preprocess. Please try again."
      );
      console.error("Error completing preprocess:", error);
      setButtonText("END PRE-PROCESSING");
    } finally {
      setIsPreProcessingCompleted(false);
    }
  };

  const handleUpdate = async (
    gogigReqId: string,
    selectedVehicle: UpdateVehicleInterface,
    index: number
  ) => {
    try {
      if (!authHeader || !selectedVehicle) return;

      const updateVehicleData = await updateVehicleAuditData(
        authHeader,
        gogigReqId,
        selectedVehicle
      );
      handleSnackbar("success", "Updated vehicle details successfully.");
      const updatedVehicleList: UpdateVehicleInterface[] = vehicleData.map(
        (vehicle) =>
          vehicle.pageNumber === updateVehicleData.pageNumber
            ? updateVehicleData
            : vehicle
      );
      setVehicleData(updatedVehicleList);
      updateSlideSaveStatus(updatedVehicleList, false);
      handleNextSlide();

    } catch (error) {
      handleSnackbar("error", "Updating vehicle Details: Please try again.");
    }
  };

  const handleClick = (imageSrc: string, index: number) => {
    setSelectedImage(imageSrc);
    setSelectedImagePageNumber(index);
  };

  return (
    <Grid container spacing={2} p={2} gap={5}>
      {/* Left side column for images */}
      <Grid item width={"15%"}>
        <Box
          sx={{
            textAlign: "center",
            backgroundColor: "#4CAF50", // Green background
            color: "white",
            borderRadius: "8px",
            padding: "4px 12px",
            fontWeight: "bold",
            marginBottom: 2, // Spacing below the counter
          }}
        >
          {savedCount}/{filteredVehicleData.length} Saved
        </Box>

        {/* Go to Slide and View Checked controls */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1, // Space between elements
            marginBottom: 2,
            width: "100%",
          }}
        >
          <TextField
            label="Jump to"
            variant="outlined"
            value={slideNumberInput}
            onChange={handleSlideNumberChange}
            sx={{ width: "70%" }}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showCheckedOnly}
                onChange={handleShowCheckedOnlyChange}
              />
            }
            label="View Checked"
            sx={{
              color: "#333", // Darker text color
              fontWeight: "500",
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            overflowY: "auto",
            height: lastPreprocessDate ? `calc(100vh - 320px)` : `calc(100vh - 260px)`,
            width: "100%",
            mt: 1,
            borderRight: "1px solid #ccc",
          }}
        >
          {isLoading ? (
            <CircularProgress sx={{ color: "blue", mt: 4, ml: 12 }} size={48} />
          ) : filteredVehicleData && filteredVehicleData.length > 0 ? (
            filteredVehicleData.map((vehicle) => {
              const pageNumber = vehicle.pageNumber ?? -1; // Fallback if pageNumber is null

              return (
                <Box
                  key={pageNumber}
                  sx={{
                    position: "relative",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mb: 2,
                    width: "100%",
                    backgroundColor: vehicle.rcModifiedFlag
                      ? "#ffa5007a"
                      : "none",
                  }}
                  ref={(el: HTMLDivElement | null) =>
                    (imageRefs.current[pageNumber] = el)
                  }
                  onClick={() => {
                    if (vehicle.imagePath) {
                      handleClick(vehicle.imagePath, pageNumber);
                    }
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      width: "100%",
                      p: 1,
                      pr: 3,
                      backgroundColor:
                        selectedImage === vehicle.imagePath
                          ? "#c1d3ea"
                          : "none",
                    }}
                  >
                    {/* Slide number */}
                    <Box
                      sx={{
                        position: "relative",
                        height: 1,
                        backgroundColor: isSavedMap[pageNumber]
                          ? "green"
                          : selectedImage === vehicle.imagePath
                            ? "blue"
                            : "blue",
                        color: "white",
                        padding: "2px 6px",
                        mr: "4px",
                        boxShadow:
                          selectedImage === vehicle.imagePath ? 5 : "0px",
                        borderRadius: "4px",

                        fontSize: "0.8rem",
                      }}
                    >
                      {pageNumber !== -1 ? pageNumber + 1 : "N/A"}
                    </Box>

                    {/* Slide image */}
                    <Box
                      sx={{
                        border:
                          selectedImage === vehicle.imagePath
                            ? "2px solid blue"
                            : isSavedMap[pageNumber] // Use the pageNumber to check saved status
                              ? "3px solid green" // Green border if the image is saved
                              : "2px solid black", // Default black border for others
                        "&:hover": { borderColor: "blue" },
                        boxShadow:
                          selectedImage === vehicle.imagePath ? 5 : "0px",
                        transition: "border-color 0.3s",
                        width: "100%",
                        maxWidth: "150px",
                        height: "100px",
                        background: `url(${vehicle.imagePath}) no-repeat center/contain`,
                      }}
                    />
                  </Box>
                </Box>
              );
            })
          ) : (
            <Typography variant="h6">No images available</Typography>
          )}
        </Box>
        {lastPreprocessDate && (
          <Box sx={{ textAlign: "center", mt: 1 }}>
            <Typography variant="caption" color="textSecondary">
              Last Preprocessed Complete at: {lastPreprocessDate}
            </Typography>
          </Box>
        )}

        {/* New Complete Preprocess Button */}
        <Box sx={{ textAlign: "center", mt: 1, width: "100%" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCompletePreprocess}
            disabled={isPreProcessingCompleted}
            startIcon={
              isPreProcessingCompleted && <CircularProgress size={20} />
            }
            sx={{ p: 1, width: "100%" }}
          >
            {buttonText}
          </Button>
        </Box>
      </Grid>
      <Grid item width={"80%"}>
        <PreProcessAuditDetails
          gogigReqId={auditData.gogigRequestId}
          selectedImageBlobUrl={selectedImage}
          selectedImagePageNumber={selectedImagePageNumber}
          vehicleData={vehicleData}
          handleUpdate={handleUpdate}
        />
      </Grid>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />
    </Grid>
  );
};

export default PPTViewer;
