import React, { useState } from 'react';
import { Box, TextField, Typography, Paper, IconButton, Tooltip } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';

interface AuditFieldsFormProps {
  gogigRequestId: string;
  requesterId: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setGogigRequestId: (value: string) => void;
}

const AuditFieldsForm: React.FC<AuditFieldsFormProps> = ({
  gogigRequestId,
  requesterId,
  handleChange,
  setGogigRequestId,
}) => {
  const [copied, setCopied] = useState<{ [key: string]: boolean }>({
    gogigRequestId: false,
    requesterId: false,
  });
  
  // Function to copy text to clipboard
  const handleCopyToClipboard = async (text: string, field: "gogigRequestId" | "requesterId") => {
    try {
      await navigator.clipboard.writeText(text); // Copy to clipboard
      setCopied((prev) => ({ ...prev, [field]: true }));

      // Reset the copied status after 2 seconds
      setTimeout(() => {
        setCopied((prev) => ({ ...prev, [field]: false }));
      }, 2000);
    } catch (error) {
      console.error("Failed to copy text to clipboard", error);
    }
  };
  return (
    <Paper elevation={3} sx={{ padding: 3 }}>
      <Typography variant="h6" gutterBottom>
        Audit Request Details
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
      <Box sx={{ display: 'flex', flex: 1, alignItems: 'center' }}>
          <TextField
            label="Gogig Req ID"
            type="text"
            value={gogigRequestId}
            fullWidth
            required
            size="small"
            disabled
          />
          <Tooltip title={copied.gogigRequestId ? "Copied!" : "Copy to clipboard"}>
            <IconButton
              onClick={() => handleCopyToClipboard(gogigRequestId, "gogigRequestId")}
              sx={{ marginLeft: 1 }}
            >
              <ContentCopy />
            </IconButton>
          </Tooltip>
        </Box>

        <Box sx={{ display: 'flex', flex: 1, alignItems: 'center' }}>
          <TextField
            label="Requester ID"
            type="text"
            value={decodeURIComponent(requesterId)} // Assuming requesterId needs decoding
            fullWidth
            required
            size="small"
            disabled
          />
          <Tooltip title={copied.requesterId ? "Copied!" : "Copy to clipboard"}>
            <IconButton
              onClick={() => handleCopyToClipboard(requesterId, "requesterId")}
              sx={{ marginLeft: 1 }}
            >
              <ContentCopy />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Paper>
  );
};

export default AuditFieldsForm;
