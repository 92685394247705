import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";

interface NumberQuestionProps {
  question: string;
  questionId: string;
  answers: Record<string, number | undefined>; 
  handleAnswerChange: (questionId: string, value: number) => void;
  disabled: boolean;
  onErrorChange: (error: string | null, field: string) => void; // Define this prop for handling errors in the parent
}

const HandleNumberQuestion: React.FC<NumberQuestionProps> = ({
  question,
  questionId,
  answers,
  handleAnswerChange,
  disabled,
  onErrorChange,
}) => {
  const [phoneNumber, setPhoneNumber] = useState<string | null>(
    answers[questionId]?.toString() || ""
  );
  const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null);

  const shouldValidatePhoneNumber =
    question.toLowerCase().includes("phone") ||
    question.toLowerCase().includes("contact") ||
    question.toLowerCase().includes("mobile");

  const validatePhoneNumber = (phoneNumber: string) => {
    if (!/^\d+$/.test(phoneNumber)) {
      setPhoneNumberError("Phone number must contain only digits");
      return false;
    } else if (phoneNumber.length !== 10) {
      setPhoneNumberError("Phone number must be 10 digits");
      return false;
    }
    setPhoneNumberError(null);
    return true;
  };

  const handlePhoneNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newPhoneNumber = e.target.value;

    // Filter out non-numeric characters from the input
    const filteredNumber = newPhoneNumber.replace(/\D/g, "");

    setPhoneNumber(filteredNumber);

    if (shouldValidatePhoneNumber && validatePhoneNumber(newPhoneNumber)) {
      handleAnswerChange(questionId, parseInt(newPhoneNumber, 10));
    } else {
      handleAnswerChange(questionId,  parseInt(newPhoneNumber, 10));
    }
  };


  React.useEffect(() => {
    const notifyParentOnError = () => {
      if (phoneNumberError) {
        onErrorChange(phoneNumberError, question);
      } else {
        onErrorChange(null, question); 
      }
    };
  
    notifyParentOnError();
  }, [onErrorChange, phoneNumberError, question]);


  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        <Typography variant="h6">{question}</Typography>
      </FormLabel>
      <TextField
        variant="outlined"
        value={phoneNumber}
        size="small"
        style={{ width: "250px" }}
        onChange={handlePhoneNumberChange}
        helperText={phoneNumberError || "Type a numeric value"}
        error={!!phoneNumberError}
        disabled={disabled}
      />
    </FormControl>
  );
};

export default HandleNumberQuestion;
