import React, {useMemo, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Divider,
  IconButton,
  Popover,
  Stack,
  StepConnector,
  styled,
} from "@mui/material";
import { HourglassEmpty, Info, InfoOutlined, PlayCircle } from "@mui/icons-material";

export const colors = {
  completed: "#4CAF50",
  inProgress: "#FF9800",
  notExtracted: "#9E9E9E",
  rerun: "#D32F2F",
  default: "#B0BEC5",
};

const formatDate = (date?: Date): string => date ? new Date(date).toLocaleString() : "N/A";

export interface ProcessTimestamps {
  manualPreProcessStart?: Date;
  manualPreProcessEnd?: Date;
  processStart?: Date;
  processEnd?: Date;
  postProcessStart?: Date;
  postProcessEnd?: Date;
  reportGeneratedAt?:Date;
  automaticPreProcessStart?: Date;
  automaticPreProcessEnd?: Date;
}

interface ProcessFlowUIProps {
  timestamps: ProcessTimestamps;
  status: string;
  lastUpdatedManualPreProcess?: Date;
  resultUrl?:string;
  isReRun?:boolean;
  isAutoRerun?:boolean;
  isReportGeneratedAgain?:boolean;
}


const CustomConnector = styled(StepConnector)(({ theme }) => ({
  "& .MuiStepConnector-line": {
    height:"50px",
    borderWidth: 3, // Increase line thickness
    borderColor: theme.palette.grey[400], // Set color for the line
  },
}));

const ProcessFlowUI: React.FC<ProcessFlowUIProps> = ({
  timestamps,
  status,
  lastUpdatedManualPreProcess,
  resultUrl,
  isReRun,
  isReportGeneratedAgain,
  isAutoRerun,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedStep, setSelectedStep] = useState<number | null>(null);
  const [showDescription, setShowDescription] = useState(false);
  const isReportUploaded = resultUrl;  // Checks if the report has been uploaded
  const isReportRegenerated = isReportUploaded && isReportGeneratedAgain;  // Checks if the report has been regenerated
  const isReportNotGenerated = !timestamps.reportGeneratedAt;  // Checks if the report was uploaded before being generated
  const stepDetails = [
    {
      label: "Automatic Pre-Processing",
      descriptions: [
        { status: "automaticpreprocessing", color:isAutoRerun?colors.rerun:colors.inProgress, description:isAutoRerun?"please complete the auto pre processing, it is ran again": "End your Auto preprocessing to proceed to next step." },
        { status: ["manualpreprocessing","automaticpreprocessed","manualpreprocessed", "processed", "postprocessed", "processing","postprocessing"], color: colors.completed, description: "Automatic preprocess is completed." },
        { status: "default", color: colors.notExtracted, description: "complete Auto pre processing." }
      ]
    },
    {
      label: "Manual Pre-Processing",
      descriptions: [
       
        { status: "manualpreprocessing", color:isReRun?colors.rerun:colors.inProgress, description:isReRun?"In preprocess, something is updated, please end the pre-process.": "End your preprocessing to proceed to next step." },
        { status: ["manualpreprocessed", "processed", "postprocessed", "processing","postprocessing"], color: colors.completed, description: "Preprocess is completed." },
        { status: "automaticpreprocessing", color: colors.notExtracted, description: "after completeion of automatic preprocess, start manual pre process." },
        { status: "default", color: colors.notExtracted, description: "complete pre processing." }

      ]
    },
    {
      label: "Processing",
      descriptions: [
        { status: "processing", color: colors.inProgress, description: "Processing is ongoing." },
        { status: ["processed", "postprocessed","postprocessing"], color: colors.completed, description: "Processing is completed." },
        { status: ["automaticpreprocessed","manualpreprocessed","automaticpreprocessing","manualpreprocessing"], color: colors.notExtracted, description: "Complete preprocessing to proceed." },
        { status: "default", color: colors.notExtracted, description: "Start preprocessing and after completion start processing." }
      ]
    },
    {
      label: "Post-Processing",
      descriptions: [
        { status: "postprocessing", color: colors.inProgress, description: "Matching is ongoing." },
        { status: "postprocessed", color: colors.completed, description: "Matching is completed." },
        { status: "processed", color: colors.notExtracted, description: "Start matching." },
        { status: "processing", color: colors.notExtracted, description: "Complete processing to start matching." },
        { status: ["automaticpreprocessing","manualpreprocessing"], color: colors.notExtracted, description: "Complete preprocessing and then complete processing to start matching." },
        { status: "default", color: colors.notExtracted, description: "Complete the first two steps before starting matching." }
      ]
    },
    {
      label: "report generated",
      descriptions: [
        {status: ["automaticpreprocessing","automaticpreprocessed","manualpreprocessing","manualpreprocessed","processing","processed","postprocessing"], color:colors.notExtracted, description: "complete above steps first"},
        {
          status: "postprocessed",
          color:isReportUploaded
          ? (isReportRegenerated || isReportNotGenerated ? colors.rerun : colors.completed)
          : colors.inProgress,
          description:  isReportUploaded
          ? (isReportRegenerated
              ? "Upload the report again" // Report was regenerated, so upload again
              : (isReportNotGenerated
                  ? "Wrong report uploaded, first generate and upload the report." // Report uploaded before it was generated
                  : "Report generated")) // Report generated successfully
          : "Go to vehicle audit Data, and generate the report.",
        },
        { status: "default", color: colors.notExtracted, description: "Complete the first three steps before generating report." }

      ]
    }
  ];
  const steps = useMemo(() => [
    {
      label: "Automatic Pre-Processing",
      start: timestamps.automaticPreProcessStart,
      end: timestamps.automaticPreProcessEnd,
      icon: isAutoRerun||(!timestamps.automaticPreProcessEnd && timestamps.automaticPreProcessStart) ? <HourglassEmpty /> : <PlayCircle />,
      assignee: "sriram@gogig.in",
    },
    {
      label: "Manual Pre-Processing",
      start: timestamps.manualPreProcessStart,
      end: timestamps.manualPreProcessEnd,
      icon:isReRun||(!timestamps.manualPreProcessEnd && timestamps.manualPreProcessStart) ? <HourglassEmpty /> : <PlayCircle />,
      assignee: "sriram@gogig.in",
    },
    {
      label: "Processing",
      start: timestamps.processStart,
      end: timestamps.processEnd,
      icon: status === "processing" ? <HourglassEmpty /> : <PlayCircle />,
      assignee: "sriram@gogig.in",
    },
    {
      label: "Post-Processing",
      start: timestamps.postProcessStart,
      end: timestamps.postProcessEnd,
      icon: <PlayCircle />,
      assignee: "sriram@gogig.in",
    },
    {
      label: "Report Generation",
      end:timestamps.reportGeneratedAt,
      icon:status==="postprocessed"? resultUrl? <PlayCircle />:<HourglassEmpty />:<PlayCircle />,
      assignee: "sriram@gogig.in",
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [timestamps, status, isReRun]);

  const getStepInfo = (stepIndex: number, status: string) => {
    const step = stepDetails[stepIndex];
    const descriptionObj = step.descriptions.find(d =>
      Array.isArray(d.status) ? d.status.includes(status) : d.status === status
    ) || step.descriptions.find(d => d.status === "default");

    return {
      description: descriptionObj?.description,
      color: descriptionObj?.color
    };
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedStep(index);
    setAnchorEl(event.currentTarget);
    setShowDescription((prev) => !prev);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedStep(null);
    setShowDescription(false);
  };

  return (
    <Card variant="outlined" sx={{ margin: 2, boxShadow: 4, maxWidth: 600, borderRadius: 3 }}>
      <CardContent>
      
        <Typography variant="h5" sx={{ textAlign: "center", fontWeight: "bold", mb: 2 }}>
          Process Flow
        </Typography>

        <Stepper orientation="vertical" activeStep={-1} connector={<CustomConnector />}>
          {steps.map((step, index) => {
            const {color} = getStepInfo(index, status);
            return (
              <Step key={index}>
                <StepLabel StepIconProps={{ icon: React.cloneElement(step.icon, { sx: { color } }) }}>
                  <Box display="flex" alignItems="center" flexDirection="column">
                    <Box display="flex" alignItems="center">
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "bold",
                          color: "white",
                          backgroundColor: color,
                          padding: "6px 12px",
                          borderRadius: "8px",
                          marginBottom: "4px",
                        }}
                      >
                        {step.label}
                      </Typography>
                      <IconButton onClick={(event) => handleClick(event, index)} size="small">
                        {showDescription && selectedStep === index ? (
                          <Info sx={{ color, marginRight: 1 }} />
                        ) : (
                          <InfoOutlined sx={{ color, marginRight: 1 }} />
                        )}
                      </IconButton>

                    </Box>
                    {step.assignee && <Typography variant="caption" color="textSecondary">Assignee: {step.assignee}</Typography>}
                    {step.start && <Typography variant="caption" color="textSecondary">Assigned Date: {formatDate(step.start)}</Typography>}
                    {step.end && <Typography variant="caption" color="textSecondary">Completed Date: {formatDate(step.end)}</Typography>}
                  </Box>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <Popover
          open={Boolean(anchorEl) && showDescription}
          anchorEl={anchorEl}
          onClose={handleClose}
          disableScrollLock
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          sx={{ p: 2, borderRadius: 2, boxShadow: 3 }}
        >
          {selectedStep !== null ? (
            <Box sx={{ p: 2 }}>
              <Typography variant="body2">
                {getStepInfo(selectedStep, status).description}
              </Typography>
            </Box>
          ) : ""}
        </Popover>

        <Box mt={2} textAlign="center">
          <Typography variant="caption" color="textSecondary">Last pre-processed at: {formatDate(lastUpdatedManualPreProcess)}</Typography>
          <Typography variant="body2" sx={{ fontWeight: "bold", mt: 1 }}>Current Status:{status === "postprocessed" && resultUrl ? (!isReportGeneratedAgain ? "Report Generated" : status) : status}
          </Typography>
        </Box>

        <Divider sx={{ marginY: 2 }} />

        <Box textAlign="center" mt={2}>
      <Typography variant="h6" fontWeight="bold">Color Legend:</Typography>
      <Stack direction="row" spacing={2} mt={1} justifyContent="center">
        {Object.entries(colors).map(([key, color]) => (
          <Stack key={key} direction="row" alignItems="center" spacing={0.5}>
            <Box
              sx={{
                width: 12,
                height: 12,
                backgroundColor: color,
                borderRadius: "2px",
              }}
            />
            <Typography variant="caption" sx={{ fontSize: "0.75rem" }}>
              {key.charAt(0).toUpperCase() + key.slice(1)}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Box>
      </CardContent>
    </Card>
  );
};

export default ProcessFlowUI;
