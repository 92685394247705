import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Container,
  Typography,
  Box,
  Button,
  Grid,
  CardActions,
  CardActionArea,
  Modal,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../providers/AuthProvider";
import CategoryTwoToneIcon from "@mui/icons-material/CategoryTwoTone";
import CustomSnackbar from "./CustomSnackbar";
import { deleteCategory, getCategories } from "@gogig-in/gogig-clients";

const CategoryListing: React.FC = () => {
  const [categoriesData, setCategoriesData] = useState<any[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { authHeader } = useAuthContext();
  const history = useNavigate();
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response: any = await getCategories();
        setCategoriesData(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    if (isLoading) {
      fetchData();
    }
  }, [authHeader, isLoading]);

  const handleRemoveCategoryClick = (categoryId: string) => {
    setSelectedCategoryId(categoryId);
    setIsModalOpen(true);
  };

  const handleDeleteCategory = async () => {
    try {
      if (authHeader && selectedCategoryId) {
        await deleteCategory(selectedCategoryId, authHeader);
        setIsLoading(true);
        handleSnackbar("success", "Category deleted successfully.");
      }
    } catch (error) {
      console.error(error);
      handleSnackbar("error", "Failed to delete category.");
    }
    setIsModalOpen(false);
  };

  const handleCancelDelete = () => {
    setIsModalOpen(false);
    setSelectedCategoryId(null);
  };

  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (severity: any, message: string) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  return (
    <Container sx={{ marginTop: "30px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h4" sx={{ marginBottom: "15px" }}>
          Manage Categories
        </Typography>
        <Link to={"/create-category"}>
          <Button
            color="primary"
            variant="contained"
            sx={{ borderRadius: "21px" }}
          >
            Create Categories
          </Button>
        </Link>
      </Box>
      <Grid container spacing={2}>
        {isLoading ? (
          <Typography>Loading...</Typography>
        ) : (
          categoriesData.map((categoryDetails) => (
            <Grid item xs={12} sm={6} md={4} key={categoryDetails.categoryId}>
              <Card
                variant="outlined"
                sx={{ backgroundColor: "background.default" }}
              >
                <CardActionArea>
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {categoryDetails.categoryIconUrl ? (
                        <img
                          src={categoryDetails.categoryIconUrl}
                          alt={`Logo of ${categoryDetails.categoryName}`}
                          style={{
                            width: "40px",
                            height: "40px",
                            marginRight: "8px",
                            borderRadius: "50%",
                          }}
                        />
                      ) : (
                        <CategoryTwoToneIcon
                          style={{ fontSize: 40, marginRight: "8px" }}
                        />
                      )}
                      <Typography variant="h6">
                        {categoryDetails.categoryName}
                      </Typography>
                    </Box>
                    <Typography variant="body1" sx={{ marginTop: "8px" }}>
                      Basic Requirements:
                      {categoryDetails.basicRequirements || "....."}
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: "8px" }}>
                      Average Salary: {categoryDetails.averageSalary || "....."}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    size="medium"
                    onClick={() =>
                      handleRemoveCategoryClick(categoryDetails.categoryId)
                    }
                    sx={{ textTransform: "none", color: "#F8675B" }}
                  >
                    Delete Category
                  </Button>
                  <Button
                    size="medium"
                    onClick={() => {
                      history(`/update-category/${categoryDetails.categoryId}`);
                    }}
                    sx={{ textTransform: "none" }}
                  >
                    Edit Category Details
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))
        )}
      </Grid>
      <Modal
        open={isModalOpen}
        onClose={handleCancelDelete}
        aria-labelledby="delete-category-modal"
        aria-describedby="delete-category-modal-description"
        BackdropProps={{ onClick: handleBackdropClick }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="delete-category-modal" variant="h6" component="h2">
            Are you sure you want to delete the category{" "}
            {selectedCategoryId &&
              categoriesData.find(
                (cat) => cat.categoryId === selectedCategoryId
              )?.categoryName}
            ?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button onClick={handleCancelDelete} variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={handleDeleteCategory}
              variant="outlined"
              color="error"
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />
    </Container>
  );
};

export default CategoryListing;
