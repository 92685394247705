import React from "react";
import { Card, CardContent, Box, Skeleton } from "@mui/material";

interface SkeletonModalProps {
  numberOfCards?: number;
  skeletonType?: string;
}

const SkeletonModal: React.FC<SkeletonModalProps> = ({
  numberOfCards,
  skeletonType,
}) => {
  return (
    <div>
      {skeletonType === "dashboard" ? (
        <React.Fragment>
          {[...Array(numberOfCards)].map((_, index) => (
            <Card
              key={index}
              variant="outlined"
              sx={{
                backgroundColor: "background.default",
                margin: "20px 0",
                width: "100%",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <Skeleton
                  variant="circular"
                  width={50}
                  height={50}
                  sx={{ marginLeft: "10px", marginTop: "10px" }}
                />
                <Skeleton
                  variant="rectangular"
                  width={100}
                  height={40}
                  sx={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    margin: "10px",
                  }}
                />
                <Skeleton variant="text" width={150} />
                <Skeleton variant="text" width={100} />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Skeleton variant="text" width={200} />
                  <Skeleton variant="text" width={250} />
                </Box>
              </CardContent>
            </Card>
          ))}
        </React.Fragment>
      ) : skeletonType === "default" ? (
        <React.Fragment>
          <Skeleton variant="text" width={150} />
          <Skeleton variant="text" width={100} />
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default SkeletonModal;
