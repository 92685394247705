import React, { useEffect, useState } from "react";
import ManageUsersUI from "./ManageUsersUI";
import { useAuthContext } from "../../providers/AuthProvider";
import { getListOfUsers } from "@gogig-in/gogig-clients";

const ManageUsers: React.FC = () => {
  const { authHeader } = useAuthContext();
  const [userData, setUserData] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchResults, setSearchResults] = useState<any[]>([]);

  useEffect(() => {
    fetchData(); // eslint-disable-next-line
  }, [authHeader]);

  const fetchData = async () => {
    try {
      if (authHeader) {
        const response: any = await getListOfUsers(authHeader);
        setUserData(response.data);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    const filteredUsers = userData.filter((user) =>
      Object.values(user).some((value) =>
        value
          ? value.toString().toLowerCase().includes(searchTerm.toLowerCase())
          : false
      )
    );

    const sortedFilteredData = filteredUsers.sort((a, b) => {
      const aSimilarity = calculateSimilarity(a, searchTerm);
      const bSimilarity = calculateSimilarity(b, searchTerm);
      return bSimilarity - aSimilarity;
    });

    setSearchResults(sortedFilteredData);
  }, [searchTerm, userData]);

  const calculateSimilarity = (user: any, term: string) => {
    let similarity = 0;
    Object.values(user).forEach((value) => {
      if (value) {
        const stringValue = value.toString().toLowerCase();
        if (stringValue.includes(term.toLowerCase())) {
          similarity += term.length / stringValue.length;
        }
      }
    });
    return similarity;
  };

  const reloadData = () => {
    fetchData();
  };

  return (
    <ManageUsersUI
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      searchResults={searchResults}
      reloadData={reloadData}
    />
  );
};

export default ManageUsers;
