import { Box, Container } from "@mui/material";
import LoginComponent from "./login/LoginComponent";

const LoginPage = () => {
  return (
    <Container sx={{ display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          maxWidth: "400px",
          border: "1px solid #ddd",
          borderRadius: "10px",
          padding: "25px",
          marginTop: "25px",
        }}
      >
        <LoginComponent />
      </Box>
    </Container>
  );
};

export default LoginPage;
