import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SpotrueApiBaseUrl } from "./baseUrls";

export async function processingProgress(authHeader: string,gogigReqId : string) {
  const url = `${SpotrueApiBaseUrl}/processingProgress?gogigReqId=${gogigReqId}`;
  return fetchWrapper(url, {
    method: "GET",
    headers: {
      Authorization: authHeader,
    },
  });
}