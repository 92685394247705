import React from "react";
import { Chip, Avatar } from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import BuildIcon from "@mui/icons-material/Build";

// Define the types for the props
interface UserRoleChipProps {
  userDetails: {
    role: string | undefined;
  };
  userRoles: {
    admin: string;
    operations: string;
  };
}

const UserRoleChip: React.FC<UserRoleChipProps> = ({
  userDetails,
  userRoles,
}) => {
  if (!userDetails) return null;

  const isUserAdmin = userDetails.role === userRoles.admin;

  return (
    <Chip
      avatar={
        <Avatar sx={{ bgcolor: "transparent" }}>
          {isUserAdmin ? (
            <AdminPanelSettingsIcon sx={{ color: "white" }} />
          ) : (
            <BuildIcon sx={{ color: "white" }} />
          )}
        </Avatar>
      }
      label={isUserAdmin ? "Admin" : "Operations"}
      sx={{
        backgroundColor: isUserAdmin ? "#2196F3" : "#009688",
        color: "white",
      }}
    />
  );
};

export default UserRoleChip;
