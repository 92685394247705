import { ChartsLegendProps, PieChart } from "@mui/x-charts";
import { Grid, Typography, Box } from "@mui/material";

import ReportTextComponent from "../reportTextComponent";
import { PieChartComponentProps } from "../reportComponentProps";

const valueFormatter = (item: { value: number }) => `${item.value}%`;
const tamperedImageColor = "#A67575";
const correctImageColor = "#A8C9A5";
const uncertainImageColor = "#F7DEC1";
export const PieChartComponent: React.FC<{ data: PieChartComponentProps }> = ({
  data,
}) => {
  const seriesData = [
    {
      id: "tamperedImages",
      valuePercentage: data.tamperedImagesPercentage,
      value:data.tamperedImages,
      label: "Tampered Images",
      color: tamperedImageColor,
    },
    {
      id: "correctImages",
      valuePercentage: data.correctImagesPercentage,
      value:data.correctImages,
      label: "Correct Images",
      color: correctImageColor,
    },
    {
      id: "uncertainImages",
      valuePercentage: data.uncertainImagesPercentage,
      value:data.uncertainImages,
      label: "Uncertain Images",
      color: uncertainImageColor,
    },
  ];

  return (
    <Grid container spacing={2} sx={{ padding: 2 }}>
      <Grid item xs={6}>
        <ReportTextComponent
          text="Tampered Images"
          subString={data?.tamperedImages}
        />
        <ReportTextComponent
          text="Correct Images"
          subString={data?.correctImages}
        />
        <ReportTextComponent
          text="Uncertain Images"
          subString={data?.uncertainImages}
        />
        <ReportTextComponent
          text="Tampered Images %"
          subString={`${data?.tamperedImagesPercentage} %`}
        />
        <ReportTextComponent
          text="Correct Images %"
          subString={`${data?.correctImages} %`}
        />
        <ReportTextComponent
          text="Uncertain Images %"
          subString={`${data?.uncertainImages} %`}
        />
      </Grid>
      <Grid item xs={6}>
        <Grid
          container
          sx={{ display: "flex", flexDirection: "row" }}
          spacing={2}
        >
          <Grid item xs={6}>
            <Typography variant="h5" color="primary" mb={1}>
              Tampered Images %
            </Typography>
            <PieChart
              colors={[
                tamperedImageColor,
                correctImageColor,
                uncertainImageColor,
              ]}
              series={[
                {
                  data: seriesData,
                  arcLabel: () => "",
                  arcLabelMinAngle: 20,
                  valueFormatter,
                },
              ]}
              slotProps={{
                legend: {
                  hidden: true, // Show the legend
                } as ChartsLegendProps,
              }}
              width={300}
              height={200}
            />
          </Grid>
          <Grid item xs={6} sx={{ mt: 1 }}>
            {seriesData.map((item) => (
              <Box
                key={item.id}
                sx={{ display: "flex", alignItems: "center", mb: 1 }}
              >
                <Box
                  sx={{
                    width: 16,
                    height: 16,
                    backgroundColor: item.color,
                    marginRight: 1,
                  }}
                />
                <Typography variant="body1">
                  <strong>{item.label}:</strong> {item.value} ({item.valuePercentage}%)
                </Typography>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
