import React, { useEffect, useState } from "react";
import {
  TextField,
  Container,
  Typography,
  Box,
  Grid,
  Avatar,
  Button,
  IconButton,
  FormHelperText,
} from "@mui/material";
import CustomButton from "../ButtonWrapper";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../providers/AuthProvider";
import MDEditor from "@uiw/react-md-editor";
import CategoryTwoToneIcon from "@mui/icons-material/CategoryTwoTone";
import CustomSnackbar from "./CustomSnackbar";
import {
  createCategory,
  getCategoryDetailsByCategoryId,
  updateCategoryDetails,
} from "@gogig-in/gogig-clients";

export interface CategoryDetails {
  categoryName: string;
  categoryDescription: string;
  averageSalary: string;
  basicRequirements: string;
  categoryDocumentUrl: string;
  categoryVideoUrl: string;
  [key: string]: string | number | Date | null;
}

const initialCategoryDetails: CategoryDetails = {
  categoryName: "",
  categoryDescription: "",
  averageSalary: "",
  basicRequirements: "",
  categoryDocumentUrl: "",
  categoryVideoUrl: "",
};

const CreateCategory: React.FC = () => {
  const [categoryDetails, setCategoryDetails] = useState<CategoryDetails>(
    initialCategoryDetails
  );
  const { authHeader } = useAuthContext();
  const [file, setFile] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [isPreviewAvailable, setIsPreviewAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [descValue, setDescValue] = useState<string>("");
  const { categoryId } = useParams();
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategoryDetails = async () => {
      if (categoryId) {
        try {
          const response: any = await getCategoryDetailsByCategoryId(
            categoryId
          );
          const categoryDetailsResponse = response.data;
          console.log(categoryDetailsResponse);
          setCategoryDetails((prevCategoryDetails) => ({
            ...prevCategoryDetails,
            ...categoryDetailsResponse,
          }));
          if (categoryDetailsResponse.categoryIconUrl) {
            setPreviewImage(categoryDetailsResponse.categoryIconUrl);
            setIsPreviewAvailable(true);
          }
        } catch (error) {
          console.error("Error fetching category details", error);
        }
      }
    };
    fetchCategoryDetails();
  }, [categoryId]);

  const handleChange = (name: string, value: any) => {
    setCategoryDetails((prevCategoryDetails) => ({
      ...prevCategoryDetails,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();

    const keysToAppend = [
      "categoryName",
      "categoryDescription",
      "averageSalary",
      "basicRequirements",
      "categoryDocumentUrl",
      "categoryVideoUrl",
    ];

    for (const key of keysToAppend) {
      const value = categoryDetails[key];

      if (value instanceof Date) {
        formData.append(key, value.toISOString());
      } else if (value !== null && value !== undefined) {
        formData.append(key, value.toString());
      }
    }

    if (file) {
      formData.append("categoryIconUrl", file);
    }

    try {
      if (authHeader) {
        if (categoryId) {
          const categoryDetails = formData;
          await updateCategoryDetails(categoryDetails, authHeader, categoryId);
          handleSnackbar("success", "Category updated successfully.");
        } else {
          await createCategory(formData, authHeader);
          handleSnackbar("success", "Category created successfully.");
        }
      }
      setTimeout(() => {
        navigate("/category-list");
      }, 3000);
    } catch (error) {
      console.error(error);
      handleSnackbar("success", "Error saving/updating category details.");
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);
      const blobUrl = URL.createObjectURL(selectedFile);
      setPreviewImage(blobUrl);
      setIsPreviewAvailable(true);
    }
  };

  const handleClearLogo = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setFile(null);
    setPreviewImage(null);
    setIsPreviewAvailable(false);
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (severity: any, message: string) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  return (
    <Container sx={{ marginTop: 2, marginBottom: "20px" }}>
      <Typography sx={{ marginBottom: "10px" }}>
        <span style={{ fontSize: "22px", fontWeight: "bold" }}>
          Create Category
        </span>
        ( Enter Category details and description.)
      </Typography>
      <form onSubmit={handleSubmit}>
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "15px",
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  id="fileInput"
                />
                <label htmlFor="fileInput">
                  <Avatar
                    alt="Preview"
                    variant="rounded"
                    src={previewImage ?? ""}
                    sx={{ width: 150, height: 150 }}
                  >
                    {previewImage ? null : (
                      <CategoryTwoToneIcon sx={{ fontSize: "150px" }} />
                    )}
                  </Avatar>
                  {isPreviewAvailable && (
                    <IconButton
                      onClick={handleClearLogo}
                      size="small"
                      sx={{ marginTop: 1 }}
                      color="error"
                    >
                      Remove Logo <ClearIcon />
                    </IconButton>
                  )}
                  {!isPreviewAvailable && (
                    <label htmlFor="fileInput">
                      <Button
                        component="div"
                        variant="outlined"
                        color="primary"
                        startIcon={<AddAPhotoIcon />}
                        sx={{ textTransform: "none", margin: "5px" }}
                      >
                        Upload Logo
                      </Button>
                    </label>
                  )}
                </label>
              </Box>
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                label="Category Name"
                name="categoryName"
                fullWidth
                sx={{ margin: "8px 0px" }}
                onChange={(e) => handleChange("categoryName", e.target.value)}
                value={categoryDetails.categoryName}
              />
              <TextField
                name="basicRequirements"
                label="Basic Requirements"
                fullWidth
                multiline
                onChange={(event) =>
                  handleChange("basicRequirements", event.target.value)
                }
                value={categoryDetails.basicRequirements}
                rows={2}
              />
              <TextField
                name="averageSalary"
                label="Average Salary"
                fullWidth
                sx={{ marginTop: "8px" }}
                onChange={(event) =>
                  handleChange("averageSalary", event.target.value)
                }
                value={categoryDetails.averageSalary}
              />
              <FormHelperText>
                Enter the salary as "000 - 000 /day or /task"
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="categoryDocumentUrl"
                label="Category Document Url"
                fullWidth
                onChange={(e) =>
                  handleChange("categoryDocumentUrl", e.target.value)
                }
                value={categoryDetails.categoryDocumentUrl}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="categoryVideoUrl"
                label="Category Video Url"
                fullWidth
                onChange={(e) =>
                  handleChange("categoryVideoUrl", e.target.value)
                }
                value={categoryDetails.categoryVideoUrl}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography sx={{ padding: "10px 0px" }}>
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    marginRight: "4px",
                  }}
                >
                  Category Description
                </span>
                (Write category description, roles and responsibilities,
                important notes, and provide key details for easy understanding
                of the category)
              </Typography>
              <Box data-color-mode="light">
                <MDEditor
                  style={{
                    width: "100%",
                    minHeight: "350px",
                  }}
                  value={descValue || categoryDetails.categoryDescription}
                  onChange={(value) => {
                    const updatedValue = value || "";
                    setDescValue(updatedValue);
                    handleChange("categoryDescription", updatedValue);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 2,
            }}
          >
            {loading ? (
              <CustomButton color="primary" variant="contained" disabled>
                {categoryId
                  ? "Updating category Details"
                  : "Saving category Details"}
              </CustomButton>
            ) : (
              <CustomButton
                tag="EditCategory/UpdateCategory"
                type="submit"
                color="primary"
                variant="contained"
              >
                {categoryId
                  ? "Update category Details"
                  : "Save category Details"}
              </CustomButton>
            )}
          </Box>
        </>
      </form>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />
    </Container>
  );
};

export default CreateCategory;
