import {
  Box,
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  Divider,
  Button,
  CircularProgress,
} from "@mui/material";
import { VehicleAuditDetails } from "../../vehicleAuditComponents/types";
import { useState } from "react";
import GlobalSnackbar from "../../../snackbarComponent/Snackbar";
import { useSnackbar } from "../../../snackbarComponent/useSnackbar";
import { SaveVehiclePropertyParams } from "./UnknownVehicleDataUI";
import { useAuthContext } from "../../../../providers/AuthProvider";
import addVehicleProperty from "../../../../clients/addVehicleProperty";

export const UnknownVehicleDataFields: React.FC<{
  vehicleData: VehicleAuditDetails;
  property: string;
  sourceValue: string;
  allowMultipleSelection?: boolean;
  gogigReqId?:string;
}> = ({
  vehicleData,
  property,
  sourceValue,
  allowMultipleSelection = false,
  gogigReqId,
}) => {
  const [statuses, setStatuses] = useState<{ [key: string]: string }>({});
  const { snackbar, openSnackbar, closeSnackbar } = useSnackbar();
  const { authHeader } = useAuthContext();
  const [selectedOptions, setSelectedOptions] = useState<{
    [key: string]: string[] | string;
  }>({});
  const [isSaving, setIsSaving] = useState<boolean|null>(false);

  const statusOptions = ["valid", "invalid", "uncertain"];
  const modelOptions = [
    "MAHINDRA & MAHINDRA",
    "ATUL",
    "APE CITY",
    "TVS KING",
    "RE COMPACT",
    "RE",
  ];
  const manufacturerOptions = [
    "Mahindra & Mahindra",
    "Atul Auto",
    "Piaggio",
    "TVS Motor",
    "Bajaj Auto",
  ];
  const colorOptions = ["Black", "Yellow", "Green", "Pink","Blue"];

  const currentStatus = statuses[vehicleData.vehicleAuditId];
  const handleStatusChange = (vehicleId: string, newStatus: string) => {
    setStatuses((prev) => ({ ...prev, [vehicleId]: newStatus }));
    if (newStatus === "uncertain" || newStatus === "invalid") {
      setSelectedOptions((prev) => ({ ...prev, [vehicleId]: [] }));
    }
  };
  const handleOptionChange = (
    vehicleId: string,
    options: string[] | string
  ) => {
    setSelectedOptions((prev) => ({ ...prev, [vehicleId]: options }));
  };

  const handleSave = async (
    vehicleData: VehicleAuditDetails,
    property: string,
    status: string,
    propertyValue: string,
    propertyAlias: string[] | string
  ) => {
    // Prepare the base payload
    const basePayload = {
      vehicleType: vehicleData.vehicleClassFromPhoto,
      property,
      status,
      propertyValue,
      propertyAlias,
    } as SaveVehiclePropertyParams;

    if (!authHeader || !gogigReqId) {alert("gog");
       return;} ;
    setIsSaving(true);

    try {
      // If multiple colors are selected, loop through them
      if (property === "color" && Array.isArray(propertyAlias)) {
        for (const color of propertyAlias) {
          const payload = {
            ...basePayload,
            propertyAlias: color,
          } as SaveVehiclePropertyParams;
          await addVehicleProperty(authHeader,gogigReqId, payload);

          openSnackbar(`Successfully saved color: ${color}`, "success");
        }
        setIsSaving(null);
      } else {
        // Handle single selection or other properties
        const payload = {
          ...basePayload,
          propertyAlias,
        } as SaveVehiclePropertyParams;
      await addVehicleProperty(authHeader,gogigReqId, payload);
        openSnackbar("Successfully saved", "success");
        setIsSaving(null);
      }
    } catch (error) {
      console.error("Failed saving", error);
      openSnackbar("Saving failed", "error");
      setIsSaving(false);
    }
  };

  // Ensure that dropdown is hidden when status is uncertain or invalid
  const showDropdown =
    currentStatus !== "uncertain" &&
    currentStatus !== "invalid" &&
    property !== "vehicleClass";

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      key={vehicleData.vehicleAuditId}
      sx={{
        maxWidth: 800,
        padding: 3,
        border: "1px solid #ddd",
        borderRadius: 2,
        backgroundColor: "#f9f9f9",
        marginBottom: 2,
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <GlobalSnackbar
        open={snackbar.open}
        message={snackbar.message}
        onClose={closeSnackbar}
        severity={snackbar.severity}
        vertical={"top"}
        horizontal={"center"}
      />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={2}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", textAlign: "center" }}
          >
            {vehicleData.rcNumber}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="subtitle2">Property</Typography>
            <TextField
              label="Property"
              value={property}
              InputProps={{ readOnly: true }}
              fullWidth
            />
            <Typography variant="subtitle2">Source Data</Typography>
            <TextField
              label={`${property} from Source`}
              value={sourceValue}
              InputProps={{ readOnly: true }}
              fullWidth
            />
          </Box>
        </Grid>

        <Grid item xs={6} sm={3}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="subtitle2">Status</Typography>
            <Select
              value={currentStatus || ""}
              onChange={(e) =>
                handleStatusChange(vehicleData.vehicleAuditId, e.target.value)
              }
              fullWidth
            >
              {statusOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>

            {/* Only render the property selection dropdown when showDropdown is true */}
            {showDropdown && (
              <>
                <Typography variant="subtitle2">
                  {property === "color"
                    ? "Select Color(s)"
                    : `Select ${property}`}
                </Typography>
                <Select
                  multiple={property === "color" && allowMultipleSelection}
                  value={
                    selectedOptions[vehicleData.vehicleAuditId] ||
                    (property === "color" ? [] : "")
                  }
                  onChange={(e) =>
                    handleOptionChange(
                      vehicleData.vehicleAuditId,
                      property === "color"
                        ? (e.target.value as string[])
                        : (e.target.value as string)
                    )
                  }
                  fullWidth
                >
                  {(property === "model"
                    ? modelOptions
                    : property === "manufacturer"
                    ? manufacturerOptions
                    : colorOptions
                  ).map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} sm={2} display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              handleSave(
                vehicleData,
                property,
                statuses[vehicleData.vehicleAuditId],
                sourceValue,
                selectedOptions[vehicleData.vehicleAuditId]
              )
            }
            disabled={isSaving===null}
            sx={{
              textTransform: "none",
              padding: "8px 16px",
              fontSize: "0.875rem",
              backgroundColor: isSaving ? "#757575" : "#1a237e",
              "&:hover": {
                backgroundColor: isSaving ? "#757575" : "#3949ab",
              },
            }}
            startIcon={
              isSaving && <CircularProgress size={20} color="inherit" />
            }
          >
              {isSaving ? "Saving..." :isSaving===null? "Saved":"Save"}
          </Button>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 2 }} />
    </Box>
  );
};
