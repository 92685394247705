import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SpotrueApiBaseUrl } from "./baseUrls";

export async function getUnknownFlags(authHeader: string,gogigReqId:string) {
  return fetchWrapper(`${SpotrueApiBaseUrl}/getUnknownFlags?gogigReqId=${gogigReqId}`, {
    method: "GET",
    headers: {
      Authorization: authHeader,
    },
  });
}