import React, { useEffect, useRef, useState } from "react";
import {
  FormControl,
  MenuItem,
  TextField,
  Container,
  Typography,
  Box,
  Grid,
  Autocomplete,
  Avatar,
  Button,
  InputAdornment,
  styled,
  LinearProgress,
  linearProgressClasses,
  IconButton,
} from "@mui/material";
import CustomButton from "../ButtonWrapper";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import ClearIcon from "@mui/icons-material/Clear";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../providers/AuthProvider";
import MDEditor from "@uiw/react-md-editor";
import {
  getGigDetailsByGigId,
  saveGigDetails,
  updateGigDetails} from "@gogig-in/gogig-clients"
import { getGigCategories } from "../../clients/getGigCategories";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

export interface GigDetails {
  companyName: string;
  gigTitle: string;
  category: string;
  description: string;
  skillsRequired: string;
  academicQualificationRequired: string;
  ageRestriction: number | null;
  site: string;
  location: string;
  communicationLanguages: string | null;
  gigStartDate: Date | null;
  gigEndDate: Date | null;
  notes: string;
  minWagePerDay: string;
  minCallsPerDay: string;
  pricePerClient: string;
  requirements: string;
  responsibilities: string;
  [key: string]: string | number | Date | null;
}
interface Category {
  categoryId: string;
  categoryName: string;
}

const initialGigDetails: GigDetails = {
  companyName: "",
  gigTitle: "",
  category: "",
  description: "",
  skillsRequired: "",
  academicQualificationRequired: "",
  ageRestriction: 18,
  site: "",
  location: "",
  communicationLanguages: null,
  gigStartDate: null,
  gigEndDate: null,
  notes: "",
  minCallsPerDay: "",
  minWagePerDay: "",
  pricePerClient: "",
  requirements: "",
  responsibilities: "",
};

const majorLanguages = [
  "Assamese (India)",
  "Bengali (India)",
  "English (India)",
  "Gujarati (India)",
  "Hindi (India)",
  "Kannada (India)",
  "Konkani (India)",
  "Marathi (India)",
  "Nepali",
  "Odia (India)",
];
const siteOptions = ["On-site", "Remote", "Hybrid"];
const qualificationOptions = [
  "Less than 10th",
  "10th Grade",
  "12th Grade",
  "Under Graduation(UG)",
  "Post Graduation(PG)",
];

const BasicGigDetails: React.FC = () => {
  const [gigDetails, setGigDetails] = useState<GigDetails>(initialGigDetails);
  const [requiredFieldsErrors, setRequiredFieldsErrors] = useState({
    companyName: false,
    gigTitle: false,
    category: false,
    gigStartDate: false,
    minWagePerDay: false,
    pricePerClient: false,
    academicQualificationRequired: false,
    communicationLanguages: false,
  });
  const { authHeader } = useAuthContext();
  const [gigStartDate, setGigStartDate] = useState<Date | null>(null);
  const [gigEndDate, setGigEndDate] = useState<Date | null>(null);
  const history = useNavigate();
  const location = useLocation();
  const gigId = location.state?.gigId;
  const [file, setFile] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [isPreviewAvailable, setIsPreviewAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [descValue, setDescValue] = useState<string>("");
  const [descError, setDescError] = useState<boolean>(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const createGigRef = useRef<HTMLButtonElement>(null);
  const requiredFields = [
    "companyName",
    "gigTitle",
    "category",
    "gigStartDate",
    "communicationLanguages",
    "academicQualificationRequired",
    "minWagePerDay",
    "pricePerClient",
  ];

  useEffect(() => {
    const fetchGigDetails = async () => {
      if (gigId) {
        try {
          const response: any = await getGigDetailsByGigId(gigId);
          const gigDetailsResponse = response.data;
          setGigDetails((prevGigDetails) => ({
            ...prevGigDetails,
            ...gigDetailsResponse,
          }));
          setGigStartDate(
            gigDetailsResponse.gigStartDate &&
              gigDetailsResponse.gigStartDate !== "0000-00-00"
              ? new Date(gigDetailsResponse.gigStartDate)
              : null
          );
          setGigEndDate(
            gigDetailsResponse.gigEndDate &&
              gigDetailsResponse.gigEndDate !== "0000-00-00"
              ? new Date(gigDetailsResponse.gigEndDate)
              : null
          );
          if (gigDetailsResponse.companyLogo) {
            setPreviewImage(gigDetailsResponse.companyLogo);
            setIsPreviewAvailable(true);
          }
        } catch (error) {
          console.error("Error fetching gig details", error);
        }
      }
    };
    fetchGigDetails();
  }, [gigId]);
  useEffect(() => {
    if (createGigRef.current) {
      createGigRef.current.focus();
    }
    const fetchCategories = async () => {
      try {
        // Fetch categories from API
        const response = await getGigCategories();
        setCategories(response.data as Category[]);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  const handleChange = (name: string, value: any) => {
    setGigDetails((prevGigDetails) => ({
      ...prevGigDetails,
      [name]: value,
    }));
    // Remove error when input changes
    setRequiredFieldsErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
    if (name === "description") {
      const desc = value ? value : "";
      value ? setDescError(false) : setDescError(true);
      setDescValue(desc);
    }
    if (name === "gigStartDate") {
      // Parse the value into a Date object or set to null if it's empty
      const startDate = value ? new Date(value) : null;
      setGigStartDate(startDate);
    }
    if (name === "gigEndDate") {
      const endDate = value ? new Date(value) : null;
      setGigEndDate(endDate);
    }
  };

  const handleLanguagesChange = (newValue: string[] | null) => {
    if (newValue) {
      const isError = !newValue || newValue.length === 0;
      const languagesString = newValue.join(", ");
      setGigDetails((prevGigDetails) => ({
        ...prevGigDetails,
        communicationLanguages: languagesString,
      }));
      setRequiredFieldsErrors((prevErrors) => ({
        ...prevErrors,
        communicationLanguages: isError,
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!gigDetails.description) {
      setDescError(true);
    }
    const errors: any = {};
    let hasError = false;

    // Check for empty required fields

    requiredFields.forEach((field) => {
      if (!gigDetails[field]) {
        errors[field] = true;
        hasError = true;
      }
      if (gigDetails[field] === "0000-00-00") {
        errors[field] = true;
        hasError = true;
      }
    });

    // Update errors state
    setRequiredFieldsErrors(errors);

    if (hasError) {
      // Focus on the first empty required field
      const firstEmptyField = requiredFields.find((field) => errors[field]);
      const inputField = document.getElementsByName(
        firstEmptyField as string
      )[0] as HTMLInputElement;
      if (inputField) {
        inputField.focus();
      }
      return;
    }
    if (!gigDetails.description) {
      setDescError(true);
      return;
    }
    setLoading(true);
    const formData = new FormData();

    const keysToAppend = [
      "companyName",
      "gigTitle",
      "category",
      "description",
      "skillsRequired",
      "academicQualificationRequired",
      "ageRestriction",
      "site",
      "location",
      "communicationLanguages",
      "notes",
      "minWagePerDay",
      "minCallsPerDay",
      "pricePerClient",
      "requirements",
      "responsibilities",
    ];

    for (const key of keysToAppend) {
      const value = gigDetails[key];

      if (value instanceof Date) {
        formData.append(key, value.toISOString());
      } else if (value !== null && value !== undefined) {
        formData.append(key, value.toString());
      }
    }

    formData.append("gigStartDate", gigStartDate?.toISOString() || "");
    formData.append("gigEndDate", gigEndDate?.toISOString() || "");

    if (file) {
      formData.append("file", file);
    }

    try {
      if (authHeader) {
        let response;

        if (gigId) {
          const gigDetails = formData;
          response = await updateGigDetails(gigDetails, authHeader, gigId);
          const details: any = response.data;
          const success: boolean = details.success;

          if (success === false) {
            throw new Error("Error updating gig details");
          }

          const updatedGigId = details.gigId;
          history("/create-gig/pre-acceptance-form", {
            state: {
              gigId: updatedGigId,
            },
          });
        } else {
          response = await saveGigDetails(formData, authHeader);

          const details: any = response.data;
          const success: boolean = details.success;

          if (success === false) {
            throw new Error("Error saving gig details");
          }

          const newGigId = details.gigId;
          history("/create-gig/pre-acceptance-form", {
            state: {
              category: gigDetails.category,
              gigId: newGigId,
            },
          });
        }
      }
    } catch (error) {
      console.error("Error saving/updating gig details:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);
      const blobUrl = URL.createObjectURL(selectedFile);
      setPreviewImage(blobUrl);
      setIsPreviewAvailable(true);
    }
  };

  const handleClearLogo = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setFile(null);
    setPreviewImage(null);
    setIsPreviewAvailable(false);
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!gigDetails.description) {
      setDescError(true);
    }
    const errors: any = {};
    let hasError = false;

    // Check for empty required fields
    requiredFields.forEach((field) => {
      if (!gigDetails[field]) {
        errors[field] = true;
        hasError = true;
      }
    });

    // Update errors state
    setRequiredFieldsErrors(errors);

    if (hasError) {
      // Focus on the first empty required field
      const firstEmptyField = requiredFields.find((field) => errors[field]);
      const inputField = document.getElementsByName(
        firstEmptyField as string
      )[0] as HTMLInputElement;
      if (inputField) {
        inputField.focus();
      }
      return;
    }
    if (!gigDetails.description) {
      setDescError(true);
      return;
    }

    history("/create-gig/pre-acceptance-form", {
      state: {
        gigId: gigId,
      },
    });
  };

  return (
    <Container sx={{ marginTop: 2, marginBottom: "20px" }}>
      <Box
        sx={{
          justifyContent: "space-between",
          display: "flex",
          margin: "0px 5px",
        }}
      >
        <Typography variant="h2">Create Gig</Typography>
        <Typography variant="h6" sx={{ margin: "auto" }}>
          Step 1 out of 4
        </Typography>
        {gigId && <Button onClick={handleNext}>Next</Button>}
      </Box>
      <BorderLinearProgress
        variant="determinate"
        value={25}
        sx={{ marginBottom: "10px" }}
      />
      <Typography variant="h3" sx={{ marginBottom: "10px" }}>
        Enter basic details and description.
      </Typography>
      <form onSubmit={handleSubmit}>
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  id="fileInput"
                />
                <label htmlFor="fileInput">
                  <Avatar
                    alt="Preview"
                    variant="rounded"
                    src={previewImage ?? ""}
                    sx={{ width: 150, height: 150 }}
                  />
                  {isPreviewAvailable && (
                    <IconButton
                      onClick={handleClearLogo}
                      size="small"
                      sx={{ marginTop: 1 }}
                      color="error"
                    >
                      Remove Logo <ClearIcon />
                    </IconButton>
                  )}
                  {!isPreviewAvailable && (
                    <label htmlFor="fileInput">
                      <Button
                        component="div"
                        variant="outlined"
                        color="primary"
                        startIcon={<AddAPhotoIcon />}
                        sx={{ textTransform: "none", margin: "5px" }}
                      >
                        Upload Logo
                      </Button>
                    </label>
                  )}
                </label>
              </Box>
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                label="companyName"
                name="companyName"
                fullWidth
                inputRef={createGigRef}
                sx={{ margin: "20px 0px" }}
                error={requiredFieldsErrors.companyName}
                helperText={
                  requiredFieldsErrors.companyName
                    ? "company name is required"
                    : ""
                }
                onChange={(e) => handleChange("companyName", e.target.value)}
                value={gigDetails.companyName}
              />
              <TextField
                name="gigTitle"
                label="gigTitle"
                fullWidth
                error={requiredFieldsErrors.gigTitle}
                helperText={
                  requiredFieldsErrors.gigTitle ? "gig title is required" : ""
                }
                onChange={(e) => handleChange("gigTitle", e.target.value)}
                value={gigDetails.gigTitle}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="category"
                label="gigCategory"
                select
                fullWidth
                error={requiredFieldsErrors.category}
                helperText={
                  requiredFieldsErrors.category ? "category is required" : ""
                }
                value={gigDetails.category}
                onChange={(e) => handleChange("category", e.target.value)}
              >
                <MenuItem value="">Select Category</MenuItem>
                {categories &&
                  categories.map((category) => (
                    <MenuItem
                      key={category.categoryId}
                      value={category.categoryId}
                    >
                      {category.categoryName}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                type="date"
                name="gigStartDate"
                label="gigStartDate"
                fullWidth
                onChange={(e) => {
                  handleChange("gigStartDate", e.target.value);
                }}
                error={requiredFieldsErrors.gigStartDate}
                helperText={
                  requiredFieldsErrors.gigStartDate
                    ? "start date is required"
                    : ""
                }
                value={
                  gigStartDate ? gigStartDate.toISOString().split("T")[0] : ""
                }
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: gigId ? "" : new Date().toISOString().split("T")[0],
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                type="date"
                name="gigEndDate"
                label="gigEndDate"
                fullWidth
                onChange={(e) => {
                  handleChange("gigEndDate", e.target.value);
                }}
                value={gigEndDate ? gigEndDate.toISOString().split("T")[0] : ""}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: gigStartDate
                    ? gigStartDate.toISOString().split("T")[0]
                    : new Date().toISOString().split("T")[0],
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  id="communicationLanguages"
                  options={majorLanguages}
                  onChange={(_, newValue) => handleLanguagesChange(newValue)}
                  value={(gigDetails.communicationLanguages || "")
                    .split(", ")
                    .filter((lang) => majorLanguages.includes(lang))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="communicationLanguages"
                      label="languages"
                      error={requiredFieldsErrors.communicationLanguages}
                      helperText={
                        requiredFieldsErrors.communicationLanguages
                          ? "language is required"
                          : ""
                      }
                    />
                  )}
                  filterOptions={(options) =>
                    options.filter((option) => option.trim() !== "")
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="location"
                label="location"
                fullWidth
                onChange={(e) => handleChange("location", e.target.value)}
                value={gigDetails.location}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="site"
                label="jobSite"
                select
                fullWidth
                onChange={(e) => handleChange("site", e.target.value)}
                value={gigDetails.site}
              >
                <MenuItem value="">Select Site</MenuItem>
                {siteOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="academicQualificationRequired"
                label="academicQualification"
                select
                fullWidth
                error={requiredFieldsErrors.academicQualificationRequired}
                helperText={
                  requiredFieldsErrors.academicQualificationRequired
                    ? "academic qualification is required"
                    : ""
                }
                onChange={(e) =>
                  handleChange("academicQualificationRequired", e.target.value)
                }
                value={gigDetails.academicQualificationRequired}
              >
                <MenuItem value="">Select Academic Qualification</MenuItem>
                {qualificationOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="ageRestriction"
                label="Age Restriction"
                fullWidth
                onChange={(e) => handleChange("ageRestriction", e.target.value)}
                value={
                  gigDetails.ageRestriction !== null
                    ? gigDetails.ageRestriction
                    : ""
                }
                type="number"
                inputProps={{
                  min: 18,
                  max: 100,
                }}
                defaultValue={18}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="minWagePerDay"
                label="minimumWage per day"
                fullWidth
                error={requiredFieldsErrors.minWagePerDay}
                helperText={
                  requiredFieldsErrors.minWagePerDay
                    ? "min wage per day is required"
                    : ""
                }
                onChange={(event) =>
                  handleChange("minWagePerDay", event.target.value)
                }
                value={gigDetails.minWagePerDay}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CurrencyRupeeIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="minCallsPerDay"
                label="minimumTasks per day"
                fullWidth
                onChange={(event) =>
                  handleChange("minCalssPerDay", event.target.value)
                }
                value={gigDetails.minCallsPerDay}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="pricePerClient"
                label="Price per task"
                fullWidth
                error={requiredFieldsErrors.pricePerClient}
                helperText={
                  requiredFieldsErrors.pricePerClient
                    ? "price per task is required"
                    : ""
                }
                onChange={(event) =>
                  handleChange("pricePerClient", event.target.value)
                }
                value={gigDetails.pricePerClient}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CurrencyRupeeIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography sx={{ padding: "10px 0px" }}>
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    marginRight: "4px",
                  }}
                >
                  Gig Description
                </span>
                (Write the gig description, roles and responsibilities,
                highlight important notes, and provide key details for easy
                understanding of the gig.)
                {descError ? (
                  <Typography color="error" variant="h5">
                    description is required
                  </Typography>
                ) : (
                  ""
                )}
              </Typography>
              <Box data-color-mode="light">
                <MDEditor
                  style={{
                    width: "100%",
                    minHeight: "450px",
                  }}
                  value={descValue || gigDetails.description}
                  onChange={(value) => {
                    const updatedValue = value || "";
                    handleChange("description", updatedValue);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 2,
            }}
          >
            {loading ? (
              <CustomButton color="primary" variant="contained" disabled>
                {gigId
                  ? "updating basic gig details"
                  : "saving basic gig details"}
              </CustomButton>
            ) : (
              <CustomButton
                tag="EditGig/UpdateGig"
                type="submit"
                color="primary"
                variant="contained"
              >
                {gigId ? "updating basic gig details" : "saving basic gig details"}
              </CustomButton>
            )}
          </Box>
        </>
      </form>
    </Container>
  );
};

export default BasicGigDetails;
