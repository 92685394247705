import React, { useState, useEffect } from "react";
import { getUnknownFlags } from "../../../../clients/getUnknownFlags";
import { useAuthContext } from "../../../../providers/AuthProvider";
import { useParams } from "react-router-dom";
import { VehicleAuditDetails } from "../../vehicleAuditComponents/types";
import GlobalSnackbar from "../../../snackbarComponent/Snackbar";
import { useSnackbar } from "../../../snackbarComponent/useSnackbar";
import { Box, Grid, Typography, Tabs, Tab, CircularProgress } from "@mui/material";
import { UnknownVehicleDataFields } from "./UnknownVehicleDataFields";
import { UnknownVehicleDataInstructions } from "./UnknownVehicleDataInstructions";

interface UnknownVehicleData {
  message?: string;
  unknownColorList: VehicleAuditDetails[];
  unknownManufacturerList: VehicleAuditDetails[];
  unknownModelList: VehicleAuditDetails[];
  unknownVehicleClassList: VehicleAuditDetails[];
}
export const tabInstructions = [
  "Instructions for Unknown Color: Select colors if the source value is valid. Set status to 'uncertain' if unsure.",
  "Instructions for Unknown Model: Select a model if the source value is valid. Set status to 'uncertain' if unsure.",
  "Instructions for Unknown Manufacturer: Choose the manufacturer if the source value is valid. Set status to 'uncertain' if unsure.",
  "Instructions for Unknown Vehicle Class: Verify the vehicle class and update if necessary.",
];

export interface SaveVehiclePropertyParams {
  vehicleType: string;
  property: string;
  status: string;
  propertyValue: string;
  propertyAlias: string;
}

const UnknownVehicleDataUI: React.FC = () => {
  const { gogigReqId } = useParams<{ gogigReqId: string }>();
  const { snackbar, openSnackbar, closeSnackbar } = useSnackbar();
  const { authHeader } = useAuthContext();

  const [unknownColors, setUnknownColors] = useState<VehicleAuditDetails[]>([]);
  const [unknownModels, setUnknownModels] = useState<VehicleAuditDetails[]>([]);
  const [unknownManufacturers, setUnknownManufacturers] = useState<VehicleAuditDetails[]>([]);
  const [unknownVehicleClasses, setUnknownVehicleClasses] = useState<VehicleAuditDetails[]>([]);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEmpty, setIsEmpty] = useState<boolean>(false); // New state for empty data

  useEffect(() => {
    const fetchUnknownVehicleData = async () => {
      if (!authHeader || !gogigReqId) return;

      setIsLoading(true);
      try {
        const res = await getUnknownFlags(authHeader, gogigReqId);
        const responseData = res.data as UnknownVehicleData;
        if (responseData.message  === "No Unknown Flags Detected") {
          setIsEmpty(true); // Set empty state
        } else {
          const unknownVehicleData = res.data as UnknownVehicleData;
          setUnknownColors(unknownVehicleData.unknownColorList);
          setUnknownModels(unknownVehicleData.unknownModelList);
          setUnknownManufacturers(unknownVehicleData.unknownManufacturerList);
          setUnknownVehicleClasses(unknownVehicleData.unknownVehicleClassList);
          setIsEmpty(false); // Ensure empty state is reset if data exists
        }

        openSnackbar("Data fetched successfully", "success");
      } catch (error) {
        openSnackbar("Failed to fetch data", "error");
      } finally {
        setIsLoading(false);
      }
    };

    fetchUnknownVehicleData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authHeader, gogigReqId]);

  return (
    <Box sx={{ backgroundColor: "#f4f6f8", minHeight: "100vh", py: 4 }}>
      <GlobalSnackbar
        open={snackbar.open}
        message={snackbar.message}
        onClose={closeSnackbar}
        severity={snackbar.severity}
        vertical={"top"}
        horizontal={"center"}
      />
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "#fff",
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Tabs
          value={tabIndex}
          onChange={(e, newIndex) => setTabIndex(newIndex)}
          centered
          sx={{
            mb: 3,
            "& .MuiTabs-flexContainer": { justifyContent: "center" },
            "& .MuiTab-root": {
              fontWeight: 600,
              color: "#555",
              textTransform: "capitalize",
            },
            "& .Mui-selected": { color: "#1976d2" },
            "& .MuiTabs-indicator": { backgroundColor: "#1976d2" },
          }}
        >
          <Tab label="Color" />
          <Tab label="Model" />
          <Tab label="Manufacturer" />
          <Tab label="Vehicle Class" />
        </Tabs>
      </Box>

      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
          <CircularProgress size={50} />
        </Box>
      ) : isEmpty ? ( // Check for empty state
        <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
          <Typography variant="h6" color="textSecondary">
            No Unknown Flags Detected
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                backgroundColor: "#fff",
                borderRadius: 2,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                p: 3,
                mb: 3,
              }}
            >
              {tabIndex === 0 &&
                (unknownColors.length > 0 ? (
                  unknownColors.map((vehicle) => (
                    <UnknownVehicleDataFields
                      key={vehicle.vehicleAuditId}
                      vehicleData={vehicle}
                      property="color"
                      sourceValue={vehicle.colorFromSource}
                      allowMultipleSelection={true}
                      gogigReqId={gogigReqId}
                    />
                  ))
                ) : (
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{ textAlign: "center" }}
                  >
                    No unknown colors found.
                  </Typography>
                ))}

              {tabIndex === 1 &&
                (unknownModels.length > 0 ? (
                  unknownModels.map((vehicle) => (
                    <UnknownVehicleDataFields
                      key={vehicle.vehicleAuditId}
                      vehicleData={vehicle}
                      property="model"
                      sourceValue={vehicle.makerModelFromSource}
                      gogigReqId={gogigReqId}
                    />
                  ))
                ) : (
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{ textAlign: "center" }}
                   
                  >
                    No unknown models found.
                  </Typography>
                ))}

              {tabIndex === 2 &&
                (unknownManufacturers.length > 0 ? (
                  unknownManufacturers.map((vehicle) => (
                    <UnknownVehicleDataFields
                      key={vehicle.vehicleAuditId}
                      vehicleData={vehicle}
                      property="manufacturer"
                      sourceValue={vehicle.manufacturerFromSource}
                      gogigReqId={gogigReqId}
                    />
                  ))
                ) : (
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{ textAlign: "center" }}
                  >
                    No unknown manufacturers found.
                  </Typography>
                ))}

              {tabIndex === 3 &&
                (unknownVehicleClasses.length > 0 ? (
                  unknownVehicleClasses.map((vehicle) => (
                    <UnknownVehicleDataFields
                      key={vehicle.vehicleAuditId}
                      vehicleData={vehicle}
                      property="vehicleClass"
                      sourceValue={vehicle.vehicleClass}
                      gogigReqId={gogigReqId}
                    />
                  ))
                ) : (
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{ textAlign: "center" }}
                  >
                    No unknown vehicle classes found.
                  </Typography>
                ))}
            </Box>
          </Grid>
          <UnknownVehicleDataInstructions tabIndex={tabIndex} />
        </Grid>
      )}
    </Box>
  );
};

export default UnknownVehicleDataUI;
